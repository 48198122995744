import React from "react";
import { Modal, Button } from "react-bootstrap";

const MobileConfirmModal = ({ show, onHide, onConfirm }) => {
  return (
    <Modal show={show} onHide={onHide}>
      <Modal.Header closeButton>
        <Modal.Title>Confirm Mobile Login</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>Would you like to add an Mobile login as well?</p>
      </Modal.Body>
      <Modal.Footer>

      <div className="d-flex justify-content-end w-100">
        <button className="theme-buttonOuteline btn btn-primary me-2 "  style={{ minWidth: "150px", padding: "10px" }} onClick={onHide}>
          No
        </button>
        <button     className="theme-button btn btn-primary"
              style={{ minWidth: "150px", padding: "10px" }} onClick={onConfirm}>
          Yes
        </button>
        </div>
      </Modal.Footer>
    </Modal>
  );
};

export default MobileConfirmModal;
