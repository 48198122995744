import React from "react";
import { Modal, Button, Spinner } from "react-bootstrap";

const ConfirmEmailLoginModal = ({ show, onHide, onConfirm,loading }) => {
  return (
    <Modal show={show} onHide={onHide}>
      <Modal.Header closeButton>
        <Modal.Title>Confirm Email Login</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>Would you like to add an email login as well?</p>
      </Modal.Body>
      <Modal.Footer>

      <div className="d-flex justify-content-end w-100">
        <button className="theme-buttonOuteline btn btn-primary me-2 "  style={{ minWidth: "150px", padding: "10px" }} onClick={onHide}>
          No
        </button>
        <button     className="theme-button btn btn-primary"
              style={{ minWidth: "150px", padding: "10px" }} onClick={onConfirm}>
                {loading ? (
          <div className="d-flex align-items-center">
            <Spinner animation="border" size="sm" role="status" className="me-2" />
          </div>
        ) : "Yes"}
        </button>
        </div>
      </Modal.Footer>
    </Modal>
  );
};

export default ConfirmEmailLoginModal;
