import React, { useEffect, useState } from 'react'
import '../../assets/css/home.css'
import Layout from '../../layout'
import TopBar from '../../components/TopBarNavigation'
import { Row, Col, Form, Button } from 'react-bootstrap';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import isEmail from 'validator/lib/isEmail';
import { get, getAuthConfig, getAuthConfigImage, post } from '../../libs/http-hydrate';
import { toast, ToastContainer } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css';
import GeoLocation from '../../common/GeoLocation';
import doneImg from "../../assets/images/doneImg.png";
import Modal from 'react-bootstrap/Modal';
import Animation from '../../Animation/Animation';

const ContactInformation = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [completeWorkout, setCompleteWorkout] = useState(false);
  const [contactInformation, setContactInformation] = useState({
    emergencyNumber: "",
    address: "",
    state: "",
    city: "",
    country: "",
    pincode: ""
  });
  const [countryLoading, setCountryLoading] = useState(false);
  const [stateLoading, setStateLoading] = useState(false);
  const [cityLoading, setCityLoading] = useState(false);
  const [countriesFetched, setCountriesFetched] = useState(false);
  const [countries, setCountries] = useState([]);
  const [states, setStates] = useState([]);
  const [cities, setCities] = useState([]);

  const [loading, setLoading] = useState(false)
  const [country, setCountry] = useState("");
  let [state, setstate] = useState("");
  let [city, setCity] = useState("");


  async function getCountrie() {
    if (countriesFetched) {
      return; // Countries have already been fetched
    }
    try {
      setCountryLoading(true);
      const data = await get("/country", getAuthConfig());
      setCountries(data?.data?.data);
      setCountriesFetched(true); // Update the state to indicate countries have been fetched

    } catch (error) {
      console.error(error);
    } finally {
      setCountryLoading(false);
    }
  }

  async function handleCountryChange(event) {
    const countryCode = event.target.value;
    setCountry(countryCode);

    if (countryCode) {
      try {
        setStateLoading(true);
        const response = await get(`/state?countryCode=${countryCode}`, getAuthConfig());
        setStates(response?.data?.data);
        setstate('');
        setContactInformation((p) => ({ ...p, state: "" }));
      } catch (error) {
        console.error(error);
      } finally {
        setStateLoading(false);
      }
    } else {
      setStates([]);
      setstate('');
    }
  }

  async function handleStateChange(event) {
    const stateCode = event.target.value;
    setstate(stateCode);

    if (country && stateCode) {
      try {
        setCityLoading(true);
        const response = await get(`/city?countryCode=${country}&stateCode=${stateCode}`, getAuthConfig());
        setCities(response?.data?.data);
        setContactInformation((p) => ({ ...p, city: "" }));
      } catch (error) {
        console.error(error);
      } finally {
        setCityLoading(false);
      }
    } else {
      setCities([]);
    }
  }

  async function savePatientData() {
    const operationalInformation = location?.state?.operationalInformation;

    // const obj3 = { ...location?.state?.personalInformation, ...location?.state?.operationalInformation, ...contactInformation }; // { a: 1, b: 2, c: 3, d: 4, e: 5, f: 6 }

      // Copy contactInformation to avoid directly modifying the state
  const modifiedContactInformation = { ...contactInformation };

  // Add '+' sign before emergencyNumber if it has data
  if (modifiedContactInformation.emergencyNumber && !modifiedContactInformation.emergencyNumber.startsWith('+')) {
    modifiedContactInformation.emergencyNumber = '+' + modifiedContactInformation.emergencyNumber;
  }

  const obj3 = {
    ...location?.state?.personalInformation,
    ...location?.state?.operationalInformation,
    ...modifiedContactInformation,
  };
      // const obj3 = {
      //   ...location?.state?.personalInformation,
      //   ...location?.state?.operationalInformation,
      //   ...contactInformation,
      // };


    try {
      setLoading(true)
      const response = await post("/addPatient", obj3, getAuthConfigImage()).then((data) => {
        if (data?.status === 200) {
          // toast.success("Patient Added successfully");

            setCompleteWorkout(true)
          // setTimeout(() => {
          //   navigate("/allPatient")
          //   setLoading(false)

          // }, 2000)
        }
      }).catch((err) => {
        setLoading(false)
        toast.error(err?.response?.data?.err);
      })
    } catch (error) {
      setLoading(false)
      toast.error(error);
    }
  }
  useEffect(() => {
    if (location?.state?.contactInformation) {
      setContactInformation(location?.state?.contactInformation);
    }
    // You might also want to handle operationalInformation if needed
  }, [location.state]);
  return (
    <>

      <Layout>
        <ToastContainer />
        <TopBar   backLinkData={{
    personalInformation: location?.state?.personalInformation,
    operationalInformation: location?.state?.operationalInformation,
    contactInformation: contactInformation // Assuming this is a variable with the current state
  }} backlink={"/operationalInformation"}  title={"Contact Information"} tab={"Add Exercises"} tabChild={"Add Patient"} />
        <div className='main-content-part'>
          <div className='form-data full-width-data'>
            {/* <h5 className='title'>Contact Information</h5> */}
            <Row className='g-4'>
              <Col lg="6">
                <div className='filed'>
                  <label>Address</label>
                  <input type='text' className='input-field' placeholder='Address line 1' value={contactInformation?.street} onChange={(e) => {
                    e.preventDefault();
                    setContactInformation((p) => ({ ...p, street: e.target.value }))
                  }} />
                </div>
                {/* <div className='filed'>
                  <input type='text' className='input-field' placeholder='Address line 2' />
                </div> */}
              </Col>
              <Col lg="6">


                <Row>

                  <Col lg="6">
                    <div className="filed" >
                      <label>Country  </label>
                      <select className="form-control form-select  " value={contactInformation?.country} onChange={(e) => {
                        handleCountryChange(e)
                        const selectedCountry = countries.find(
                          (c) => c.isoCode === e.target.value
                        );
                        if (selectedCountry) {
                          setContactInformation((prevData) => ({
                            ...prevData,
                            country: selectedCountry.name,
                          }));
                        }
                      }} onClick={(e) => {
                        getCountrie()
                      }}>
                        <option value="">{contactInformation?.country ? contactInformation?.country: "Select Country"}</option>
                        {countryLoading ? (
                          <option disabled>Loading...</option>
                        ) : (
                          countries.map((country) => (
                            <option
                              key={country?.code}
                              value={country?.isoCode}
                            >
                              {country?.name}
                            </option>
                          ))
                        )}

                      </select>
                      {/* <GeoLocation
                locationTitle="Country"
                isCountry
                name="country" value={contactInformation?.country} onChange={(e) =>{

                  setContactInformation((p) =>({...p,country:e?.label}))
                  setCountry(e?.value)
                } }
              /> */}

                    </div>
                  </Col>
                  <Col lg="6" >
                    <div className="filed" >
                      <label>State  </label>
                      <select className="form-control form-select " value={contactInformation?.state} onChange={(e) => {
                        handleStateChange(e)
                        const selectedStates = states.find(
                          (c) => c.isoCode === e.target.value
                        );
                        if (selectedStates) {
                          setContactInformation((prevData) => ({
                            ...prevData,
                            state: selectedStates.name,
                          }));
                        }
                      }}>
                        <option value="">{contactInformation?.state ? contactInformation?.state: "Select State"}</option>
                        {stateLoading ? (
                          <option disabled>Loading...</option>
                        ) : (
                          states.map((state) => (
                            <option
                              key={state?.code}
                              value={state?.isoCode}
                            >
                              {state?.name}
                            </option>
                          ))
                        )}
                      </select>
                      {/* <GeoLocation
                locationTitle="State"
                geoId={country}
                name="country" value={contactInformation?.country} onChange={(e) =>{
                  setContactInformation((p) =>({...p,state:e?.label}))
                  setstate(e?.value)
                } }
              /> */}

                    </div>
                  </Col>
                  <Col lg="6" >
                    <div className="filed" >
                      <label>City  </label>

                      <select className="form-control form-select" value={contactInformation?.city} onChange={(e) => {
                        setContactInformation((p) => ({ ...p, city: e?.target?.value }))
                      }}>
                        <option value="">{contactInformation?.city ? contactInformation?.city: "Select City"}</option>
                        {cityLoading ? (
                          <option disabled>Loading...</option>
                        ) : (
                          cities.map((city) => (
                            <option key={city?.code} value={city?.code}>
                              {city?.name}
                            </option>
                          ))
                        )}
                      </select>
                      {/* <GeoLocation
                locationTitle="City"
                onChange={(e) =>{
                  setContactInformation((p) =>({...p,city:e?.lable}))
                }}
                geoId={state}
              /> */}

                    </div>
                  </Col>
                  <Col lg="6" >


                    <div className="filed" >
                      <label>Pincode</label>

                      <input
  type="text"
  className="input-field form-control"
  placeholder="Pincode"
  name="zip"
  value={contactInformation?.pincode || ""}
  onChange={(e) => {
    const value = e.target.value;
    // Regular expression to allow only numeric values
    if (/^[0-9]*$/.test(value)) {
      setContactInformation((p) => ({ ...p, pincode: value }));
    }
  }}
/>


                    </div>
                  </Col>
                </Row>
              </Col>
            </Row>
            <div className='buttonPart mt-5 d-flex justify-content-center'>

              <Button className='theme-button'
                disabled={loading}
                onClick={(e) => {
                  e.preventDefault();
                  savePatientData()
                }}> {loading ? "Saving Details" :"Done"}</Button>
            </div>
          </div>
        </div>

        <Modal
                    show={completeWorkout}
                    onHide={() => setCompleteWorkout(false)}
                    size="md"
                    className="complete-modal"
                >
                    <Modal.Body>
                        <div className="top-heading text-end">
                            <button
                                className="btn btn-transpatent"
                                onClick={() => setCompleteWorkout(false)}
                            >
                                X
                            </button>
                        </div>
                        <div className="content-block text-center">
                <Animation />
            </div>
                        <div className="bottom-block text-center">
                            <div className="workout-desc body-content" style={{
                                "display": "flex",
                                "align-items": "center",
                                "justify-content": "center",
                                "flex-direction": "column",
                                "padding-top": "25px"
                            }}>
                                <p>Patient Added successfully</p>

                                <button className="theme-button btn btn-primary" style={{ minWidth: "300px", padding: "14px" }} onClick={(e) => {
                                    e.preventDefault();
                                    navigate("/allPatient")
                                }}>Done</button>
                            </div>
                        </div>
                    </Modal.Body>
                </Modal>
      </Layout>
    </>
  )
}

export default ContactInformation
