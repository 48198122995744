import React, { useEffect, useRef, useState } from "react";
import "../../assets/css/login.css";
import doctorsImg from "../../assets/images/Group 3038.svg";
import { AiOutlineClose } from "react-icons/ai";
import IndiaFlag from "../../assets/images/country-flag.svg";
import { BiSolidDownArrow } from "react-icons/bi";
import { Modal, Badge } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import TruecalloerLogo from "../../assets/images/Truecaller_Logo.png";
import ReactFlagsSelect from "react-flags-select";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { get, getAuthConfig, post, put } from "../../libs/http-hydrate";
import Auth from "../../libs/auth";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import FieldValidationError from "../../common/FieldValidation";
import logo from "../../assets/images/RecureMe.svg";

const Email = ({ mobile, setMobile }) => {
  const navigate = useNavigate();
  const [showModal, setModalShow] = useState(false);
  const [selected, setSelected] = useState("IN");
  const [name, setName] = useState(""); // Add this line for the new name state
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [country, setCountry] = useState("");

  // const [mobile, setMobile] = useState("");
  const [otpDigits, setOtpDigits] = useState(["", "", "", "", "", ""]); // Array to store individual OTP digits
  const [isLoading, setLoading] = useState(false); // Add a loading state
  const [isLoadingVer, setLoadingVer] = useState(false); // Add a loading state
  const [timerSeconds, setTimerSeconds] = useState(15 * 60); // 30 minutes in seconds
  const [isTimerRunning, setTimerRunning] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [countriesFetched, setCountriesFetched] = useState(false);
  const [countryLoading, setCountryLoading] = useState(false);
  const [countries, setCountries] = useState([]);

  const [otperrorMessage, setOTPErrorMessage] = useState("");

  const otpInputRefs = useRef([]);
  const closeModal = () => setModalShow(false);

  const submitOTP = () => {
    navigate("/dashboard");
  };
  const emailRef = useRef(null);
  const passwordRef = useRef(null);
  const confirmPasswordRef = useRef(null);
  const mobileInputRef = useRef(null);
  const nameInputRef = useRef(null);
  const countryInputRef = useRef(null);

  const [error, seterror] = useState({
    name: false,
    email: false,
    password: false,
    confirmPassword: false,
    emailOrMobile: false,
    country: false,
  });

  const validatePassword = (password) => {
    const minLength = 8;
    const hasUpperCase = /[A-Z]/.test(password);
    const hasLowerCase = /[a-z]/.test(password);
    const hasNumbers = /[0-9]/.test(password);
    const hasSpecialChar = /[!@#$%^&*(),.?":{}|<>]/.test(password);

    return password.length >= minLength && hasUpperCase && hasLowerCase && hasNumbers && hasSpecialChar;
  };

  const handleFieldError = (fieldRef) => {
    const inputField = fieldRef?.current?.querySelector("input");
    if (inputField) {
      inputField.focus(); // Focus on the input field
      fieldRef.current.scrollIntoView({ behavior: "smooth" });
    } else {
      const selectField = fieldRef?.current?.querySelector("select");
      if (selectField) {
        selectField.classList.add("field-error"); // Add the error class to the select field
        fieldRef.current.scrollIntoView({ behavior: "smooth" });
      }
    }
  };

  const resetTimer = () => {
    setTimerSeconds(15 * 60); // Reset timer to 15 minutes
    setTimerRunning(true); // Start the timer
  };
  useEffect(() => {
    if (Auth?.getCurrentUser()) {
      navigate("/dashboard");
    }
  }, []);
  async function signUpWithEmail() {
    if (!name.trim()) {
      seterror((p) => ({ ...p, name: true }));
      handleFieldError(nameInputRef);
      return;
    }
    if (!email && !mobile) {
      seterror((p) => ({ ...p, email: true }));
      handleFieldError(emailRef);
      return;
    }
    // Email format validation using regex
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(email)) {
      seterror((p) => ({ ...p, email: true }));
      handleFieldError(emailRef);
      return;
    }
    if (!password) {
      seterror((p) => ({ ...p, password: true }));
      handleFieldError(passwordRef);
      return;
    }
    if (!validatePassword(password)) {
      seterror((p) => ({ ...p, password: true }));
      handleFieldError(passwordRef);
      return;
    }
    if (!country) {
      seterror((p) => ({ ...p, country: true }));
      handleFieldError(countryInputRef);
      return;
    }
    // if (!confirmPassword) {
    //   seterror((p) => ({ ...p, confirmPassword: true }));
    //   handleFieldError(confirmPasswordRef);
    //   return;
    // }
    // // Check if the password and confirmPassword match
    // if (password !== confirmPassword) {
    //   seterror((p) => ({ ...p, confirmPassword: true }));
    //   handleFieldError(confirmPasswordRef);
    //   return;
    // }

    const formdata = new FormData();
    formdata.append("name", name);
    formdata.append("email", email);
    formdata.append("password", password);
    formdata.append("type", "physiotherapist");
    formdata.append("device", "Web");
    formdata.append("country", country);

    // if (mobile != "") {
    //   formdata.append("phone", "+" + mobile);
    // }
    try {
      setLoading(true);

      const response = await post("/signup", formdata)
        .then((data) => {
          if (data?.status === 200) {
            // toast.success("Registered Sucssfully ");
            //sendOtp();
            setModalShow(true);
            setTimerRunning(true);
          }
        })
        .catch((err) => {
          setErrorMessage(err?.response?.data?.err);
          if (err?.response?.data?.err === "Duplicate email found") {
            seterror((p) => ({ ...p, email: true }));
            handleFieldError(emailRef);
            return false;
          }
          if (err?.response?.data?.err === "Phone Number Already exists") {
            seterror((p) => ({ ...p, phone: true }));
            handleFieldError(mobileInputRef);
            return false;
          }
        })
        .finally(() => {
          setLoading(false); // Set loading to false after API call completion
        });
    } catch (error) {
      toast.error(error);
    }
  }

  async function getCountrie() {
    if (countriesFetched) {
      return; // Countries have already been fetched
    }
    try {
      setCountryLoading(true);
      const data = await get("/country");
      setCountries(data?.data?.data);
      setCountriesFetched(true); // Update the state to indicate countries have been fetched
    } catch (error) {
      console.error(error);
    } finally {
      setCountryLoading(false);
    }
  }
  async function sendOtp() {
    const formdata = new FormData();
    formdata.append("email", email);
    formdata.append("type", "physiotherapist");
    formdata.append("device", "Web");

    try {
      const response = await put("/sendOTPEmail", formdata)
        .then((data) => {
          if (data?.status === 200) {
          }
        })
        .catch((err) => {
          toast.error(err?.response?.data?.err);
        });
    } catch (error) {}
  }

  async function verifyOTP() {
    const isAllFieldsFilled = otpDigits.every((digit) => digit !== "");
    if (!isAllFieldsFilled) {
      setOTPErrorMessage("Please Add Correct OTP");
    }

    const formdata = new FormData();

    formdata.append("type", "physiotherapist");
    formdata.append("email", email);
    formdata.append("OTP", otpDigits.join(""));
    formdata.append("device", "Web");
    try {
      setLoadingVer(true);

      const response = await post("/verifyOTP", formdata)
        .then((data) => {
          if (data?.status === 200) {
            localStorage.setItem(
              "user",
              JSON.stringify({
                email: data?.data?.physiotherapist?.email,
                mobile_number: data?.data?.physiotherapist?.mobile,
                token: data?.data?.physiotherapist?.token,
                profile_image: data?.data?.physiotherapist?.profile_image,
                _id: data?.data?.physiotherapist?._id,
                profileCompleted: data?.data?.physiotherapist?.profileCompleted,
                gender: data?.data?.physiotherapist?.genders,
                name: data?.data?.physiotherapist?.name,
              })
            );
            setTimeout(() => {
              setLoadingVer(false); // Set loading to false after API call completion
              navigate("/dashboard");
            }, 1500);
          }
        })
        .catch((err) => {
          setLoadingVer(false); // Set loading to false after API call completion

          setOtpDigits(["", "", "", "", "", ""]); // Reset OTP digits
          setOTPErrorMessage(err?.response?.data?.err);
          if (otpInputRefs.current[0]) {
            otpInputRefs.current[0].focus();
          }
          // toast.error(err?.response?.data?.err);
        });
      // .finally(() => {
      //   setLoadingVer(false); // Set loading to false after API call completion
      // });
    } catch (error) {}
  }

  // Handle input change for each OTP digit
  const handleOtpChange = (index, value) => {
    // If the user enters a non-digit value or clears the input, set the corresponding digit to an empty string
    const newValue = /^\d$/.test(value) ? value : "";
    setOtpDigits((prevDigits) => {
      const newDigits = [...prevDigits];
      newDigits[index] = newValue;
      return newDigits;
    });

    // Move to the next input field if a digit is entered
    if (/^\d$/.test(value) && otpInputRefs.current[index + 1]) {
      otpInputRefs.current[index + 1].focus();
    }

    setOTPErrorMessage("");
  };

  useEffect(() => {
    let interval;

    if (isTimerRunning) {
      interval = setInterval(() => {
        setTimerSeconds((prevSeconds) => {
          if (prevSeconds === 0) {
            // Timer has reached 0, do something here
            setTimerRunning(false);
            // Maybe close the modal or take any necessary action
            return 0;
          }
          return prevSeconds - 1;
        });
      }, 1000);
    }

    return () => clearInterval(interval); // Clear the interval when the component unmounts
  }, [isTimerRunning]);

  const handlePaste = (e) => {
    e.preventDefault();
    const pastedText = e.clipboardData.getData("text/plain").trim(); // Get the pasted text

    // Split the pasted text into individual characters
    const otpCharacters = pastedText.split("");

    // Loop through the OTP input elements and assign characters
    otpCharacters.forEach((character, index) => {
      if (index < otpDigits.length) {
        // Update the OTP digit at the current index
        handleOtpChange(index, character);
      }
    });
  };
  const handleBackspace = (index, e) => {
    if (e.key === "Backspace" && index > 0 && !otpDigits[index]) {
      const newOtpDigits = [...otpDigits];
      newOtpDigits[index - 1] = ""; // Clear the previous box
      setOtpDigits(newOtpDigits); // Update state if using React hooks or similar state management
      otpInputRefs.current[index - 1].focus(); // Move focus to previous input
    } else if (e.key === "Enter") {
      // Trigger OTP verification when Enter key is pressed
      const isAllFieldsFilled = otpDigits.every((digit) => digit !== "");
      if (isAllFieldsFilled) {
        verifyOTP();
      } else {
        // Handle the case when all fields are not filled
        setOTPErrorMessage("Please fill all the OTP digits");
      }
    }
  };

  return (
    <>
      <ToastContainer />
      <div className="content">
        <div className="header-part text-center w-100 gap-0">
          <h4 className="mb-0" style={{whiteSpace:"nowrap"}}>Sign Up Now </h4>
          <p>No commitment. No hidden fees.</p>
          <div className="d-flex mt-3 justify-content-center">
            <div className="px-2">
              <label style={{ color: "#f36f49", cursor: "pointer" }}>
                <input
                  type="radio"
                  name="loginOption"
                  value="email"
                  checked={!mobile}
                  onChange={() => setMobile(false)}
                />
                &nbsp; Email ?
              </label>
            </div>
            <div className="px-2">
              <label style={{ color: "#f36f49", cursor: "pointer" }}>
                <input
                  type="radio"
                  name="loginOptionMo"
                  value="email"
                  checked={mobile}
                  onChange={() => setMobile(true)}
                  style={{ color: "#23BEE3" }}
                />
                &nbsp; Mobile ?
              </label>
            </div>
          </div>
        </div>
        <div className="middle-form" style={{ maxWidth: "100%" }}>
          <div className="filed w-100" ref={nameInputRef}>
            <input
              type="text"
              className={`form-control w-100 ${
                error?.name ? "field-error" : ""
              }`}
              placeholder="Enter your name"
              value={name}
              onChange={(e) => {
                setName(e.target.value);
                // Reset name error if needed
                seterror((prevErrors) => ({
                  ...prevErrors,
                  name: false,
                }));
                // Clear any specific error message for name if needed
              }}
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  e.preventDefault(); // Prevent default Enter behavior
                  signUpWithEmail(); // Manually trigger form submission logic
                }
              }}
            />
            {/* Conditionally render error message for name if needed */}
            {error?.name && (
              <FieldValidationError message="Please enter a valid name" />
            )}
          </div>
          <div className="filed w-100" ref={emailRef}>
            <input
              type="email"
              className={`form-control w-100 ${
                error?.email ? "field-error" : ""
              }`}
              placeholder="Enter your email"
              value={email}
              onChange={(e) => {
                setEmail(e.target.value);
                seterror((p) => ({ ...p, email: false }));
                setErrorMessage("");
              }}
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  e.preventDefault(); // Prevent default Enter behavior
                  signUpWithEmail(); // Manually trigger form submission logic
                }
              }}
            />
            {error?.email ? (
              <FieldValidationError message="Please enter valid email" />
            ) : (
              ""
            )}
          </div>
          <div className="filed w-100" ref={countryInputRef}>
            <select
              className={`form-control form-select   ${
                error?.country ? "field-error" : ""
              }`}
              onChange={(e) => {
                const selectedCountry = countries.find(
                  (c) => c.isoCode === e.target.value
                );
                if (selectedCountry) {
                  setCountry(selectedCountry.name);
                }
                seterror((p) => ({ ...p, country: false }));
              }}
              onClick={(e) => {
                getCountrie();
              }}
            >
              <option value={""}>Please select Country</option>
              {countryLoading ? (
                <option disabled>Loading...</option>
              ) : (
                countries.map((country) => (
                  <option key={country?.code} value={country?.isoCode}>
                    {country?.name}
                  </option>
                ))
              )}
            </select>
            {error?.country && (
              <FieldValidationError message="Please select country" />
            )}
          </div>
          <div className="filed w-100" ref={passwordRef}>
            <input
              type="password"
              className={`form-control w-100 ${
                error?.password ? "field-error" : ""
              }`}
              placeholder="Enter your Password"
              value={password}
              onChange={(e) => {
                setPassword(e.target.value);
                seterror((p) => ({ ...p, password: false }));
                setErrorMessage("");
              }}
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  e.preventDefault(); // Prevent default Enter behavior
                  signUpWithEmail(); // Manually trigger form submission logic
                }
              }}
            />
            {error?.password ? (
              <FieldValidationError message="Password must be at least 8 characters long, contain uppercase and lowercase letters, a number, and a special character." />
            ) : (
              ""
            )}
          </div>
          {/* <div className="filed w-100" ref={confirmPasswordRef}>
            <input
              type="password"
              placeholder="Confirm Password"
              className={`form-control w-100 ${
                error?.confirmPassword ? "field-error" : ""
              }`}
              value={confirmPassword}
              onChange={(e) => {
                setConfirmPassword(e.target.value);
                seterror((p) => ({ ...p, confirmPassword: false }));
                setErrorMessage("");
              }}
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  e.preventDefault(); // Prevent default Enter behavior
                  signUpWithEmail(); // Manually trigger form submission logic
                }
              }}
            />
            {error?.confirmPassword ? (
              <FieldValidationError message="Password and confirm password are not matched " />
            ) : (
              ""
            )}
          </div> */}
          {errorMessage && <FieldValidationError message={errorMessage} />}

          <p className="info-text">
            By signing up you are agree to our{" "}
            <a
              href="https://virtuelife.ai/terms/"
              target="_blank"
              rel="noreferrer"
            >
              Terms of Use
            </a>{" "}
            and{" "}
            <a
              href="https://virtuelife.ai/privacy/"
              target="_blank"
              rel="noreferrer"
            >
              Privacy Policy
            </a>
          </p>
        </div>
        <div className="action-part">
          <button
            className="btn btn-orange"
            disabled={isLoading}
            onClick={(e) => {
              e.preventDefault();
              signUpWithEmail();
            }}
          >
            Signup
          </button>

          <div className="text-center w-100 mt-3 d-flex justify-content-center">
            <p className="text-secondary m-0">Already have an account?</p>
              &nbsp;
            <p
              className="info-text"
              style={{
                color: "#ff6f48",
                fontWeight: "500",
                cursor: "pointer",
              }}
              onClick={(e) => {
                e.preventDefault();
                navigate("/login", { state: { email: "true" } });
                // sendOtp();
              }}
            >
              Login 
            </p>
          </div>
        </div>
      </div>
      <Modal show={showModal} onHide={closeModal} centered>
        <Modal.Body style={{ border: "none" }}>
          <div className="content-modal">
            <div className="top-btn-part">
              <button className="btn close-btn" onClick={closeModal}>
                <AiOutlineClose size={20} color="#000" />
              </button>
            </div>
            <div className="modal-inside-content">
              <h5 className="heading">
                Authentication Code Sent to your Email
              </h5>
              <p className="desc-text">
                6 digit Authentication Code has sent to your Email{" "}
              </p>
              <p className="mobileno" style={{ cursor: "pointer" }}>
                {email}{" "}
                <Badge
                  pill
                  className="edit-badge"
                  onClick={(e) => {
                    e.preventDefault();
                    setModalShow(false);
                  }}
                >
                  Edit
                </Badge>
              </p>
              <div className="otp-input-group" onPaste={handlePaste}>
                {otpDigits.map((digit, index) => (
                  <input
                    key={index}
                    type="text"
                    maxLength={1}
                    value={digit}
                    autoFocus={index === 0}
                    onChange={(e) => handleOtpChange(index, e.target.value)}
                    onKeyDown={(e) => handleBackspace(index, e)}
                    ref={(el) => (otpInputRefs.current[index] = el)} // Store refs to OTP input elements
                  />
                ))}
              </div>
              {otperrorMessage && (
                <FieldValidationError message={otperrorMessage} />
              )}
              <button className="btn-continue" onClick={verifyOTP}>
                {isLoadingVer ? "Verifying OTP" : "Continue"}
              </button>
              <span className="notreceive-text">
                Haven't you received the Email?
              </span>
              <button
                className="resend-btn"
                onClick={(e) => {
                  e.preventDefault();
                  sendOtp();
                  resetTimer(); // Call resetTimer to restart the 15-minute countdown
                }}
                disabled={timerSeconds !== 0} // Disable button until timer reaches 0
                style={{
                  // Prevents hover and click
                  cursor: timerSeconds !== 0 ? "not-allowed" : "pointer",
                }}
              >
                Resend Code
              </button>
              <p className="timer">
                {timerSeconds > 0
                  ? `${Math.floor(timerSeconds / 60)}:${(timerSeconds % 60)
                      .toString()
                      .padStart(2, "0")}`
                  : "You can now resend the code"}
              </p>{" "}
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default Email;
