import React, { useState } from "react";
import { Modal, Button, Form } from "react-bootstrap";

const EmailLoginModal = ({ show, onHide, onSubmit , data , setData ,errorMessage, successMessage  }) => {
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [error, setError] = useState("");

  const handleSubmit = () => {
    if (data?.password !== confirmPassword) {
      setError("Passwords do not match");
      return;
    }
    onSubmit(password);
  };

  return (
    <Modal show={show} onHide={onHide}>
      <Modal.Header closeButton>
        <Modal.Title>Set Email Login Password</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form.Group>
          <Form.Label>Password</Form.Label>
          <Form.Control
            type="password"
            value={data?.password}
            onChange={(e) => setData((p) =>({...p,password:e.target.value}))}
          />
        </Form.Group>
        <Form.Group>
          <Form.Label>Confirm Password</Form.Label>
          <Form.Control
            type="password"
            value={confirmPassword}
            onChange={(e) => setConfirmPassword(e.target.value)}
          />
        </Form.Group>
        {error && <p style={{ color: "red" }}>{error}</p>}
        {errorMessage && <p className="text-danger mt-2">{errorMessage}</p>}

        {/* Display success message */}
        {successMessage && <p className="text-success mt-2">{successMessage}</p>}
     
      </Modal.Body>
      <Modal.Footer>
        <div className="d-flex justify-content-end w-100">
        <button className="theme-buttonOuteline btn btn-primary me-2 "  style={{ minWidth: "150px", padding: "10px" }} onClick={onHide}>
          Cancel
        </button>
        <button     className="theme-button btn btn-primary"
              style={{ minWidth: "150px", padding: "10px" }} onClick={handleSubmit}>
          Submit
        </button>
        </div>
      </Modal.Footer>
    </Modal>
  );
};

export default EmailLoginModal;
