import { useLocation, useNavigate } from "react-router-dom";
import doneImg from "../assets/images/doneImg.png";
import { useState } from "react";
import Layout from "../layout";
import { get, getAuthConfig, post } from "../libs/http-hydrate";
import { UseEffectOnce } from "../Hook/UseEffectOnce";
import Auth from "../libs/auth";
import Animation from "../Animation/Animation";

function PaymentCompletedCredit() {
  const navigate = useNavigate();
  const [subscribeId, setSubscribeId] = useState("");
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [message, setMessage] = useState("");
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const sessionId = queryParams.get('session_id');
  const qty = queryParams.get('qty');
  UseEffectOnce(() =>{
    getHomeData()
  },[])

  async function getHomeData() {
    try {
      setLoading(true);
      const dataToSend={
        sessionId:sessionId,
        patientCountToAdd:qty
      }
      const data = await post("/payment-status-verify/credit",dataToSend, getAuthConfig());
      if (data?.status === 200) {
        setLoading(false);
        setSubscribeId(data?.data?.subscriptionId)
        setMessage(data?.data?.message || "");
      } else {
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);

      if (error?.response?.status === 401) {
        Auth.logout();
        navigate("/login", { replace: true });
      } else {
        setError(error.response?.data?.message || "An error occurred. Please try again later.");
      }
    }
  }

  return (
    <>
      <Layout>
        {loading ? (
          <div className="preloader-whirlpool text-center align-items-center">
            <div className="whirlpool"></div>
            <span>Your Payment is being verified</span>
          </div>
        ) : error ? (
          <div className="text-center">{error}</div>
        ) : (
          <>
          <div className="content-block text-center">
                <Animation />
            </div>
            <div className="bottom-block text-center">
              <div
                className="workout-desc body-content"
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  flexDirection: "column",
                  paddingTop: "25px",
                }}
              >
                 {message && <p>{message}</p>}

                <p>Payment Done</p>
                <span>Thank you for Choosing us</span>
                <button className="btn btn-outline-secondary my-3">
                 {subscribeId &&  "Order Id : " + subscribeId}
                </button>
                <button
                  className="theme-button btn btn-primary"
                  style={{ minWidth: "300px", padding: "14px" }}
                  onClick={(e) => {
                    e.preventDefault();
                    navigate("/dashboard");
                  }}
                >
                  Done
                </button>
              </div>
            </div>
          </>
        )}
      </Layout>
    </>
  );
}

export default PaymentCompletedCredit;
