import React, { useEffect, useRef, useState } from "react";
import "../../assets/css/login.css";
import doctorsImg from "../../assets/images/Group 3038.svg";
import { AiOutlineClose } from "react-icons/ai";
import IndiaFlag from "../../assets/images/country-flag.svg";
import { BiSolidDownArrow } from "react-icons/bi";
import { Modal, Badge } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import TruecalloerLogo from "../../assets/images/Truecaller_Logo.png";
import ReactFlagsSelect from "react-flags-select";
import FieldValidationError from "../../common/FieldValidation";
import Auth from "../../libs/auth";
import isEmail from "validator/lib/isEmail";
import { getAuthConfig, post } from "../../libs/http-hydrate";
import logo from '../../assets/images/RecureMe.svg'
import { Col, Row } from "react-bootstrap";
import include from "../../assets/images/include.png"

const ForgotPassword = () => {
  const [inputErrors, setInputErrors] = useState({
    password: false,
    confirmPassword: false,
    common:false
  });
  const [timerSeconds, setTimerSeconds] = useState(15 * 60);
  const passwordInputRef = useRef(null);
  const confirmpasswordInputRef = useRef(null);
  const [isLoadingVer, setLoadingVer] = useState(false); // Add a loading state
  const [loginData, setLoginData] = useState({
    email: "",
    password: "",
    password: "",
    confirmPassword: "",
  });
  const [loginError, setLoginError] = useState({
    email: false,
    password: false,
    emailError:""
  });
  const [showModal, setModalShow] = useState(false);
  const [passwordmodal, setPassowrdModalShow] = useState(false);
  const [forgotPasswordMode, setForgotPasswordMode] = useState(false);
  const [err, setErr] = useState(false);
  const navigate = useNavigate();
  async function Login() {
    if (!loginData.email.trim() ) {
      setLoginError({
        email: !loginData.email.trim(),
      });
      setErr(false);
      return;
    }

     // Check if email is valid
     if (!isEmail(loginData.email.trim())) {
      setLoginError({
        email: true,
        password: false,
      });
      setErr(false);
      return;
    }
    
    if (!loginData.password.trim()){
      setLoginError({
        password: !loginData.password.trim(),
      });
      setErr(false);
      return;
    }

   
    const formdata = new FormData();
    formdata.append("email", loginData?.email);
    formdata.append("password", loginData?.password);
    formdata.append("type", "physiotherapist");

    try {
      const response = await Auth.login(formdata);
      if (response?.status === 200) {
        // if (response?.is_verified === false) {
        //   navigate("/profileData")
        // }

        // else {
          navigate("/dashboard")
        // }

      } else {
        setErr(true);
        if(response?.data?.err === "Email is not registered")
        {
          setLoginError({ ...loginError, emailError: "Email is not registered" });

        }
        else{
          setLoginError({ ...loginError, email: "Invalid Email or Password" });
        }
      }
    } catch (error) {}
  }
  const [otpDigits, setOtpDigits] = useState(["", "", "", "", "", ""]);
  const otpInputRefs = useRef([]);
  const [otperrorMessage, setOTPErrorMessage] = useState("");
  const closeModal = () => setModalShow(false);
  const closePassowordModal = () => setForgotPasswordMode(false);
  const [isOtpLoading, setOtpLoading] = useState(false);
  const [isResetPasswordLoading, setResetPasswordLoading] = useState(false);
  const [userData,setUserData] =useState();
  useEffect(() => {
    let interval;
  
    if (timerSeconds > 0) {
      interval = setInterval(() => {
        setTimerSeconds((prevSeconds) => prevSeconds - 1);
      }, 1000);
    }
  
    return () => clearInterval(interval); // Cleanup interval when component unmounts
  }, [timerSeconds]);
  
  // const sendForgotPasswordRequest = async () => {
  //   if (!isEmail(otpData.email)) {
  //     setLoginError({ ...loginError, email: true });
  //     return;
  //   }

  //   setLoading(true);
  //   try {
  //   }catch
  // }
  useEffect(() => {
    if (Auth?.getCurrentUser()) {
      navigate("/dashboard");
    }
  }, []);
  // Function to handle changes in OTP input fields
  const handleOtpInputChange = (index, value) => {
    // If the user enters a non-digit value or clears the input, set the corresponding digit to an empty string
    const newValue = /^\d$/.test(value) ? value : "";
    setOtpDigits((prevDigits) => {
      const newDigits = [...prevDigits];
      newDigits[index] = newValue;
      return newDigits;
    });

    // Move to the next input field if a digit is entered
    if (/^\d$/.test(value) && otpInputRefs.current[index + 1]) {
      otpInputRefs.current[index + 1].focus();
    }
    setOTPErrorMessage("");
  };
  // Function to check if all fields have data and call verifyOTP function
  const handleVerifyOTPs = () => {
    const isAllFieldsFilled = otpDigits.every((digit) => digit !== "");
    if (isAllFieldsFilled) {
      const otp = otpDigits.join(""); // Convert the array of digits to a single OTP string
      handleVerifyOTP(otp); // Call your verifyOTP function with the OTP as a parameter
    } else {
      // Handle the case when all fields are not filled
      // Maybe show an error message or take appropriate action
      setOTPErrorMessage("Please Add Correct OTP");
    }
  };
  const handleVerifyOTP = async (otp) => {
    // Set loading to true when starting the API call
    setLoadingVer(true);

    const isAllFieldsFilled = otpDigits.every((digit) => digit !== "");
    if (!isAllFieldsFilled) {
      setOTPErrorMessage("Please Add Correct OTP");
    }

    const formdata = new FormData();

    formdata.append("type", "physiotherapist");
    formdata.append("email", loginData?.email);
    formdata.append("enteredOTP", otpDigits.join(""));
    formdata.append("device", "Web");


    try {
      setLoadingVer(true);

      const response = await post("/verifyotpforgot", formdata)
        .then((data) => {
          if (data?.status === 200) {

            setUserData(data?.data?.msg)
            // localStorage.setItem(
            //   "user",
            //   JSON.stringify({
            //     email: data?.data?.msg?.email,
            //     mobile_number: data?.data?.msg?.mobile,
            //     token: data?.data?.msg?.token,
            //     profile_image: data?.data?.msg?.profile_image,
            //     _id: data?.data?.msg?._id,
            //   })
            // );
            setPassowrdModalShow(true)
            closeModal()
            // navigate("/profileData", { state: { email: true } })
          }
        })
        .catch((err) => {
          setOtpDigits(["", "", "", "", "", ""]); // Reset OTP digits
          setOTPErrorMessage(err?.response?.data?.err);
          if (otpInputRefs.current[0]) {
            otpInputRefs.current[0].focus();
          }
          // toast.error(err?.response?.data?.err);
        })
        .finally(() => {
          setLoadingVer(false); // Set loading to false after API call completion
        });
    } catch (error) {}
  };
  const handleChange = (e) => {
    const { name, value } = e?.target;
    setLoginData((prevData) => ({
      ...prevData,
      [name]: value,
    }));

    // Update the specific field's error state to false when data is entered
    setInputErrors((prevErrors) => ({
      ...prevErrors,
      [name]: false,
    }));
  };
  async function sendOtp() {
       // Check if email is valid
       setLoginData((p) =>({...p,password:""}))

       if (!isEmail(loginData.email.trim())) {
        setLoginError({
          email: true,
          password: false,
        });
        setErr(false);
        return;
      }
    setOtpLoading(true)
    const formdata = new FormData();
    formdata.append("email", loginData?.email);
    formdata.append("type", "physiotherapist");
    try {
      const response = await post("/forgotPassword", formdata)
        .then((data) => {
          if (data?.status === 200) {
            setModalShow(true);
            setTimerSeconds(15*60)
          }
        })
        .catch((err) => {
          setErr(true);

          if(err?.response?.data?.err === "Email not found in the system")
          {
            setLoginError({ ...loginError, emailError: "Email is not registered" });
  
          }
          else if (err?.response?.data?.err === "Access to the panel has been revoked by the admin. Please contact support@virtuelife.ai for assistance")
          {
            setLoginError({ ...loginError, emailError:"Access to the panel has been revoked by the admin. Please contact support@virtuelife.ai for assistance"  });
          }
          else{
            setLoginError({ ...loginError, email: "Invalid Email or Password" });
          }


          // if(err?.response?.data?.err === "Email not found in the system")
          // setLoginError((p) =>({...p,emailError:}))
          // console.log(err,"emailError")

        });
    } catch (error) {}
    finally{
      setOtpLoading(false)
    }
  }
  const handlePaste = (e) => {
    e.preventDefault();
    const pastedText = e.clipboardData.getData("text/plain").trim(); // Get the pasted text

    // Split the pasted text into individual characters
    const otpCharacters = pastedText.split("");

    // Loop through the OTP input elements and assign characters
    otpCharacters.forEach((character, index) => {
      if (index < otpDigits.length) {
        // Update the OTP digit at the current index
        handleOtpInputChange(index, character);
      }
    });
  };
  const handleBackspace = (index, e) => {
    if (e.key === "Backspace" && index > 0 && !otpDigits[index]) {
      const newOtpDigits = [...otpDigits];
      newOtpDigits[index - 1] = ""; // Clear the previous box
      setOtpDigits(newOtpDigits); // Update state if using React hooks or similar state management
      otpInputRefs.current[index - 1].focus(); // Move focus to previous input
    } else if (e.key === "Enter") {
      // Trigger OTP verification when Enter key is pressed
      const isAllFieldsFilled = otpDigits.every((digit) => digit !== "");
      if (isAllFieldsFilled) {
        handleVerifyOTPs();
      } else {
        // Handle the case when all fields are not filled
        setOTPErrorMessage("Please fill all the OTP digits");
      }
    }
  };
  const handleFieldError = (fieldRef) => {
    const inputField = fieldRef?.current?.querySelector('input')
    if (inputField) {
      inputField.focus(); // Focus on the input field
      fieldRef.current.scrollIntoView({ behavior: "smooth" });
    } else {
      const selectField = fieldRef?.current?.querySelector('select');
      if (selectField) {
        selectField.classList.add('field-error'); // Add the error class to the select field
        fieldRef.current.scrollIntoView({ behavior: "smooth" });
      }
    }
  };

  async function sendVerifyOtp() {
    if(!loginData?.password){
      handleFieldError(passwordInputRef)
      return false
    }
    if(!loginData?.confirmPassword){
      handleFieldError(confirmpasswordInputRef)
      return false;
    }

    if (loginData.password !== loginData.confirmPassword) {
      // handleFieldError(confirmpasswordInputRef)

      setInputErrors({
        ...inputErrors,
        common: true,
      });
      return;
    }
    setResetPasswordLoading(true)
    const formdata = new FormData();
    formdata.append("newPassword", loginData?.password);
    try {
      const response = await post("/resetPassword", formdata,  {headers: {
        'Content-Type': 'application/json',
        Authorization: "Web " + "Physiotherapist " + userData?.token
      }})
        .then((data) => {
          if (data?.status === 200) {
            // setModalShow(true);
            localStorage.setItem(
              "user",
              JSON.stringify({
                email: userData?.email,
                mobile_number: userData?.mobile,
                token: userData?.token,
                profile_image: userData?.profile_image,
                _id: userData?._id,
                profileCompleted:userData?.profileCompleted,
                gender:userData?.gender,
                name: userData?.name
              })
            );
            setTimeout(()=>{
                setResetPasswordLoading(false)

              navigate("/dashboard")
            },1500)

          }
        })
        .catch((err) => {});
    } catch (error) {
        setResetPasswordLoading(false)
    }
  }

  return (
    <>
       
          {/* <button className='close-btn'>
            <AiOutlineClose size={28} />
          </button> */}

<div className='login-main'>
        <div className="content-wrapper">
          <div className="banner-part">
            <div className="banner-content">
            <div>
            <div
              className="container laptop-logo"
              style={{
                paddingLeft: "5rem",
                paddingRight: "5rem",
                marginBottom: "4rem",
              }}
            >
              <img
                src="https://virtuelife.s3.ap-south-1.amazonaws.com/RecureMe/VirtueLifelogo.svg"
                style={{width:"200px"}}
              />
            </div>
            <div className="container main-text"               style={{ paddingLeft: "5rem", paddingRight: "5rem" }}
>
<h3
                style={{
                  color: "#ff6f48",
                  fontWeight: "300",
                  fontSize:"24px"
                }}
                className=""
              >
                {" "}
                <span style={{whiteSpace:"nowrap"}}>Next-Gen Physio Solutions at Your Fingertips</span>
                <br />
                <span style={{fontWeight: "700"}}>Start Your Journey Today! </span>
              </h3>
            </div>
            <div
              className="container text-start "
              style={{ paddingLeft: "5rem", paddingRight: "5rem" }}
            >
               
              <p
                className=""
                style={{
                  fontWeight: "500",
                  fontSize: "16px",
                }}
              >
                What we included ?
              </p>
              <section
                className="include-section"
                style={{ paddingBottom: "1rem" }}
              >
              <div className="">
                  <div
                    className="include-allcontent-section"
                    style={{ marginLeft: "0px" }}
                  >
                    <Row className="justify-content-center">
                      <Col md={12} sm={12}>
                        <div className="include-content-section">
                          <img
                            alt=""
                            className="include-description-container"
                            style={{ width: "26px", height: "26px" }}
                            src={include}
                          />
                          <p
                            className="include-description-text-block"
                            style={{ fontSize: "14px" }}
                          >
                           Transform with our<strong> Next-Gen mobile app </strong> for patients and physiotherapists.
                          </p>
                        </div>
                        <div className="include-content-section">
                          <img
                            alt=""
                            className="include-description-container"
                            style={{ width: "26px", height: "26px" }}
                            src={include}
                          />
                          <p
                            className="include-description-text-block"
                            style={{ fontSize: "14px" }}
                          >
Explore an <strong>advanced library </strong> of expertly designed exercises.
                          </p>
                        </div>
                      </Col>
                      <Col md={12} sm={12}>
                        <div className="include-content-section">
                          <img
                            alt=""
                            className="include-description-container"
                            style={{ width: "26px", height: "26px" }}
                            src={include}
                          />
                          <p
                            className="include-description-text-block"
                            style={{ fontSize: "14px" }}
                          >
Leverage <strong>AI-powered protocols </strong>for effective and personalized care.
                          </p>
                        </div>
                        <div className="include-content-section">
                          <img
                            alt=""
                            className="include-description-container"
                            style={{ width: "26px", height: "26px" }}
                            src={include}
                          />
                          <p
                            className="include-description-text-block"
                            style={{ fontSize: "14px" }}
                          >
                           Assign workouts <strong>digitally</strong> through the mobile app, or share via email or print.

                          </p>
                        </div>
                        <div className="include-content-section">
                          <img
                            alt=""
                            className="include-description-container"
                            style={{ width: "26px", height: "26px" }}
                            src={include}
                          />
                          <p
                            className="include-description-text-block"
                            style={{ fontSize: "14px" }}
                          >
Experience seamless  <strong>digital patient and workout management.</strong>
                          </p>
                        </div>
                        <div className="include-content-section">
                          <img
                            alt=""
                            className="include-description-container"
                            style={{ width: "26px", height: "26px" }}
                            src={include}
                          />
                          <p
                            className="include-description-text-block"
                            style={{ fontSize: "14px" }}
                          >
Unlock a world of <strong>innovative features </strong> designed to elevate your practice.
                          </p>
                        </div>
                      </Col>
                    </Row>
                  </div>
                </div>
              </section>
              {/* <span
                style={{
                  color: "#ff6f48",
                  fontWeight: "500",
                  fontSize: "13px",
                }}
              >
                is my data safe ?
              </span>{" "}
              <br />
              <span style={{ fontSize: "11px" }}>
                Yes , your data is protected please read our{" "}
                <a
                  href="https://virtuelife.ai/privacy/"
                  target="_blank"
                  rel="noreferrer"
                  style={{ textDecoration: "underline", fontWeight: "700" }}
                >
                  Privacy Policy{" "}
                </a>{" "}
              </span> */}

            </div>

            <div className="container main-text" style={{ paddingLeft: "5rem", paddingRight: "5rem",marginTop:"2rem"}} >

            <span style={{fontSize:"11px"}}>
             Copyright  ©  2024 VirtueLife. All rights reserved.
                </span>

                <div style={{fontSize:"10px"}}>
                    <span> 
                    <a
              href="https://virtuelife.ai/terms/"
              target="_blank"
              rel="noreferrer"
            >
              Terms of Use
            </a>
             </span> &nbsp;   | &nbsp;
                    <span>  <a
              href="https://virtuelife.ai/privacy/"
              target="_blank"
              rel="noreferrer"
            >
              Privacy Policy
            </a> </span>

                  </div>
            </div>
          </div>
            </div>
          </div>
          <div className="mobile-logo  text-center py-3">
        <img
                src="https://virtuelife.s3.ap-south-1.amazonaws.com/RecureMe/VirtueLifelogo.svg"
                width={200}
              />
          </div>
          <div className="form-part">
          <div className="content">
            <div className="header-part text-center w-100">
              <h3>Enter your Email</h3>
              <p>We will send you a confirmation code</p>
              

            </div>
            <div className="middle-form">
              <input
                type="text"
                className="form-control"
                value={loginData?.email}
                placeholder="Enter Your Email"
                onChange={(e) => {
                  e.preventDefault();
                  setLoginData((p) => ({ ...p, email: e.target.value }));
                  setLoginError((p) => ({ ...p, email: false }));
                  setLoginError((p) => ({ ...p, emailError: "" }));

                  setErr(false);
                }}

                onKeyDown={(event) => {
                    if (event.key === "Enter") {
                        sendOtp();
                    }
                  }}
              />
              {loginError?.email === true && !loginError?.emailError && (
                <FieldValidationError name="email" />
              )}

             
              {err === true  && !loginError?.emailError ? (
                <FieldValidationError message="Invalid Email or Password" />
              ) : (
                ""
              )}
                  {err === true  && loginError?.emailError ? (
                <FieldValidationError message={loginError?.emailError} />
              ) : (
                ""
              )}
              <p className="info-text">
                By signing up you are agree to our <a href="https://virtuelife.ai/terms/" target='_blank' rel="noreferrer">Terms of Use</a> and <a href="https://virtuelife.ai/privacy/" target='_blank' rel="noreferrer">Privacy Policy</a>
              </p>

              {/* <p
                className="info-text text-end w-100"
                style={{
                  color: "#ff6f48",
                  fontWeight: "500",
                  cursor: "pointer",
                }}
                onClick={(e) => {
                  e.preventDefault();
                  sendOtp();
                }}
              >
               {isOtpLoading ? "Sending Email" : "Forgot Password ?" }  
              </p> */}
            </div>
            <div className="action-part">
              <button
                className="btn btn-orange outline"
                disabled={isOtpLoading}
                onClick={(e) => {
                  e.preventDefault();
                  sendOtp();
                }}
              >
               {isOtpLoading ? "Sending Email" : "Forgot Password" }  
              </button>

         
            </div>
          </div>

      <Modal show={showModal} onHide={closeModal} centered>
        <Modal.Body style={{ border: "none" }}>
          <div className="content-modal">
            <div className="top-btn-part">
              <button className="btn close-btn" onClick={closeModal}>
                <AiOutlineClose size={20} color="#000" />
              </button>
            </div>
            <div className="modal-inside-content">
              <h5 className="heading">Authentication Code Sent to your email</h5>
              <p className="desc-text">
                6 digit OTP has sent to your email address
              </p>
              <p className="mobileno">
                {loginData?.email}{" "}
                {/* <Badge
                  pill
                  className="edit-badge"
                  onClick={(e) => {
                    e.preventDefault();
                    setModalShow(false);
                    window.location.reload();
                  }}
                >
                  Edit
                </Badge> */}
              </p>
              <div className="otp-input-group" onPaste={handlePaste}>
                {otpDigits.map((digit, index) => (
                  <input
                    key={index}
                    type="text"
                    maxLength={1}
                    value={digit}
                    onChange={(e) =>
                      handleOtpInputChange(index, e.target.value)
                    }
                    onKeyDown={(e) => handleBackspace(index, e)}
                    autoFocus={index === 0}
                    ref={(el) => (otpInputRefs.current[index] = el)} // Save input element references to the ref array
                  />
                ))}
              </div>
              {otperrorMessage && (
                <FieldValidationError message={otperrorMessage} />
              )}
              <button
                className="btn-continue"
                disabled={isLoadingVer}
                onClick={handleVerifyOTPs}
              >
                {isLoadingVer ? "Verifying OTP" : "Continue"}
              </button>
              <span className="notreceive-text">
                Haven't you received the Email?
              </span>
              <button
  className="resend-btn"
  onClick={(e) => {
    e.preventDefault();
    sendOtp(); // Call your function to Authentication Code
    setTimerSeconds(15 * 60); // Reset timer to 15 minutes
  }}
  disabled={timerSeconds !== 0} // Disable button until timer reaches 0
  style={{
    // Prevents hover and click
    cursor : timerSeconds !== 0 ? 'not-allowed' : 'pointer',
  }}
>
  Resend Code
</button>

<p className="timer">
  {`${Math.floor(timerSeconds / 60)}:${(timerSeconds % 60).toString().padStart(2, '0')}`}
</p>

            </div>
          </div>
        </Modal.Body>
      </Modal>
      <Modal show={passwordmodal} onHide={closePassowordModal} centered>
        <Modal.Body style={{ border: "none" }}>
          <div className="content-modal">
            <div className="top-btn-part">
              <button className="btn close-btn" onClick={closePassowordModal}>
                <AiOutlineClose size={20} color="#000" />
              </button>
            </div>
            <div className="modal-inside-content">
              <h5 className="heading">Reset Password</h5>
              <p className="desc-text">
                Add your password and confirm password{" "}
              </p>

              <div className={`filed w-100`} ref={passwordInputRef}>
                <label>
                  Password <span className="text-danger">* </span>
                </label>
                <input
                  type="password"
                  className={`input-field form-control ${
                    inputErrors?.password ? "field-error" : ""
                  }`}
                  name="password"
                  onChange={handleChange}
                  value={loginData?.password || ""}
                />
                {inputErrors?.password && (
                  <FieldValidationError message="Please enter your password" />
                )}
              </div>

              <div className={`filed w-100 `} ref={confirmpasswordInputRef}>
                <label>
                  Confirm Password <span className="text-danger">* </span>
                </label>
                <input
                  type="password"
                  className={`input-field form-control ${
                    inputErrors?.password ? "field-error" : ""
                  }`}
                  name="confirmPassword"
                  onChange={handleChange}
                  value={loginData?.confirmPassword || ""}
                />
                {inputErrors?.confirmPassword && (
                  <FieldValidationError message="Please enter confirm password" />
                )}

{inputErrors?.common && (
                  <FieldValidationError message="Password and Confirm Password is not matched" />
                )}
              </div>
              <button
                className="btn-continue"
                onClick={sendVerifyOtp}
                disabled={isResetPasswordLoading}
              >
            {isResetPasswordLoading ? "Reseting Your Password": "Continue"}
              </button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
      </div>
          </div>
          </div>
    </>
  );
};

export default ForgotPassword;
