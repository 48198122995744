import { UseEffectOnce } from "../../Hook/UseEffectOnce"
import { useRef, useState } from "react";
import Layout from "../../layout";
import TopBar from '../../components/TopBarNavigation'
import UploadIcon from '../../assets/images/upload.svg'
import { Button, Col, Row } from 'react-bootstrap';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css';
import { get, getAuthConfig, getAuthConfigImage, post } from '../../libs/http-hydrate';
import swal from "sweetalert";
import { toast, ToastContainer } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import isEmail from 'validator/lib/isEmail';
import FieldValidationError from '../../common/FieldValidation'
import PDFIcon from '../../assets/images/pdf.svg'
import moment from "moment";
import doneImg from "../../assets/images/doneImg.png";
import Modal from 'react-bootstrap/Modal';
import Animation from "../../Animation/Animation";


function EditPatient() {
    const location = useLocation();
    const navigate = useNavigate();
    const [existingPatient, setExistingPatient] = useState(null);
    const [personalInformation, setPersonalInformation] = useState({});
    const [profileImagePreview, setProfileImagePreview] = useState(null);
    const [personalInformationError, setPersonalInformationError] = useState({
        name: false,
        age: false,
        profile: false,
        email: false,
        phone: false,
        common: false,
        inguredBodyPart: false
    });
    const [completeWorkout, setCompleteWorkout] = useState(false);
    const [countryLoading, setCountryLoading] = useState(false);
    const [stateLoading, setStateLoading] = useState(false);
    const [cityLoading, setCityLoading] = useState(false);
    const [countriesFetched, setCountriesFetched] = useState(false);
    const [countries, setCountries] = useState([]);
    const [states, setStates] = useState([]);
    const [cities, setCities] = useState([]);

    const [loading, setLoading] = useState(false)
    const [country, setCountry] = useState("");
    let [state, setstate] = useState("");
    let [city, setCity] = useState("");


    const name = useRef(null);
    const email = useRef(null);
    const phone = useRef(null);
    const age = useRef(null);
    const inguredBodyPart = useRef(null);

    UseEffectOnce(() => {
        if (location?.state?.id) {
            getPatientDetails()
        }
    }, [location?.state?.id])
    async function getPatientDetails() {
        const response = await get(`/patientDetail?id=${location?.state?.id}`, getAuthConfig())
        if (response.status === 200) {
            setPersonalInformation((p) => (response?.data?.msg))
            if (response?.data?.msg?.profile_image) {
                setProfileImagePreview(response?.data?.msg?.profile_image);
            }
            if (response?.data?.msg?.address?.country) {
                setPersonalInformation((p) => ({ ...p, country: response?.data?.msg?.address?.country }))
                setCountry(response?.data?.msg?.address?.country)
            }
            if (response?.data?.msg?.address?.city) {
                setPersonalInformation((p) => ({ ...p, city: response?.data?.msg?.address?.city }))
                setCity(response?.data?.msg?.address?.city)
            }
            if (response?.data?.msg?.address?.state) {
                setPersonalInformation((p) => ({ ...p, state: response?.data?.msg?.address?.state }))
                setstate(response?.data?.msg?.address?.state)

            }
            if (response?.data?.msg?.address?.pincode) {
                setPersonalInformation((p) => ({ ...p, pincode: response?.data?.msg?.address?.pincode }))
                // setstate(response?.data?.msg?.address?.state)
            }
            if (response?.data?.msg?.address?.street) {
                setPersonalInformation((p) => ({ ...p, street: response?.data?.msg?.address?.street }))
                // setstate(response?.data?.msg?.address?.state)
            }
            if (response?.data?.msg?.emergencyNumber) {
                setPersonalInformation((p) => ({ ...p, pincode: response?.data?.msg?.emergencyNumber }))
                // setstate(response?.data?.msg?.address?.state)
            }
            if (response?.data?.msg?.operationDate) {
                setPersonalInformation((p) => ({ ...p, operationDate: moment(response?.data?.msg?.operationDate).format('YYYY-MM-DD') }))
                // setstate(response?.data?.msg?.address?.state)
            }
        }
    }
    const handleContinue = () => {


        if (!personalInformation?.name) {
            setPersonalInformationError((p) => ({ ...p, name: true }))
            handleFieldError(name)
            return false;
        }
        if (!personalInformation?.age) {
            setPersonalInformationError((p) => ({ ...p, age: true }))
            handleFieldError(age)
            return false;
        }

      // Check if both email and phone are empty
      if (!personalInformation?.email && !personalInformation?.phone) {
        setPersonalInformationError((p) => ({ ...p, common: true }));
        handleFieldError(email); // Assuming email should be focused first
        return false;
    }

    // Check if email is provided but invalid
    if (personalInformation?.email && !isEmail(personalInformation?.email)) {
        setPersonalInformationError((p) => ({ ...p, email: true }));
        handleFieldError(email);
        return false;
    }

    // Check if phone number is just a "+"
    if (personalInformation?.phone && personalInformation.phone === "+") {
        setPersonalInformationError((p) => ({ ...p, phone: true }));
        handleFieldError(phone);
        return false;
    }

        if (personalInformation?.inguredBodyPart?.trim() === "") {
            setPersonalInformationError((p) => ({ ...p, inguredBodyPart: true }))
            handleFieldError(inguredBodyPart);
            return false;

        }
        checkForExistingPatient();

    };

    const handleFieldError = (fieldRef) => {
        const inputField = fieldRef?.current?.querySelector('input')
        if (inputField) {
            inputField.focus(); // Focus on the input field
            fieldRef.current.scrollIntoView({ behavior: "smooth" });
        } else {
            const selectField = fieldRef?.current?.querySelector('select');
            if (selectField) {
                selectField.classList.add('field-error'); // Add the error class to the select field
                fieldRef.current.scrollIntoView({ behavior: "smooth" });
            }
        }
    };
    // Function to check if patient already exists
    const checkForExistingPatient = async () => {
        try {
            const formdata = new FormData();
            formdata.append("email", personalInformation.email)
            formdata.append("id", personalInformation._id)

            if (personalInformation?.phone?.trim() != "") {
                formdata.append("phone", `+${personalInformation.phone}`);
              } else {
                formdata.append("phone", ``);
              }
        
            const response = await post("/checkForPatient", formdata, getAuthConfig())

            if (response.status === 200) {
                savePatientData()
            }
        } catch (error) {
            if (error?.response?.status === 409) {
                // Patient already exists
                swal({
                    title: "Warning !",
                    text: `From this email or phone user already Exists , Please try different email or phone.`,
                    icon: "warning",
                    dangerMode: true,
                    buttons: ["No", "Okay"],
                }).then(async (willDelete) => {

                });
                setExistingPatient(true);
            }
            console.error('Error checking for existing patient:', error);
        }
    };
    async function getCountrie() {
        if (countriesFetched) {
            return; // Countries have already been fetched
        }
        try {
            setCountryLoading(true);
            const data = await get("/country", getAuthConfig());
            setCountries(data?.data?.data);
            setCountriesFetched(true); // Update the state to indicate countries have been fetched

        } catch (error) {
            console.error(error);
        } finally {
            setCountryLoading(false);
        }
    }

    async function handleCountryChange(event) {
        const countryCode = event.target.value;
        setCountry(countryCode);

        if (countryCode) {
            try {
                setStateLoading(true);
                const response = await get(`/state?countryCode=${countryCode}`, getAuthConfig());
                setStates(response?.data?.data);
                setstate('');
                //   setContactInformation((p) => ({ ...p, state: "" }));
            } catch (error) {
                console.error(error);
            } finally {
                setStateLoading(false);
            }
        } else {
            setStates([]);
            setstate('');
        }
    }

    async function handleStateChange(event) {
        const stateCode = event.target.value;
        setstate(stateCode);

        if (country && stateCode) {
            try {
                setCityLoading(true);
                const response = await get(`/city?countryCode=${country}&stateCode=${stateCode}`, getAuthConfig());
                setCities(response?.data?.data);
                //   setContactInformation((p) => ({ ...p, city: "" }));
            } catch (error) {
                console.error(error);
            } finally {
                setCityLoading(false);
            }
        } else {
            setCities([]);
        }
    }

    async function savePatientData() {
        try {
            setLoading(true)

             // Check if phone number is just "+", if so, set it to an empty string
        let updatedPhone = (personalInformation?.phone === "+") ? "" : personalInformation?.phone;
        if (updatedPhone && !updatedPhone.startsWith("+")) {
            updatedPhone = "+" + updatedPhone;
        }
        // Create a new object with the updated phone number
        const updatedPersonalInformation = {
            ...personalInformation,
            phone: updatedPhone
        };



            const response = await post("/Editpatient", updatedPersonalInformation, getAuthConfigImage()).then((data) => {
                if (data?.status === 200) {
                    setCompleteWorkout(true)
                    // toast.success("Patient Details Updated successfully");
                    // setTimeout(() => {
                    //     navigate("/allPatient")
                    //     setLoading(false)
                    // }, 2000)
                }
            }).catch((err) => {
                setLoading(false)
                toast.error(err?.response?.data?.err);
            })
        } catch (error) {
            setLoading(false)
            toast.error(error);
        }
    }

    return (
        <>

            <ToastContainer />
            <Layout>
                <TopBar backlink={"/pate"} title={" Edit Patient Information"} tab={"Patient"} tabChild={"Add Patient"} />


                <div className='main-content-part'>
                    <div className='form-data'>
                        <h5 className='title'>Personal Information</h5>
                        <div className='filed' ref={name}>
                            <label>Patient Name <span className='text-danger'>*</span></label>
                            <input type='text' className={`input-field ${personalInformationError?.name ? 'field-error' : ''}`} placeholder='Jack Williams' value={personalInformation?.name} onChange={(e) => {
                                e.preventDefault();
                                setPersonalInformation((p) => ({ ...p, name: e.target.value }))
                                setPersonalInformationError((p) => ({ ...p, name: false }))
                            }} />
                            {personalInformationError?.name && <FieldValidationError message="Please enter name" />}

                        </div>
                        <div className='filed' ref={age}>
                            <label>Age <span className='text-danger'>*</span></label>
                            <input type='text' className={`input-field ${personalInformationError?.age ? 'field-error' : ''}`} placeholder='25' value={personalInformation?.age}
                             onChange={(e) => {
                                e.preventDefault();
                                const value = e.target.value;
                                if (value === '' || /^[0-9]*$/.test(value)) {
                                  let newAge = value === '' ? '' : parseInt(value, 10);
                              
                                  // Check if the new age is within the range 0 to 100
                                  if (newAge === '' || (newAge >= 0 && newAge <= 100)) {
                                    setPersonalInformation((p) => ({
                                      ...p,
                                      age: newAge,
                                    }));
                                    setPersonalInformationError((p) => ({ ...p, age: false }));
                                  } else if (newAge > 100) {
                                    // If age is more than 100, set age to 100
                                    setPersonalInformation((p) => ({
                                      ...p,
                                      age: 100,
                                    }));
                                    setPersonalInformationError((p) => ({ ...p, age: false }));
                                  }
                                } else {
                                  // Handle invalid input
                                  setPersonalInformationError((p) => ({ ...p, age: true }));
                                }
                            }} />
                            {personalInformationError?.age && <FieldValidationError message="Please enter valid age" />}

                        </div>


                        <Row className='g-4'>
                            <Col lg="6">
                                <div className='filed' ref={email}>
                                    <label>Email Id</label>
                                    <input type='text' className={`input-field ${personalInformationError?.email ? 'field-error' : ''}`} placeholder='jackwilliams@gmail.com' value={personalInformation?.email} onChange={(e) => {
                                        e.preventDefault();
                                        setPersonalInformation((p) => ({ ...p, email: e.target.value }))
                                        setPersonalInformationError((p) => ({ ...p, email: false }))
                                        setPersonalInformationError((p) => ({ ...p, common: false }))

                                    }} />
                                    {personalInformationError?.email && <FieldValidationError message="Please enter valid email" />}

                                </div>
                            </Col>
                            <Col lg="6">

                                <div className='filed'>
                                    <label>Patient Mobile Number</label>

                                    <PhoneInput country={'in'}
                                        className='input-field'
                                        value={personalInformation?.phone != "" ? personalInformation?.phone : ""}
                                        onChange={(phone, country) => {
                                            setPersonalInformation((p) => ({ ...p, countryCode: country?.dialCode }))
                                            setPersonalInformation((p) => ({ ...p, countryCode: country?.countryCode }))

                                            setPersonalInformation((p) => ({ ...p, phone: phone }))
                                            setPersonalInformationError((p) => ({ ...p, phone: false }))
                                            setPersonalInformationError((p) => ({ ...p, common: false }))

                                        }}
                                    />
                                    {personalInformationError?.phone && <FieldValidationError message="Please enter valid mobile number" />}

                                </div>
                            </Col>
                        </Row>
                        {personalInformationError?.common && <FieldValidationError message="Please enter email / mobile number" />}

                        <div className='filed'>
                            <label>Add Profile Image</label>
                            <div className='upload-part mt-2'>
                                <div className="form-group file-area">
                                    <input type="file" name="images" id="images" required="required" 
                                     
                                     accept="image/png,image/gif,image/jpeg,image/jpg,application/pdf,application/msword"
                                     onChange={(e) => {
                                       const files = e.target.files;
                                       let isValid = true;
                                     
                                       for (let i = 0; i < files.length; i++) {
                                         const fileType = files[i].type;
                                         const fileSize = files[i].size;
                                     
                                         if (
                                           fileType !== "image/png" &&
                                           fileType !== "image/jpg" &&
                                           fileType !== "image/jpeg" &&
                                           fileType !== "image/gif" &&
                                           fileType !== "application/pdf" &&
                                           fileType !== "application/msword" &&
                                           fileType !== "application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                                         ) {
                                           alert("Only images, PDF, DOC, and DOCX are allowed");
                                           isValid = false;
                                           break;
                                         }
                                     
                                         if (fileSize > 2097152) { // 2 MB in bytes
                                           alert("File size should not be more than 2 MB");
                                           isValid = false;
                                           break;
                                         }
                                       }
                                     
                                       if (isValid) {
                                         setPersonalInformation((p) => ({
                                           ...p,
                                           profile_image: e.target.files[0], // or handle all files if needed
                                         }));

                                         // Create a preview of the selected image
        const reader = new FileReader();
        reader.onloadend = () => {
            setProfileImagePreview(reader.result);
        };
        reader.readAsDataURL(e.target.files[0]);
                                       } else {
                                         // Optionally reset the input if invalid file is selected
                                         e.target.value = null;
                                       }
                                     }}
                                     />
                                    <div className="file-dummy">
                                        {/* <div className="success">

                                        {profileImagePreview && (
            <div className="image-preview mt-3">
                <img src={profileImagePreview} alt="Profile Preview" style={{ maxWidth: '200px', maxHeight: '200px' }} />
            </div>
        )}
                                        </div> */}
                                        

                                        {profileImagePreview ? (
            <div className="image-preview mt-3">
                <img src={profileImagePreview} alt="Profile Preview" style={{ maxWidth: '100px', maxHeight: '100px' }} />
            </div>
        ) : (
            <>
            <div className="default"><div className='image-block mb-2'>
            <img src={UploadIcon} alt='icon' />
        </div>Add Profile Image here (png, jpg, pdf)</div></>
        )}
                                     
                                    </div>
                                </div>

                            </div>
                        </div>

                    </div>


                    <div className='form-data mt-5'>
                        <h5 className='title'>Surgery Information</h5>
                        <Row className='g-4'>
                            <Col lg="6">
                                <div className='filed' ref={inguredBodyPart} >
                                    <label>Injury Body Part <span className='text-danger'>*</span></label>
                                    <input type='text' className={`input-field ${personalInformationError?.inguredBodyPart ? "field-error" : ""}`} placeholder='Knee' value={personalInformation?.inguredBodyPart} onChange={(e) => {
                                        e.preventDefault();
                                        setPersonalInformation((p) => ({ ...p, inguredBodyPart: e.target.value }))
                                    }} />
                                    {personalInformationError?.inguredBodyPart ? <FieldValidationError message="Enter injured Body Part" /> : ""}
                                </div>
                                <div className='filed'>
                                    <label>Did you get surgery?</label>
                                    <div className='yes-no-radio'>
                                        <label>
                                            <input type="radio" name="radio" checked={personalInformation?.isOperation} />
                                            <div className="front-end box" onClick={(e) => {
                                                e.preventDefault();
                                                setPersonalInformation((p) => ({ ...p, isOperation: true }))
                                            }}>
                                                <span>Yes</span>
                                            </div>

                                        </label>

                                        <label>
                                            <input type="radio" name="radio" checked={!personalInformation?.isOperation} />
                                            <div className="back-end box" onClick={(e) => {
                                                e.preventDefault();
                                                setPersonalInformation((p) => ({ ...p, isOperation: false }))
                                            }}>
                                                <span>No</span>
                                            </div>
                                        </label>
                                    </div>
                                </div>
                                {personalInformation?.isOperation  &&

                                <div className='filed'>
                                    <label>Surgery Date</label>
                                    <input type='date' className='input-field' value={personalInformation?.operationDate} defaultValue={'2023-02-22'} onChange={(e) => {
                                        e.preventDefault();
                                        setPersonalInformation((p) => ({ ...p, operationDate: e.target.value }))
                                    }} />
                                </div>}
                                <div className='filed'>
                                    <label>Injury information</label>
                                    <div className="mb-3">
                                        <textarea className="form-control" id="exampleFormControlTextarea1" rows="3" placeholder='Write here…' value={personalInformation?.injuryInfo} onChange={(e) => {
                                            e.preventDefault();
                                            setPersonalInformation((p) => ({ ...p, injuryInfo: e.target.value }))
                                        }}></textarea>
                                    </div>
                                </div>
                            </Col>
                            <Col lg="6">
                                <div className='filed'>

                                    <div className='block'>
                                        <p style={{ fontWeight: '500' }}>Reports</p>

                                        {personalInformation?.report?.length > 0 &&
                                            <div className='report-card' style={{cursor:"pointer"}}                                 onClick={() => window.open(personalInformation?.report[0], '_blank')}>
                                                <div className='image-block'>
                                                    <img src={PDFIcon} alt='icon' />
                                                </div>
                                                <div className='text-block ms-3'>
                                                    <p className='fileName mb-0'>General Check</p>
                                                    <p className='fileNo mb-0'>{personalInformation?.report?.length} files</p>
                                                </div>
                                            </div>
                                        }
                                    </div>
                                </div>
                                <div className='filed'>
                                    <div className='upload-part mt-2'>
                                        <div className="form-group file-area">
                                            <input type="file" name="images" id="images" required="required" multiple="multiple" accept="image/png,image/gif,image/jpeg,image/jpg,application/pdf,application/msword"
                                                onChange={(e) => {
                                                    if (
                                                        e?.target?.files[0] && 
                                                        !(
                                                            e.target.files[0].type === "image/png" ||
                                                            e.target.files[0].type === "image/jpg" ||
                                                            e.target.files[0].type === "image/jpeg" ||
                                                            e.target.files[0].type === "image/gif" ||
                                                            e.target.files[0].type === "application/pdf" ||
                                                            e.target.files[0].type ===
                                                            "application/msword" ||
                                                            e.target.files[0].type ===
                                                            "application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                                                        )
                                                    ) {
                                                        alert("Only images,pdf,doc,docx allowded");
                                                        return false;
                                                    }
                                                    setPersonalInformation((p) => ({ ...p, report: e.target.files[0] }));
                                                }} />
                                            <div className="file-dummy">
                                                <div className="success">Great, your files are selected. Keep on.</div>
                                                <div className="default"><div className='image-block mb-2'>
                                                    <img src={UploadIcon} alt='icon' />
                                                </div>Add report here (png, jpg, pdf)</div>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </Col>
                        </Row>

                    </div>


                    <div className='form-data mt-5'>
                        <h5 className='title'>Contact Information</h5>
                        <Row className='g-4'>
                            <Col lg="6">
                                <div className='filed'>
                                    <label>Emergency Number</label>

                                    <PhoneInput country={'in'}
                                        className='input-field'
                                        value={personalInformation?.emergencyNumber != "" ? personalInformation?.emergencyNumber : ""}

                                        onChange={(phone, country) => {
                                            setPersonalInformation((p) => ({ ...p, emergencyNumber: phone }))
                                        }} />

                                </div>
                                <div className='filed'>
                                    <label>Address</label>
                                    <input type='text' className='input-field' placeholder='Address line 1' value={personalInformation?.street} onChange={(e) => {
                                        e.preventDefault();
                                        setPersonalInformation((p) => ({ ...p, street: e.target.value }))
                                    }} />
                                </div>
                                {/* <div className='filed'>
                                    <input type='text' className='input-field' placeholder='Address line 2' />
                                </div> */}
                            </Col>
                            <Col lg="6">


                                <Row>

                                    <Col lg="6">
                                        <div className="filed" >
                                            <label>Country  </label>
                                            <select className="form-control form-select"
                                                value={country ? country : personalInformation?.country} onChange={(e) => {
                                                    handleCountryChange(e)
                                                    const selectedCountry = countries.find(
                                                        (c) => c.isoCode === e.target.value
                                                    );
                                                    if (selectedCountry) {
                                                        setPersonalInformation((prevData) => ({
                                                            ...prevData,
                                                            country: selectedCountry.name,
                                                        }));
                                                    }
                                                }} onClick={(e) => {
                                                    getCountrie()
                                                }}>
                                                <option value="">{personalInformation?.country ? personalInformation?.country : "Select Country"}</option>
                                                {countryLoading ? (
                                                    <option disabled>Loading...</option>
                                                ) : (
                                                    countries.map((country) => (
                                                        <option
                                                            key={country?.code}
                                                            value={country?.isoCode}
                                                        >
                                                            {country?.name}
                                                        </option>
                                                    ))
                                                )}

                                            </select>
                                        </div>
                                    </Col>
                                    <Col lg="6" >
                                        <div className="filed" >
                                            <label>State  </label>
                                            <select className="form-control form-select " value={personalInformation?.state} onChange={(e) => {
                                                handleStateChange(e)
                                                const selectedStates = states.find(
                                                    (c) => c.isoCode === e.target.value
                                                );
                                                if (selectedStates) {
                                                    setPersonalInformation((prevData) => ({
                                                        ...prevData,
                                                        state: selectedStates.name,
                                                    }));
                                                }
                                            }}>
                                                <option value="">{personalInformation?.state ? personalInformation?.state : "Select State"}</option>
                                                {stateLoading ? (
                                                    <option disabled>Loading...</option>
                                                ) : (
                                                    states.map((state) => (
                                                        <option
                                                            key={state?.code}
                                                            value={state?.isoCode}
                                                        >
                                                            {state?.name}
                                                        </option>
                                                    ))
                                                )}
                                            </select>
                                        </div>
                                    </Col>
                                    <Col lg="6" >
                                        <div className="filed" >
                                            <label>City  </label>

                                            <select className="form-control form-select" value={personalInformation?.city} onChange={(e) => {
                                                setPersonalInformation((p) => ({ ...p, city: e?.target?.value }))
                                            }}>
                                                <option value="">{personalInformation?.city ? personalInformation?.city : "Select City"}</option>
                                                {cityLoading ? (
                                                    <option disabled>Loading...</option>
                                                ) : (
                                                    cities.map((city) => (
                                                        <option key={city?.code} value={city?.code}>
                                                            {city?.name}
                                                        </option>
                                                    ))
                                                )}
                                            </select>
                                        </div>
                                    </Col>
                                    <Col lg="6" >


                                        <div className="filed" >
                                            <label>Pincode</label>

                                            <input
                                                type="text"
                                                className="input-field form-control"
                                                placeholder="Pincode"
                                                name="zip"
                                                value={personalInformation?.pincode || ""}
                                                onChange={(e) => {

                                                    const value = e.target.value;
                                                    // Regular expression to allow only numeric values
                                                    if (/^[0-9]*$/.test(value)) {
                                                        setPersonalInformation((p) => ({ ...p, pincode: value }));
                                                    }
                                                }}
                                            />


                                        </div>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>

                    </div>

                    <div className='buttonPart mt-5 d-flex justify-content-center'>

                        <Button className='theme-button' onClick={(e) => {
                            e.preventDefault();
                            handleContinue()
                        }}>Continue</Button>


                    </div>
                </div>


                <Modal
                    show={completeWorkout}
                    onHide={() => setCompleteWorkout(false)}
                    size="md"
                    className="complete-modal"
                >
                    <Modal.Body>
                        <div className="top-heading text-end">
                            <button
                                className="btn btn-transpatent"
                                onClick={() => setCompleteWorkout(false)}
                            >
                                X
                            </button>
                        </div>
                        <div className="content-block text-center">
                <Animation />
            </div>
                        <div className="bottom-block text-center">
                            <div className="workout-desc body-content" style={{
                                "display": "flex",
                                "align-items": "center",
                                "justify-content": "center",
                                "flex-direction": "column",
                                "padding-top": "25px"
                            }}>
                                <p>Patient details updated successfully</p>

                                <button className="theme-button btn btn-primary" style={{ minWidth: "300px", padding: "14px" }} onClick={(e) => {
                                    e.preventDefault();
                                    navigate("/allPatient")
                                }}>Done</button>
                            </div>
                        </div>
                    </Modal.Body>
                </Modal>
            </Layout>


        </>
    )
}
export default EditPatient;