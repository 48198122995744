

import { useState, useEffect, useRef, useCallback } from 'react'
import React from 'react';
import '../../assets/css/home.css'
import Layout from '../../layout'
import TopBar from '../../components/TopBarNavigation'
import SearchIcon from '../../assets/images/search.svg'
import { Row, Col, Button, Nav, Tab, Form } from 'react-bootstrap';
import '../../assets/css/foot.css'
import Ex1 from '../../assets/images/ex-1.png'
import Modal from 'react-bootstrap/Modal';
import { useLocation, Link, useNavigate } from 'react-router-dom'
import checkIcon from '../../assets/images/check (1).png'
import FilterIcon from '../../assets/images/filter.svg'
import RemoveIcon from '../../assets/images/remove.png'
import ReactPlayer from "react-player";
import UploadIcon from '../../assets/images/upload.svg'
import { deletes, get, getAuthConfig } from '../../libs/http-hydrate'
import { toast, ToastContainer } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import { UseEffectOnce } from '../../Hook/UseEffectOnce'
const ExerciseEdit = () => {
    const [isVideoLoaded, setIsVideoLoaded] = useState(false);

    const handleOnReady = () => {
      setIsVideoLoaded(true);
    };
    const location = useLocation();

    const [selected, setSelected] = useState({
        set: "",
        counter: "",
        restTime: "",
        _id: ""
    })
    const [selectedEx, setSelectedEx] = useState({})
    const navigate = useNavigate();
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const [showFilter, setShowFilter] = useState(false);
    const handleCloseFilter = () => setShowFilter(false);
    const handleShowFilter = () => setShowFilter(true);

    const [showNote, setShowNote] = useState(false);
    const handleCloseNote = () => setShowNote(false);
    const handleShowNote = () => setShowNote(true);

    const [showDataModal, setshowDataModal] = useState(false);
    const handleCloseDataModal = () => setshowDataModal(false);
    const handleShowDataModal = () => setshowDataModal(true);

    const [ExerciseList, setExerciseList] = useState({
        items: [],
        has_more: false
    })
    const [page, setPage] = useState(0);
    const [readyData, setReadyData] = useState({
        morning: [],
        afternoon: [],
        evening: [],
        night: [],
        patient_id: location?.state?.patient_id,
        date: new Date(),
        note: ""
    });
    const [selectedExercises, setSelectedExercises] = useState([]);
    const [selectedSessions, setSelectedSessions] = useState([]);

    const [bodyPartData, setBodyPart] = useState(
        {
            has_more: false,
            items: [],
        });
    const [bodypage, setBodyPage] = useState(0);
    const [filter, setFilter] = useState({
        body_part_id: "",
        title: "",
        searchTerm: "",
        patient_id: ""
    })
    UseEffectOnce(() => {
        if (location?.state?.bodypartId != undefined) {
            getExerciseList()
        }
    }, [location?.state?.bodypartId])
    useEffect(() => {
        // if (location?.state?.workout != undefined || location?.state?.workout != null) {
        //     setReadyData(location?.state?.workout)
        // }
        organizeDataByTimeOfDay(location?.state?.workout)
    }, [location?.state?.workout])
    useEffect(() => {
        if (filter?.body_part_id != "" || filter?.searchTerm != "") {
            getExerciseList();

        }
        // organizeDataByTimeOfDay(readyData);
    }, [filter]);

    useEffect(() => {
        if (page >= 1) {
            getExerciseList()
        }

    }, [page])

    const observer = useRef();
    const lastBookElementRef = useCallback(
        (node) => {
            // if (loading) return;
            if (observer.current) observer.current.disconnect();
            observer.current = new IntersectionObserver((entries) => {
                if (entries[0].isIntersecting && ExerciseList?.has_more) {
                    setPage((prevPageNumber) => prevPageNumber + 1);
                }
            });
            if (node) observer.current.observe(node);
        },
        [ExerciseList?.has_more]
    );

    async function getExerciseList() {
        try {
            const bodyPartId = filter.body_part_id !== "" ? filter.body_part_id : (location?.state?.bodypartId || "");
            const searchTerm = filter?.searchTerm || "";


            let queryParams = `pageNumber=${page}`;

            if (searchTerm) {
                queryParams += `&searchTerm=${searchTerm}`;
            }
            else {
                queryParams += `&body_part_id=${bodyPartId}`
            }
            // if(readyData?.patient_id){
            //     queryParams +=`&patient_id=${readyData?.patient_id}`
            // }

            const data = await get(`/exerciseList?${queryParams}`, getAuthConfig());
            if (data?.status === 200) {

                if (page > 0) {
                    setExerciseList((p) => ({ ...p, items: ExerciseList?.items?.concat(data?.data?.data) }))
                }
                else {
                    setExerciseList((p) => ({ ...p, items: data?.data?.data }))
                }
                setExerciseList((p) => ({ ...p, has_more: data?.data?.has_more }))
            }

        } catch (error) {
            if (error?.response?.status === 401) {
                navigate("/login")
            }
        }
    }

    const handleExerciseSelection = (exerciseData, isSelected) => {
        if (isSelected) {
            setSelectedExercises((prevSelected) => [...prevSelected, exerciseData]);
        } else {
            setSelectedExercises((prevSelected) =>
                prevSelected.filter((exercise) => exercise._id !== exerciseData._id)
            );
        }
    };

    const handleSessionSelection = (sessionId, isSelected) => {
        if (isSelected) {
            setSelectedSessions((prevSelected) => [...prevSelected, sessionId]);
        } else {
            setSelectedSessions((prevSelected) =>
                prevSelected.filter((id) => id !== sessionId)
            );
        }
    };

    const updateSelectedExercises = (exerciseId, newData) => {
        setSelectedExercises((prevSelected) =>
            prevSelected.map((exercise) =>
                exercise._id === exerciseId ? { ...exercise, ...newData } : exercise
            )
        );
    };
    const organizeDataByTimeOfDay = (dm) => {


        const dataByTimeOfDay = dm || {
            morning: [],
            afternoon: [],
            evening: [],
            night: [],
            patient_id: location?.state?.patient_id ? location?.state?.patient_id : readyData?.patient_id,
            date: new Date(),
            note: ""
        };
        selectedSessions.forEach((session) => {
            const name = location?.state?.bodyPart;
            const workout = selectedExercises.map((exercise) => ({
                exercise_id: exercise._id,
                timeToDo: exercise.timeToDo,
                timeRange: exercise.timeRange,
                count: exercise.count,
                set: exercise.set,
                title: exercise?.title,
                restTime: exercise?.restTime,
                exerciseImage: exercise?.exerciseImage,
            }));

            // Check if workout with the same name exists in the session
            const existingWorkout =
                dataByTimeOfDay[session] &&
                dataByTimeOfDay[session]?.find((entry) => entry.name === name);

            if (existingWorkout) {
                // Check if exercise already exists in the session, and only add if not
                const exerciseExists = existingWorkout.workout.some(
                    (existingExercise) =>
                        workout.some((newExercise) => newExercise.exercise_id === existingExercise.exercise_id)
                );

                if (!exerciseExists) {
                    existingWorkout.workout.push(...workout);
                }
            } else {
                // If workout with the same name does not exist, create a new entry in the session
                const sessionData = { name, workout };
                dataByTimeOfDay[session] = dataByTimeOfDay[session] || [];
                dataByTimeOfDay[session].push(sessionData);
            }
        });

        setReadyData((prevData) => ({
            ...prevData,
            ...dataByTimeOfDay,
        }));

        return dataByTimeOfDay;
    };
    const handleDeleteExercise = (sessionIndex, workoutIndex, exerciseIndex) => {
        setReadyData(prevData => {
            const newData = JSON.parse(JSON.stringify(prevData));
            const session = Object.keys(newData)[sessionIndex];
            const workouts = newData[session];

            if (workouts?.length > 0) {
                // Delete the exercise from the workout
                const deletedExerciseId = workouts[workoutIndex].workout[exerciseIndex].exercise_id;

                workouts[workoutIndex].workout.splice(exerciseIndex, 1);

                // Check if the workout is empty after deletion, then delete the workout itself
                if (workouts[workoutIndex]?.workout?.length === 0) {
                    workouts.splice(workoutIndex, 1);
                }

                // Update the readyData state
                newData[session] = workouts;
                // Remove the deleted exercise from the selectedExercises array
                setSelectedExercises((prevSelected) =>
                    prevSelected.filter((exercise) => exercise._id !== deletedExerciseId)
                );
            }

            return newData;

        });




    };
    async function getBodyPartData() {

        try {
            const data = await get(`/bodyPartList?pageNumber=${bodypage}`, getAuthConfig());
            if (data?.status === 200) {
                setBodyPart((p) => ({ ...p, items: data?.data?.data }))
                setBodyPart((p) => ({ ...p, has_more: data?.data?.has_more }))

            }

        } catch (error) {
            if (error?.response?.status === 401) {
                navigate("/login")
            }
        }
    }
    async function DeleteExercise(exercise_id) {
        try {
            const formdata = new FormData();
            formdata.append("workoutId", location?.state?.workoutId);
            formdata.append("session", location?.state?.session);
            formdata.append("exercise_id", exercise_id);
            formdata.append("workoutItemId", location?.state?.workoutItemId);
            const response = await deletes("/delete-exercise", formdata).then((data) => {
                if (data?.status === 200) {
                }
            }).catch((err) => {
                toast.error(err?.response?.data?.err);
            })
        } catch (error) {
            toast.error(error);
        }

    }


    useEffect(() => {
        // if(selectedExercises.length > 0 ){
        organizeDataByTimeOfDay()
        // }
    }, [selectedExercises])
    UseEffectOnce(() => {
        if (location?.state?.session) {
            handleSessionSelection(location?.state?.session, true)
        }
        if (location?.state?.workout) {
            const exercisesToAdd = [];
            const updatedItems = location?.state?.workout?.workout.map((exercise) => {
                exercisesToAdd.push({ ...exercise?.exercise_id, workoutItemId: exercise?._id });
            });
            setSelectedExercises((prevSelected) => [...prevSelected, ...exercisesToAdd]);
            setExerciseList((p) => ({ ...p, items: [...exercisesToAdd] }))
        }

    }, [location?.state?.session, location?.state?.workout])
    return (

        <>
            <ToastContainer />
            <Layout>

                <TopBar title={location?.state?.bodyPart} tab={"Workout"} tabChild={"Update Workout List"} />

                <div className='main-content-part foot-page p-0' defaultActiveKey="first">
                    <Tab.Container id="left-tabs-example" defaultActiveKey="first">
                        <Row className='h-100'>
                            {/* <Col xl={Object.entries(readyData).some(([session, workouts]) => Array.isArray(workouts) && workouts?.length > 0) ? 3 : 4} lg={Object.entries(readyData).some(([session, workouts]) => Array.isArray(workouts) && workouts?.length > 0) ? 4 : 3} sm={12}>
                            <div className='foot-sidebar' style={{"overflow-y": "scroll",height:"86vh" }}>
                                    <h6>Selected Session</h6>
                                    <Nav variant="pills" className="flex-column">
                                        {selectedSessions.includes("morning") && (

                                            <Nav.Item className='d-flex justify-content-start align-items-center'>
                                                <div className="checkbox me-2">
                                                    <input
                                                        type="checkbox"
                                                        id={`checkbox_${"morning"}`}
                                                        checked={selectedSessions.includes("morning")}
                                                    // onChange={(e) => handleSessionSelection("morning", e.target.checked)}
                                                    />
                                                    <label for={`checkbox_${"morning"}`}></label>
                                                </div>
                                                <Nav.Link eventKey="first">

                                                    Morning Session</Nav.Link>

                                            </Nav.Item>
                                        )}
                                        {selectedSessions.includes("afternoon") && (

                                            <Nav.Item className='d-flex justify-content-start align-items-center'>
                                                <div className="checkbox me-2">
                                                    <input
                                                        type="checkbox"
                                                        id={`checkbox_${"afternoon"}`}
                                                        checked={selectedSessions.includes("afternoon")}
                                                    // onChange={(e) => handleSessionSelection("afternoon", e.target.checked)}
                                                    />
                                                    <label for={`checkbox_${"afternoon"}`}></label>
                                                </div>
                                                <Nav.Link eventKey="first">Afternoon Session </Nav.Link>

                                            </Nav.Item>
                                        )}
                                        {selectedSessions.includes("evening") && (

                                            <Nav.Item className='d-flex justify-content-start align-items-center'>
                                                <div className="checkbox me-2">
                                                    <input
                                                        type="checkbox"
                                                        id={`checkbox_${"evening"}`}
                                                        checked={selectedSessions.includes("evening")}
                                                    // onChange={(e) => handleSessionSelection("evening", e.target.checked)}
                                                    />
                                                    <label for={`checkbox_${"evening"}`}></label>
                                                </div>
                                                <Nav.Link eventKey="first">Evening Session</Nav.Link>

                                            </Nav.Item>
                                        )}
                                        {selectedSessions.includes("night") && (

                                            <Nav.Item className='d-flex justify-content-start align-items-center'>
                                                <div className="checkbox me-2">
                                                    <input
                                                        type="checkbox"
                                                        id={`checkbox_${"night"}`}
                                                        checked={selectedSessions.includes("night")}
                                                    // onChange={(e) => handleSessionSelection("night", e.target.checked)}
                                                    />
                                                    <label for={`checkbox_${"night"}`}></label>
                                                </div>
                                                <Nav.Link eventKey="first">Night Session</Nav.Link>

                                            </Nav.Item>
                                        )}
                                    </Nav>
                                </div>

                            </Col> */}
                            <Col xl={Object.entries(readyData).some(([session, workouts]) => Array.isArray(workouts) && workouts.length > 0) ? 8 : 8} lg={Object.entries(readyData).some(([session, workouts]) => Array.isArray(workouts) && workouts?.length > 0) ? 5 : 7} sm={12}>

                                <div className='content-data mt-5 '>
                                    <div class="serchPart d-flex justify-content-center">
                                        <div className='searchInput'>
                                            <div className='searchIcon'>
                                                <img src={SearchIcon} alt='searchIcon' />
                                            </div>
                                            <input type='text' placeholder='Search exercises' value={filter?.searchTerm} onChange={(e) => {
                                                e.preventDefault();

                                                if (e.target.value === "") {
                                                    setPage(0)
                                                }
                                                setFilter((p) => ({ ...p, searchTerm: e.target.value }))
                                            }} />
                                            <div className='filterIcon' onClick={(e) => {
                                                getBodyPartData();
                                                handleShowFilter();
                                            }}>
                                                <img src={FilterIcon} alt='filterIcon' />
                                            </div>
                                        </div>
                                    </div>
                                    <Tab.Content>
                                        <Tab.Pane eventKey="first">
                                            <div className='exercise-content' style={{ maxHeight: "65vh", overflowY: "auto" }}>
                                                {ExerciseList?.items && ExerciseList?.items?.length <= 0 && <div className='text-center'>Not Exercise Found  </div>}

                                                {ExerciseList?.items && ExerciseList?.items?.length > 0 &&
                                                    ExerciseList?.items?.map((item, index) => {
                                                        const exerciseId = item._id;
                                                        const selectedExercise = selectedExercises.find((exercise) => exercise._id === exerciseId);
                                                        const result = selectedExercise ? Number(selectedExercise.set) * Number(selectedExercise.count) : (Number(item?.set) * Number(item?.count));

                                                        return (
                                                            <>
                                                                <div className='exercise-card mb-3 mx-1 mt-1' ref={lastBookElementRef}>
                                                                    <div className='img-text-part'>
                                                                        <div className='img-block'>
                                                                            <img src={item?.exerciseImage} height={70} width={105} alt='ex-1' />
                                                                        </div>
                                                                        <div className='text-part ps-3 mt-2' style={{display:"block",alignItems:"center"}}>
                                                                            <h6  style={{cursor:"pointer"}} onClick={(e) =>{
                                                                                e.preventDefault()
                                                                                setSelectedEx(item);
                                                                                handleShowDataModal(); }}>{item?.title}</h6>
                                                                            <p style={{ cursor: "pointer",display:"block" }} onClick={() => {
                                                                                if (selectedExercises.some((exercise) => exercise._id === item._id)) {



                                                                                    if (item?.set) {
                                                                                        setSelected((p) => ({ ...p, set: item?.set }))
                                                                                    }
                                                                                    if (item?._id) {
                                                                                        setSelected((p) => ({ ...p, _id: item?._id }))
                                                                                    }
                                                                                    if (item?.count) {
                                                                                        setSelected((p) => ({ ...p, counter: item?.count }))
                                                                                    }
                                                                                    if (item?.restTime) {
                                                                                        setSelected((p) => ({ ...p, restTime: item?.restTime }))
                                                                                    }
                                                                                    handleShow()
                                                                                }
                                                                            }
                                                                            }>{selectedExercise ? selectedExercise?.set : item?.set} Reps</p>
                                                                        </div>
                                                                    </div>
                                                                    <div className="checkbox me-2">
                                                                        <input
                                                                            type="checkbox"
                                                                            id={`checkbox_${item._id}`}
                                                                            checked={selectedExercises.some((exercise) => exercise._id === item._id)}
                                                                            onChange={(e) => {
                                                                                if (selectedSessions.length <= 0) {
                                                                                    toast.error("please select session first")
                                                                                }
                                                                                else {
                                                                                    handleExerciseSelection(item, e.target.checked)
                                                                                    if (!e.target?.checked && item?.workoutItemId) {
                                                                                        DeleteExercise(item?.workoutItemId)
                                                                                    }
                                                                                }

                                                                            }}
                                                                        />                                                    <label for={`checkbox_${item._id}`}></label>
                                                                    </div>
                                                                </div>
                                                            </>
                                                        )

                                                    })
                                                }
                                            </div>

                                        </Tab.Pane>
                                        <Tab.Pane eventKey="second">
                                            <div className='exercise-content'>
                                                <div className='exercise-card mb-3'>
                                                    <div className='img-text-part'>
                                                        <div className='img-block'>
                                                            <img src={Ex1} alt='ex-1' />
                                                        </div>
                                                        <div className='text-part ps-3'>
                                                            <h6>Running</h6>
                                                            <p>15 Reps</p>
                                                        </div>
                                                    </div>
                                                    <div className="checkbox me-2">
                                                        <input type="checkbox" id="checkbox_7" />
                                                        <label for="checkbox_7"></label>
                                                    </div>
                                                </div>

                                                <div className='exercise-card mb-3'>
                                                    <div className='img-text-part'>
                                                        <div className='img-block'>
                                                            <img src={Ex1} alt='ex-1' />
                                                        </div>
                                                        <div className='text-part ps-3'>
                                                            <h6>Bhujapidasana pose</h6>
                                                            <p>3 Times</p>
                                                        </div>
                                                    </div>
                                                    <div className="checkbox me-2">
                                                        <input type="checkbox" id="checkbox_8" />
                                                        <label for="checkbox_8"></label>
                                                    </div>
                                                </div>
                                            </div>
                                        </Tab.Pane>
                                        <Tab.Pane eventKey="third">
                                            <div className='exercise-content'>
                                                <div className='exercise-card mb-3'>
                                                    <div className='img-text-part'>
                                                        <div className='img-block'>
                                                            <img src={Ex1} alt='ex-1' />
                                                        </div>
                                                        <div className='text-part ps-3'>
                                                            <h6>Running</h6>
                                                            <p>15 Reps</p>
                                                        </div>
                                                    </div>
                                                    <div className="checkbox me-2">
                                                        <input type="checkbox" id="checkbox_9" />
                                                        <label for="checkbox_9"></label>
                                                    </div>
                                                </div>
                                                <div className='exercise-card mb-3'>
                                                    <div className='img-text-part'>
                                                        <div className='img-block'>
                                                            <img src={Ex1} alt='ex-1' />
                                                        </div>
                                                        <div className='text-part ps-3'>
                                                            <h6>squatting with rubber</h6>
                                                            <p>15 Reps</p>
                                                        </div>
                                                    </div>
                                                    <div className="checkbox me-2">
                                                        <input type="checkbox" id="checkbox_10" />
                                                        <label for="checkbox_10"></label>
                                                    </div>
                                                </div>
                                                <div className='exercise-card mb-3'>
                                                    <div className='img-text-part'>
                                                        <div className='img-block'>
                                                            <img src={Ex1} alt='ex-1' />
                                                        </div>
                                                        <div className='text-part ps-3'>
                                                            <h6>Bhujapidasana pose</h6>
                                                            <p>3 Times</p>
                                                        </div>
                                                    </div>
                                                    <div className="checkbox me-2">
                                                        <input type="checkbox" id="checkbox_11" />
                                                        <label for="checkbox_11"></label>
                                                    </div>
                                                </div>
                                            </div>
                                        </Tab.Pane>
                                        <Tab.Pane eventKey="four">
                                            <div className='exercise-content'>
                                                <div className='exercise-card mb-3'>
                                                    <div className='img-text-part'>
                                                        <div className='img-block'>
                                                            <img src={Ex1} alt='ex-1' />
                                                        </div>
                                                        <div className='text-part ps-3'>
                                                            <h6>Running</h6>
                                                            <p>15 Reps</p>
                                                        </div>
                                                    </div>
                                                    <div className="checkbox me-2">
                                                        <input type="checkbox" id="checkbox_12" />
                                                        <label for="checkbox_12"></label>
                                                    </div>
                                                </div>
                                                <div className='exercise-card mb-3'>
                                                    <div className='img-text-part'>
                                                        <div className='img-block'>
                                                            <img src={Ex1} alt='ex-1' />
                                                        </div>
                                                        <div className='text-part ps-3'>
                                                            <h6>squatting with rubber</h6>
                                                            <p>15 Reps</p>
                                                        </div>
                                                    </div>
                                                    <div className="checkbox me-2">
                                                        <input type="checkbox" id="checkbox_13" />
                                                        <label for="checkbox_13"></label>
                                                    </div>
                                                </div>

                                            </div>
                                        </Tab.Pane>
                                    </Tab.Content>
                                 

                                </div>

                            </Col>
                            {Object.entries(readyData).some(([session, workouts]) => Array.isArray(workouts) && workouts?.length > 0) && (
                                <Col xl={4} lg={4} sm={12}>



<div className='foot-sidebar' style={{"overflow-y": "scroll",height:"86vh" }}>
                                        {Object.entries(readyData).map(([session, workouts], sessionIndex) => {
                                            if (["morning", "evening", "afternoon", "night"].includes(session)) {
                                                return (
                                                    <div key={session}>
                                                        {Array.isArray(workouts) && workouts?.length > 0 ? (
                                                            <ul style={{padding:"0px"}}>
                                                                <h6>{session.charAt(0).toUpperCase() + session.slice(1)}</h6>
                                                                {workouts.map((workout, index) => (
                                                                    Array.isArray(workout.workout) && (
                                                                        workout.workout.map((exercise, idx) => (
                                                                            <div className='exercise-card mb-3' key={idx}>
                                                                                <div className='img-text-part'>
                                                                                    <div className='img-block'>
                                                                                        <img src={exercise?.exerciseImage} height={70} width={105} alt='ex-1' />
                                                                                    </div>
                                                                                    <div className='text-part ps-3'>
                                                                                        <h6>{exercise?.title}</h6>
                                                                                        <p style={{ cursor: "pointer" }}>{Number(exercise?.set)} Reps</p>
                                                                                    </div>
                                                                                </div>
                                                                                <div className="checkbox me-2" style={{ cursor: "pointer" }} onClick={() => { handleDeleteExercise(sessionIndex, index, idx) }}>
                                                                                    <img src={RemoveIcon} height={30} />
                                                                                </div>
                                                                            </div>
                                                                        ))
                                                                    )
                                                                ))}
                                                            </ul>
                                                        ) : ("")}
                                                    </div>
                                                );
                                            } else {
                                                return null; // Don't render anything for other sessions
                                            }
                                        })}

<div className=' mt-4 mx-auto'>

<Button className='theme-button d-block  mx-auto' onClick={async (e) => {
    e.preventDefault();
    // Check if readyData has any exercises
    const hasExercises = Object.values(readyData).some((workouts) =>
        Array.isArray(workouts) && workouts.some((workout) => workout?.workout?.length > 0)
    );
    if (!hasExercises) {


        if (selectedSessions?.length === 0) {
            // Display an error message or take appropriate action
            toast.error('Please select at least one session.')
            return;
        }

        if (selectedExercises?.length === 0) {
            // Display an error message or take appropriate action
            toast.error('Please select at least one exercise.')
            return;
        }
        // Display an error message or take appropriate action
        //    toast.error('Please add at least one exercise to proceed.');
        //    return;



    }



    // const data = await  organizeDataByTimeOfDay(readyData);
    // handleShowNote()

    navigate("/excersiceList", { state: { update:true,workout: readyData, patient_id: location?.state?.patient_id, session: location?.state?.session, workoutItemId: location?.state?.workoutItemId, workoutId: location?.state?.workoutId } })

}}>Next</Button>

{/* 
<Button className='theme-outline-btn mt-3 d-block  mx-auto' style={{ backgroundColor: '#FDF5F2', borderColor: '#FDF5F2' }}

    onClick={(e) => {
        e.preventDefault();
        if ((selectedSessions.length > 0 && selectedExercises.length === 0)) {
            // Display an error message or take appropriate action
            toast.error('Please select at least one exercise.')
            return;
        }

        if ((selectedSessions.length === 0 && selectedExercises.length > 0)) {
            // Display an error message or take appropriate action
            toast.error('Please select at least one session.')
            return;
        }


        let data = organizeDataByTimeOfDay(location?.state?.workout ? readyData : null);



        navigate("/points", { state: { workout: data } })

    }}
>+ Add More</Button> */}


</div>
                                    </div>


                                </Col>
                            )}
                        </Row>
                    </Tab.Container>
                </div>
                {/* Exercises Counter modal */}

                {/* <Modal show={show} onHide={handleClose} centered size="md">
                <Modal.Header closeButton>
                    <Modal.Title className='text-center'>Exercises Counter</Modal.Title>
                </Modal.Header>
                <Modal.Body className='pb-0'>
                    <div className='body-content'>
                        <div className='form-data w-100' style={{ maxWidth: '100%' }}>
                            <div className='filed'>
                                <label>Patient Mobile Number</label>
                                <input type='text' className='input-field' placeholder='+1 123 456 789' />
                            </div>
                            <div className='filed'>
                                <label>Exercises Set</label>
                                <Form.Select aria-label="Default select example" className='mb-3'>
                                    <option>Pincode</option>
                                    <option value="1">02</option>
                                    <option value="2">03</option>
                                    <option value="3">04</option>
                                </Form.Select>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button className='theme-button'>
                        Done
                    </Button>
                </Modal.Footer>
            </Modal> */}

                {/* Filter modal */}

                <Modal show={show} onHide={handleClose} centered size="md">
                    <Modal.Header closeButton>
                        <Modal.Title className='text-center'>Exercises Counter</Modal.Title>
                    </Modal.Header>
                    <Modal.Body className='pb-0'>
                        <div className='body-content'>
                            <div className='form-data w-100' style={{ maxWidth: '100%' }}>
                                <div className='filed'>
                                    <label>Exercises Count </label>
                                    <input type='text' className='input-field' value={selected?.counter} placeholder='05' onChange={(e) => {
                                        e.preventDefault();
                                        setSelected((p) => ({ ...p, counter: e.target.value }))
                                    }} />
                                </div>
                                <div className='filed'>
                                    <label>Exercises Set</label>
                                    <Form.Select aria-label="Default select example" value={selected?.set} className='mb-3' onChange={(e) => {
                                        e.preventDefault();
                                        setSelected((p) => ({ ...p, set: e.target.value }))
                                    }}>
                                        <option value="1">01</option>
                                        <option value="2">02</option>
                                        <option value="3">03</option>
                                        <option value="4">04</option>
                                        <option value="5">05</option>
                                        <option value="6">06</option>
                                        <option value="7">07</option>

                                    </Form.Select>
                                </div>

                                <div className='filed'>
                                    <label>Rest Time</label>

                                    <Form.Select aria-label="Default select example" vvalue={selected?.restTime} placeholder='05' onChange={(e) => {
                                        e.preventDefault();
                                        setSelected((p) => ({ ...p, restTime: e.target.value }))
                                    }}>
                                        <option value="60">01 Min</option>
                                        <option value="120">02 Min</option>
                                        <option value="180">03 Min</option>
                                        <option value="240">04 Min</option>
                                        <option value="300">05 Min</option>
                                        <option value="360">06 Min</option>
                                        <option value="420">07 Min</option>
                                        <option value="480">08 Min</option>
                                        <option value="540">09 Min</option>
                                        <option value="600">10 Min</option>

                                    </Form.Select>

                                </div>
                            </div>
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button className='theme-button' onClick={(e) => {
                            e.preventDefault();
                            // Example: Updating the data for an exercise with ID "example_id"
                            updateSelectedExercises(selected?._id, { set: selected?.set, count: selected?.counter, restTime: selected?.restTime });

                            handleClose();
                            // handleShowFilter();
                        }}>
                            Done
                        </Button>
                    </Modal.Footer>
                </Modal>

                {/* Filter modal */}
                <Modal show={showFilter} onHide={handleCloseFilter} centered size="md">
                    <Modal.Header closeButton>
                        <Modal.Title className='text-center'>Filter</Modal.Title>
                    </Modal.Header>
                    <Modal.Body className='pb-0'>
                        <div className='body-content'>
                            <ul className='filterList'>
                                {bodyPartData?.items && bodyPartData?.items?.length > 0 && (
                                    bodyPartData.items.map((item, index) => (
                                        <li
                                            key={index}
                                            style={{ cursor: "pointer" }}
                                            onClick={(e) => {
                                                setFilter((prevFilter) => ({
                                                    ...prevFilter,
                                                    body_part_id: item?._id,
                                                }));
                                            }}
                                        >
                                            <a >{item?.title}
                                                {filter?.body_part_id === item?._id && (
                                                    <div className="select-img">
                                                        <img src={checkIcon} alt="checkIcon" style={{ display: "block" }} />
                                                    </div>
                                                )}
                                            </a>
                                        </li>
                                    ))
                                )}


                            </ul>
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button className='theme-button' onClick={(e) => {
                            e.preventDefault();
                            handleCloseFilter();

                        }}>
                            Done
                        </Button>
                    </Modal.Footer>
                </Modal>


                {/* Add Note Filter  */}
                <Modal show={showNote} onHide={handleCloseNote} centered size="lg">
                    <Modal.Header closeButton>
                        <Modal.Title className='text-center'>Add Note</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className='body-content'>
                            <p className='body-title'>Information Note</p>
                            <div className="mb-3">
                                <textarea className="form-control" id="exampleFormControlTextarea1" rows="5" placeholder='Write text…' value={readyData?.note} // Bind the value to the note state
                                    onChange={(e) => {

                                        setReadyData((p) => ({ ...p, note: e.target.value }))
                                    }}
                                ></textarea>
                            </div>
                            <p className='body-title'>Add Reports</p>
                            <div className='upload-part'>
                                <div className="form-group file-area">
                                    <input type="file" name="images" id="images" required="required" multiple="multiple" />
                                    <div className="file-dummy">
                                        <div className="success">Great, your files are selected. Keep on.</div>
                                        <div className="default"><div className='image-block mb-2'>
                                            <img src={UploadIcon} alt='icon' />
                                        </div>Add report here (png, jpg, pdf)</div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <Link to={"/excersiceList"} state={{ readyData: readyData }}> <Button className='theme-button'>
                            Next
                        </Button></Link>
                        <Link to="/excersiceList" state={{ readyData: readyData }} className="footerLink">Skip for now</Link>
                    </Modal.Footer>
                </Modal>
                <Modal
        show={showDataModal}
        onHide={handleCloseDataModal}
        className=""
        centered
        size="lg"
      >
        <Modal.Header closeButton style={{ justifyContent: "flex-start" }}>
          <div className="w-1000 text-start">
            <span className="exerciseLabfont name p-1">
              {selectedEx?.title}
            </span>
            <br />
          </div>
        </Modal.Header>

        <Modal.Body className="">
        {!isVideoLoaded && (
          <div className="preloader-whirlpool text-center align-items-center">
            <div className="whirlpool"></div>
          </div>
        )}
          <ReactPlayer
          url={selectedEx?.videoLink}
          width="100%"
          height="200px"
          style={{ borderRadius: "14px", display: isVideoLoaded ? 'block' : 'none' }}
          controls
          onReady={handleOnReady}
        />
          <div className="text-part text-start">
            <div className="info-section">
              <span className="label m-2">Equipment:</span>
              <span className="data m-2">
                {selectedEx?.equipment && selectedEx?.equipment?.length > 0
                  ? selectedEx.equipment.map((equip, index) => (
                      <React.Fragment key={index}>
                        {index > 0 && " , "}
                        {equip}
                      </React.Fragment>
                    ))
                  : "No Equipment"}
              </span>
            </div>

            <div className="info-section">
              <span className="label m-2">BodyPart:</span>
              <span className="data m-2">
                {selectedEx?.body_part_id &&
                  selectedEx?.body_part_id?.length > 0 &&
                  selectedEx?.body_part_id.map((itemEq, ind) => (
                    <React.Fragment key={ind}>
                      {ind > 0 && " , "}
                      {itemEq?.title}
                    </React.Fragment>
                  ))}
              </span>
            </div>

            <div className="info-section">
              <span className="label m-2">Description:</span>
              <div className="data m-2">
                {selectedEx?.description &&
                  selectedEx?.description
                    .split(".")
                    .filter((sentence) => sentence.trim() !== "")
                    .map((sentence, index) => (
                      <React.Fragment key={index}>
                        <span className="">{index + 1}.</span>
                        <span className="exerciseLabfontSUB name p-1">
                          {sentence.trim()}.
                        </span>
                        <br /> <br />
                      </React.Fragment>
                    ))}
              </div>
            </div>
          </div>
          <div className='text-center'>
          <button className="theme-button btn btn-primary" style={{"min-width":"300px","padding":"14px"}} onClick={(e) =>{
            e.preventDefault();
            handleCloseDataModal()
          }}>Close</button>
          </div>
        </Modal.Body>
      </Modal>
            </Layout>
        </>
    )
}

export default ExerciseEdit;
