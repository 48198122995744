import { useLocation } from 'react-router-dom'
function PageLink() {
    const pageLinks = {
      "/dashboard": "https://youtu.be/nwRRtTatioY?si=6pOmd7ZUJmelV4R8",
      "/personal-information": "https://youtu.be/d1eu6lhCdz4?si=9x4brc_6NynS6Pas",
      "/operationalInformation":"https://youtu.be/d1eu6lhCdz4?si=9x4brc_6NynS6Pas",
      "/contactInformation": "https://youtu.be/d1eu6lhCdz4?si=9x4brc_6NynS6Pas",
      "/allPatient": "https://youtu.be/_39agTNJVV4?si=hbr-JTRxm0JWjr0J",
      "/patientDetails": "https://youtu.be/LOQnta-kt1A?si=lQMy3iaX9l9-aGzp",
      "/points": "https://youtu.be/CSKvttCAS3g?si=gRlhgueWTzoH1W8V",
      "/surgeryTemplate": "https://youtu.be/s1inkdaJMo4?si=IelC__iT04yQhDgg",
      "/createExercise": "https://youtu.be/UvyS9XCbHY8?si=BE12JiBJrtExNnYs",
      "/selectedExercise": "https://youtu.be/Yn87BXSt5RQ?si=MGg0JUC8wxVuWwhK",
      "/myExercise": "https://youtu.be/IS1RHJvVHuA?si=bTghITshqOlWGo1X",
      "/community": "https://youtu.be/TRFbDIilDLE?si=JGmIXGxdvAcbco4v",
      "/AddBlog": "https://youtu.be/EAIK9xcYWdc?si=eIfBX3YtI7hmAGEn",
      "/appointmentCalendar": "https://youtu.be/-_lJIXWR8BE?si=XfOum421GF2Emz0_",
      "/appointSetting": "https://youtu.be/oB1NM0H3ULk?si=uKy-lTcRE0M3u_F6",
      "/savedExercise": "https://youtu.be/fvB4QV-ObiI?si=fKPwX9KuM-Lmaa_i"
        // Add more pages and corresponding links as needed
      };
      
      const location = useLocation();
      const currentPath = location.pathname;
    
      //alert(currentPath)
      // Get the corresponding YouTube link for the current path
      const link = pageLinks[currentPath];
    
  return (
    <>
      <span
        style={{
          color: "#FF6036",
          textDecoration: "none",
          cursor: "pointer",
          fontSize: "13px",
          whiteSpace: "nowrap"
        }}
      >
        <a
          href={link}
          target="_blank"
          rel="noopener noreferrer"
          style={{
            color: "#FF6036",
            textDecoration: "none",
            cursor: "pointer",
            fontSize: "13px",
          }}
        >
          Help Video - Must Watch &nbsp;
          <svg
            width="16"
            height="11"
            viewBox="0 0 16 11"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M12.8 1.57143C12.8 0.704786 12.0824 0 11.2 0H1.6C0.7176 0 0 0.704786 0 1.57143V9.42857C0 10.2952 0.7176 11 1.6 11H11.2C12.0824 11 12.8 10.2952 12.8 9.42857V6.80979L16 9.42857V1.57143L12.8 4.19021V1.57143Z"
              fill="#FF6036"
            />
          </svg>
        </a>
      </span>
    </>
  );
}

export default PageLink;
