import PDFIcon from "../../assets/images/pdf.svg";

function Documents({ patient }) {
  return (
    <>
      <div className="main-content-part topBox">
        <div className="innerInfo withoutOrange">
          <div
            className="doctorDetailsCard startdata mb-3"
            style={{ cursor: "pointer" }}
          >
            <div className="rightPartData">
              <div className="infoField filed d-flex justify-content-between">
                <div className="data">
                  <h5>Reports Submitted</h5>
                </div>
              </div>

              <div className="filed">
                <div className="session-card-content">
                  {!patient?.report || patient?.report?.length <= 0 && (
                    <div className="text-center">No Records Found</div>
                  )}

                  {patient?.report?.length > 0 && (
                    <div className="doctorDetailsCard startdata">
                      <div className="rightPartData">
                        <div className="filed">
                          <div className="block">
                            {patient.report.map((report, index) => (
                              <div
                                className="report-card"
                                style={{ cursor: "pointer" }}
                                onClick={() => window.open(report, "_blank")}
                                key={index}
                              >
                                <div className="image-block">
                                  <img src={PDFIcon} alt="icon" />
                                </div>
                                <div className="text-block ms-3">
                                  <p className="fileName">{`Report ${
                                    index + 1
                                  }`}</p>
                                  <p className="fileNo">1 file</p>
                                </div>
                              </div>
                            ))}
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
export default Documents;
