import React from "react";
import TopBarNavigation from "../../components/TopBarNavigation";
import Layout from "../../layout";
import ReactPlayer from "react-player";
import {
  Row,
  Col,
  Button,
  Nav,
  Tab,
  Form,
  Collapse,
  Modal,
} from "react-bootstrap";
import SearchIcon from "../../assets/images/search.svg";
import Auth from "../../libs/auth";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useCallback, useEffect, useRef, useState } from "react";
import { UseEffectOnce } from "../../Hook/UseEffectOnce";
import { deletes, get, getAuthConfig, post } from "../../libs/http-hydrate";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import RightIconSvg from '../../assets/images/rightIconSVG.svg'
import ProfileCompletionModal from "../AddProfileData/ProfileCompletionModal";
import PageLink from "../../components/PageLink";

function MyExercise() {
  const location = useLocation();
  const [cart, setCart] = useState([]);
  const [selectedEx, setSelectedEx] = useState({});
  const [showVideoModal, setshowVideoModal] = useState(false);
  const handleCloseVideoModal = () => setshowVideoModal(false);
  const handleShowVideoModal = () => setshowVideoModal(true);
  const [showDataModal, setshowDataModal] = useState(false);
  const handleCloseDataModal = () => setshowDataModal(false);
  const handleShowDataModal = () => setshowDataModal(true);
  const [isLoading, setIsLoading] = useState(false);
  const [showSidebar, setShowSidebar] = useState(false);
  const handleCloseSidebar = () => setShowSidebar(false);
  const handleShowSidebar = () => setShowSidebar(true);
  const [loading, setLoading] = useState(true);
  const user = Auth.getCurrentUser();
  const navigate = useNavigate();
  const [isVideoLoaded, setIsVideoLoaded] = useState(false);

  const handleOnReady = () => {
    setIsVideoLoaded(true);
  };
  const [favoriteExerList, setfavoriteExerList] = useState({
    items: [],
    has_more: false,
  });
  const [page, setPage] = useState(0);
  const [filter, setFilter] = useState({
    searchTerm: "",
  });
  UseEffectOnce(() => {
    getPhysiotherapistLit();
  }, []);
  useEffect(() => {
    if (page >= 1) {
      getPhysiotherapistLit();
    }
  }, [page]);
  useEffect(() => {
    getPhysiotherapistLit();
    // organizeDataByTimeOfDay(readyData);
  }, [filter]);

  const observer = useRef();
  const addToCart = (item) => {
    // Check if the item is already in the cart based on its _id
    const isItemInCart = cart.some((cartItem) => cartItem._id === item._id);

    // If the item is not already in the cart, add it
    if (!isItemInCart) {
      setCart([...cart, item]);
    }
  };
  const removeFromCart = (itemToRemove) => {
    // Use filter to create a new cart array without the item to remove
    const updatedCart = cart.filter((item) => item._id !== itemToRemove._id);
    setCart(updatedCart);
  };
  const lastBookElementRef = useCallback(
    (node) => {
      // if (loading) return;
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && favoriteExerList?.has_more) {
          setPage((prevPageNumber) => prevPageNumber + 1);
        }
      });
      if (node) observer.current.observe(node);
    },
    [favoriteExerList?.has_more]
  );

  async function getPhysiotherapistLit() {
    setLoading(true);
    try {
      let queryParams = `pageNumber=${page}`;

      if (filter?.searchTerm) {
        queryParams += `&search=${filter?.searchTerm}`;
      }
      const data = await get(`/exercises/my?${queryParams}`, getAuthConfig());
      if (data?.status === 200) {
        if (page > 0) {
          setfavoriteExerList((p) => ({
            ...p,
            items: favoriteExerList?.items.concat(data?.data?.exercises),
          }));
        } else {
          setfavoriteExerList((p) => ({ ...p, items: data?.data?.exercises }));
        }
        setfavoriteExerList((p) => ({ ...p, has_more: data?.data?.has_more }));
      }
    } catch (error) {
      if (error?.response?.status === 401) {
        Auth.logout();
        navigate("/login");
      }
    }
    finally {
      setLoading(false);
  }
  }
  const RemoveFromFavorites = async (exerciseId) => {
    try {
      const response = await deletes(
        `/removeFromFavorites?exerciseId=${exerciseId}`
      );
      if (response.status === 200) {
        // Handle success, e.g., show a success message
        // toast.success("Exercise removed from favorites successfully.");
        // Remove the exercise with the given exerciseId from the favoriteExerList.items
        setfavoriteExerList((prevList) => ({
          ...prevList,
          items: prevList.items.filter((item) => item._id !== exerciseId),
        }));
      }
    } catch (err) {
      // Handle API request error, e.g., show an error message
      toast.error(err?.response?.data?.err);
    }
  };

  const [showProfileCompletionModal, setShowProfileCompletionModal] = useState(false);

  const handleAddPatientClick = () => {
    // if (user?.profileCompleted === false) {
    //   setShowProfileCompletionModal(true);
    // } else {
      navigate("/exerciseAdd");
    // }
  };
  return (
    <>
      <Layout>
        <ToastContainer />

        {/* <TopBarNavigation title={"Face"} tab={"Physiotherapist"} tabChild={"Doctor Profile"} /> */}
        <div
          className="main-content-part "
        >
         
          <div className="content-data mt-3">
            <div className="serchPart d-flex justify-content-between align-items-center mb-3">
              <div className="searchInput">
                <div className="searchIcon">
                  <img src={SearchIcon} alt="searchIcon" />
                </div>
                <input
                  type="text"
                  placeholder="Search exercises"
                  value={filter?.searchTerm}
                  onChange={(e) => {
                    e.preventDefault();
                    setPage(0);
                    setFilter((p) => ({ ...p, searchTerm: e?.target?.value }));
                  }}
                />
              </div>
              <PageLink />
            </div>
            <div className="buttonPart d-flex justify-content-center my-2">
            <Button
              className="theme-button"
              onClick={handleAddPatientClick}
              // onClick={(e) => {
              //   e.preventDefault();
              //   navigate("/exerciseAdd");
              // }}
            >
              Add Exercise{" "}
            </Button>
          </div>
            <Row>

            {loading && page === 0 ?  (
                <div className="preloader-whirlpool text-center align-items-center">
                    <div className="whirlpool"></div>
                </div>
            ) : ( <>
            
              {favoriteExerList?.items &&
                favoriteExerList?.items?.length <= 0 && (
                  <div className="text-center">No Exercise Found</div>
                )}
              {favoriteExerList?.items &&
                favoriteExerList?.items?.length > 0 &&
                favoriteExerList?.items?.map((item, index) => {
                  return (
                    <>
                      <Col
                        xl={3}
                        lg={3}
                        md={3}
                        sm={12}
                        ref={lastBookElementRef}
                        key={index}
                      >
                        <div className="assign-doctor-card p-0 px-3 pb-3">
                          <div className="doctor-card-content">
                            <div className="image-part py-3 ">
                              <div
                                className="box-video"
                                style={{ cursor: "pointer" }}
                                onClick={(e) => {
                                  e.preventDefault();
                                  setSelectedEx(item);
                                  handleShowDataModal();
                                }}
                              >
                                <div
                                  className="bg-video"
                                  style={{
                                    "background-image":
                                      "url(https://unsplash.imgix.net/photo-1425036458755-dc303a604201?fit=crop&fm=jpg&q=75&w=1000);",
                                  }}
                                >
                                  <div className="bt-play"></div>
                                </div>
                                <div className="video-container">
                                <img className="" src={item?.exerciseImage}  width="200px"
                                                                                    height="200px"  style={{ borderRadius: "14px" }}/> 
                                                                               
                                  {/* <ReactPlayer
                                    url={item?.videoLink}
                                    width="100%"
                                    height="200px"
                                    style={{ borderRadius: "14px" }}
                                    // controls
                                  /> */}
                                </div>
                              </div>
                              {/* <ReactPlayer
                                                                url={item?.videoLink}
                                                                width="100%"
                                                                height="200px"
                                                                style={{ borderRadius: "14px" }}
                                                                controls
                                                            /> */}
                              {/* <img src={Patient} height="200px" width={"100%"}  style={{borderRadius:"25%"}} alt='Patient' /> */}
                            </div>
                            <div
                              className="text-part text-start "
                              style={{ cursor: "pointer" }}
                              onClick={(e) => {
                                e.preventDefault();
                                setSelectedEx(item);
                                handleShowDataModal();
                              }}
                            >
                              <span className="exerciseLabfont name p-1">
                                {item?.title}
                              </span>
                              <br />
                              {/* <span className='exerciseLabfontSUB name p-1'>LTR - WIG - WAGS - KNEE ROCKS</span> */}
                            </div>

                            <div className="divi pt-2 pb-2">
                              <span className="divider"></span>
                            </div>

                            <div className="d-flex justify-content-between ">
                            <Button className='theme-outline-btn d-block py-1 ' style={{ backgroundColor: '#FDF5F2', borderRadius: "7px", borderColor: '#FDF5F2',   minWidth: "30px",
                                  maxWidth:"30px",   alignItems: "center",
                                  display: "flex",
                                  justifyContent: "center", 
                                  padding:"0",}}
                              
                              onClick={(e) =>{
                                e.preventDefault();
                                navigate("/exerciseAdd",{state:{exercise:item}})
                              }}
                              ><i className="fa fa-pen fa-xs "></i>  </Button>
 <Button
                                className={
                                  cart.some(
                                    (cartItem) => cartItem._id === item._id
                                  )
                                    ? "selectedExeBtn d-block text-center"
                                    : "theme-outline-btn d-block text-center"
                                }
                                style={{
                                  backgroundColor: "#FDF5F2",
                                  borderRadius: "7px",
                                  borderColor: "#FDF5F2",
                                  minWidth: "30px",
                                  maxWidth:"30px",
                                  fontSize: "20px",
                                  lineHeight: "30px",
                                  alignItems: "center",
                                  display: "flex",
                                  justifyContent: "center", 
                                  padding:"0",
                                  border:"1px solid #ff603600"
                                }}
                                onClick={() => {
                                  const isItemInCart = cart.some(
                                    (cartItem) => cartItem._id === item._id
                                  );
                                  if (isItemInCart) {
                                    removeFromCart(item);
                                  } else {
                                    addToCart(item);
                                  }
                                }}
                              >
                                {" "}
                                {cart.some(
                                  (cartItem) => cartItem._id === item._id
                                ) ? (
                                  "✓"
                                ) : (
                                  // Render the '+' sign when the item is not in the cart
                                  "+"
                                )}{" "}
                              </Button>
                             

                            </div>
                          </div>
                        </div>
                      </Col>
                    </>
                  );
                })}
                   </>)}

                   {loading && page > 0 && (
                    <div className="text-center">
                        <div className="whirlpool"></div>
                    </div>
                )}
            </Row>
          </div>
        </div>
        {cart.length > 0 ? (
          <div
            className="center-div p-2 text-center"
            style={{ cursor: "pointer" }}
            onClick={(e) => {
              e.preventDefault();
              setShowSidebar(true);
            }}
          >
            <Nav variant="pills" className="flex-column">
              <Nav.Item className="d-flex justify-content-start align-items-center">
                <div className="checkbox me-2">
                  <input type="checkbox" id="checkbox_6" checked />
                  <label for="checkbox_6"></label>
                </div>
                <Nav.Link eventKey="first" style={{ fontWeight: "500" }}>
                  Excersices
                </Nav.Link>
              </Nav.Item>
            </Nav>

            <h1 style={{ color: "#FF6036" }}>
              {cart.length < 10 ? `0${cart.length}` : cart.length}
            </h1>
          </div>
        ) : (
          ""
        )}
      </Layout>
      <ProfileCompletionModal
        show={showProfileCompletionModal}
        onHide={() => setShowProfileCompletionModal(false)}
        From={"My Exercise"}
      />
      <Modal
        show={showSidebar}
        onHide={handleCloseSidebar}
        className="modal-sidebar"
        size="md"
      >
        <Modal.Header closeButton>
          <Modal.Title className="text-start">
            <div>
              <Nav variant="pills" className="flex-column">
                <Nav.Item className="d-flex justify-content-start align-items-center">
                  <div className="checkbox me-2 sidebarCheckbox">
                    <input
                      type="checkbox"
                      id={`checkbox_${"morning"}`}
                      checked={true}
                    />
                    <label for={`checkbox_${"morning"}`}></label>
                  </div>
                  <Nav.Link eventKey="first">
                    Exercises (
                    {cart.length < 10 ? `0${cart.length}` : cart.length})
                  </Nav.Link>
                </Nav.Item>
              </Nav>
            </div>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="body-content">
            {cart &&
              cart.length > 0 &&
              cart.map((item, index) => {
                return (
                  <>
                    <div
                      className="exercise-card mb-3"
                      style={{ boxShadow: "none" }}
                    >
                      <div className="img-text-part">
                        <div className="img-block">
                          <img
                            src={item?.exerciseImage}
                            height={130}
                            width={180}
                            alt="ex-1"
                          />
                        </div>
                        <div className="text-part ps-3">
                          <h6>{item?.title}</h6>
                          <p style={{ cursor: "pointer" }}>
                          {item?.body_part_id &&
                  item?.body_part_id?.length > 0 &&
                  item?.body_part_id.map((itemEq, ind) => (
                    <React.Fragment key={ind}>
                      {ind > 0 && " - "}
                      {itemEq?.title}
                    </React.Fragment>
                  ))}{" "}
                          </p>
                        </div>
                      </div>
                      <div
                        className="checkbox me-2"
                        style={{ cursor: "pointer" }}
                        onClick={() => removeFromCart(item)}
                      >
                        <svg
                          width="30"
                          height="30"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <line
                            x1="5"
                            y1="5"
                            x2="25"
                            y2="25"
                            stroke="#ADADAD"
                            stroke-width="2"
                          />
                          <line
                            x1="25"
                            y1="5"
                            x2="5"
                            y2="25"
                            stroke="#ADADAD"
                            stroke-width="2"
                          />
                        </svg>
                      </div>
                    </div>

                    <hr />
                  </>
                );
              })}

            {/* <div className='exercise-card mb-3' style={{ boxShadow: "none" }}>
                            <div className='img-text-part'>
                                <div className='img-block'>
                                    <img src={Ex1} height={130} width={180} alt='ex-1' />
                                </div>
                                <div className='text-part ps-3'>

                                    <h6>LOWER TRUNK ROTATION</h6>
                                    <p style={{ cursor: "pointer" }} >LTR - WIG - WAGS - KNEE ROCKS </p>

                                </div>
                            </div>
                            <div className="checkbox me-2" style={{ cursor: "pointer" }} >
                                <svg width="30" height="30" xmlns="http://www.w3.org/2000/svg">
                                    <line x1="5" y1="5" x2="25" y2="25" stroke="#ADADAD" stroke-width="2" />
                                    <line x1="25" y1="5" x2="5" y2="25" stroke="#ADADAD" stroke-width="2" />
                                </svg>



                            </div>
                        </div> */}
          </div>
        </Modal.Body>
        <Modal.Footer>
          {cart.length > 0 && (
            <Link
              to={"/createExercise"}
              state={{
                routine: location?.state?.routine,
                cart: cart,
                bodyPart: location?.state?.bodyPart,
              }}
            >
              {" "}
              <Button className="theme-button">Done</Button>
            </Link>
          )}
        </Modal.Footer>
      </Modal>
      {/* Filter modal */}
      <Modal
        show={showVideoModal}
        onHide={handleCloseVideoModal}
        className="p-0 m-0 modal-content-p0"
        centered
        size="lg"
      >
        <Modal.Header closeButton className="ml-5 withoutBorder" />

        <Modal.Body className="p-0 m-0">
          <ReactPlayer
            url={selectedEx?.videoLink}
            width="100%"
            height="400px"
            style={{ borderRadius: "14px" }}
            // onDuration={handleDuration}
            controls
          />
        </Modal.Body>
      </Modal>
      <Modal
        show={showDataModal}
        onHide={handleCloseDataModal}
        className=""
        centered
        size="md"
      >
        <Modal.Header closeButton className="" />

        <Modal.Body className="">
        {!isVideoLoaded && (
          <div className="preloader-whirlpool text-center align-items-center">
            <div className="whirlpool"></div>
          </div>
        )}
          <ReactPlayer
          url={selectedEx?.videoLink}
          width="100%"
          height="250px"
          style={{ borderRadius: "14px", display: isVideoLoaded ? 'block' : 'none' }}
          controls
          onReady={handleOnReady}
        />
          <div>
            <div className="text-part text-start ">
              <span className="exerciseLabfont name p-1">
                {selectedEx?.title}
              </span>
              <br />

              <span className="exerciseLabfontSUB name p-1">
                {selectedEx?.equipment && selectedEx.equipment.length > 0
                  ? selectedEx.equipment.map((equip, index) => (
                      <React.Fragment key={index}>
                        {index > 0 && " - "}{" "}
                        {/* Display a hyphen for all elements except the first one */}
                        {equip}
                      </React.Fragment>
                    ))
                  : "No Equipment"}
              </span>
            </div>
          </div>
          <div className='text-center'>
          <button className="theme-button btn btn-primary" style={{"min-width":"300px","padding":"14px"}} onClick={(e) =>{
            e.preventDefault();
            handleCloseDataModal()
          }}>Close</button>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}
export default MyExercise;
