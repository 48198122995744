import React from "react";
import {
  Button,
  Col,
  Form,
  Row,
  Nav,
  OverlayTrigger,
  Tooltip,
} from "react-bootstrap";
import TopBarNavigation from "../../components/TopBarNavigation";
import Layout from "../../layout";
import { ReactComponent as UserAssign } from "../../assets/images/userAssi.svg";
import { ReactComponent as Print } from "../../assets/images/print.svg";
import { ReactComponent as Folder } from "../../assets/images/folder.svg";
import { ReactComponent as FolderDownload } from "../../assets/images/folder-download.svg";
import { ReactComponent as AddSvg } from "../../assets/images/Add.svg";
import DeleteSvg from "../../assets/images/delete.svg";
import { useLocation, useNavigate } from "react-router-dom";
import { useCallback, useEffect, useRef, useState } from "react";
import jsPDF from "jspdf";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  get,
  getAuthConfig,
  getAuthConfigImage,
  post,
  put,
} from "../../libs/http-hydrate";
import Modal from "react-bootstrap/Modal";
import { UseEffectOnce } from "../../Hook/UseEffectOnce";
import Auth from "../../libs/auth";
import SearchIcon from "../../assets/images/search.svg";
import FilterIcon from "../../assets/images/filter.svg";
import { ReactComponent as LanguageIcon } from "../../assets/images/LanguageIcon.svg";
import FieldValidationError from "../../common/FieldValidation";
import ReactPlayer from "react-player";
import moment from "moment";
import Multiselect from "multiselect-react-dropdown";
import doneImg from "../../assets/images/doneImg.png";
import checkIcon from "../../assets/images/check (1).png";
import { PDFDownloadLink, pdf } from "@react-pdf/renderer";
import MyDocument from "../MyDocument";
import ProfileCompletionModal from "../AddProfileData/ProfileCompletionModal";
import PatientCommon from "../../assets/images/Patint.png";
import PatientFemale from "../../assets/images/Patient Female.png";
import PatientMale from "../../assets/images/Patient male.png";
import Credit from "../../components/Credit";
import ClinicVerficationModal from "../../components/ClinicVerificationModal";
import ClinicPurchasedModal from "../../components/ClinicPurchasedModal";
import ClinicCreditModal from "../../components/ClinicCreditModal";
import PremiumUserModal from "../../components/PremiumUserModal";
import Animation from "../../Animation/Animation";
import { FaEdit } from "react-icons/fa";
function CreateExercise() {
  const [appName, setAppName] = useState(localStorage.getItem("appName"));
  const [token, setToken] = useState(localStorage.getItem("token"));
  const [userName, setUserName] = useState(localStorage.getItem('userName'))
  const [headerData, setHeaderData] = useState(() => {
    // Fetch data from localStorage
    const storedData = localStorage.getItem("notificationData");
  
    // Parse JSON data if available, otherwise return an empty object
    return storedData ? JSON.parse(storedData) : {};
  });
  const [credit, setCredit] = useState(0);
  const [paymentType, setPaymentType] = useState({
    payment: "",
    paymentTypeError: false,
  });
  const [startDate, setStartDate] = useState("");
  const [days, setDays] = useState("");
  const [isVideoLoaded, setIsVideoLoaded] = useState(false);

  const handleOnReady = () => {
    setIsVideoLoaded(true);
  };
  const [months, setMonths] = useState(""); // Default to 7 as per your given code
  const [note, setNote] = useState("");
  const [validationError, setValidationError] = useState({
    date: false,
    month: false,
  });
  const [startDateError, setStartDateError] = useState("");
  const [loaderAssign, setLoaderAssign] = useState(false);

  const [loading, setLoading] = useState(false); // Add this line
  const [completeWorkout, setCompleteWorkout] = useState(false);
  const [completeEmail, setCompleteEmail] = useState(false);
  const [language, setLanguage] = useState({
    items: [],
    has_more: false,
  });
  const [sendEmail, setSendEmail] = useState(false);
  const [savedWorkout, setSavedWorkout] = useState(false);
  const [selectedEx, setSelectedEx] = useState({});
  const [showVideoModal, setshowVideoModal] = useState(false);
  const handleCloseVideoModal = () => setshowVideoModal(false);
  const handleShowVideoModal = () => setshowVideoModal(true);
  const [showFilterLanguage, setShowFilterLanguage] = useState(false);
  const handleCloseFilterLanguage = () => setShowFilterLanguage(false);
  const handleShowFilterLanguage = () => setShowFilterLanguage(true);
  const [show, setShow] = useState(false);
  const [ printModal,setPrintModal] = useState(false)
  const handleClose = () => {
    setFilter((p) => ({ ...p, title: "" }));
    setShow(false);
  };
  const handleShow = () => setShow(true);
  const location = useLocation();
  const [cart, setCart] = useState([]);
  const [currentDate, setCurrentDate] = useState(() => {
    const now = new Date();
    const currentUTCDate = Date.UTC(
      now.getUTCFullYear(),
      now.getUTCMonth(),
      now.getUTCDate(),
      now.getUTCHours(),
      now.getUTCMinutes(),
      now.getUTCSeconds()
    );
    // Create new date adjusted for IST offset
    const istDate = new Date(currentUTCDate);

    // Format date as YYYY-MM-DD using the adjusted IST date
    const year = istDate.getFullYear(); // Use getFullYear() to get the year of the IST adjusted date
    const month = `${istDate.getMonth() + 1}`.padStart(2, "0"); // Months are 0-indexed, add 1 for correct month
    const day = `${istDate.getDate()}`.padStart(2, "0"); // Use getDate() to get the day of the IST adjusted date

    return `${year}-${month}-${day}`;
  });

  const [data, setData] = useState(() => {
    // Retrieve the item from localStorage
    const storedData = localStorage.getItem("notificationData");
    if (storedData) {
      try {
        // Parse it to an object and return as initial state
        return JSON.parse(storedData);
      } catch (error) {
        console.error(
          "Error parsing notificationData from localStorage:",
          error
        );
        // Return a fallback initial state if parsing fails
        return {};
      }
    }
    // Return an initial state if nothing is stored in localStorage
    return {};
  });
  const [showClinicModal, setShowClinicModal] = useState(false);
  const [showClinicPurchasedModal, setShowClinicPurchasedModal] = useState(false);
  const [showClinicCreditdModal, setShowClinicCreditdModal] = useState(false);
  const [showPremiumModal, setShowPremiumModal] = useState(false);

  const [Workout, setWorkout] = useState({
    name: "",
    nameError: false,
  });
  const [modalShow, setModalShow] = useState(false);
  const [modalShowEmail, setModalShowEmail] = useState(false);
  const [showNote, setShowNote] = useState(false);
  const handleCloseNote = () => setShowNote(false);
  const handleShowNote = () => setShowNote(true);
  const [patientName, setPatientName] = useState("");
  const [patientEmail, setPatientEmail] = useState({
    email: "",
    emailError: false,
    apiError: "",
  });
  const [downloadLink, setDownloadLink] = useState("");

  const handleModalClose = () => setModalShow(false);
  const handleModalShow = () => setModalShow(true);
  const handleModalCloseEmail = () => setModalShowEmail(false);
  const handleModalShowEmail = () => setModalShowEmail(true);

  const handleNameChange = (e) => setPatientName(e.target.value);
  const handleEmailChange = (e) =>
    setPatientEmail((p) => ({
      ...p,
      email: e.target.value,
      emailError: false,
      apiError: "",
    }));

  const handleFormSubmit = (e) => {
    e.preventDefault();
    const inputDate = new Date(startDate);
    const today = new Date(getTodayDate());

    if (!startDate) {
      setValidationError((p) => ({ ...p, date: true }));
      return false;
    } else if (inputDate < today) {
      // Ensure this comparison works correctly
      setStartDateError("Start date cannot be before today's date.");
      return false;
    }

    if (!months && !days) {
      setValidationError((p) => ({ ...p, month: true }));
      return false;
    }
    handleModalClose();
    // Set the download link after the name is submitted
    setDownloadLink(
      <PDFDownloadLink
        document={
          <MyDocument
          PatientName={patientName}
          ProgramName={Workout?.name}
          cart={cart}
          startDate={startDate}
          numberOfDays={calculateTotalDays()}
          notes={note}
          language={filterlanguage?.code}
          clinic_image={headerData?.clinic_image}
          selectedPatientsDetail={selectedPatientsDetail}

          />
        }
        fileName="fitness_calendar.pdf"
      >
        {({ blob, url, loading, error }) =>
          loading ? "Loading document..." : <Print />
        }
      </PDFDownloadLink>
    );
    downloadPDF();
  };
  const [showFilter, setShowFilter] = useState(false);
  const handleCloseFilter = () => {
    setShowFilter(false);
  };
  const handleShowFilter = () => setShowFilter(true);
  const [bodyPartData, setBodyPart] = useState({
    has_more: false,
    items: [
      {
        title: "Today",
      },
      {
        title: "Upcoming",
      },
      {
        title: "Monthly",
      },
      {
        title: "Weekly",
      },
    ],
  });
  const option = {
    objectArray: [
      { key: "Morning", cat: "morning" },
      { key: "Afternoon", cat: "afternoon" },
      { key: "Evening", cat: "evening" },
      { key: "Night", cat: "night" },
    ],
  };
  const nameInputRef = useRef(null);
  // Define state to store generated workouts
  const [generatedWorkouts, setGeneratedWorkouts] = useState({
    morning: [],
    evening: [],
    afternoon: [],
    night: [],
    name: "",
  });

  useEffect(() => {
    if (location?.state?.cart) {
      if (location?.state?.cart) {
        // Check if 'time' property is missing or has length <= 0 in each item
        const updatedCart = location.state.cart.map((item) => {
          if (!item.time || item.time.length <= 0) {
            // Add a default time value here (e.g., 0 for no time)
            return { ...item, time: [{ key: "Morning", cat: "morning" }] };
          }
          return item;
        });

        // Set the cart state with the updated cart
        setCart(cart?.concat(updatedCart));
      }
      // Set the cart state with the value from location.state.cart
    }
    if (location?.state?.cart && !location?.state?.routine) {
      getRotineName();
    }
  }, [location?.state?.cart]);
  const removeFromCart = (itemToRemove) => {
    // Use filter to create a new cart array without the item to remove
    const updatedCart = cart.filter((item) => item._id !== itemToRemove._id);
    setCart(updatedCart);
    if (updatedCart.length === 0) {
      navigate("/points")
    }
  };

  const handleRepsChange = (itemId, value) => {
    setCart((prevCart) =>
      prevCart.map((item) =>
        item._id === itemId ? { ...item, set: value } : item
      )
    );
  };

  const handleCompleteChange = (itemId, value) => {
    setCart((prevCart) =>
      prevCart.map((item) =>
        item._id === itemId ? { ...item, count: value } : item
      )
    );
  };

  const handleRestChange = (itemId, value) => {
    setCart((prevCart) =>
      prevCart.map((item) =>
        item._id === itemId ? { ...item, restTime: value } : item
      )
    );
  };

  const handleHoldChange = (itemId, value) => {
    setCart((prevCart) =>
      prevCart.map((item) =>
        item._id === itemId ? { ...item, hold: value } : item
      )
    );
  };

  const handleTimesChange = (itemId, value) => {
    setCart((prevCart) =>
      prevCart.map((item) =>
        item._id === itemId ? { ...item, time: value } : item
      )
    );
  };
  const generatePDF = async () => {
    const doc = new jsPDF();
    const pageWidth = doc.internal.pageSize.width;
    const margin = 20;
    const maxPageHeight = 250;
    let yOffset = 20;

    // Add the Workout Name as a title
    doc.setFont("Poppins", "bold");
    doc.setFontSize(20);
    doc.text(Workout?.name, pageWidth / 2, yOffset, { align: "center" }); // Center-align the text
    const loadImage = (url) => {
      return new Promise((resolve, reject) => {
        const img = new Image();
        img.onload = () => resolve(img);
        img.onerror = () => reject(new Error("Image load error"));
        img.src = url;
      });
    };

    let currentPage = 1;
    let itemsOnPage = 0;

    function addFooter() {
      doc.setPage(currentPage); // Set the current page for the footer
      doc.setFont("Poppins", "normal");
      doc.setFontSize(12);
      doc.text(pageWidth / 2, 293, "All Rights Reserved By : VirtuaLife", {
        align: "center",
      }); // Footer text
      doc.page++;
    }

    for (const item of cart) {
      const remainingSpace = maxPageHeight - yOffset;

      if (item.exerciseImage) {
        try {
          const image = await loadImage(item.exerciseImage);
          const imgWidth = 30;
          const imgHeight = (imgWidth * image.height) / image.width;
          const minSpacing = 5; // Adjust the minimum vertical spacing between items

          if (remainingSpace < imgHeight + minSpacing) {
            addFooter();
            doc.addPage();
            yOffset = 10; // Reset yOffset for a n page
            currentPage++;
            itemsOnPage = 0;
          }

          doc.addImage(
            image,
            "JPEG",
            margin,
            yOffset + imgHeight,
            imgWidth,
            imgHeight
          );
          doc.setFont("Poppins", "bold");
          doc.setFontSize(15);
          doc.text(item.title, margin + imgWidth + 10, yOffset + imgHeight + 1);

          doc.setFont("Poppins", "normal");
          doc.setFontSize(10);
          const descriptionLines = doc.splitTextToSize(
            item.description,
            pageWidth - margin * 2 - imgWidth - 20
          );
          const descriptionHeight = descriptionLines.length * 5;
          const textContentHeight = descriptionHeight + 10;
          doc.text(
            descriptionLines,
            margin + imgWidth + 10,
            yOffset + imgHeight + 10
          );
          doc.text(
            `Reps: ${item.set}`,
            margin + imgWidth + 10,
            yOffset + imgHeight + textContentHeight + 5
          );
          doc.text(
            `Count: ${item.count}`,
            margin + imgWidth + 10,
            yOffset + imgHeight + textContentHeight + 10
          );
          doc.text(
            `Rest Time: ${item.restTime}`,
            margin + imgWidth + 10,
            yOffset + imgHeight + textContentHeight + 15
          );

          if (item?.time && Array.isArray(item.time) && item.time.length > 0) {
            const timesText = item.time
              .map((timeObj) => timeObj?.cat)
              .join(", ");
            doc.text(
              `Times: ${timesText}`,
              margin + imgWidth + 10,
              yOffset + imgHeight + textContentHeight + 20
            );
          }

          // if (item?.time) {
          //     doc.text(`Times: ${item.time}`, margin + imgWidth + 10, yOffset + imgHeight + textContentHeight + 20);
          // }

          yOffset += imgHeight + textContentHeight + minSpacing; // Reduce the vertical spacing
          itemsOnPage++;
        } catch (error) {
          console.error("Error loading image:", error);
        }
      }
    }
    // Add a footer to the last page
    addFooter();

    const pdfBlob = doc.output("blob");
    const pdfBlobUrl = URL.createObjectURL(pdfBlob);
    const newTab = window.open(pdfBlobUrl, "_blank");
  };
  async function generateWorkout(modalOpen) {
    if (Workout?.name.trim() === "" && !modalOpen) {
      const inputField = nameInputRef?.current?.querySelector("input");

      if (inputField) {
        inputField.focus(); // Focus on the input field
        nameInputRef.current.scrollIntoView({ behavior: "smooth" });

        setWorkout((p) => ({ ...p, nameError: true }));

        return false;
      }
    }

    // Create an object to store workouts for each time slot
    // const workoutsByTime = {
    //     _id: location?.state?.routine?._id ? location?.state?.routine?._id : undefined,
    //     morning: [],
    //     evening: [],
    //     afternoon: [],
    //     night: [],
    //     name: Workout?.name.trim() !== "" ? Workout?.name : location?.state?.bodyPart,
    // };
    const workoutsByTime = {
      name:
        Workout?.name.trim() !== "" ? Workout?.name : location?.state?.bodyPart,
      _id: location?.state?.routine?._id
        ? location?.state?.routine?._id
        : undefined,
    };

    // Loop through the items in the cart
    for (const cartItem of cart) {
      if (!cartItem.time || cartItem.time.length <= 0) {
        // Scroll to the item
        const itemRef = document.getElementById(
          `exercise-item-${cartItem._id}`
        );
        if (itemRef) {
          itemRef.classList.add("field-error");
          itemRef.scrollIntoView({ behavior: "smooth" });
          itemRef.focus();
        }
        return false;
      } else {
        const modifiedDescription =
        modifiedExercises[cartItem._id] || cartItem.description;
  
        // Create a new exercise item based on the cart item and exercise details
        const exerciseItem = {
          exercise_id: cartItem._id,
          count: cartItem.count,
          set: cartItem.set,
          description:
          (filterlanguage?.code?.trim() !== "EN" ||  modifiedExercises[cartItem._id] ) ? cartItem.description : null,
          restTime: cartItem.restTime,
          hold: cartItem.hold,
          timeRange: 120,
          notes: specialNotes[cartItem._id]?.show
            ? specialNotes[cartItem._id]?.text
            : undefined,
        };

        // Loop through the time slots of the cart item
        for (const timeObj of cartItem.time) {
          // Check if the time slot already has workouts, if not, create a new workout
          if (!workoutsByTime[timeObj.cat]) {
            workoutsByTime[timeObj.cat] = [
              {
                name:
                  Workout?.name.trim() !== ""
                    ? Workout?.name
                    : location?.state?.bodyPart,
                image: location?.state?.bodyPartImage,
                workout: [],
              },
            ];
          }

          // Push the exercise item into the workout for the current time slot
          workoutsByTime[timeObj?.cat][0].workout.push(exerciseItem);
        }
      }
    }

    // Now workoutsByTime will contain separate workout items for each time slot with the appropriate exercises.

    // Update the state with the generated workouts
    setGeneratedWorkouts(workoutsByTime);
    if (modalOpen === "modalOpen") {
      handleShow();
    } else {
      if (location?.state?.routine?._id) {
        EditRoutine(workoutsByTime);
      } else {
        saveRoutine(workoutsByTime);
      }
    }
  }

  async function saveRoutine(workoutsByTime) {
    try {
      const response = await post(
        "/savedWorkout",
        workoutsByTime,
        getAuthConfig()
      )
        .then((data) => {
          if (data?.status === 200) {
            setSavedWorkout(true);
            // navigate("/savedExercise")

            // toast.success("Routine Added successfully");
          }
        })
        .catch((err) => {
          toast.error(err?.response?.data?.err);
        });
    } catch (error) {
      toast.error(error);
    }
  }

  async function EditRoutine(workoutsByTime) {
    try {
      const response = await put(
        "/editWorkout",
        workoutsByTime,
        getAuthConfig()
      )
        .then((data) => {
          if (data?.status === 200) {
            navigate("/savedExercise");
          }
        })
        .catch((err) => {
          toast.error(err?.response?.data?.err);
        });
    } catch (error) {
      toast.error(error);
    }
  }
  const user = Auth.getCurrentUser();

  const navigate = useNavigate();
  const [patientList, setPatientList] = useState({
    items: [],
    has_more: false,
  });
  const [page, setPage] = useState(0);
  const [filter, setFilter] = useState({
    searchTerm: "",
  });
  const [filterlanguage, setFilterlanguage] = useState({
    code: "EN",
  });
  const [selectedPatients, setSelectedPatients] = useState([]);
  const [selectedPatientsDetail, setSelectedPatientsDetail] = useState({});

  useEffect(() => {
    if (page >= 1 && show === true && !location?.state?.patient_id) {
      getPhysiotherapistLit();
    }
  }, [page, show]);
  useEffect(() => {
    if (show === true && !location?.state?.patient_id) {
      getPhysiotherapistLit();
    }
    // organizeDataByTimeOfDay(readyData);
  }, [filter, show]);
  UseEffectOnce(() => {
    if (location?.state?.patient_id) {
      setSelectedPatients((prevSelected) => [
        ...prevSelected,
        location?.state?.patient_id,
      ]);
      setPatientList((p) => ({
        ...p,
        items: [location?.state?.patientDetail, ...patientList?.items],
      }));

      setSelectedPatientsDetail(location?.state?.patientDetail);
    }
  }, [location?.state]);
  const observer = useRef();
  const lastBookElementRef = useCallback(
    (node) => {
      // if (loading) return;
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && patientList?.has_more) {
          setPage((prevPageNumber) => prevPageNumber + 1);
        }
      });
      if (node) observer.current.observe(node);
    },
    [patientList?.has_more]
  );

  async function getPhysiotherapistLit() {
    setLoading(true); // Start loading

    try {
      let queryParams = `pageNumber=${page}`;

      if (filter?.searchTerm) {
        setLoading(false); // Start loading
        queryParams += `&searchTerm=${filter?.searchTerm}`;
      }
      if (filter?.title) {
        queryParams += `&appointmentType=${filter?.title.toLowerCase()}`;
      }
      const data = await get(`/patientList?${queryParams}`, getAuthConfig());
      if (data?.status === 200) {
        if (page > 0) {
          setPatientList((p) => ({
            ...p,
            items: patientList?.items.concat(data?.data?.data),
          }));
        } else {
          setPatientList((p) => ({ ...p, items: data?.data?.data }));
        }
        setPatientList((p) => ({ ...p, has_more: data?.data?.has_more }));
      }
    } catch (error) {
      if (error?.response?.status === 401) {
        Auth.logout();
        navigate("/login");
      }
    } finally {
      setLoading(false); // Stop loading
    }
  }

  async function getRotineName() {
    try {
      const data = await get(`/routineName`, getAuthConfig());
      if (data.status === 200) {
        if (data?.data?.msg) {
          setWorkout((p) => ({ ...p, name: data?.data?.msg }));
        }
      }
    } catch (error) {
      if (error?.response?.status === 401) {
        Auth.logout();
        navigate("/login");
      }
    }
  }

  async function getLanguageList() {
    try {
      const data = await get(`/api/languages`, getAuthConfig());
      if (data?.status === 200) {
        if (data?.data?.msg) {
          setLanguage((p) => ({ ...p, items: data?.data?.msg }));
        }
      }
    } catch (error) {
      if (error?.response?.status === 401) {
        Auth.logout();
        navigate("/login");
      }
    }
  }

  async function getLanguageDescription() {
    try {
      if (filterlanguage.code === "EN") {
        // Reset descriptions to original values while preserving the current time values
        if (location?.state?.cart) {
          const updatedCart = location.state.cart.map((originalItem) => {
            // Find the corresponding item in the current cart state
            const currentItem = cart.find(
              (item) => item._id === originalItem._id
            );
            // Use the current time values if available, otherwise use originalItem's time
            const currentTime = currentItem
              ? currentItem.time
              : originalItem.time;
            return { ...originalItem, time: currentTime };
          });
          setCart(updatedCart);
        }
        return;
      }
      // Assuming your cart state is accessible and named "cart"
      const exerciseIds = cart.map((exercise) => exercise._id); // Extracts IDs from the cart state.
      const languageCode = filterlanguage.code; // The language code for which you want descriptions

      // Constructing the query parameters for the API request
      let queryParams = `languageCode=${languageCode}`;
      exerciseIds.forEach((id, index) => {
        queryParams += `&exerciseIds[${index}]=${id}`;
      });

      let url = `/api/exerciseDescriptions?${queryParams}`;
      const data = await get(url, getAuthConfig()); // Ensure get is properly defined to handle the request
      if (data?.status === 200 && data?.data?.descriptions) {
        // Map descriptions back to cart items
        const updatedCart = cart.map((item) => {
          // Find the matching description for this item
          const description = data.data.descriptions.find(
            (desc) => desc.exerciseId === item._id
          );
          if (description) {
            // Update description in the item
            return { ...item, description: description.text };
          }
          return item; // Return item unchanged if no description was found
        });

        // Update cart state with new descriptions
        setCart(updatedCart);
      }
    } catch (error) {
      if (error?.response && error?.response?.status === 401) {
        Auth.logout();
        navigate("/login");
      } else {
        // Handle other errors
        console.error("Failed to fetch language descriptions", error);
      }
    }
  }

  const handleCheckboxChange = (patientId, detail) => {
    setSelectedPatients((prevSelected) => {
      if (prevSelected.includes(patientId)) {
        // If the patient ID is already selected, clear the selection
        setSelectedPatientsDetail("");
        return [];
      } else {
        // Set the selected patient ID
        setSelectedPatientsDetail(detail);
        return [patientId];
      }
    });
    //setSelectedPatients([patientId]); // Set the selected patient ID at the zero index
    // if (selectedPatients.includes(patientId)) {
    //   // If the patient ID is already in the selectedPatients array, remove it
    //   setSelectedPatients((prevSelected) =>
    //     prevSelected.filter((id) => id !== patientId)
    //   );
    // } else {
    //   // If the patient ID is not in the selectedPatients array, add it
    //   setSelectedPatients((prevSelected) => [...prevSelected, patientId]);
    // }
  };

  async function AssignRoutinetoThePatient(recievedWorkout, modal) {
    try {
      setLoaderAssign(true);
      const response = await post(
        "/addWorkoutsToPatients",
        {
          workout: recievedWorkout,
          patientList: selectedPatients,
          isRenewWorkout: location?.state?.isRenew
            ? location?.state?.isRenew
            : undefined,
          paymentType: paymentType?.payment,
        },
        getAuthConfig()
      )
        .then((data) => {
          if (data?.status === 200) {
            handleCloseNote();
            setCompleteWorkout(true);
          }
          if(modal){
            handleModalClose();
          }
          setLoaderAssign(false);
        })
        .catch((err) => {
          toast.error(err?.response?.data?.err);
          setLoaderAssign(false);
        });
    } catch (error) {
      toast.error(error);
      setLoaderAssign(false);
    }
  }
  const formattedCart = cart
    .map((item, index) => {
      const itemId = item._id;
      const imageSrc = item?.exerciseImage;

      return `
      Item ${index + 1}:
      Title: ${item?.title}
      Description: ${item?.description}
      Reps: ${item?.set}
      Complete: 01
      Times: ${item?.time?.map((timeObj) => timeObj?.cat).join(", ")}
      Holds: 5 seconds
      Perform: ${item?.count}
    `;
    })
    .join("\n\n");

  const emailBody = encodeURIComponent(`
   
        ${formattedCart}

  `);

  // Add an effect to update the cart based on location.state.routine
  useEffect(() => {
    if (location?.state?.routine) {
      if (location?.state?.cart) {
        if (location?.state?.routine?.name) {
          setWorkout((p) => ({ ...p, name: location?.state?.routine?.name }));
        }
        if (location?.state?.routine?.date) {
          setCurrentDate(
            new Date(location?.state?.routine?.date).toISOString().split("T")[0]
          );
        }
        // return false
      } else {
        if (location?.state?.routine?.name) {
          setWorkout((p) => ({ ...p, name: location?.state?.routine?.name }));
        }
        if (location?.state?.routine?.date) {
          setCurrentDate(
            new Date(location?.state?.routine?.date).toISOString().split("T")[0]
          );
        }

        // Calculate the new cart items based on location.state.routine
        const newCart = calculateCart(location.state.routine);
        setCart(cart?.concat(newCart));
      }
    }
  }, [location?.state?.routine]);

  // Function to calculate the cart items based on routine
  function calculateCart(routine) {
    let arr = [];
    // Initialize a Set to keep track of added exercise IDs
    const addedExerciseIds = new Set();

    // Iterate through the routine's morning, evening, afternoon, and night arrays
    ["morning", "evening", "afternoon", "night"].forEach((timeOfDay) => {
      if (routine[timeOfDay] && Array.isArray(routine[timeOfDay])) {
        routine[timeOfDay].forEach((workout) => {
          if (workout.workout && Array.isArray(workout.workout)) {
            workout.workout.forEach((exercise) => {
              const exerciseId = exercise.exercise_id._id;

              // Check if the exercise ID has already been added
              if (!addedExerciseIds.has(exerciseId)) {
                // Push a new object containing exercise_id and time/session info
                arr.push({
                  ...exercise.exercise_id,
                  hold: exercise?.hold,
                  count: exercise?.count,
                  set: exercise?.set,
                  restTime: exercise?.restTime,
                  // ...exercise,
                  time: [{ key: titleCase(timeOfDay), cat: timeOfDay }],
                });

                // Add the exercise ID to the Set to mark it as added
                addedExerciseIds.add(exerciseId);
              } else {
                // Find the existing exercise entry in arr and update its time info
                const existingExercise = arr.find(
                  (entry) => entry._id === exerciseId
                );
                if (existingExercise) {
                  existingExercise.time.push({
                    key: titleCase(timeOfDay),
                    cat: timeOfDay,
                  });
                }
              }
            });
          }
        });
      }
    });
    return arr;
  }
  function titleCase(str) {
    var splitStr = str.toLowerCase().split(" ");
    for (var i = 0; i < splitStr.length; i++) {
      // You do not need to check if i is larger than splitStr length, as your for does that for you
      // Assign it back to the array
      splitStr[i] =
        splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
    }
    // Directly return the joined string
    return splitStr.join(" ");
  }

  const [specialNotes, setSpecialNotes] = useState({});

  const handleSpecialNotesToggle = (itemId, isChecked) => {
    setSpecialNotes((prevNotes) => ({
      ...prevNotes,
      [itemId]: { ...prevNotes[itemId], show: isChecked },
    }));
  };

  const handleSpecialNotesChange = (itemId, text) => {
    setSpecialNotes((prevNotes) => ({
      ...prevNotes,
      [itemId]: { ...prevNotes[itemId], text: text },
    }));
  };
  async function translateText(text, sourceLang, targetLang) {
    try {
      const response = await fetch("https://libretranslate.de/translate", {
        method: "POST",
        body: JSON.stringify({
          q: text,
          source: sourceLang,
          target: targetLang,
          format: "text",
        }),
        headers: { "Content-Type": "application/json" },
      });
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      const data = await response.json();
      return data.translatedText;
    } catch (error) {
      console.error("There was a problem with the fetch operation:", error);
    }
  }
  const [showProfileCompletionModal, setShowProfileCompletionModal] =
    useState(false);
  const isValidEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };
  const handleFormSubmitEmail = async (e) => {
    e.preventDefault();
    if (!patientEmail.email || !isValidEmail(patientEmail.email)) {
      setPatientEmail((prev) => ({ ...prev, emailError: true }));
      return; // Stop submission if email is not valid
    }
    const inputDate = new Date(startDate);
    const today = new Date(getTodayDate());

    if (!startDate) {
      setValidationError((p) => ({ ...p, date: true }));
      return false;
    } else if (inputDate < today) {
      // Ensure this comparison works correctly
      setStartDateError("Start date cannot be before today's date.");
      return false;
    }

    if (!months && !days) {
      setValidationError((p) => ({ ...p, month: true }));
      return false;
    }

    // API call to send the email with the PDF attachment
    try {
      setSendEmail(true);

      // Generate the PDF document
      const doc = (
        <MyDocument
        PatientName={patientName}
        ProgramName={Workout?.name}
        cart={cart}
        startDate={startDate}
        numberOfDays={calculateTotalDays()}
        notes={note}
        language={filterlanguage?.code}
        clinic_image={headerData?.clinic_image}
        selectedPatientsDetail={selectedPatientsDetail}
        />
      );
      const asPdf = pdf([]); // Empty array as placeholder
      asPdf.updateContainer(doc);
      const blob = await asPdf.toBlob();

      // Prepare the data to be sent via API
      let formData = new FormData();
      formData.append("pdf", blob, "workout.pdf");
      formData.append("email", patientEmail.email);

      const response = await post("/send-pdf", formData, getAuthConfigImage());
      if (response?.status === 200) {
        // toast.success('Email sent successfully!');
        handleModalCloseEmail(); // Close the modal
        setCompleteEmail(true);
      } else {
        toast.error("Failed to send email.");
      }
    } catch (error) {
      setPatientEmail((p) => ({ ...p, apiError: error?.response?.data?.err }));
      // toast.error(error?.response?.data?.err);
    } finally {
      setSendEmail(false);
    }
  };
  async function downloadPDF() {
    try {
      const inputDate = new Date(startDate);
      const today = new Date(getTodayDate());

      if (!startDate) {
        setValidationError((p) => ({ ...p, date: true }));
        return false;
      } else if (inputDate < today) {
        // Ensure this comparison works correctly
        setStartDateError("Start date cannot be before today's date.");
        return false;
      }

      if (!months && !days) {
        setValidationError((p) => ({ ...p, month: true }));
        return false;
      }

      // Generate the PDF document
      const doc = (
        <MyDocument
        PatientName={patientName}
        ProgramName={Workout?.name}
        cart={cart}
        startDate={startDate}
        numberOfDays={calculateTotalDays()}
        notes={note}
        language={filterlanguage?.code}
        clinic_image={headerData?.clinic_image}
        selectedPatientsDetail={selectedPatientsDetail}
        />
      );
      const asPdf = pdf([]); // Empty array as placeholder
      try {
        asPdf.updateContainer(doc);
    } catch (error) {
        console.error("Error generating PDF:", error);
    }
          const blob = await asPdf.toBlob();
          setLoaderAssign(true);

      // Prepare the data to be sent via API
      let formData = new FormData();
      formData.append("pdf", blob, "workout.pdf");
      formData.append("name", patientName);
      await post("/printWorkout", formData, getAuthConfigImage());
      prepareAndAssignRoutine("modalClose");
      //handleModalClose();
    } catch (error) {}
  }

  const calculateTotalDays = () => {
    // Parse days and months, defaulting days to 0 if not provided or selected as 0
    const parsedDays = parseInt(days, 10) || 0;
    const parsedMonths = parseInt(months, 10) || 0;

    // Start with calculating total days based on months, assuming 30 days per month
    let totalDays = parsedDays + parsedMonths * 30;

    // // Special handling for February
    // if (parsedMonths === 2) {
    //     // If days is more than 15 in February, adjust totalDays considering February typically has 28 days
    //     // Since we are adding days separately, we don't add them again for February
    //     totalDays = (parsedDays > 15 ? 28 : parsedDays) + (parsedMonths - 1) * 30;
    // }

    return totalDays;
  };
  const getTodayDate = () => {
    const today = new Date();
    const year = today.getFullYear();
    // Month is 0-indexed in JavaScript, so add 1; ensure it's two digits with .padStart()
    const month = (today.getMonth() + 1).toString().padStart(2, "0");
    const day = today.getDate().toString().padStart(2, "0");
    return `${year}-${month}-${day}`;
  };

  const handleDateChange = (e) => {
    // Clear previous error message
    setStartDateError("");
    setValidationError((p) => ({ ...p, date: false }));
    setStartDate(e.target.value);
  };

  const handleDaysChange = (e) => {
    setDays(e.target.value);
    setValidationError((p) => ({ ...p, month: false }));
  };

  const handleMonthsChange = (e) => {
    setMonths(e.target.value);
    setValidationError((p) => ({ ...p, month: false }));
  };

  const updateWorkoutsWithDateAndDays = () => {
    const totalDays = calculateTotalDays(); // Assuming this function is defined elsewhere and calculates correctly

    // Iterate through each session in generatedWorkouts
    const updatedWorkouts = Object.entries(generatedWorkouts).reduce(
      (acc, [sessionKey, sessionValue]) => {
        // Check if sessionValue is an array and proceed accordingly
        if (Array.isArray(sessionValue)) {
          // It's an array, so we can map over it
          const updatedSession = sessionValue.map((workoutSession) => ({
            ...workoutSession,
            startDate, // assuming startDate is already defined and formatted correctly
            daysToComplete: totalDays,
            note: note,
          }));
          acc[sessionKey] = updatedSession;
        } else {
          // Handle non-array sessionValue, if necessary. This part depends on the expected structure of your data
          console.warn(
            `Expected an array for session '${sessionKey}', received:`,
            sessionValue
          );
          // If you expect some sessions might not be arrays but still need to be processed, adjust the logic here
        }
        return acc;
      },
      {}
    );

    return updatedWorkouts;
  };

  const prepareAndAssignRoutine = async (modal) => {
    const inputDate = new Date(startDate);
    const today = new Date(getTodayDate());

    if (!startDate) {
      setValidationError((p) => ({ ...p, date: true }));
      return;
    } else if (inputDate < today) {
      // Ensure this comparison works correctly
      setStartDateError("Start date cannot be before today's date.");
      return;
    }

    if (!months && !days) {
      setValidationError((p) => ({ ...p, month: true }));
      return;
    }

    // Reset validation error
    setValidationError("");

    if (selectedPatientsDetail?.isAssignedExercise === false) {
      if (!paymentType?.payment) {
        setPaymentType((p) => ({ ...p, paymentTypeError: true }));
        return false;
      }
    }

    if(selectedPatientsDetail?.isAssignedExercise === true && selectedPatientsDetail?.workoutGivenBy != user?._id )
    {
      if (!paymentType?.payment) {
        setPaymentType((p) => ({ ...p, paymentTypeError: true }));
        return false;
      }
    }
    // Update workouts with startDate and totalDays
    const updatedWorkouts = updateWorkoutsWithDateAndDays();
    // Proceed with assigning routine using updatedWorkouts
    try {
      await AssignRoutinetoThePatient(updatedWorkouts,modal);
      // handle success, e.g., closing modal, showing success message
    } catch (error) {
      // handle errors, e.g., showing error message
    }
  };
  const inputDate = new Date(startDate);
  const today = new Date(getTodayDate());



  const generateAndSendPDF = async () => {
    if (appName && userName && token) {
      try {
        const doc = (
          <MyDocument
            PatientName={userName} // You can set a default patient name if needed
            ProgramName={Workout?.name}
            cart={cart}
            startDate={startDate}
            numberOfDays={calculateTotalDays()}
            notes={note}
            language={filterlanguage?.code}
            clinic_image={headerData?.clinic_image}
            selectedPatientsDetail={selectedPatientsDetail}
          />
        );
        const asPdf = pdf([]); // Initialize the PDF
        asPdf.updateContainer(doc);
        const blob = await asPdf.toBlob();
  
        // Create FormData for API call
        let formData = new FormData();
        formData.append("pdf", blob, "workout.pdf");
        formData.append("patientName", userName);
  
        // Send the PDF via API
        const response = await post("/createAssignment", formData, getAuthConfigImage());
  
       
          toast.success("Workout Assigned successfully successfully!");
        
          handleCloseNote()
      } catch (error) {
        toast.error("An error occurred while generating the PDF.");
        console.error("Error generating PDF:", error);
      }
    } else {
      // Show the modal for patient details if appName, userName, and token are not available
      handleModalShow();
    }
  };
  const [editingDescription, setEditingDescription] = useState(null);
  const [modifiedExercises, setModifiedExercises] = useState({}); // State to track modified exercises

  const handleDescriptionChange = (itemId, newDescription) => {
    // Update the cart state with the new description
    setCart((prevCart) => {
      const updatedCart = prevCart.map((item) => {
        if (item._id === itemId) {
          return { ...item, description: newDescription };
        }
        return item;
      });
      return updatedCart;
    });

    // Update the modifiedExercises state
    setModifiedExercises((prevModified) => ({
      ...prevModified,
      [itemId]: newDescription,
    }));
  };
  const textareaRef = useRef(null);
  // Set the initial height based on content
  useEffect(() => {
    if (textareaRef.current) {
      textareaRef.current.style.height = 'auto';
      textareaRef.current.style.height = `${textareaRef.current.scrollHeight}px`;
    }
  }, [textareaRef , editingDescription]); // Runs only on mount

  return (
    <>
      <Layout>
        <ToastContainer />

        <TopBarNavigation
          backlink={"/createExercise"}
          title={"Create Workout"}
          tab={location?.state?.bodyPart}
          tabChild={"Create Workout"}
        />
        <div className="main-content-part" key={"createrExercisetest"}>
          <div className="exercise-card " key={"createrExercisetest1"}>
            <div
              className="form-data w-100  px-2"
              style={{ margin: "0", maxWidth: "100%" }}
              key={"createrExercisetest2"}
            >
              <Row className="align-items-center">
                <Col lg="3" key={"e3"}>
                  <div className="d-flex">
                    <div className="filed w-100" ref={nameInputRef}>
                      <label>Routine Name</label>
                      <input
                        type="text"
                        className={`input-field w-100 ${
                          Workout?.nameError ? "field-error" : ""
                        } `}
                        value={Workout?.name}
                        onChange={(e) => {
                          e.preventDefault();
                          setWorkout((p) => ({ ...p, name: e.target.value }));
                          setWorkout((p) => ({ ...p, nameError: false }));
                        }}
                        placeholder="Sunday Workout"
                      />
                      {Workout?.nameError === true ? (
                        <FieldValidationError message="Please enter the routine name" />
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                </Col>

                <Col lg="4" key={"e4"}>
                  <div className="filed mx-2">
                    <label>Created Date</label>
                    <input
                      type="date"
                      value={currentDate} // Set the value to today's date
                      disabled // Disable the input field
                      className="input-field w-75"
                      placeholder="25"
                    />
                  </div>
                </Col>
                <Col
                  lg="5"
                  className="d-flex justify-content-between"
                  key={"e5"}
                >
                  <div>
                    <OverlayTrigger
                      delay={{ hide: 450, show: 300 }}
                      overlay={(props) => (
                        <Tooltip {...props}>Select Language</Tooltip>
                      )}
                      placement="bottom"
                    >
                      <Button
                        className="printBtn btn rounded-start"
                        onClick={(e) => {
                          e.preventDefault();
                          // if (user?.profileCompleted === false) {
                          //   setShowProfileCompletionModal(true);
                          // } else 
                          
                          if (
                            data?.associatedEntity != "Individual" &&
                            data?.clinicVerified === false
                          ) {
                            setShowClinicModal(true);
                          }
                          else if(data?.associatedEntity != "Individual" && data?.associatedEntity?.isPremiumUser === false)
                          {
                            setShowClinicPurchasedModal(true)
                          }
                          else {
                            getLanguageList();
                            handleShowFilterLanguage();
                          }
                        }}
                      >
                        {" "}
                        <LanguageIcon />
                      </Button>
                    </OverlayTrigger>
                    <OverlayTrigger
                      delay={{ hide: 450, show: 300 }}
                      overlay={(props) => (
                        <Tooltip {...props}>Print Workout</Tooltip>
                      )}
                      placement="bottom"
                    >
                      <Button
                        className="printBtn  btn border-none"
                        onClick={(e) => {
                          e.preventDefault();
                          // if (user?.profileCompleted === false) {
                          //   setShowProfileCompletionModal(true);
                          // } else
                          
                          if (
                            data?.associatedEntity != "Individual" &&
                            data?.clinicVerified === false
                          ) {
                            setShowClinicModal(true);
                          } 
                          
                          else if(data?.associatedEntity != "Individual" && data?.associatedEntity?.isPremiumUser === false)
                          {
                            setShowClinicPurchasedModal(true)
                          }
                          else {
                            generateWorkout("modalOpen");                            setPrintModal(true)
                            //handleModalShow();
                          }
                        }}
                      >
                        <Print />
                        {/* <PDFDownloadLink document={<MyDocument PatientName={"priti"} ProgramName={Workout?.name} cart={cart}/>} fileName="fitness_calendar.pdf">
      {({ blob, url, loading, error }) =>
        loading ? 'Loading document...' : <Print /> 
      }
    </PDFDownloadLink> */}
                      </Button>
                    </OverlayTrigger>
                    <OverlayTrigger
                      delay={{ hide: 450, show: 300 }}
                      overlay={(props) => (
                        <Tooltip {...props}>Email Workout</Tooltip>
                      )}
                      placement="bottom"
                    >
                      <Button
                        className="printBtn  btn border-none "
                        onClick={() => {
                          // if (user?.profileCompleted === false) {
                          //   setShowProfileCompletionModal(true);
                          // } else
                           if (
                            data?.associatedEntity != "Individual" &&
                            data?.clinicVerified === false
                          ) {
                            setShowClinicModal(true);
                          }
                          
                          else if(data?.associatedEntity != "Individual" && data?.associatedEntity?.isPremiumUser === false)
                          {
                            setShowClinicPurchasedModal(true)
                          }
                          else {
                            setModalShowEmail(true);
                          }
                        }}
                      >
                        {/* <a
                          href={`mailto:email@example.com?subject=Suggest%20Routine&body=${emailBody}`}
                        > */}
                        <Folder />
                        {/* </a>{" "} */}
                      </Button>
                    </OverlayTrigger>
                    <OverlayTrigger
                      delay={{ hide: 450, show: 300 }}
                      overlay={(props) => (
                        <Tooltip {...props}>Save Workout</Tooltip>
                      )}
                      placement="bottom"
                    >
                      <Button
                        className="printBtn  btn rounded-end "
                        onClick={() => {
                          // if (user?.profileCompleted === false) {
                          //   setShowProfileCompletionModal(true);
                          // } else 
                          if (
                            data?.associatedEntity != "Individual" &&
                            data?.clinicVerified === false
                          ) {
                            setShowClinicModal(true);
                          } 
                          else if(data?.associatedEntity != "Individual" && data?.associatedEntity?.isPremiumUser === false)
                          {
                            setShowClinicPurchasedModal(true)
                          }
                          else {
                            generateWorkout();
                          }
                        }}
                      >
                        {" "}
                        <FolderDownload />
                      </Button>
                    </OverlayTrigger>
                  </div>
                  <div>
                    <OverlayTrigger
                      delay={{ hide: 450, show: 300 }}
                      overlay={(props) => (
                        <Tooltip {...props}>Add Exercise</Tooltip>
                      )}
                      placement="bottom"
                    >
                      <Button
                        className="printBtn btn rounded "
                        onClick={(e) => {
                          e.preventDefault();
                          // if (user?.profileCompleted === false) {
                          //   setShowProfileCompletionModal(true);
                          // } else 
                          if (
                            data?.associatedEntity != "Individual" &&
                            data?.clinicVerified === false
                          ) {
                            setShowClinicModal(true);
                          } 

                          else if(data?.associatedEntity != "Individual" && data?.associatedEntity?.isPremiumUser === false)
                          {
                            setShowClinicPurchasedModal(true)
                          }
                          else {
                            navigate("/points", {
                              state: {
                                cart: cart,
                                routine: location?.state?.routine,
                                patient_id: location?.state?.patient_id,
                                patientDetail: location?.state?.patientDetail,
                              },
                            });
                          }
                        }}
                      >
                        {" "}
                        <AddSvg />
                      </Button>
                    </OverlayTrigger>
                  </div>

                  <div>
                    <OverlayTrigger
                      delay={{ hide: 450, show: 300 }}
                      overlay={(props) => (
                        <Tooltip {...props}>Assign Workout</Tooltip>
                      )}
                      placement="bottom"
                    >
                      {(appName && token && userName) ? <Button
                        className="printBtnn  btn rounded p-2"
                        onClick={(e) => {
                          e.preventDefault();
                          handleShowNote()
                        }}
                      >
                        {" "}
                        <UserAssign /> <span className="p-2"> Assign </span>
                      </Button> : <Button
                        className="printBtnn  btn rounded p-2"
                        onClick={(e) => {
                          e.preventDefault();
                          // if (user?.profileCompleted === false) {
                          //   setShowProfileCompletionModal(true);
                          // } else 
                          if (
                            data?.associatedEntity != "Individual" &&
                            data?.clinicVerified === false
                          ) {
                            setShowClinicModal(true);
                          } 
                          
                          else if(data?.associatedEntity != "Individual" && data?.associatedEntity?.isPremiumUser === false)
                          {
                            setShowClinicPurchasedModal(true)
                          }
                          else if(data?.associatedEntity === "Individual" && data?.isPremiumUser === false)
                          {
                            setShowPremiumModal(true)
                          }
                          else {
                            generateWorkout("modalOpen");
                          }
                        }}
                      >
                        {" "}
                        <UserAssign /> <span className="p-2"> Assign </span>
                      </Button> }
                      
                    </OverlayTrigger>
                  </div>
                </Col>
              </Row>
            </div>
          </div>

          <div className="mt-4 mb-5 pb-4">
            {cart &&
              cart.length > 0 &&
              cart.map((item, index) => {
                let itemId = item._id;
                const sentences = item.description
                ? (filterlanguage?.code === "HND"
                    ? item.description.split("।")
                    : item.description.split("."))
                    .filter((sentence) => sentence.trim() !== "")
                : [];
              
                return (
                  <>
                    <div className="exercise-card mt-1 w-100 " key={index}>
                      <div className="body-content w-100">
                        <div
                          className="exercise-card mb-3 createCard w-100"
                          style={{ boxShadow: "none" }}
                        >
                          <div className="img-text-part align-items-start row w-100">
                            <div className="img-block col-lg-3 col-md-3 col-12">
                              <div
                                style={{ cursor: "pointer" }}
                                onClick={(e) => {
                                  e.preventDefault();
                                  setSelectedEx(item);
                                  handleShowVideoModal();
                                }}
                              >
                                <div className="box-video">
                                  <div className="bg-video">
                                    <div className="bt-play"></div>
                                  </div>
                                  <div className="video-container">
                                    <img
                                      className="img-fluid"
                                      src={item?.exerciseImage}
                                     
                                      style={{ borderRadius: "14px"}}
                                    />

                                    {/* <ReactPlayer
                                      url={item?.videoLink}
                                      style={{ borderRadius: "14px" }}
                                      // controls
                                    /> */}
                                  </div>
                                </div>
                              </div>
                              {/* <img src={item?.exerciseImage} style={{ height: "100%", width: "100%" }} alt='ex-1' /> */}
                            </div>
                            <div className="content-container  col-lg-4 col-md-4 col-12">
                              <div className="text-part ps-3 mt-2">
                                <div className="d-flex justify-content-between">
                                <h6>{item?.title}</h6>

                                <FaEdit
                          style={{ cursor: "pointer", marginLeft: "8px" , color:"#ff6036"}}
                          onClick={() => setEditingDescription(editingDescription === itemId ? null : itemId)}
                        /></div>

{editingDescription === itemId ? (
                        <textarea
                        ref={textareaRef}
                          className="form-control"
                          style={{
                            width: '100%',
                            minHeight: '40px',
                            maxHeight: '300px',
                            overflow: 'hidden',
                            resize: 'none',
                            padding: '10px',
                            fontSize: '14px',
                          }}
                    
                         // rows="3"
                          value={item.description}
                          onChange={(e) => handleDescriptionChange(itemId, e.target.value)}
                        />
                      ) : (
                        sentences.map((sentence, sentenceIndex) => (
                          <div className="review d-flex" style={{ fontSize: "12px" }} key={sentenceIndex}>
                            <span
                              className="no-block"
                              style={{
                                maxWidth: "15px",
                                minWidth: "15px",
                                marginTop: "4px",
                                marginRight: "10px",
                                height: "15px",
                                width: "15px",
                                fontSize: "10px",
                              }}
                            >
                              {sentenceIndex + 1}
                            </span>
                            <span className="rv-text">
                              {sentence.trim()} {filterlanguage?.code !== "HND" && "."}
                            </span>
                          </div>
                        ))
                      )}
                              </div>
                              {item?.equipment &&
                                item?.equipment.length > 0 && (
                                  <div className="text-part ps-3 mt-4">
                                    <h6>Equipment</h6>
                                    <p style={{ cursor: "pointer" }}>
                                      {item?.equipment &&
                                        item?.equipment.length > 0 &&
                                        item?.equipment.map((itemEq, ind) => (
                                          <React.Fragment key={ind}>
                                            {ind > 0 && " - "}{" "}
                                            {/* Display a hyphen for all elements except the first one */}
                                            {itemEq}
                                          </React.Fragment>
                                        ))}
                                    </p>
                                  </div>
                                )}

                              {item?.body_part_id &&
                                item?.body_part_id.length > 0 && (
                                  <div className="text-part ps-3 mt-2">
                                    <h6>BodyPart</h6>
                                    <p style={{ cursor: "pointer" }}>
                                      {item?.body_part_id &&
                                        item?.body_part_id.length > 0 &&
                                        item?.body_part_id.map(
                                          (itemEq, ind) => (
                                            <React.Fragment key={ind}>
                                              {ind > 0 && " - "}{" "}
                                              {/* Display a hyphen for all elements except the first one */}
                                              {itemEq?.title}
                                            </React.Fragment>
                                          )
                                        )}
                                    </p>
                                  </div>
                                )}

                              <div className="special-notes">
                                <label className="m-1">
                                  &nbsp; &nbsp;
                                  <input
                                    type="checkbox"
                                    checked={
                                      specialNotes[item._id]?.show || false
                                    }
                                    onChange={(e) =>
                                      handleSpecialNotesToggle(
                                        item._id,
                                        e.target.checked
                                      )
                                    }
                                  />
                                  &nbsp; Add Special Notes
                                </label>
                                {specialNotes[item._id]?.show && (
                                  <>
                                    <textarea
                                      className="form-control"
                                      value={specialNotes[item._id]?.text || ""}
                                      onChange={(e) =>
                                        handleSpecialNotesChange(
                                          item._id,
                                          e.target.value
                                        )
                                      }
                                      placeholder="Enter your special notes here"
                                    />
                                  </>
                                )}
                              </div>
                            </div>
                            <div className=" col-lg-5 col-md-5 col-12 ">
                            <Row >
                              <Col lg="6">
                                <div className="filed">
                                  <label>Sets</label>
                                  <Form.Select
                                    aria-label="Default select example"
                                    className="mb-3"
                                    onChange={(e) =>
                                      handleRepsChange(itemId, e.target.value)
                                    }
                                    value={item?.set}
                                  >
                                    {[...Array(10)].map((_, index) => (
                                      <option key={index + 1} value={index + 1}>
                                        {index + 1}
                                      </option>
                                    ))}
                                  </Form.Select>
                                </div>

                                <div className="filed">
                                  <label>Rest Time</label>
                                  <Form.Select
                                    aria-label="Default select example"
                                    className="mb-3"
                                    onChange={(e) =>
                                      handleRestChange(itemId, e.target.value)
                                    }
                                    value={item?.restTime}
                                  >
                                    {[...Array(14)].map(
                                      (
                                        _,
                                        index // Changed 13 to 14 to include an extra option for 0 seconds
                                      ) => (
                                        <option key={index} value={index * 5}>
                                          {index * 5} seconds
                                        </option>
                                      )
                                    )}
                                  </Form.Select>
                                </div>
                                <div className="filed">
                                  <label>Times</label>

                                  <Multiselect
                                    id={`exercise-item-${item._id}`}
                                    options={option.objectArray}
                                    style={{ border: "1px solid #ccc" }}
                                    // closeIcon="close"
                                    customArrow={
                                      <img src="data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 16 16%27%3e%3cpath fill=%27none%27 stroke=%27%23343a40%27 stroke-linecap=%27round%27 stroke-linejoin=%27round%27 stroke-width=%272%27 d=%27m2 5 6 6 6-6%27/%3e%3c/svg%3e" />
                                    }
                                    displayValue="key"
                                    placeholder="Select Time"
                                    showArrow={true}
                                    keepSearchTerm={true}
                                    selectedValues={item?.time} // Preselected value to persist in dropdown
                                    onSelect={(selectedList, selectedItem) => {
                                      const itemRef = document.getElementById(
                                        `exercise-item-${item._id}`
                                      );
                                      if (itemRef) {
                                        itemRef.classList.remove("field-error");
                                      }
                                      handleTimesChange(itemId, selectedList);
                                    }} // Function will trigger on select event
                                    onRemove={(selectedList, removedItem) => {
                                      handleTimesChange(itemId, selectedList);
                                    }}
                                  />

                                  {/* <Form.Select aria-label="Default select example"  className='mb-3' value={item?.time} id={`exercise-item-${item._id}`}
                                                                    onChange={(e) => {
                                                                        const itemRef = document.getElementById(`exercise-item-${item._id}`);
                                                                        if (itemRef) {
                                                                            itemRef.classList.remove('field-error');
                                                                        }
                                                                        handleTimesChange(itemId, e.target.value)

                                                                    }}


                                                                >
                                                                    <option>Select Time</option>
                                                                    <option value="morning">Morning</option>
                                                                    <option value="afternoon">Afternoon</option>
                                                                    <option value="evening">Evening</option>
                                                                    <option value="night">Night</option>
                                                                </Form.Select> */}
                                </div>
                              </Col>
                              <Col lg="6">
                                <div className="filed">
                                  <label>Holds </label>
                                  <Form.Select
                                    aria-label="Default select example"
                                    className="mb-3"
                                    placeholder="Select Seconds"
                                    onChange={(e) =>
                                      handleHoldChange(itemId, e.target.value)
                                    }
                                    value={item?.hold}
                                  >
                                    {[...Array(14)].map(
                                      (
                                        _,
                                        index // Changed 13 to 14 to include an extra option for 0 seconds
                                      ) => (
                                        <option key={index} value={index * 5}>
                                          {index * 5} seconds
                                        </option>
                                      )
                                    )}
                                  </Form.Select>
                                </div>

                                <div className="filed">
                                  <label>Counts</label>
                                  <Form.Select
                                    aria-label="Default select example"
                                    className="mb-3"
                                    onChange={(e) =>
                                      handleCompleteChange(
                                        itemId,
                                        e.target.value
                                      )
                                    }
                                    value={item?.count}
                                  >
                                    {[...Array(40)].map((_, index) => (
                                      <option key={index + 1} value={index + 1}>
                                        {index + 1}
                                      </option>
                                    ))}
                                  </Form.Select>
                                </div>
                                <div className="filed text-end mt-5">
                                  <label> </label>

                                  <button
                                    className="rounded-circle bg-white"
                                    style={{
                                      border: "1px solid #EAEAEA",
                                      borderRadius: "8px",
                                      paddingLeft: "12px",
                                      paddingRight: "12px",
                                      paddingTop: "8px",
                                      paddingBottom: "8px",
                                    }}
                                    onClick={() => removeFromCart(item)}
                                  >
                                    {" "}
                                    <img src={DeleteSvg} />
                                  </button>
                                </div>
                              </Col>
                            </Row>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </>
                );
              })}
          </div>
        </div>
        <ProfileCompletionModal
          show={showProfileCompletionModal}
          onHide={() => setShowProfileCompletionModal(false)}
          From={"Exercise Creation Option"}
        />
       <ClinicVerficationModal
        show={showClinicModal}
        onHide={() => setShowClinicModal(false)}
       />     
      <ClinicPurchasedModal
        show={showClinicPurchasedModal}
        onHide={() => setShowClinicPurchasedModal(false)}
      />  

              <PremiumUserModal show={showPremiumModal} onHide={() => setShowPremiumModal(false)}/>
      <ClinicCreditModal 
      show={showClinicCreditdModal}
      onHide={() => setShowClinicCreditdModal(false)}/> 
         {/* Filter modal */}
        <Modal
          show={showVideoModal}
          onHide={handleCloseVideoModal}
          className="p-0 m-0 modal-content-p0"
          centered
          size="lg"
        >
          <Modal.Header closeButton className="ml-5 withoutBorder" />

          <Modal.Body className="p-0 m-0">
            {!isVideoLoaded && (
              <div className="preloader-whirlpool text-center align-items-center">
                <div className="whirlpool"></div>
              </div>
            )}
            <ReactPlayer
              url={selectedEx?.videoLink}
              width="100%"
              height="400px"
              style={{
                borderRadius: "14px",
                display: isVideoLoaded ? "block" : "none",
              }}
              controls
              onReady={handleOnReady}
            />
          </Modal.Body>
        </Modal>

        <Modal show={show} onHide={handleClose} centered size="lg">
          <Modal.Header closeButton className="justify-content-start border-0">
            <Modal.Title className="text-center">Patient List</Modal.Title>
          </Modal.Header>

          <Modal.Body className="p-0">
            <div className="serchPart d-flex justify-content-center mb-4">
              <div className="searchInput">
                <div className="searchIcon">
                  <img src={SearchIcon} alt="searchIcon" />
                </div>
                <input
                  type="text"
                  placeholder="Search patient"
                  value={filter?.searchTerm}
                  onChange={(e) => {
                    e.preventDefault();
                    setPage(0);
                    setFilter((p) => ({ ...p, searchTerm: e?.target?.value }));
                  }}
                />
                <div
                  className="filterIcon"
                  onClick={(e) => {
                    e.preventDefault();
                    handleShowFilter();
                  }}
                >
                  <img src={FilterIcon} alt="filterIcon" />
                </div>
              </div>
            </div>
            <Row
              className=" g-4"
              style={{ maxHeight: "55vh", overflow: "scroll" }}
            >
              {loading && page === 0 ? (
                <div className="preloader-whirlpool preloader-whirlpoolmodal">
                  <div className="whirlpool"></div>
                </div>
              ) : (
                <>
                  {patientList?.items && patientList?.items?.length <= 0 && (
                    <div className="text-center">No Patient found</div>
                  )}
                  {location?.state?.patient_id &&
                    selectedPatients?.length > 0 &&
                    selectedPatients.map((selectedId) => {
                      const selectedItem = patientList.items.find(
                        (item) => item?._id === selectedId
                      );
                      return (
                        <>
                          <Col lg="12" ref={lastBookElementRef} className="mt-2">
                            {/* <Link to="/patientDetails" state={{ patient: item }} className="linkCard"> */}
                            <div className="doctor-card upcomingPatientsCard">
                              <div className="doctor-card-content" style={{paddingTop:"10px",paddingBottom:"10px"}}>
                                <div className="">
                                  <img
                                    src={
                                      selectedItem?.profile_image
                                        ? selectedItem?.profile_image
                                        : selectedItem?.gender === "female"
                                        ? PatientFemale
                                        : selectedItem?.gender === "male"
                                        ? PatientMale
                                        : PatientCommon
                                    }
                                    height={30}
                                    width={30}
                                    alt="Patient"
                                    className="rounded-circle"
                                  />
                                </div>
                                <div className="text-part w-100">
                                  <h5 className="mb-0" style={{fontSize:"12px"}}>{selectedItem?.name}</h5>
                                  <p className="gray" style={{fontSize:"12px"}}>
                                    {selectedItem?.appoinmentFor}
                                  </p>
                                  <p style={{fontSize:"11px"}}>
                                    {moment(selectedItem?.created_at)
                                      .utcOffset("+05:30")
                                      .format("h:mm A")}{" "}
                                    &nbsp;
                                    <span style={{ color: "#ADADAD" }}>
                                      {moment(selectedItem?.created_at).format(
                                        "MMM DD"
                                      )}
                                    </span>
                                  </p>
                                </div>
                                <div>
                                  <Nav.Item
                                    key={selectedItem?._id}
                                    className="d-flex justify-content-start align-items-center"
                                  >
                                    <div className="checkbox me-2">
                                      <input
                                        type="checkbox"
                                        id={`checkbox_${selectedItem?._id}`}
                                        //onChange={() =>
                                        //handleCheckboxChange(selectedItem?._id)
                                        //} // Handle checkbox changes
                                        checked={selectedPatients.includes(
                                          selectedItem?._id
                                        )} // Set checked based on selectedPatients
                                      />

                                      <label
                                        htmlFor={`checkbox_${selectedItem?._id}`}
                                      ></label>
                                    </div>
                                    <Nav.Link
                                      eventKey={`checkbox_${selectedItem?._id}`}
                                    ></Nav.Link>
                                  </Nav.Item>
                                </div>
                              </div>
                            </div>
                            {/* </Link> */}
                          </Col>
                        </>
                      );
                    })}
                  {!location?.state?.patient_id &&
                    patientList.items
                      // .filter((item) => !selectedPatients.includes(item?._id))
                      .filter(
                        (item) =>
                          item?.workoutGivenBy === null ||
                          item?.workoutGivenBy === user?._id ||
                          item?.allowedPermission === true
                      )
                      .map((item) => (
                        <Col lg="12" ref={lastBookElementRef} className="mt-2">
                          {/* <Link to="/patientDetails" state={{ patient: item }} className="linkCard"> */}
                          <div className="doctor-card upcomingPatientsCard">
                            <div className="doctor-card-content" style={{paddingTop:"10px",paddingBottom:"10px"}}>
                              <div className="">
                                <img
                                  src={
                                    item?.profile_image
                                      ? item?.profile_image
                                      : item?.gender === "female"
                                      ? PatientFemale
                                      : item?.gender === "male"
                                      ? PatientMale
                                      : PatientCommon
                                  }
                                  height={30}
                                  width={30}
                                  alt="Patient"
                                  className="rounded-circle"
                                />
                              </div>
                              <div className="text-part w-100">
                                <h5 className="mb-0" style={{fontSize:"12px"}}>{item?.name}</h5>
                                <p className="gray" style={{fontSize:"12px"}}>{item?.appoinmentFor}</p>
                                <p style={{fontSize:"12px"}}>
                                  {moment(item?.created_at)
                                    .utcOffset("+05:30")
                                    .format("h:mm A")}
                                  &nbsp;
                                  <span style={{ color: "#ADADAD" }}>
                                    {moment(item?.created_at).format("MMM DD")}
                                  </span>
                                </p>
                              </div>
                              <div>
                                <Nav.Item
                                  key={item?._id}
                                  className="d-flex justify-content-start align-items-center"
                                >
                                  <div className="checkbox me-2">
                                    <input
                                      type="checkbox"
                                      id={`checkbox_${item?._id}`}
                                      onChange={() =>
                                        handleCheckboxChange(item?._id, item)
                                      } // Handle checkbox changes
                                      checked={selectedPatients.includes(
                                        item?._id
                                      )} // Set checked based on selectedPatients
                                    />

                                    <label
                                      htmlFor={`checkbox_${item?._id}`}
                                    ></label>
                                  </div>
                                  <Nav.Link
                                    eventKey={`checkbox_${item?._id}`}
                                  ></Nav.Link>
                                </Nav.Item>
                              </div>
                            </div>
                          </div>
                          {/* </Link> */}
                        </Col>
                      ))}{" "}
                </>
              )}

              {loading && page > 0 && (
                <div className="preloader-whirlpool">
                  <div className="whirlpool"></div>
                </div>
              )}
            </Row>
          </Modal.Body>

          <Modal.Footer>
            <div className="d-flex text-end">
              <button
                className="theme-button d-block btn btn-primary px-4"
                style={{ minWidth: "fit-content" }}
                onClick={handleClose}
              >
                {" "}
                Cancel
              </button>
              <button
                className="theme-button d-block  mx-3 btn btn-primary px-4"
                style={{ minWidth: "fit-content" }}
                onClick={(e) => {
                  e.preventDefault();
                  navigate("/personalInformation");
                }}
              >
                {" "}
                Add Patient
              </button>
              <button
                className="theme-button d-block  mx-auto btn btn-primary px-4"
                style={{ minWidth: "fit-content" }}
                onClick={() => {
                  handleClose();
                  if (selectedPatientsDetail?.isAssignedExercise === false){
                    
                    if (
                      data?.associatedEntity != "Individual" &&
                      data?.clinicVerified === false
                    ) {
                      setShowClinicModal(true);
                    }
                    else if(data?.associatedEntity != "Individual" && data?.associatedEntity?.isPremiumUser === false)
                    {
                      setShowClinicPurchasedModal(true)
                    }
                    else if (data?.associatedEntity != "Individual" && data?.associatedEntity?.isPremiumUser === true &&  data?.associatedEntity?.credits <= 0 )
                    {
                      setShowClinicCreditdModal(true)
                    }
                    else{
                      handleShowNote();
                    }
                  }
                  else{
                    handleShowNote();
                  }
                }}
                disabled={selectedPatients.length === 0} // Disable button if no patients are selected
              >
                {" "}
                Assign Workout
              </button>

              {printModal ===  true &&     <button
                className="theme-button d-block  mx-3 btn btn-primary px-4"
                style={{ minWidth: "fit-content" }}
                onClick={(e) => {
                  e.preventDefault();
                  handleModalShow();
                  setPatientName(selectedPatientsDetail?.name);
                  handleClose()
                  setPrintModal(false)
                  //navigate("/personalInformation");
                }}
                disabled={selectedPatients.length === 0} // Disable button if no patients are selected
              >
                {" "}
                Print For Patient
              </button> }
              
            </div>
          </Modal.Footer>
        </Modal>

        <Modal
          show={completeWorkout}
          onHide={() => setCompleteWorkout(false)}
          size="md"
          className="complete-modal"
        >
          <Modal.Body>
            <div className="content-block text-center">
                <Animation />
            </div>
            <div className="bottom-block text-center">
              <div
                className="workout-desc body-content"
                style={{
                  display: "flex",
                  "align-items": "center",
                  "justify-content": "center",
                  "flex-direction": "column",
                  "padding-top": "25px",
                }}
              >
                <p>Workout Assigned successfully</p>

                <button
                  className="theme-button btn btn-primary mt-3"
                  style={{ minWidth: "300px", padding: "14px" }}
                  onClick={(e) => {
                    e.preventDefault();
                    navigate("/points");
                  }}
                >
                  Done
                </button>
              </div>
            </div>
          </Modal.Body>
        </Modal>
        <Modal
          show={completeEmail}
          onHide={() => setCompleteEmail(false)}
          size="md"
          className="complete-modal"
        >
          <Modal.Header>
            <div className="top-heading w-100 d-flex justify-content-end text-end">
              <button
                className="btn btn-transpatent"
                onClick={() => setCompleteEmail(false)}
              >
                X
              </button>
            </div>
          </Modal.Header>
          <Modal.Body>
          <div className="content-block text-center">
                <Animation />
            </div>
            <div className="bottom-block text-center">
              <div
                className="workout-desc body-content"
                style={{
                  display: "flex",
                  "align-items": "center",
                  "justify-content": "center",
                  "flex-direction": "column",
                  "padding-top": "25px",
                }}
              >
                <p>Email Sent successfully</p>

                <button
                  className="theme-button btn btn-primary mt-3"
                  style={{ minWidth: "300px", padding: "14px" }}
                  onClick={(e) => {
                    e.preventDefault();
                    setCompleteEmail(false);
                    // navigate("/points");
                  }}
                >
                  Done
                </button>
              </div>
            </div>
          </Modal.Body>
        </Modal>
        <Modal
          show={savedWorkout}
          onHide={() => setSavedWorkout(false)}
          size="md"
          className="complete-modal"
        >
          <Modal.Body>
            <div className="top-heading text-end">
              <button
                className="btn btn-transpatent"
                onClick={() => setSavedWorkout(false)}
              >
                X
              </button>
            </div>
            <div className="content-block text-center">
                <Animation />
            </div>
            <div className="bottom-block text-center">
              <div
                className="workout-desc body-content"
                style={{
                  display: "flex",
                  "align-items": "center",
                  "justify-content": "center",
                  "flex-direction": "column",
                  "padding-top": "25px",
                }}
              >
                <p>Protocol saved to your Workout</p>

                <button
                  className="theme-button btn btn-primary"
                  style={{ minWidth: "300px", padding: "14px" }}
                  onClick={(e) => {
                    e.preventDefault();
                    navigate("/savedExercise");
                  }}
                >
                  Done
                </button>
              </div>
            </div>
          </Modal.Body>
        </Modal>

        <Modal show={showFilter} onHide={handleCloseFilter} centered size="md">
          <Modal.Header closeButton>
            <Modal.Title className="text-center">Filter</Modal.Title>
          </Modal.Header>
          <Modal.Body className="pb-0">
            <div className="body-content">
              <ul className="filterList">
                {bodyPartData?.items &&
                  bodyPartData?.items.length > 0 &&
                  bodyPartData.items.map((item, index) => (
                    <li
                      key={index}
                      style={{ cursor: "pointer" }}
                      onClick={(e) => {
                        if (page != 0) {
                          setPage(0);
                        }
                        setFilter((prevFilter) => {
                          // Check if the current item's title is already the selected filter
                          if (prevFilter.title === item?.title) {
                            // If so, return an updated state that removes the filter
                            const newFilter = { ...prevFilter };
                            delete newFilter.title; // Remove the title filter
                            return newFilter;
                          } else {
                            // If not, set the filter to the clicked item
                            return {
                              ...prevFilter,
                              title: item?.title,
                            };
                          }
                        });
                      }}
                    >
                      <a>
                        {item?.title}
                        {filter?.title === item?.title && (
                          <div className="select-img">
                            <img
                              src={checkIcon}
                              alt="checkIcon"
                              style={{ display: "block" }}
                            />
                          </div>
                        )}
                      </a>
                    </li>
                  ))}
              </ul>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button
              className="theme-button"
              onClick={(e) => {
                e.preventDefault();
                handleCloseFilter();
              }}
            >
              Done
            </Button>
          </Modal.Footer>
        </Modal>

        <Modal show={modalShow} onHide={handleModalClose} size="lg">
          <Modal.Header closeButton>
            <Modal.Title>Enter Patient details</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form onSubmit={handleFormSubmit}>
              <Form.Group>
                <Form.Label>Patient Name</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter patient name"
                  value={patientName}
                  onChange={handleNameChange}
                  required
                  disabled
                />
              </Form.Group>

              <Form.Group>
                <Form.Label>Notes</Form.Label>
                <Form.Control
                  as="textarea"
                  rows={3}
                  value={note} // Bind the value to the note state
                  onChange={(e) => {
                    setNote(e.target.value);
                  }}
                />
              </Form.Group>

              <div className="d-flex justify-content-between w-100">
                <Row className="g-4 w-100 mt-2">
                  <Col lg="6">
                    <div className={`filed`}>
                      <label for="start-date">Start Date</label>
                      <input
                        type="date"
                        id="start-date"
                        name="start-date"
                        className="form-control"
                        onClick={(e) =>  {
                          if (e.target.showPicker) {
                            e.target.showPicker();
                          }                  
                        }} // Ensure focus to open calendar
                        
                        min={getTodayDate()} // Set the min attribute to today's date
                        onChange={handleDateChange}
                        required
                      />

                      {validationError?.date && (
                        <FieldValidationError
                          message={"please select valid date"}
                        />
                      )}
                      {startDateError && (
                        <FieldValidationError message={startDateError} />
                      )}
                    </div>
                  </Col>
                  <Col lg="6" className=" p-0">
                    <div className={`filed`}>
                      <label for="start-date">Exercise Duration</label>

                      <div className="d-flex justify-content-between">
                        <div class="dropdown-component w-50 ml-3">
                          <select
                            className="dropdownSelect form-select"
                            id="months-dropdown"
                            name="months"
                            required
                            onChange={handleMonthsChange}
                          >
                            <option value="0">00</option>
                            <option value="1">01</option>
                            <option value="2">02</option>
                            <option value="3">03</option>
                            <option value="4">04</option>
                            <option value="5">05</option>
                            <option value="6">06</option>
                            <option value="7">07</option>
                            <option value="8">08</option>
                            <option value="9">09</option>
                            <option value="10">10</option>
                            <option value="11">11</option>
                            <option value="12">12</option>
                          </select>
                          <span class="dropdown-label">Months</span>
                        </div>

                        <div
                          class="dropdown-component"
                          style={{ width: "47%" }}
                        >
                          <select
                            className="dropdownSelect form-select"
                            id="months-dropdown"
                            name="months"
                            onChange={handleDaysChange}
                            required
                          >
                            {Array.from({ length: 30 }, (_, i) => i).map(
                              (day) => (
                                <option key={day} value={day}>
                                  {day < 10 ? `0${day}` : day}
                                </option>
                              )
                            )}
                          </select>
                          <span class="dropdown-label">Days</span>
                        </div>
                      </div>
                    </div>

                    {validationError?.month && (
                      <FieldValidationError
                        message={"please select valid Month / Day"}
                      />
                    )}
                  </Col>
                </Row>
              </div>
              <Button type="submit" className="mt-3 p-2 printBtnn">
                {loaderAssign ? "Assigning"  : ((patientName &&
                startDate &&
                inputDate >= today) &&
                (months || days)) ? (
                  <PDFDownloadLink
                    document={
                      <MyDocument
                        PatientName={patientName}
                        ProgramName={Workout?.name}
                        cart={cart}
                        startDate={startDate}
                        numberOfDays={calculateTotalDays()}
                        notes={note}
                        language={filterlanguage?.code}
                        clinic_image={headerData?.clinic_image}
                        selectedPatientsDetail={selectedPatientsDetail}
                      />
                    }
                    fileName="fitness_calendar.pdf"
                    aria-disabled={loading}
                    onClick={downloadPDF}
                  >
                    {({ blob, url, loading, error }) =>
                      loading ? "Loading document..." : "Submit"
                    }
                  </PDFDownloadLink>
                ) :  (
                  "Submit"
                )}
              </Button>
            </Form>
          </Modal.Body>
        </Modal>

        <Modal show={modalShowEmail} onHide={handleModalCloseEmail} size="lg">
          <Modal.Header closeButton>
            <Modal.Title> Email Workout</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form onSubmit={handleFormSubmitEmail}>
              <Form.Group>
                <Form.Label>Patient Name</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter patient name"
                  value={patientName}
                  onChange={handleNameChange}
                  required
                />
              </Form.Group>
              <Form.Group>
                <Form.Label>Patient Email Address</Form.Label>
                <Form.Control
                  type="email"
                  placeholder="Enter patient email"
                  value={patientEmail?.email}
                  onChange={handleEmailChange}
                  required
                />
                {patientEmail?.emailError && (
                  <FieldValidationError message="Please add correct email" />
                )}
                {patientEmail?.apiError && (
                  <FieldValidationError message={patientEmail?.apiError} />
                )}
              </Form.Group>
              <Form.Group>
                <Form.Label>Notes</Form.Label>
                <Form.Control
                  as="textarea"
                  rows={3}
                  value={note} // Bind the value to the note state
                  onChange={(e) => {
                    setNote(e.target.value);
                  }}
                />
              </Form.Group>
              <Form.Group>
                <div className="d-flex justify-content-between w-100">
                  <Row className="g-4 w-100 mt-2">
                    <Col lg="6">
                      <div className={`filed`}>
                        <label for="start-date">Start Date</label>
                        <input
                          type="date"
                          id="start-date"
                          name="start-date"
                          className="form-control"
                          onClick={(e) =>  {
                            if (e.target.showPicker) {
                              e.target.showPicker();
                            }                  
                          }} // Ensure focus to open calendar
                          
                          min={getTodayDate()} // Set the min attribute to today's date
                          onChange={handleDateChange}
                          required
                        />

                        {validationError?.date && (
                          <FieldValidationError
                            message={"please select valid date"}
                          />
                        )}
                        {startDateError && (
                          <FieldValidationError message={startDateError} />
                        )}
                      </div>
                    </Col>
                    <Col lg="6" className=" p-0">
                      <div className={`filed`}>
                        <label for="start-date">Exercise Duration</label>

                        <div className="d-flex justify-content-between">
                          <div class="dropdown-component w-50 ml-3">
                            <select
                              className="dropdownSelect form-select"
                              id="months-dropdown"
                              name="months"
                              required
                              onChange={handleMonthsChange}
                            >
                              <option value="0">00</option>
                              <option value="1">01</option>
                              <option value="2">02</option>
                              <option value="3">03</option>
                              <option value="4">04</option>
                              <option value="5">05</option>
                              <option value="6">06</option>
                              <option value="7">07</option>
                              <option value="8">08</option>
                              <option value="9">09</option>
                              <option value="10">10</option>
                              <option value="11">11</option>
                              <option value="12">12</option>
                            </select>
                            <span class="dropdown-label">Months</span>
                          </div>

                          <div
                            class="dropdown-component"
                            style={{ width: "47%" }}
                          >
                            <select
                              className="dropdownSelect form-select"
                              id="months-dropdown"
                              name="months"
                              onChange={handleDaysChange}
                              required
                            >
                              {Array.from({ length: 30 }, (_, i) => i).map(
                                (day) => (
                                  <option key={day} value={day}>
                                    {day < 10 ? `0${day}` : day}
                                  </option>
                                )
                              )}
                            </select>
                            <span class="dropdown-label">Days</span>
                          </div>
                        </div>
                      </div>

                      {validationError?.month && (
                        <FieldValidationError
                          message={"please select valid Month / Day"}
                        />
                      )}
                    </Col>
                  </Row>
                </div>{" "}
              </Form.Group>
              <Button
                type="submit"
                className="mt-3 printBtnn"
                disabled={sendEmail}
              >
                {sendEmail ? "Sending Email" : "Submit"}
              </Button>
            </Form>
          </Modal.Body>
        </Modal>

        <Modal
          show={showFilterLanguage}
          onHide={handleCloseFilterLanguage}
          centered
          size="md"
        >
          <Modal.Header closeButton>
            <Modal.Title className="text-center">Select Language</Modal.Title>
          </Modal.Header>
          <Modal.Body className="pb-0">
            <div className="body-content">
              <ul className="filterList">
                {language?.items &&
                  language?.items?.length > 0 &&
                  language?.items?.map((item, index) => (
                    <li
                      key={index}
                      style={{ cursor: "pointer" }}
                      onClick={(e) => {
                        setFilterlanguage((prevFilter) => ({
                          ...prevFilter,
                          code: item?.code,
                        }));
                      }}
                    >
                      <a>
                        {item?.name}
                        {filterlanguage?.code === item?.code && (
                          <div className="select-img">
                            <img
                              src={checkIcon}
                              alt="checkIcon"
                              style={{ display: "block" }}
                            />
                          </div>
                        )}
                      </a>
                    </li>
                  ))}
              </ul>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button
              className="theme-button"
              onClick={(e) => {
                e.preventDefault();
                getLanguageDescription();
                handleCloseFilterLanguage();
              }}
            >
              Done
            </Button>
          </Modal.Footer>
        </Modal>

        <Modal show={showNote} onHide={handleCloseNote} centered size="lg">
          <Modal.Header closeButton>
            <Modal.Title className="text-center">Add Note</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="body-content">
              <p className="body-title">Information Note</p>
              <div className="mb-3">
                <textarea
                  className="form-control"
                  id="exampleFormControlTextarea1"
                  rows="5"
                  placeholder="Write text…"
                  value={note} // Bind the value to the note state
                  onChange={(e) => {
                    setNote(e.target.value);
                  }}
                ></textarea>
              </div>
              <div className="d-flex justify-content-between w-100">
                <Row className="g-4 w-100 mt-2">
                  <Col lg="6">
                    <div className={`filed`}>
                      <label for="start-date">Start Date</label>
                      <input
                        type="date"
                        id="start-date"
                        name="start-date"
                        className="form-control"
                        onClick={(e) =>  {
                          if (e.target.showPicker) {
                            e.target.showPicker();
                          }                  
                        }} // Ensure focus to open calendar
                        
                        min={getTodayDate()} // Set the min attribute to today's date
                        onChange={handleDateChange}
                        value={startDate} // Assuming `selectedDate` is the state variable holding the selected date value
                      />

                      {validationError?.date && (
                        <FieldValidationError
                          message={"please select valid date"}
                        />
                      )}
                      {startDateError && (
                        <FieldValidationError message={startDateError} />
                      )}
                    </div>
                  </Col>
                  <Col lg="6" className=" p-0">
                    <div className={`filed`}>
                      <label for="start-date">Exercise Duration</label>

                      <div className="d-flex justify-content-between">
                        <div class="dropdown-component w-50 ml-3">
                          <select
                            className="dropdownSelect form-select"
                            id="months-dropdown"
                            name="months"
                            value={months}
                            onChange={handleMonthsChange}
                          >
                            <option value="0">00</option>
                            <option value="1">01</option>
                            <option value="2">02</option>
                            <option value="3">03</option>
                            <option value="4">04</option>
                            <option value="5">05</option>
                            <option value="6">06</option>
                            <option value="7">07</option>
                            <option value="8">08</option>
                            <option value="9">09</option>
                            <option value="10">10</option>
                            <option value="11">11</option>
                            <option value="12">12</option>
                          </select>
                          <span class="dropdown-label">Months</span>
                        </div>

                        <div
                          class="dropdown-component"
                          style={{ width: "47%" }}
                        >
                          <select
                            className="dropdownSelect form-select"
                            id="months-dropdown"
                            name="months"
                            onChange={handleDaysChange}
                            value={days}
                          >
                            {Array.from({ length: 30 }, (_, i) => i).map(
                              (day) => (
                                <option key={day} value={day}>
                                  {day < 10 ? `0${day}` : day}
                                </option>
                              )
                            )}
                          </select>
                          <span class="dropdown-label">Days</span>
                        </div>
                      </div>
                    </div>

                    {validationError?.month && (
                      <FieldValidationError
                        message={"please select valid Month / Day"}
                      />
                    )}
                  </Col>
                  {selectedPatientsDetail?.isAssignedExercise === false && (
                    <>
                      <Col>
                        <div className="mb-1 filed">
                          <label className="form-label">
                            Type of Payment Mode
                          </label>
                          <div style={{ whiteSpace: "nowrap" }}>
                            <label
                              className="radio-container"
                              style={{
                                fontSize: "12px",
                                marginRight: "10px",
                                display: "inline-flex",
                                alignItems: "center",
                              }}
                            >
                              <input
                                type="radio"
                                name="typeOfExercise"
                                value="CreditUsage"
                                checked={paymentType?.payment === "CreditUsage"}
                                onChange={(e) => {
                                  //e.preventDefault();
                                  setPaymentType((p) => ({
                                    ...p,
                                    payment: "CreditUsage",
                                    paymentTypeError: false,
                                  }));
                                }}
                              />
                              <span className="checkmark"></span>{" "}
                              {/* Moved after input for CSS sibling selector */}
                              &nbsp; Credit Deduct
                            </label>

                            <label
                              className="radio-container"
                              style={{
                                fontSize: "12px",
                                marginRight: "30px",
                                display: "inline-flex",
                                alignItems: "center",
                              }}
                            >
                              <input
                                type="radio"
                                name="typeOfExercise" // Corrected to match the first radio button
                                value="DirectPayment"
                                checked={
                                  paymentType?.payment === "DirectPayment"
                                }
                                onChange={(e) => {
                                  //e.preventDefault();
                                  setPaymentType((p) => ({
                                    ...p,
                                    payment: "DirectPayment",
                                    paymentTypeError: false,
                                  }));
                                }}
                              />
                              <span className="checkmark"></span>
                              &nbsp; Take From Patient
                            </label>
                          </div>

                          {paymentType.paymentTypeError && (
                            <FieldValidationError
                              message={"Choose payment type"}
                            />
                          )}
                        </div>
                      </Col>
                    </>
                  )}

                  {selectedPatientsDetail?.isAssignedExercise === true && selectedPatientsDetail?.workoutGivenBy != user?._id && (
                    <>
                      <Col>
                        <div className="mb-1 filed">
                          <label className="form-label">
                            Type of Payment Mode
                          </label>
                          <div style={{ whiteSpace: "nowrap" }}>
                            <label
                              className="radio-container"
                              style={{
                                fontSize: "12px",
                                marginRight: "10px",
                                display: "inline-flex",
                                alignItems: "center",
                              }}
                            >
                              <input
                                type="radio"
                                name="typeOfExercise"
                                value="CreditUsage"
                                checked={paymentType?.payment === "CreditUsage"}
                                onChange={(e) => {
                                  e.preventDefault();
                                  setPaymentType((p) => ({
                                    ...p,
                                    payment: "CreditUsage",
                                    paymentTypeError: false,
                                  }));
                                }}
                              />
                              <span className="checkmark"></span>{" "}
                              {/* Moved after input for CSS sibling selector */}
                              &nbsp; Credit Deduct
                            </label>

                            <label
                              className="radio-container"
                              style={{
                                fontSize: "12px",
                                marginRight: "30px",
                                display: "inline-flex",
                                alignItems: "center",
                              }}
                            >
                              <input
                                type="radio"
                                name="typeOfExercise" // Corrected to match the first radio button
                                value="DirectPayment"
                                checked={
                                  paymentType?.payment === "DirectPayment"
                                }
                                onChange={(e) => {
                                  e.preventDefault();
                                  setPaymentType((p) => ({
                                    ...p,
                                    payment: "DirectPayment",
                                    paymentTypeError: false,
                                  }));
                                }}
                              />
                              <span className="checkmark"></span>
                              &nbsp; Take From Patient
                            </label>
                          </div>

                          {paymentType.paymentTypeError && (
                            <FieldValidationError
                              message={"Choose payment type"}
                            />
                          )}
                        </div>
                      </Col>
                    </>
                  )}
                      
                  <Col>
                    <Credit setCredit={setCredit} />
                  </Col>
                </Row>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>


          {selectedPatientsDetail?.isAssignedExercise === false &&  credit === 0 && <span className="text-danger">Doesn't have enough credit to assign exercise</span> } 
           

            {(appName && token && userName) ?    <Button
              className="theme-button"
              onClick={generateAndSendPDF}
            >
              {loaderAssign ? "Assigning" : "Next"}
            </Button> : 
             <Button
              className="theme-button"
              onClick={prepareAndAssignRoutine}
              disabled={loaderAssign ||  (selectedPatientsDetail?.isAssignedExercise === false &&  credit === 0) || (selectedPatientsDetail?.workoutGivenBy != user?._id &&  credit === 0)}
            >
              {loaderAssign ? "Assigning" : "Next"}
            </Button>}
            {/* <Link to="/excersiceList" state={{ readyData: readyData }} className="footerLink">Skip for now</Link> */}
          </Modal.Footer>
        </Modal>

        <ClinicVerficationModal
        show={showClinicModal}
        onHide={() => setShowClinicModal(false)}
      />
      </Layout>
    </>
  );
}

export default CreateExercise;
