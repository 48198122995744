import React, { useEffect, useState } from "react";

import Layout from "../layout";
import { Col, Row } from "react-bootstrap";
import { useLocation, useNavigate } from "react-router-dom";
import { get, getAuthConfig, post } from "../libs/http-hydrate";
import Auth from "../libs/auth";
import doneImg from "../assets/images/doneImg.png";
import Modal from "react-bootstrap/Modal";
import Animation from "../Animation/Animation";
const loadScript = (src) => {
  return new Promise((resolve) => {
    const script = document.createElement('script');
    script.src = src;
    script.onload = () => {
      resolve(true);
    };
    script.onerror = () => {
      resolve(false);
    };
    document.body.appendChild(script);
  });
};

const App = () => {
  const location = useLocation()
  const user = Auth.getCurrentUser()
  const navigate = useNavigate()
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [subscribeId,setSubscribeId] = useState("")
  const [completeWorkout, setCompleteWorkout] = useState(false);
  const [contactInformation, setContactInformation] = useState({
    emergencyNumber: "",
    address: "",
    state: "",
    city: "",
    country: "",
    pincode: "",
  });
  const [showCompanyDetails, setShowCompanyDetails] = useState(false);
const [companyDetails, setCompanyDetails] = useState({
  companyName: "",
  gstNumber: "",
});

  const [data, setData] = useState(() => {
    // Retrieve the item from localStorage
    const storedData = localStorage.getItem("notificationData");
    if (storedData) {
      try {
        // Parse it to an object and return as initial state
        return JSON.parse(storedData);
      } catch (error) {
        console.error(
          "Error parsing notificationData from localStorage:",
          error
        );
        // Return a fallback initial state if parsing fails
        return {};
      }
    }
    // Return an initial state if nothing is stored in localStorage
    return {};
  });

  const [useResidenceAddress, setUseResidenceAddress] = useState(false);

  const [countryLoading, setCountryLoading] = useState(false);
  const [stateLoading, setStateLoading] = useState(false);
  const [cityLoading, setCityLoading] = useState(false);
  const [countriesFetched, setCountriesFetched] = useState(false);
  const [countries, setCountries] = useState([]);
  const [states, setStates] = useState([]);
  const [cities, setCities] = useState([]);
  const [country, setCountry] = useState("");
  let [state, setstate] = useState("");
  const [isFormValid, setIsFormValid] = useState(false);

  useEffect(() => {
    const checkFormValidity = () => {
      const isBillingAddressComplete = useResidenceAddress
        ? data?.address?.street &&
          data?.address?.city &&
          data?.address?.state &&
          data?.address?.country &&
          data?.address?.pincode
        : contactInformation.street &&
          contactInformation.city &&
          contactInformation.state &&
          contactInformation.country &&
          contactInformation.pincode;
      setIsFormValid(isBillingAddressComplete);
    };

    checkFormValidity();
  }, [useResidenceAddress, contactInformation, data]);

  async function getCountrie() {
    if (countriesFetched) {
      return; // Countries have already been fetched
    }
    try {
      setCountryLoading(true);
      const data = await get("/country", getAuthConfig());
      setCountries(data?.data?.data);
      setCountriesFetched(true); // Update the state to indicate countries have been fetched
    } catch (error) {
      console.error(error);
    } finally {
      setCountryLoading(false);
    }
  }

  async function handleCountryChange(event) {
    const countryCode = event.target.value;
    setCountry(countryCode);

    if (countryCode) {
      try {
        setStateLoading(true);
        const response = await get(
          `/state?countryCode=${countryCode}`,
          getAuthConfig()
        );
        setStates(response?.data?.data);
        setstate("");
        setContactInformation((p) => ({ ...p, state: "" }));
      } catch (error) {
        console.error(error);
      } finally {
        setStateLoading(false);
      }
    } else {
      setStates([]);
      setstate("");
    }
  }

  async function handleStateChange(event) {
    const stateCode = event.target.value;
    setstate(stateCode);

    if (country && stateCode) {
      try {
        setCityLoading(true);
        const response = await get(
          `/city?countryCode=${country}&stateCode=${stateCode}`,
          getAuthConfig()
        );
        setCities(response?.data?.data);
        setContactInformation((p) => ({ ...p, city: "" }));
      } catch (error) {
        console.error(error);
      } finally {
        setCityLoading(false);
      }
    } else {
      setCities([]);
    }
  }
  const validateGST = (gstNumber) => {
    const gstRegex = /^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[A-Z0-9]{1}[Z]{1}[A-Z0-9]{1}$/;
    return gstRegex.test(gstNumber);
  };
  
  async function displayRazorpay() {
    setLoading(true);  // Start loading before the script loads
    if (showCompanyDetails) {
      if (!companyDetails.companyName) {
        setError("Company name is required.");
        setLoading(false);
        return;
      }
      if (!companyDetails.gstNumber) {
        setError("GST number is required.");
        setLoading(false);
        return;
      }
      if (!validateGST(companyDetails.gstNumber)) {
        setError("Invalid GST number.");
        setLoading(false);
        return;
      }
    }
    setError("");
    const res = await loadScript('https://checkout.razorpay.com/v1/checkout.js');
    if (!res) {
      alert('Razorpay SDK failed to load. Are you online?');
      setLoading(false);
      return;
    }
    const nowTime = new Date();
    const date = nowTime.getTime();
    
    const userData = {
      paymentDate: date,
      priceId: location?.state?.plan?.id,
      plans: location?.state?.plan,
      isPremiumUser: false,
      credits: 10,
      gstNumber:companyDetails?.gstNumber,
      companyName:companyDetails?.companyName,
      billing_address:contactInformation
    };
    try {

  
    const data = await post('/create/subscription',userData ,getAuthConfig());
    const result =  data.data
  
    if (!result.id) {
      alert('Failed to create subscription. Please try again.');
      return;
    }
  
    const options = {
      key: 'rzp_live_opK2gM7NYmZGRs', // Enter the Key ID generated from the Razorpay dashboard
      subscription_id: result.id,
      name: "Lauruss",
      description: "Subscription for Premium Plan",
      image: "https://virtuelife.s3.ap-south-1.amazonaws.com/RecureMe/VirtueLifelogo.svg",
      handler: async function (response) {
        const { razorpay_payment_id, razorpay_subscription_id, razorpay_signature } = response;
        // Call your backend to verify the payment and activate the subscription
        setSubscribeId(razorpay_payment_id)
        const verifyUrl = '/verify_payment';
        const verifyData = { paymentId: razorpay_payment_id, subscriptionId: razorpay_subscription_id, signature: razorpay_signature , billing_address:contactInformation,plans:location?.state?.plan};
        const verifyResponse = await post(verifyUrl,verifyData ,getAuthConfig());
        const verifyResult = verifyResponse?.data
        if (verifyResult.valid) {
          setCompleteWorkout(true);
          // alert('Subscription successful');
          // Handle further actions like updating UI or database accordingly
        } else {
          alert('Subscription verification failed');
        }
      },
      prefill: {
        name: user?.name,
        email: user?.email,
        contact: user?.mobile_number
      },
      theme: {
        color: "#ff6036"
      }
    };
  
    const paymentObject = new window.Razorpay(options);
    paymentObject.open();
  }catch (error) {
      console.error('Error creating subscription:', error);
      alert('Failed to process your request. Please try again.');
    } finally {
      setLoading(false);
    }
  }
  return (
    <>
    <Layout>

    <div className="payment">
          <div className="text-center mt-5">
            <h5 className="title">Enter Payment Information</h5>
            <p>One more step before closing more revenue with RecureMe.</p>
          </div>
          <div className="d-flex justify-content-center">
            <div className="card shadow m-4 w-75 bg-white border-0 p-3 mb-5">
                <div className="form-data p-2" style={{ maxWidth: "100%" }}>
                  <h5 className="text-start  py-3">Billing address</h5>

                  <Row className="g-4">
                    <Col lg="6">
                      <div className="filed">
                        <label>Your Name <span className="text-danger">*</span></label>
                        <input
                          type="text"
                          placeholder="First Name"
                          className="form-control"
                          disabled
                          value={data?.name}
                        />
                      </div>
                    </Col>
                    {/* <Col lg="6">
                      <div className="filed">
                        <label>Last Name</label>
                        <input
                          type="text"
                          placeholder="Last Name"
                          className="form-control"
                          disabled={lastName ? "disabled" : undefined} // Optionally disable if lastName is not present
                          value={lastName}
                        />
                      </div>
                    </Col> */}
                    <Col lg="12">
                      <div
                        className="w-100 d-flex align-items-center"
                        style={{ display: "flex", alignItems: "center" }}
                      >
                        <input
                          type="checkbox"
                          id="useResidenceAddress"
                          checked={useResidenceAddress}
                          onChange={(e) => {
                            const isChecked = e.target.checked;
                            setUseResidenceAddress(isChecked);
                            if (isChecked) {
                              // Populate the contactInformation state with the residence address
                              setContactInformation({
                                ...contactInformation,
                                street: data?.address?.street || "",
                                city: data?.address?.city || "",
                                state: data?.address?.state || "",
                                country: data?.address?.country || "",
                                pincode: data?.address?.pincode || "",
                              });
                            } else {
                              // Clear the billing address fields
                              setContactInformation({
                                ...contactInformation,
                                street: "",
                                city: "",
                                state: "",
                                country: "",
                                pincode: "",
                              });
                            }
                          }}
                          style={{
                            marginRight: "8px",
                            alignSelf: "center",
                            transform: "scale(1.5)",
                            WebkitTransform: "scale(1.5)",
                          }}
                        />
                        <label
                          htmlFor="useResidenceAddress"
                          style={{ marginBottom: "0", alignSelf: "center" }}
                        >
                          Use Profile address as billing address
                        </label>
                      </div>
                    </Col>
                    <Col lg="12">
                      <div className="filed">
                        <label>Address  <span className="text-danger">*</span></label>
                        <input
                          type="text"
                          placeholder="Address"
                          className="form-control"
                          value={contactInformation.street}
                          onChange={(e) =>
                            setContactInformation({
                              ...contactInformation,
                              street: e.target.value,
                            })
                          }
                          disabled={useResidenceAddress}
                        />
                      </div>
                    </Col>

                    <Col lg="6">
                      <div className="filed">
                        <label>Country <span className="text-danger">*</span></label>
                        <select
                          className="form-control form-select  "
                          value={contactInformation?.country}
                          onChange={(e) => {
                            handleCountryChange(e);
                            const selectedCountry = countries.find(
                              (c) => c.isoCode === e.target.value
                            );
                            if (selectedCountry) {
                              setContactInformation((prevData) => ({
                                ...prevData,
                                country: selectedCountry.name,
                              }));
                            }
                          }}
                          onClick={(e) => {
                            getCountrie();
                          }}
                          disabled={useResidenceAddress}
                        >
                          <option value="">
                            {contactInformation?.country
                              ? contactInformation?.country
                              : "Select Country"}
                          </option>
                          {countryLoading ? (
                            <option disabled>Loading...</option>
                          ) : (
                            countries.map((country) => (
                              <option
                                key={country?.code}
                                value={country?.isoCode}
                              >
                                {country?.name}
                              </option>
                            ))
                          )}
                        </select>
                      </div>
                    </Col>
                    <Col lg="6">
                      <div className="filed">
                        <label>State <span className="text-danger">*</span></label>
                        <select
                          className="form-control form-select "
                          value={contactInformation?.state}
                          onChange={(e) => {
                            handleStateChange(e);
                            const selectedStates = states.find(
                              (c) => c.isoCode === e.target.value
                            );
                            if (selectedStates) {
                              setContactInformation((prevData) => ({
                                ...prevData,
                                state: selectedStates.name,
                              }));
                            }
                          }}
                          disabled={useResidenceAddress}
                        >
                          <option value="">
                            {contactInformation?.state
                              ? contactInformation?.state
                              : "Select State"}
                          </option>
                          {stateLoading ? (
                            <option disabled>Loading...</option>
                          ) : (
                            states.map((state) => (
                              <option key={state?.code} value={state?.isoCode}>
                                {state?.name}
                              </option>
                            ))
                          )}
                        </select>
                      </div>
                    </Col>
                    <Col lg="6">
                      <div className="filed">
                        <label>City <span className="text-danger">*</span></label>

                        <select
                          className="form-control form-select"
                          value={contactInformation?.city}
                          onChange={(e) => {
                            setContactInformation((p) => ({
                              ...p,
                              city: e?.target?.value,
                            }));
                          }}
                          disabled={useResidenceAddress}
                        >
                          <option value="">
                            {contactInformation?.city
                              ? contactInformation?.city
                              : "Select City"}
                          </option>
                          {cityLoading ? (
                            <option disabled>Loading...</option>
                          ) : (
                            cities.map((city) => (
                              <option key={city?.code} value={city?.code}>
                                {city?.name}
                              </option>
                            ))
                          )}
                        </select>
                        {/* <GeoLocation
                locationTitle="City"
                onChange={(e) =>{
                  setContactInformation((p) =>({...p,city:e?.lable}))
                }}
                geoId={state}
              /> */}
                      </div>
                    </Col>
                    <Col lg="6">
                      <div className="filed">
                        <label>Pincode <span className="text-danger">*</span></label>

                        <input
                          type="text"
                          className="input-field form-control"
                          placeholder="Pincode"
                          name="zip"
                          value={contactInformation?.pincode || ""}
                          onChange={(e) => {
                            setContactInformation((p) => ({
                              ...p,
                              pincode: e?.target?.value,
                            }));
                          }}
                          disabled={useResidenceAddress}
                        />
                      </div>
                    </Col>

                    <Col lg="12">
  <div
    className="w-100 d-flex align-items-center"
    style={{ display: "flex", alignItems: "center" }}
  >
    <input
      type="checkbox"
      id="showCompanyDetails"
      checked={showCompanyDetails}
      onChange={(e) => {
        setShowCompanyDetails(e.target.checked);
        if (!e.target.checked) {
          setCompanyDetails({
            companyName: "",
            gstNumber: "",
          });
        }
      }}
      style={{
        marginRight: "8px",
        alignSelf: "center",
        transform: "scale(1.5)",
        WebkitTransform: "scale(1.5)",
      }}
    />
    <label
      htmlFor="showCompanyDetails"
      style={{ marginBottom: "0", alignSelf: "center" }}
    >
      Add Company Name and GST Details
    </label>
  </div>
</Col>

{showCompanyDetails && (
  <>
    <Col lg="6">
      <div className="filed">
        <label>Company Name <span className="text-danger">*</span></label>
        <input
          type="text"
          placeholder="Company Name"
          className="form-control"
          value={companyDetails.companyName}
          onChange={(e) =>
            setCompanyDetails({
              ...companyDetails,
              companyName: e.target.value,
            })
          }
        />
      </div>
    </Col>
    <Col lg="6">
      <div className="filed">
        <label>GST Number <span className="text-danger">*</span></label>
        <input
          type="text"
          placeholder="GST Number"
          className="form-control"
          value={companyDetails.gstNumber}
          onChange={(e) =>
            setCompanyDetails({
              ...companyDetails,
              gstNumber: e.target.value,
            })
          }
        />
      </div>
    </Col>
  </>
)}
                  </Row>
                </div>

      
                {error && (
                  <div className="text-danger text-center mb-5">{error}</div>
                )}

                <div className="buttonPart mt-3 d-flex justify-content-center mb-5">
                  <button
                    className="theme-button text-white border-0"
                    disabled={!isFormValid || loading}
                    onClick={displayRazorpay}
                  >
  {loading ? "Processing..." : "Pay with Razorpay"}
                  </button>
                </div>
            </div>
          </div>
        </div>
      {/* <div className="container text-center mt-4">
                <button onClick={displayRazorpay} className="btn btn-primary">Pay with Razorpay</button>
      </div> */}
      <Modal
          show={completeWorkout}
          onHide={() => setCompleteWorkout(false)}
          size="md"
          className="complete-modal"
        >
          <Modal.Body>
            <div className="top-heading text-end">
              <button
                className="btn btn-transpatent"
                onClick={() => setCompleteWorkout(false)}
              >
                X
              </button>
            </div>
            <div className="content-block text-center">
                <Animation />
            </div>
            <div className="bottom-block text-center">
              <div
                className="workout-desc body-content"
                style={{
                  display: "flex",
                  "align-items": "center",
                  "justify-content": "center",
                  "flex-direction": "column",
                  "padding-top": "25px",
                }}
              >
                <p>Payment Done</p>

                <span>Thank you for Choosing us</span>

                <button className="btn btn-outline-secondary my-3">
                  Order Id : {subscribeId}
                </button>

                <button
                  className="theme-button btn btn-primary"
                  style={{ minWidth: "300px", padding: "14px" }}
                  onClick={(e) => {
                    e.preventDefault();
                    navigate("/dashboard");
                  }}
                >
                  Done
                </button>
              </div>
            </div>
          </Modal.Body>
        </Modal>
    </Layout>
    </>
  );
};

export default App;
