import { useState, React, useEffect } from "react";
import "../../assets/css/chat.css";
import BackIcon from "../../assets/images/left.svg";
import CallBlack from "../../assets/images/call-2.svg";
import Button from "react-bootstrap/Button";
import Person from "../../assets/images/onlineUser.png";
import Clip from "../../assets/images/clip.svg";
import Picture from "../../assets/images/picture.svg";
import Sent from "../../assets/images/sent.svg";
import SeacrchIcon from "../../assets/images/search.svg";
import Spinner from "react-bootstrap/Spinner";
import io from "socket.io-client";

import Auth from "../../libs/auth";
import { get, getAuthConfig, getAuthConfigImage, post } from "../../libs/http-hydrate";
import moment from "moment";
import { useLocation, useNavigate } from "react-router-dom";
import PatientCommon from "../../assets/images/Patint.png";
import PatientFemale from "../../assets/images/Patient Female.png";
import PatientMale from "../../assets/images/Patient male.png";
import { useRef } from "react";

import mixkitBubbleSound from "../../assets/images/mixkit-bubble-pop-up-alert-notification-2357.wav"; // Import the sound file
import { useCallback } from "react";
import { MdFilePresent } from "react-icons/md";

const Chat = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const heartbeatInterval = useRef(null);
  const [key, setKey] = useState("patient");
  const [users, setUsers] = useState([]);
  const [message, setMessage] = useState("");
  const [totalPages, setTotalPages] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const [selectedUser, setSelectedUser] = useState(location?.state?.patient);
  const [chatList, setChatList] = useState({
    page: 1,
    chats: [],
    has_more: false,
  });
  const [image, setImage] = useState(null); // State for selected image
  const [loadingUsers, setLoadingUsers] = useState(true); // Add loading state for users
  const [loadingChats, setLoadingChats] = useState(false); // Add loading state for chats
  const user = Auth.getCurrentUser();
  const [unreadMessages, setUnreadMessages] = useState({}); // Track unread messages
  const [searchTerm, setSearchTerm] = useState(""); // State for search term
  const [sendingMessage, setSendingMessage] = useState(false); // New state for sending loader
  const messagesEndRef = useRef(null); // Create a reference for the end of chat messages
  const [loadingPreviousMessages, setLoadingPreviousMessages] = useState(false); // Add loading state for previous messages
  const [audioEnabled, setAudioEnabled] = useState(false); // Track if audio is allowed
  const chatContainerRef = useRef(null); // Reference for the chat container

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setImage(file);
    }
  };
  const audioRef = useRef(new Audio(mixkitBubbleSound)); // Reference to play the sound

  useEffect(() => {
    fetchUserList(currentPage);
  }, [searchTerm, currentPage]);

  const socket = useRef(null);

  useEffect(() => {
    socket.current = io("https://api.virtuelife.ai",{  reconnection: true,
    reconnectionAttempts: Infinity,
    reconnectionDelay: 1000,
    reconnectionDelayMax: 5000,}); // Update with your server address
   // socket.current = io("http://localhost:1337"); // Update with your server address

    const userId = user?._id;
  
    socket.current.emit("join", userId);
  
    socket.current.on("connect", () => {
      console.log("Connected to server");
      heartbeatInterval.current = setInterval(() => {
        socket.current.emit("ping"); // Send ping to server
      }, 3000); // Send every 3 seconds
    });
  
    // socket.current.on("message", (message) => {
    //   if (
    //     message?.sender === selectedUser?._id ||
    //     message?.receiver === selectedUser?._id
    //   ) {
    //     setChatList((prevState) => ({
    //       ...prevState,
    //       chats: [...prevState.chats, message],
    //     }));
    //   }
    //   console.log("messageReceived", message);
    // });
  
    return () => {
      socket.current.disconnect();
      if (heartbeatInterval.current) {
        clearInterval(heartbeatInterval.current);
      }
    };
  }, []);
  useEffect(() => {
    socket.current.on("message", async (message) => {
      console.log(message, "message: received");
      handleNewMessage(message);
      
      const senderOrReceiverId = message.sender === user._id ? message.receiver : message.sender;
  
      // Check if selectedUser is defined and the user is in the users list
      if (selectedUser && selectedUser._id === senderOrReceiverId) {
        // Append the message to the chat list if it belongs to the selected user
        setChatList((prevState) => ({
          ...prevState,
          chats: [...prevState.chats, message],
        }));
      } else {
        // Mark it as unread if it’s not the selected user
        setUnreadMessages((prevUnread) => ({
          ...prevUnread,
          [senderOrReceiverId]: true,
        }));
      }
  
      // Move the user to the top of the user list if they are already in the list
      const userIndex = users.findIndex((u) => u._id === senderOrReceiverId);
      if (userIndex !== -1) {
        const existingUser = users[userIndex];
        setUsers((prevUsers) => [
          existingUser,
          ...prevUsers.slice(0, userIndex),
          ...prevUsers.slice(userIndex + 1),
        ]);
      } else {
        // Optionally fetch new user details if they’re not in the list
        try {
          const data = await get(`/chat/getUserList?page=1`, getAuthConfig());
          if (data?.status === 200) {
            console.log(data, "data");
            setUsers(data?.data?.users);
            setTotalPages(data?.data?.totalPages);
          }
        } catch (error) {
          console.error("Error fetching new user details:", error);
        }
      }
    });
  
    // Clean up the listener when `selectedUser` changes
    return () => {
      socket.current?.off("message");
    };
  }, [selectedUser, users, audioEnabled]);
  
  
  useEffect(() => {
    // Listener to enable audio after the first interaction
    const enableAudioOnInteraction = () => {
      setAudioEnabled(true);
      audioRef.current.play().catch(() => {
        // Audio can't play immediately, but it's now unlocked for future use
      });
      // Remove listener after initial interaction
      document.removeEventListener("click", enableAudioOnInteraction);
      document.removeEventListener("keydown", enableAudioOnInteraction);
    };

    // Add event listeners for the first user interaction
    document.addEventListener("click", enableAudioOnInteraction);
    document.addEventListener("keydown", enableAudioOnInteraction);

    return () => {
      document.removeEventListener("click", enableAudioOnInteraction);
      document.removeEventListener("keydown", enableAudioOnInteraction);
    };
  }, []);

  const handleNewMessage = (message) => {
    if (audioEnabled) {
      audioRef.current.play().catch((error) => {
        console.log("Audio play failed:", error);
      });
    }
    // Handle the message display logic
  };

  useEffect(() => {
    if (selectedUser?._id) {
      getChatList(1, selectedUser?._id);
    }
  }, [selectedUser]);
  useEffect(() => {
    if (loadingPreviousMessages === false) {
      scrollToBottom();
    }
  }, [chatList]);

  function scrollToBottom() {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  }

  async function fetchUserList(page) {
    setLoadingUsers(true); // Set loading to true before fetching users
    try {
      const data = await get(
        `/chat/getUserList?page=${page}&searchTerm=${searchTerm}`,
        getAuthConfig()
      );
      if (data?.status === 200) {
        console.log(data, "data");
        setUsers(data?.data?.users);
        setTotalPages(data?.data?.totalPages);
      }
    } catch (error) {
      console.error("Error fetching user list:", error);
    } finally {
      setLoadingUsers(false); // Set loading to false after fetching users
    }
  }

  async function getChatList(page, otherUserId) {
    setLoadingChats(true); // Set loading to true before fetching chats
    try {
      const data = await get(
        `/chat/getMessages?page=${page}&otherUserId=${otherUserId}`,
        getAuthConfig()
      );
      if (data?.status === 200) {
        console.log(data, "data");

        // Combine old and new messages and sort them by date
        const newChats =
          page === 1
            ? data.data.messages
            : [...data.data.messages, ...chatList.chats];
        newChats.sort(
          (a, b) => new Date(a.created_at) - new Date(b.created_at)
        );

        setChatList((prevState) => ({
          page,
          chats: newChats,
          has_more: data.data.has_more,
        }));
      }
    } catch (error) {
      console.error("Error fetching messages:", error);
    } finally {
      setLoadingChats(false); // Set loading to false after fetching chats
    }
  }

  const loadMoreMessages = async () => {
    if (chatList.has_more && !loadingPreviousMessages) {
      setLoadingPreviousMessages(true);

      // Store current scroll height and top offset
      const currentScrollHeight = chatContainerRef.current?.scrollHeight;
      const currentScrollTop = chatContainerRef.current?.scrollTop;

      await getChatList(chatList.page + 1, selectedUser?._id); // Load more messages

      // Calculate the new scroll position by adjusting for the new messages' height
      if (chatContainerRef.current) {
        const newScrollHeight = chatContainerRef.current.scrollHeight;
        chatContainerRef.current.scrollTop = newScrollHeight - currentScrollHeight + currentScrollTop;
      }

      setLoadingPreviousMessages(false);
    }
  };
  async function sendMessage() {
    setSendingMessage(true); // Start the sending loader

    const payload = new FormData();
    payload.append("senderId", user?._id);
    payload.append("senderModel", "Physiotherapist");
    payload.append("receiverId", selectedUser?._id);
    payload.append(
      "receiverModel",
      location?.state?.patient ? "Patient" : "Physiotherapist"
    );
    payload.append("message", message);
    if (image) {
      payload.append("chat_image", image); // Append image to payload if exists
    }
  
    try {
      
     
      const data = await post(`/chat/sendMessage`, payload, getAuthConfigImage());

      if (data?.status === 200) {

        const payload1 = {
          senderId: user?._id,
          senderModel: "Physiotherapist", // or "Physiotherapist" based on your context
          receiverId: selectedUser?._id,
          receiverModel: "Patient", // or "Patient" based on the target
          message: message,
          fileUrl: data.data.message[0]?.fileUrl,
          created_at: data.data.message[0].created_at
        };
        socket.current.emit("sendMessage", payload1);

       //s socket.emit("sendMessage", payload1); // Emit the event to the server
        setChatList((prevState) => ({
          ...prevState,
          chats: [...prevState.chats, data.data.message[0]],
        }));
        setMessage(""); // Clear the message input after sending
        setImage(null); // Reset image after sending
        scrollToBottom();

      }
    } catch (error) {
      console.error("Error sending message:", error);
    } finally {
      setSendingMessage(false); // Stop the sending loader
    }
  }

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };

  const handleUserClick = useCallback((user) => {
    setSelectedUser(user);
  
    // Clear unread indicator for this user
    setUnreadMessages((prevUnread) => ({
      ...prevUnread,
      [user._id]: false,
    }));
  
    // Optionally reset the chat list if needed for new user
    setChatList({ page: 1, chats: [], has_more: false });
  }, []);
  
  return (
    <>
      <div className="chat-page">
        <div className="header-part">
          <div className="back-button">
            <Button className="back" onClick={() => navigate(-1)}>
              <img src={BackIcon} alt="back" />
            </Button>
          </div>
          {selectedUser && (
            <div className="name-call">
              <div className="profile">
                <div className="image">
                  <img
                    src={
                      selectedUser?.profile_image
                        ? selectedUser?.profile_image
                        : selectedUser?.gender === "female"
                        ? PatientFemale
                        : selectedUser?.gender === "male"
                        ? PatientMale
                        : PatientCommon
                    }
                    alt="Person"
                    style={{borderRadius:"25px"}}
                    height={50}
                    width={50}
                  />
                </div>
                <div className="user-name ms-3">
                  <p>{selectedUser?.name}</p>
                  
                </div>
              </div>
              {/* <Button className="call">
                <img src={CallBlack} alt="back" />
              </Button> */}
            </div>
          )}
        </div>
        <div className="main-chat-part" 
       // ref={chatContainerRef}
        >
          <div className="chat-side-part">
            <div className="chat-person">
              <div className="tab-part-data exercise-tab-part mt-0">
                <div className="tab-ans-part">
                  <div className="search-box">
                    <input
                      className="searchInput"
                      placeholder="Search"
                      value={searchTerm}
                      onChange={(e) => {
                        setSearchTerm(e.target.value);
                      }}
                    />
                    <div className="searchIcon">
                      <img src={SeacrchIcon} alt="icon" />
                    </div>
                  </div>
                  <div className="chatting-person">
                    {loadingUsers ? (
                      <div className="text-center">
                        <Spinner animation="border" />
                      </div>
                    ) : (
                      <>
                        {users?.length <= 0 && (
                          <div className="text-center">No User found</div>
                        )}
                        {users?.length > 0 &&
                          users?.map((user, index) => (
                            <div
                              key={index}
                              className="chat-card"
                              style={{ cursor: "pointer" }}
                              onClick={() => handleUserClick(user)}
                            >
                              <div className="image-blovk rounded">
                                <img
                                  src={
                                    user?.profile_image
                                    ? user?.profile_image
                                    : user?.gender === "female"
                                    ? PatientFemale
                                    : user?.gender === "male"
                                    ? PatientMale
                                    : PatientCommon
                                  }
                                  alt="user"
                                  style={{ borderRadius: "25px" }}
                                  height={50}
                                  width={50}
                                />
                              </div>
                              <div className="text-block">
                                <div className="left">
                                  <p>{user?.name}</p>

                                  {user?.lastMessageType === "file" ? (
      <MdFilePresent size={20} /> // Adjust size as needed
    ) : (
      <small>{user?.lastMessage}</small>
    )}

                                  
                                  {/* //<small>{user?.lastMessage}</small> */}
                                </div>
                                {(unreadMessages[user._id] || user?.unreadMessageCount != 0) && <span className="unread-indicator right">●</span>}

                                {/* <div className='right'>
                                <p className='time'>9:11</p>
                              </div> */}
                              </div>
                            </div>
                          ))}
                      </>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="messages-part">
            {loadingChats ? (
              <div className="spinner-container">
                <Spinner animation="border" />
              </div>
            ) : selectedUser ? (
              <>
                <div className="chatting">
                  {chatList?.has_more && (
                    <div className="text-center">
                      <button
                        onClick={loadMoreMessages}
                        style={{
                          display: "inline-flex",
                          alignItems: "center",
                          justifyContent: "center",
                          padding: "10px 20px",
                          backgroundColor: "#FF6036", // Set to the specified orange shade
                          color: "white",
                          fontWeight: "bold",
                          fontSize: "14px",
                          border: "none",
                          borderRadius: "25px",
                          cursor: "pointer",
                          boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                          transition: "background-color 0.3s ease",
                          marginBottom: "10px",
                        }}
                        onMouseEnter={(e) =>
                          (e.target.style.backgroundColor = "#e5562e")
                        } // Slightly darker orange on hover
                        onMouseLeave={(e) =>
                          (e.target.style.backgroundColor = "#FF6036")
                        } // Reset to original color on leave
                      >
                        {loadingChats ? (
                          <Spinner
                            animation="border"
                            size="sm"
                            style={{ marginRight: "8px" }}
                          />
                        ) : (
                          "Load More"
                        )}
                      </button>
                    </div>
                  )}
                  {chatList?.chats.map((chat, index) => (
                    <div
                      key={index}
                      className={`messages w-75 ${
                        chat?.sender === user?._id ? "sender" : "reciver"
                      }`}
                    >
                      <div className="message-card">
                      {chat?.fileUrl && (
                          <img
                            src={chat.fileUrl}
                            alt="attachment"
                            style={{ maxWidth: "100%", borderRadius: "8px" }}
                          />
                        )}
                        <p>{chat?.message}</p>
                      </div>
                      <p className="timer">
                        {moment(chat.created_at).format("hh:mm A")}
                      </p>
                    </div>
                  ))}
                                    <div ref={messagesEndRef} className="mb-4" /> {/* Scroll to here */}

                </div>

                <div className="message-typing-block">
                  <div className="input-box d-flex">
                    <input
                      type="text"
                      className="msg-type"
                      placeholder="Type…"
                      value={message}
                      onChange={(e) => {
                        setMessage(e.target.value);
                      }}
                      onKeyDown={(e) => {
                        if (e.key === 'Enter') {
                          if (message?.trim() !== "" || image != null) {
                            sendMessage();
                          }
                        }
                      }}
                    />

<input
                      type="file"
                      id="fileUpload"
                      style={{ display: "none" }}
                      accept="image/*"
                      onChange={handleImageChange}
                    />
                    <Button
                      className="file-attach"
                      onClick={() => document.getElementById("fileUpload").click()}
                    >
                      <img src={Clip} alt="clip" />
                    </Button>
                    {image && (
  <div style={{ position: "relative", display: "inline-block", marginRight: "10px" }}>
    <img
      src={URL.createObjectURL(image)}
      alt="Preview"
      style={{
        maxWidth: "50px",
        maxHeight: "50px",
        borderRadius: "8px",
        boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
        marginLeft:"10px"
      }}
    />
    <button
      onClick={() => setImage(null)} // Remove image on click
      style={{
        position: "absolute",
        top: "-5px",
        right: "-5px",
        background: "red",
        color: "white",
        border: "none",
        borderRadius: "50%",
        width: "20px",
        height: "20px",
        cursor: "pointer",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        fontSize: "12px"
      }}
    >
      &times;
    </button>
  </div>
)}

                  </div>
                  <div className="sent-btn">
                    <Button
                      className="sent"
                      onClick={(e) => {
                        e.preventDefault();
                        if (message?.trim() !== "" || image != null) {
                          sendMessage();
                        }
                      }}
                      disabled={sendingMessage} // Disable button while sending
                    >
                                            {sendingMessage ? (
                        <Spinner animation="border" size="sm" />
                      ) : (
                        <img src={Sent} alt="sent" />
                      )}
                    </Button>
                  </div>
                </div>
              </>
            ) : (
              <div className="start-chat">
                <p>Select a user to start chatting</p>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default Chat;
