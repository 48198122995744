import Layout from "../../layout";
import SearchIcon from "../../assets/images/search.svg";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { useEffect, useState } from "react";
import { get, getAuthConfig } from "../../libs/http-hydrate";
import "primereact/resources/themes/nova/theme.css";
import "primereact/resources/primereact.min.css";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import { Modal } from "react-bootstrap";
import PDFIcon from "../../assets/images/pdf.svg";

function Activity() {
  const navigate = useNavigate();
  const [activity, setActivity] = useState({
    items: [],
    has_more: false,
    totalItems: 0,
  });
  const [currentActivity, setCurrentActivity] = useState();
  const [showHistoryModal, setshowHistoryModal] = useState(false);
  const [filter, setFilter] = useState({
    searchTerm: "",
  });
  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState(1);
  const [lazyState, setlazyState] = useState({
    first: 0,
    rows: 10,
    page: 1,
  });
  useEffect(() => {
    if (page >= 1) {
      getActivityList();
    }
  }, [page, filter?.searchTerm]);
  async function getActivityList() {
    setLoading(true);

    try {
      let queryParams = `pageNumber=${page}`;

      if (filter?.searchTerm) {
        queryParams += `&search=${filter?.searchTerm}`;
      }
      const data = await get(`/activities?${queryParams}`, getAuthConfig());
      if (data?.status === 200) {
        const fetchedActivities = data.data.result.activities.map(
          (activity) => {
            const formattedDate = `${moment(activity?.activities?.date).format(
              "h:mm A"
            )} , ${moment(activity?.activities?.date).format("MMM DD")}`;

            return {
              id: activity._id, // Transform _id to id
              patientName:activity?.title ? activity?.title : activity?.patientName,
              activity: activity?.activities?.type, // Assuming you have activityType or similar
              date: formattedDate,
              patientId:activity?.patient_id,
              title: activity?.title,
              attachment:activity?.attachment
            };
          }
        );

        setActivity((p) => ({
          ...p,
          items: fetchedActivities,
          has_more: data?.data?.result?.has_more,
          totalItems: data?.data?.result?.totalItems,
        }));
      }
    } catch (error) {
    } finally {
      setLoading(false);
    }
  }

  const representativeBodyTemplate = (rowData) => {
    const representative = rowData;
    return (
      <div className="flex align-items-center gap-2 activity">
        <button className="theme-outline-btn btn btn-primary">
          {representative?.activity}{" "}
        </button>
      </div>
    );
  };

  const representativeBody = (rowData) => {
    const representative = rowData;
    return <span className="textGry">{representative?.date}</span>;
  };
  const onPage = (event) => {
    // PrimeReact pages are zero-based; add 1 to match your backend API expectations
    const newPage = event.page + 1;

    setlazyState((prevState) => ({
      ...prevState,
      first: event.first,
      rows: event.rows,
      page: newPage,
    }));

    // Fetch the new page of data using the updated lazyState
    setPage(newPage);
  };

  const patientNameBodyTemplate = (rowData) => {
    const patientName = rowData.title ? rowData.title  : rowData.patientName;
    return <span>{patientName}</span>;
};

const onSort = (event) => {
  const { sortField, sortOrder } = event;

  const sortedItems = [...activity.items].sort((data1, data2) => {
    let value1 = data1[sortField];
    let value2 = data2[sortField];
    let result = 0;

    if (typeof value1 === 'string' && typeof value2 === 'string') {
      result = value1.localeCompare(value2);
    } else {
      result = value1 < value2 ? -1 : (value1 > value2 ? 1 : 0);
    }

    return sortOrder * result;
  });

  setActivity(prevState => ({
    ...prevState,
    items: sortedItems,
  }));
};


  return (
    <>
      <Layout>
        <div className="main-content-part">
          <div className="content-data mt-2">
            <div className="serchPart mb-3">
              <div className="searchInput mx-auto">
                <div className="searchIcon">
                  <img src={SearchIcon} alt="searchIcon" />
                </div>
                <input
                  type="text"
                  placeholder="Search History"
                  onChange={(e) => {
                    e.preventDefault();

                    setlazyState((prevState) => ({
                      ...prevState,
                      first: 0,
                      rows: 10,
                      page: 1,
                      sortField: null,
                      sortOrder: null,
                    }));

                    // Fetch the new page of data using the updated lazyState
                    setPage(1);
                    setFilter((p) => ({ ...p, searchTerm: e.target.value }));
                  }}
                />
              </div>
            </div>
            <div className="shadow doctor-card  bg-body border-0 bg-white p-3">
            <DataTable value={activity.items}
           paginator
           lazy
           first={lazyState.first}
           onPage={onPage}
           onSort={onSort}
           removableSort
           sortField={lazyState.sortField}
           sortOrder={lazyState.sortOrder}
           rows={10}
           totalRecords={activity.totalItems}
           loading={loading}
           onRowClick={(e) => {
             setCurrentActivity(e.data);
             setshowHistoryModal(true);
           }}
           rowClassName={() => 'datatable-row'}>
  <Column field="patientName" header="Activity Title"   sortable body={patientNameBodyTemplate}></Column>
  <Column field="activity" header="Activity" sortable body={representativeBodyTemplate}></Column>
  <Column field="date" header="Date"  sortable body={representativeBody}></Column>
</DataTable>

            </div>
          </div>
        </div>
      </Layout>

      <Modal
        show={showHistoryModal}
        onHide={() => {
          setshowHistoryModal(false);
        }}
        size="md"
        className="complete-modal"
        centered
      >
        <Modal.Header>
          <div className="top-heading w-100 d-flex justify-content-end text-end">
            <button
              className="btn btn-transpatent"
              onClick={() => {
                setshowHistoryModal(false);
              }}
            >
              X
            </button>
          </div>
        </Modal.Header>
        <Modal.Body>
          <div className="bottom-block text-center">
            <div
              className="workout-desc body-content"
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                flexDirection: "column",
                paddingTop: "25px",
              }}
            >
              <p>History</p>
              <div class="detail-block rounded py-3 px-3 my-2 text-start">
                <div class="detail-item mb-2 d-flex align-items-center">
                  <span class="label">Patient Name:</span>
                  <strong class="text">{currentActivity?.title ?currentActivity?.title :currentActivity?.patientName }</strong>
                </div>
                <div class="detail-item mb-2 d-flex align-items-center">
                  <span class="label">Activity:</span>
                  <strong class="text">{currentActivity?.activity}</strong>
                </div>
                <div class="detail-item mb-2 d-flex align-items-center">
                  <span class="label">Activity Date:</span>
                  <strong class="text">{currentActivity?.date}</strong>
                </div>
                <div class="detail-item mb-2 d-flex align-items-center">
                  <span class="label">Attachment:</span>
                  <strong class="text">{currentActivity?.attachment ? (<>
                    <div className="filed">
                          <div className="image-block">
                            <a href={currentActivity?.attachment} target="_blank" rel="noopener noreferrer">
                            <img src={PDFIcon} alt="icon" />
                            </a>
                          </div>
                    </div>
                  </>)  : "N/A"}</strong>
                </div>
              </div>
              {currentActivity?.patientId  &&  (currentActivity?.activity ==="Edit Workout" || currentActivity?.activity ==="Assign Workout" ) &&
              <button
                className="theme-button btn btn-primary mt-3"
                style={{ minWidth: "300px", padding: "14px" }}
                onClick={() =>  navigate("/history", {
                  state: { patient_id: { _id: currentActivity?.patientId } },
                })}
              >
                Visit Profile
              </button>}
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}
export default Activity;
