import TopBarNavigation from "../../components/TopBarNavigation";
import Layout from "../../layout";
import ReactPlayer from "react-player";
import {
  Row,
  Col,
  Button,
  Nav,
  Tab,
  Form,
  Collapse,
  Spinner,
} from "react-bootstrap";
import SearchIcon from "../../assets/images/search.svg";
import DeleteSvg from "../../assets/images/delete.svg";
import Ex1 from "../../assets/images/Surgery.svg";
import Auth from "../../libs/auth";
import { useNavigate } from "react-router-dom";
import { useCallback, useEffect, useRef, useState } from "react";
import { UseEffectOnce } from "../../Hook/UseEffectOnce";
import { get, getAuthConfig } from "../../libs/http-hydrate";
import moment from "moment";
import Modal from "react-bootstrap/Modal";
import React from "react";
import { MdDelete } from 'react-icons/md';  // Import delete icon
import PageLink from "../../components/PageLink";

function SavedExercise() {
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [selectedWorkout, setSelectedWorkout] = useState(null);
  const [loadingDelete, setLoadingDelete] = useState(false);
  const [errorDelete, setErrorDelete] = useState(null);
  const uniqueExercises = new Set();
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const user = Auth.getCurrentUser();
  const navigate = useNavigate();
  const [patientList, setPatientList] = useState({
    items: [],
    has_more: false,
  });
  const [cart, setCart] = useState([]);
  const [page, setPage] = useState(0);
  const [filter, setFilter] = useState({
    searchTerm: "",
  });
  const [loading, setLoading] = useState(true);

  UseEffectOnce(() => {
    getPhysiotherapistLit();
  }, []);
  useEffect(() => {
    if (page >= 1) {
      getPhysiotherapistLit();
    }
  }, [page]);
  useEffect(() => {
    getPhysiotherapistLit();
    // organizeDataByTimeOfDay(readyData);
  }, [filter]);

  const observer = useRef();
  const lastBookElementRef = useCallback(
    (node) => {
      // if (loading) return;
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && patientList?.has_more) {
          setPage((prevPageNumber) => prevPageNumber + 1);
        }
      });
      if (node) observer.current.observe(node);
    },
    [patientList?.has_more]
  );
  const handleDeleteModalOpen = (workout) => {
    setSelectedWorkout(workout);
    setShowDeleteModal(true);
  };

  const handleDeleteModalClose = () => {
    setShowDeleteModal(false);
    setSelectedWorkout(null);
  };
  async function handleDeleteWorkout(workoutId) {
    setLoadingDelete(true);
    setErrorDelete(null);

    try {
      const data = await get(
        `/savedWorkout/delete?workoutId=${workoutId}`,
        getAuthConfig()
      );
      if (data?.status === 200) {
        setPatientList((prevState) => ({
          ...prevState,
          items: prevState.items.filter((item) => item._id !== workoutId),
        }));
        handleDeleteModalClose(); // Close modal after successful deletion
      }
    } catch (error) {
      setErrorDelete("Failed to delete workout");
    } finally {
      setLoadingDelete(false);
    }
  }

  async function getPhysiotherapistLit() {
    setLoading(true);

    try {
      let queryParams = `pageNumber=${page}`;

      if (filter?.searchTerm) {
        queryParams += `&search=${filter?.searchTerm}`;
      }
      const data = await get(`/savedWorkout?${queryParams}`, getAuthConfig());
      if (data?.status === 200) {
        if (page > 0) {
          setPatientList((p) => ({
            ...p,
            items: patientList?.items.concat(data?.data?.workouts),
          }));
        } else {
          setPatientList((p) => ({ ...p, items: data?.data?.workouts }));
        }
        setPatientList((p) => ({ ...p, has_more: data?.data?.has_more }));
      }
    } catch (error) {
      if (error?.response?.status === 401) {
        Auth.logout();
        navigate("/login");
      }
    } finally {
      setLoading(false);
    }
  }
  function calculateTotalExerciseCount(item) {
    const uniqueExercises = new Set();

    // Iterate through morning, evening, afternoon, and night arrays
    ["morning", "evening", "afternoon", "night"].forEach((timeOfDay) => {
      if (item[timeOfDay] && Array.isArray(item[timeOfDay])) {
        item[timeOfDay].forEach((workout) => {
          if (workout.workout && Array.isArray(workout.workout)) {
            workout.workout.forEach((exercise) => {
              const exerciseId = exercise?.exercise_id?._id;
              uniqueExercises.add(exerciseId); // Add exercise ID to the Set
            });
          }
        });
      }
    });

    return uniqueExercises.size; // Return the size of the Set, which is the unique exercise count
  }

  function displayExercise(item) {
    let arr = [];

    // Iterate through morning, evening, afternoon, and night arrays
    ["morning", "evening", "afternoon", "night"].forEach((timeOfDay) => {
      if (item[timeOfDay] && Array.isArray(item[timeOfDay])) {
        item[timeOfDay].forEach((workout) => {
          if (workout.workout && Array.isArray(workout.workout)) {
            workout.workout.forEach((exercise) => {
              arr.push(exercise);
            });
          }
        });
      }
    });

    setCart(arr);
  }

  return (
    <>
      <Layout>
        {/* <TopBarNavigation title={"Face"} tab={"Physiotherapist"} tabChild={"Doctor Profile"} /> */}
        <div className="main-content-part ">
          <div className="content-data mt-3">
            <div className="serchPart d-flex justify-content-between align-items-center mb-3">
              <div className="searchInput ">
                <div className="searchIcon">
                  <img src={SearchIcon} alt="searchIcon" />
                </div>
                <input
                  type="text"
                  placeholder="Search routine"
                  value={filter?.searchTerm}
                  onChange={(e) => {
                    e.preventDefault();
                    setPage(0);
                    setFilter((p) => ({ ...p, searchTerm: e?.target?.value }));
                  }}
                />
              </div>
              <PageLink />
            </div>

            {/* <Row className="px-3">

                            <Col xl={4} lg={4} md={4} sm={12}>
                                <div className="exercise-card mt-3 w-100">
                                    <div className='body-content w-100'>
                                        <div className='' style={{ boxShadow: "none" }}>
                                            <div className='img-text-part row'>
                                                <div className='img-block col-lg-4'>
                                                    <img src={Ex1} height={"100%"} width={"100%"} alt='ex-1' />
                                                </div>

                                                <div className='text-part  mt-2 col-lg-4'>

                                                    <h6>Sunday Workout </h6>
                                                    <p style={{ cursor: "pointer" }} >18 feb 2023</p>

                                                </div>
                                                <div className="col-lg-4 text-end">
                                                    <Button className='theme-outline-btn  ' style={{ backgroundColor: '#FDF5F2', borderRadius: "15px", borderColor: '#FDF5F2', minWidth: "fit-content", fontSize: "12px", fontWeight: "500", padding: "12px" }}>+5 Exercises   </Button>
                                                </div>

                                            </div>



                                        </div>

                                    </div>
                                </div>
                            </Col>

                        </Row> */}
            <Row className="mt-4 px-2">
              {loading && page === 0 ? (
                <div className="preloader-whirlpool text-center align-items-center">
                  <div className="whirlpool"></div>
                </div>
              ) : (
                <>
                  {patientList?.items && patientList?.items?.length <= 0 && (
                    <div className="text-center">No Template found</div>
                  )}
                  {patientList?.items &&
                    patientList?.items?.length > 0 &&
                    patientList?.items?.map((item, index) => {
                      return (
                        <>
                          <Col
                            xl={3}
                            lg={3}
                            md={3}
                            sm={12}
                            ref={lastBookElementRef}
                            key={index}
                          >
                            <div className="assign-doctor-card p-0 px-3 pb-3">

                                <div className="d-flex justify-content-end gap-2">
                                <button
                                className="mt-2 arrow-icon btn btn-transparent border-0"
                                style={{"box-shadow":"0px 4px 8px 0px rgba(0, 0, 0, 0.2)"}}
                                onClick={(e) => {
                                  e.preventDefault();
                                  navigate("/createExercise", {
                                    state: { routine: item },
                                  });
                                }}
                              >
<i className="fa fa-pen fa-xs "></i>                             
 </button>
                              <button
                                className="mt-2 arrow-icon btn btn-transparent  border-0"
                                style={{"box-shadow":"0px 4px 8px 0px rgba(0, 0, 0, 0.2)"}}
                                onClick={(e) => {
                                  e.preventDefault();
                                  handleDeleteModalOpen(item);
                                }}
                              >
                                <i className="fa fa-trash fa-xs "></i>                             

                                {/* <img src={DeleteSvg} alt="Delete" width={15} height={15} /> */}
                              </button>
                                </div>
                              <div className="doctor-card-content">
                                <div
                                  style={{ cursor: "pointer" }}
                                  onClick={(e) => {
                                    e.preventDefault();
                                    navigate("/createExercise", {
                                      state: { routine: item },
                                    });
                                  }}
                                >
                                  <div className="image-part py-3">
                                    <img
                                      src={Ex1}
                                      width={"100%"}
                                      style={{ borderRadius: "14px" }}
                                      alt="Patient"
                                    />
                                  </div>
                                  <div className="text-part text-start ">
                                    <span
                                      className="exerciseLabfont name p-1"
                                      style={{ fontWeight: "500" }}
                                    >
                                      {item?.name}
                                    </span>
                                    <br />
                                    <span
                                      className="exerciseLabfontSUB name p-1"
                                      style={{
                                        color: "#ADADAD",
                                        fontWeight: "500",
                                      }}
                                    >
                                      {moment(item?.date).format("DD MMM YYYY")}
                                    </span>
                                  </div>

                                  <div className="divi p-2">
                                    <span className="divider"></span>
                                  </div>
                                </div>
                                <div
                                  className="d-flex justify-content-center "
                                  style={{ cursor: "pointer" }}
                                  onClick={(e) => {
                                    e.preventDefault();
                                    displayExercise(item);
                                    handleShow();
                                  }}
                                >
                                  <p
                                    className=""
                                    style={{
                                      color: "#FF6036",
                                      fontWeight: "500",
                                    }}
                                  >
                                    {" "}
                                    +{calculateTotalExerciseCount(item)}{" "}
                                    Exercises
                                  </p>
                                </div>
                              </div>
                            </div>
                          </Col>
                        </>
                      );
                    })}
                </>
              )}

              {loading && page > 0 && (
                <div className="text-center">
                  <div className="whirlpool"></div>
                </div>
              )}
            </Row>
          </div>
        </div>

        <Modal show={showDeleteModal} onHide={handleDeleteModalClose} centered>
          <Modal.Header closeButton>
            <Modal.Title>Delete Workout</Modal.Title>
          </Modal.Header>
          <Modal.Body className="d-flex flex-column justify-content-center align-items-center">
            <p>Are you sure you want to delete this workout?</p>{" "}
            {/* Confirmation text */}
            <Button
              variant="primary"
              className="theme-button btn btn-primary px-2 me-3"
              onClick={(e) => {
                e.preventDefault();
                handleDeleteWorkout(selectedWorkout?._id); // Call delete function
              }}
              disabled={loadingDelete} // Disable during delete process
            >
              {loadingDelete ? (
                <>
                  <Spinner animation="border" size="sm" /> &nbsp; Deleting...
                </>
              ) : (
                <>
                  <MdDelete /> &nbsp; Confirm Delete
                </>
              )}
            </Button>
          </Modal.Body>
          {errorDelete && <div className="text-danger mt-2">{errorDelete}</div>}
        </Modal>

        <Modal show={show} onHide={handleClose} centered size="lg">
          <Modal.Header closeButton className="justify-content-start">
            <Modal.Title className="text-center">Exercise List</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="body-content">
              {/* Create a Set to keep track of unique exercise titles */}

              {cart &&
                cart.length > 0 &&
                cart.map((item, index) => {
                  // Check if the exercise title is unique
                  if (!uniqueExercises.has(item?.exercise_id?.title)) {
                    uniqueExercises.add(item?.exercise_id?.title);

                    return (
                      <>
                        <div
                          className="exercise-card mb-3"
                          style={{ boxShadow: "none" }}
                        >
                          <div className="img-text-part">
                            <div className="img-block">
                              <img
                                src={item?.exercise_id?.exerciseImage}
                                height={130}
                                width={130}
                                style={{ borderRadius: "10%" }}
                                alt="ex-1"
                                className="img-fluid"
                              />
                            </div>
                            <div className="text-part ps-3">
                              <h6>{item?.exercise_id?.title}</h6>
                              <p style={{ cursor: "pointer" }}>
                                {item?.exercise_id?.body_part_id &&
                                  item?.exercise_id?.body_part_id?.length > 0 &&
                                  item?.exercise_id?.body_part_id.map(
                                    (itemEq, ind) => (
                                      <React.Fragment key={ind}>
                                        {ind > 0 && " - "}
                                        {itemEq?.title}
                                      </React.Fragment>
                                    )
                                  )}
                              </p>
                            </div>
                          </div>
                          <div
                            className="checkbox me-2"
                            style={{ cursor: "pointer" }}
                          >
                            <Nav variant="pills" className="flex-column">
                              <Nav.Item className="d-flex justify-content-start align-items-center">
                                <div className="checkbox me-2 sidebarCheckbox">
                                  <input
                                    type="checkbox"
                                    id={`checkbox_${"morning"}`}
                                    checked={true}
                                  />
                                  <label
                                    htmlFor={`checkbox_${"morning"}`}
                                  ></label>
                                </div>
                                <Nav.Link eventKey="first"></Nav.Link>
                              </Nav.Item>
                            </Nav>
                          </div>
                        </div>
                        <hr />
                      </>
                    );
                  }
                  return null; // Skip duplicate exercises
                })}
            </div>
          </Modal.Body>
        </Modal>
      </Layout>
    </>
  );
}

export default SavedExercise;
