import React, { useEffect, useState } from "react";
import "../assets/css/layout.css";
import Header from "../components/Header";
import Sidebar from "../components/Sidebar";
import { useLocation } from "react-router-dom";
import InternetConnectivity from "../components/InternetConnectivity";

const Layout = (props) => {
  const location = useLocation();
  const [showInternetModal, setShowInternetModal] = useState(false);


  const [appName, setAppName] = useState(localStorage.getItem("appName"));
  const [token, setToken] = useState(localStorage.getItem("token"));

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const appNameFromUrl = queryParams.get('appName');
    const tokenFromUrl = queryParams.get('token');
    const userName = queryParams.get('userName')

    // Store appName and token in localStorage if present in the URL
    if (appNameFromUrl && tokenFromUrl && userName) {
      localStorage.setItem('appName', appNameFromUrl);
      localStorage.setItem('token', tokenFromUrl);
      localStorage.setItem('userName', userName);
      setAppName(appNameFromUrl);
      setToken(tokenFromUrl);
    }

       // Clean up on window unload
       const handleUnload = () => {
        localStorage.removeItem('appName');
        localStorage.removeItem('token');
        localStorage.removeItem('userName')
      };
  
      window.addEventListener('beforeunload', handleUnload);
  
      // Cleanup the event listener on component unmount
      return () => {
        window.removeEventListener('beforeunload', handleUnload);
      };

  }, [location]);
  useEffect(() => {
    if (!navigator.onLine) {
      setShowInternetModal(true);
      return;
    }
  }, [navigator]);

  const shouldShowSidebar =
    location.pathname == "/payment" ||
    location.pathname == "/paymentForm" ||
    location.pathname == "/myPlanDetails" ||
    location.pathname == "/paymentStripe" ||
    location.pathname == "/paymentFormStripe";
  const isSpecialPage =
    location.pathname.includes("/exerciseLab") ||
    location.pathname.includes("/surgeryTemplate");

  // Style object for content div
  const contentStyle = {
    paddingLeft: !shouldShowSidebar ? "265px" : "0",
    // ...(isSpecialPage ? { overflowY: 'hidden' } : {})
  };
  return (
    <>
      <div className="layout-main">
        {/* {showInternetModal && (
          <div
            className="alert alert-danger"
            role="alert"
            style={{
              position: "absolute",
              zIndex: "15",
              top: "20%",
              left: "40%",
            }}
          >
            Please Check your Internet connectivity
          </div>
        )} */}
        {!appName  && 
        !shouldShowSidebar  && (
          <div className="sider">
            <Sidebar />
          </div>
        )}
        <div className="content-part" style={{ position: "relative" }}>

          {!appName &&
          <div className="header-part" style={contentStyle}>
            <Header shouldShowSidebar />
          </div>}
          <div className={ appName ? "p-0" : "content" } style={contentStyle}>
            {props.children}
          </div>
        </div>
      </div>
    </>
  );
};

export default Layout;
