import React from 'react'
import '../../assets/css/home.css'
import Layout from '../../layout'
import LeftIcon from '../../assets/images/left.svg'
import { Link } from 'react-router-dom'
import Calendar from '../../assets/images/calendar.svg'
import Profile from '../../assets/images/profile.svg'
import Activity from '../../assets/images/Activity.svg'
import MyAppointment from '../../assets/images/My Appointments.svg'
import AppointmentSettings from '../../assets/images/Appointment Settings.svg'
import MyPatient from '../../assets/images/My Patients.svg'
import MyPlan from '../../assets/images/My plans.svg'

import payment from '../../assets/images/payment.svg'
import Calendar2 from '../../assets/images/calendar-2.svg'
import ChatIcon from '../../assets/images/chatIcon.svg'
import User from '../../assets/images/userIcon.svg'
import Setting from '../../assets/images/setting.svg'
import Auth from '../../libs/auth'
import DoctorCommon from "../../assets/images/Docotr.png";
import DoctorMale from "../../assets/images/Doctor Male.png";
import DoctorFeMale from "../../assets/images/Doctor Female.png";
const DoctorDetailsForm = () => {
  const user = Auth.getCurrentUser();

  return (
    <Layout>
      <div className='main-content-part'>
        <div className='doctorName'>
        <div className="image-part mb-2">
  <img
    src={
      user?.profile_image
        ? user?.profile_image
        : user?.gender === "female"
        ? DoctorFeMale
        : user?.gender === "male"
        ? DoctorMale
        : DoctorCommon
    }
    alt="doctor"
    className="profile-image-doc"
  />
</div>

          <p>{user?.name}</p>
          <p className='email'>{user?.email ? user?.email : user?.mobile_number}</p>
        </div>
        <div className='common-link-card-part'>
        <Link to="/profileData" state={{formProfile:"true"}} className='link-card'>
            <div className='iconPart'>
              <div className='iconRound'>
                <img src={Profile} alt='icon' />
              </div>
              <p className='text'>Profile</p>
            </div>

            <div className='img-block'>
              <img src={LeftIcon} alt='left' />
            </div>
          </Link>

          <Link to="/activity" state={{formProfile:"true"}} className='link-card'>
            <div className='iconPart'>
              <div className='iconRound'>
                <img src={Activity} alt='icon' />
              </div>
              <p className='text'>Activity</p>
            </div>

            <div className='img-block'>
              <img src={LeftIcon} alt='left' />
            </div>
          </Link>
          <Link to="/myPlanDetails" state={{formProfile:"true"}} className='link-card'>
            <div className='iconPart'>
              <div className='iconRound'>
                <img src={MyPlan} alt='icon' />
              </div>
              <p className='text'>My Plan</p>
            </div>
            <div className='img-block'>
              <img src={LeftIcon} alt='left' />
            </div>
          </Link>
          <Link to="/upcomingAppointment" className='link-card'>
            <div className='iconPart'>
              <div className='iconRound'>
                <img src={MyAppointment} alt='icon' />
              </div>
              <p className='text'>My Appointments</p>
            </div>

            <div className='img-block'>
              <img src={LeftIcon} alt='left' />
            </div>
          </Link>
          <Link to="/appointSetting" className='link-card'>
            <div className='iconPart'>
              <div className='iconRound'>
                <img src={AppointmentSettings} alt='icon' />
              </div>
              <p className='text'>Appointment Settings</p>
            </div>

            <div className='img-block'>
              <img src={LeftIcon} alt='left' />
            </div>
          </Link>
          {/* <Link to="/chat" className='link-card'>
            <div className='iconPart'>
              <div className='iconRound'>
                <img src={ChatIcon} alt='icon' />
              </div>
              <p className='text'>Chat</p>
            </div>

            <div className='img-block'>
              <img src={LeftIcon} alt='left' />
            </div>
          </Link> */}
          <Link to="/allPatient" className='link-card'>
            <div className='iconPart'>
              <div className='iconRound'>
                <img src={MyPatient} alt='icon' />
              </div>
              <p className='text'>My Patient</p>
            </div>

            <div className='img-block'>
              <img src={LeftIcon} alt='left' />
            </div>
          </Link>
          <Link to="/settings" className='link-card'>
            <div className='iconPart'>
              <div className='iconRound'>
                <img src={Setting} alt='icon' />
              </div>
              <p className='text'>Settings</p>
            </div>

            <div className='img-block'>
              <img src={LeftIcon} alt='left' />
            </div>
          </Link>
        </div>
      </div>
    </Layout>
  )
}

export default DoctorDetailsForm
