import { useState, React, useEffect, useRef } from "react";
// import '../../assets/css/home.css'
import Layout from "../../layout";
import TopBar from "../../components/TopBarNavigation";
import { Row, Col, Button } from "react-bootstrap";
import "../../assets/css/doctorInfo.css";
import { useLocation, Link, useNavigate } from "react-router-dom";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import Ex1 from "../../assets/images/ex-1.png";
import { get, getAuthConfig } from "../../libs/http-hydrate";
import Auth from "../../libs/auth";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DateCalendar } from "@mui/x-date-pickers/DateCalendar";
import moment from "moment";
import leftSvvg from "../../assets/images/left.svg";
import defaultSVG from "../../assets/images/defaultWorkout.jpg";
import checkMark from "../../assets/images/check-mark.png";
import NotesSvg from "../../assets/images/notes.svg";
import { PickersDay, PickersDayProps } from "@mui/x-date-pickers/PickersDay";
import Badge from "@mui/material/Badge";
import dayjs, { Dayjs } from "dayjs";
import { DayCalendarSkeleton } from "@mui/x-date-pickers/DayCalendarSkeleton";
import swal from "sweetalert";
import PatientDummy from "../../assets/images/defaultpatient@3x.png";
import ProgressBar from "react-bootstrap/ProgressBar";
import doneImg from "../../assets/images/Opps.svg"; // Import the image
import { Modal } from "react-bootstrap";
import DoctorCommon from "../../assets/images/Docotr.png";
import DoctorMale from "../../assets/images/Doctor Male.png";
import DoctorFeMale from "../../assets/images/Doctor Female.png";
import PatientCommon from "../../assets/images/Patint.png";
import PatientFemale from "../../assets/images/Patient Female.png";
import PatientMale from "../../assets/images/Patient male.png";
import { IoIosArrowDown } from "react-icons/io";
import { MdKeyboardArrowUp } from "react-icons/md";
import Appointment from "./Appointment";
import Workout from "./Workout";
const formatDuration = (totalSeconds) => {
  const minutes = Math.floor(totalSeconds / 60);
  const seconds = totalSeconds % 60;
  // Pad the seconds with a leading zero if less than 10
  const paddedSeconds = seconds.toString().padStart(2, '0');
  return `${minutes}:${paddedSeconds} Min`;
};

const OverView = ({ patient_id , patient , workoutHistory,appointmentList,user }) => {
  const sessionClassMap = {
    Heart: "heart",
    Arm: "arm",
    Shoulder: "shoulder",
    // Add more session names and their class names as needed
  };
  const [ModalNotes, setModalNotes] = useState(false);
  const [key, setKey] = useState("morning");
  const location = useLocation();
  const navigate = useNavigate();
  const [workout, setWorkout] = useState();
  const [date, setDate] = useState(dayjs());
  const [loading, setLoading] = useState(false);
  const [displayExercises, setDisplayExercises] = useState(false); // State to track whether to display exercises
  const [selectedWorkout, setSelectedWorkout] = useState(null); // State to track the selected workout
  const requestAbortController = useRef(null);
  const [isLoading, setIsLoading] = useState(false);
  const [highlightedDays, setHighlightedDays] = useState([]);
  const [fullcompletedDays, setCompletedDays] = useState([]);
  const [totoalCount, setTotalCount] = useState(0);
  const defaultActiveTab = () => {
    if (workout?.morning?.length > 0) {
      return "morning";
    } else if (workout?.afternoon?.length > 0) {
      return "afternoon";
    } else if (workout?.evening?.length > 0) {
      return "evening";
    } else if (workout?.night?.length > 0) {
      return "night";
    } else {
      // If none of the sessions have data, set a default tab
      return "morning"; // or "afternoon", "evening", "night" as desired
    }
  };

  const [activeTab, setActiveTab] = useState(defaultActiveTab());
  const handleTabSelect = (tabKey) => {
    setActiveTab(tabKey);
    setDisplayExercises(false);
    setSelectedWorkout(null);
  };

  const handleWorkoutClick = (workout, modal) => {
    setSelectedWorkout(workout);
    if (modal) {
      setModalNotes(true);
    } else {
      setDisplayExercises(true);
    }
  };

  const handleBackClick = () => {
    setSelectedWorkout(null);
    setDisplayExercises(false);
  };

  useEffect(() => {
    getWorkoutDetails();
  }, [patient_id]);
  async function getWorkoutDetails() {
    setLoading(true); // Start loading
    try {
      const data = await get(
        `/PatientWorkout?patientId=${patient_id?._id}`,
        getAuthConfig()
      );
      if (data?.status === 200) {
        setWorkout(data?.data?.msg);
      }
    } catch (error) {
      if (error?.response?.status === 401) {
        Auth.logout();
        navigate("/login");
      }
      // Handle other error scenarios if necessary
    } finally {
      setLoading(false); // End loading regardless of result
    }
  }

  useEffect(() => {
    setActiveTab(defaultActiveTab());
  }, [workout]);
  // Extract and store the date_Completed dates
  useEffect(() => {
    if (workout) {
      const completedDates = [];
      let exerciseCount = 0;

      const fullcompletedDays = [];
      // Get the current month and year from the date state
      const currentMonth = date.month() + 1; // Adding 1 because months in dayjs are 0-indexed
      const currentYear = date.year();

      // Iterate through your workout data to collect date_Completed
      // Assuming workout is properly passed as a prop
      const { morning, evening, afternoon, night } = workout;
      const sessions = [morning, evening, afternoon, night];

      sessions?.forEach((session) => {
        session?.forEach((s) => {
          s?.workout?.forEach((workout) => {
            exerciseCount = exerciseCount + 1;
            workout?.date_Complted?.forEach((date) => {
              const workoutDate = dayjs(date);
              if (
                workoutDate.month() + 1 === currentMonth &&
                workoutDate.year() === currentYear
              ) {
                completedDates.push(workoutDate.date());
              }
            });
          });
        });
      });

      // Define a helper function to check if all workouts for a specific session are completed for a date
      const areWorkoutsCompleted = (workoutDate, session) => {
        return session.every((s) =>
          s.workout.some((workout) =>
            workout.date_Complted.some((date) => {
              const completedDate = dayjs(date);
              return (
                completedDate.date() === workoutDate.date() &&
                completedDate.month() + 1 === currentMonth &&
                completedDate.year() === currentYear
              );
            })
          )
        );
      };

      // Iterate through dates and categorize them
      for (let day = 1; day <= date.daysInMonth(); day++) {
        const workoutDate = dayjs(`${currentYear}-${currentMonth}-${day}`);

        const allWorkoutsCompleted = sessions.every((session) =>
          areWorkoutsCompleted(workoutDate, session)
        );

        if (allWorkoutsCompleted) {
          fullcompletedDays.push(day);
        }
      }
      setHighlightedDays(completedDates);
      setCompletedDays(fullcompletedDays);

      setTotalCount(exerciseCount);
    }
  }, [workout, date]);
  const initialValue = dayjs(date);

  function ServerDay(props) {
    const { highlightedDays = [], day, outsideCurrentMonth, ...other } = props;

    const isFullCompleted = fullcompletedDays.includes(day.date());

    // Initialize isSelected as false
    const isSelected =
      !outsideCurrentMonth && highlightedDays.indexOf(day.date()) >= 0;
    const customStyles = {
      backgroundColor: isSelected
        ? isFullCompleted
          ? "#ff6036"
          : "white"
        : "transparent",
      color: isSelected ? (isFullCompleted ? "white" : "inherit") : "inherit",
      border:
        isSelected && !isFullCompleted
          ? "2px solid #ff6036"
          : day?.$D == date?.$D && !isFullCompleted
          ? "2px solid gray"
          : "none", // Add a 2px solid orange border if isSelected and isFullCompleted
    };

    return (
      <Badge key={day.toString()} overlap="circular">
        <PickersDay
          {...other}
          outsideCurrentMonth={outsideCurrentMonth}
          day={day}
          style={customStyles}
        />
      </Badge>
    );
  }

  function DateCalendarServerRequest() {
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
      const completedDates = [];
      let exerciseCount = 0;

      for (const workoutType in workout) {
        for (const workout of workout[workoutType]) {
          for (const exercise of workout.workout) {
            const { date_Complted } = exercise;
            exerciseCount = exerciseCount + 1;
            completedDates.push(...date_Complted);
          }
        }
      }
      setHighlightedDays(completedDates);
      setIsLoading(false);
      setTotalCount(exerciseCount);
    }, []);
  }

  const handleMonthChange = (newDate) => {
    setDate(newDate);

    setHighlightedDays([]);
  };

  const doesWorkoutMeetDateConditions = (session, selectedDate) => {
    const sessionCreatedAt = moment(session?.startDate);
    const sessionEndDate = moment(session?.startDate).add(
      session.daysToComplete - 1,
      "days"
    );

    return (
      moment(selectedDate?.$d).isSameOrBefore(sessionEndDate, "day") &&
      (moment(selectedDate?.$d).isSame(sessionCreatedAt, "day") ||
        moment(selectedDate?.$d).isAfter(sessionCreatedAt))
    );
  };

  // Before rendering, filter the workouts based on the conditions
  const filteredWorkoutsMorning = workout?.morning.filter((session) =>
    doesWorkoutMeetDateConditions(session, date)
  );
  const filteredWorkoutsAfterNoon = workout?.afternoon.filter((session) =>
    doesWorkoutMeetDateConditions(session, date)
  );
  const filteredWorkoutsEvening = workout?.evening.filter((session) =>
    doesWorkoutMeetDateConditions(session, date)
  );
  const filteredWorkoutsNight = workout?.night.filter((session) =>
    doesWorkoutMeetDateConditions(session, date)
  );
  const [openSessions, setOpenSessions] = useState({}); // State to track opened/closed sessions

  const toggleSessionOpen = (sessionId) => {
    setOpenSessions((prevState) => ({
      ...prevState,
      [sessionId]: !prevState[sessionId],
    }));
  };

  return (
    <>
      {loading ? (
        <div className="preloader-whirlpool text-center align-items-center">
          <div className="whirlpool"></div>
        </div>
      ) : (
        <>
        {!workout && appointmentList?.length <= 0 && workoutHistory?.length <= 0 &&
        <div className="text-center mt-3">No Activity Found</div>
        }
        {workout && 
        <div className="mb-1">
          <div className="innerInfo withoutOrange">
            <Row>
              <Col lg={4}>
                <div
                  className="doctorDetailsCard"
                  style={{ boxShadow: "0px 1px 15px #0000000f" }}
                >
                  <div className="doctorContent">
                    {workout && (
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DateCalendar
                          value={date}
                          renderLoading={() => <DayCalendarSkeleton />}
                          slots={{
                            day: ServerDay,
                          }}
                          slotProps={{
                            day: {
                              highlightedDays,
                            },
                          }}
                          onMonthChange={handleMonthChange}
                          onChange={(newValue) => {
                            setDate(newValue);
                            setDisplayExercises(false);
                          }}
                        />
                      </LocalizationProvider>
                    )}
                  </div>
                </div>
              </Col>
              <Col lg={8}>
                <div
                  className="doctorDetailsCard startdata mb-3"
                  style={{ boxShadow: "0px 1px 15px #0000000f" }}
                >
                  <div className="rightPartData">
                    <div className="filed pb-3">
                      <div className="exercise-tab-part">
                        <h6 className="title">
                          {moment(date?.$d).format("Do MMM")} Exercises
                        </h6>

                        <div>
                          {!filteredWorkoutsMorning &&
                            !filteredWorkoutsAfterNoon &&
                            !filteredWorkoutsEvening &&
                            !filteredWorkoutsNight && (
                              <div> No Workout Found</div>
                            )}

                          {filteredWorkoutsMorning?.length <= 0 &&
                            filteredWorkoutsAfterNoon?.length <= 0 &&
                            filteredWorkoutsEvening?.length <= 0 &&
                            filteredWorkoutsNight?.length <= 0 && (
                              <div> No Workout Found</div>
                            )}
                        </div>
                        <Tabs
                          id="controlled-tab-example"
                          activeKey={activeTab}
                          onSelect={handleTabSelect}
                          className="mb-3"
                        >
                          {filteredWorkoutsMorning?.length > 0 && (
                            <Tab eventKey="morning" title="Morning">
                              <div className="exercise-content">
                                  <div className="session-card-content">
                                    { filteredWorkoutsMorning.length > 0 ? (
      filteredWorkoutsMorning.map((session, index) => {
                                        const morningIndex =
                                          index %
                                          Object.keys(sessionClassMap).length;
                                        const altClass =
                                          Object.keys(sessionClassMap)[
                                            morningIndex
                                          ];
                                        // Compare the selected date with session's created_at
                                        const sessionCreatedAt = moment(
                                          session?.startDate
                                        ); // Assuming 'created_at' is a date string
                                        const selectedDate = moment(date); // Assuming 'date' is the selected date in appropriate format
                                        let currentDate = new Date(date);
                                        let totalExercises =
                                          session?.workout.length;
                                        let completedExercises =
                                          session?.workout.filter(
                                            (exercise) => {
                                              return exercise?.date_Complted?.includes(
                                                moment(
                                                  new Date(date).setUTCHours(
                                                    0,
                                                    0,
                                                    0,
                                                    0
                                                  )
                                                ).toISOString()
                                              );
                                            }
                                          ).length;

                                        // Calculate completion ratio
                                        const completionRatio =
                                          totalExercises > 0
                                            ? completedExercises /
                                              totalExercises
                                            : 0;

                                        // Determine background color based on completion ratio
                                        let backgroundColor;
                                        if (completionRatio === 1) {
                                          backgroundColor = "#90ee906e"; // Light green for fully completed
                                        } else if (completionRatio > 0) {
                                          backgroundColor = "#FFFFE0"; // Light yellow for partially completed
                                        } else {
                                          backgroundColor = "transparent"; // Default background for not completed
                                        }
                                        const isAnyExerciseIncomplete =
                                          session?.workout?.some((exercise) => {
                                            // Check if the exercise has incomplete dates
                                            return (
                                              exercise?.date_Complted
                                                ?.length === 0 ||
                                              !exercise.date_Complted.includes(
                                                moment(
                                                  currentDate?.setUTCHours(
                                                    0,
                                                    0,
                                                    0,
                                                    0
                                                  )
                                                ).toISOString()
                                              )
                                            );
                                          });
                                          const sessionEndDate = moment(session?.startDate).add(session.daysToComplete-1, 'days');
                                          if (moment(date?.$d).isSameOrBefore(sessionEndDate,"day")) {
                                          if (
                                          moment(date?.$d).isSame(
                                            sessionCreatedAt,
                                            "day"
                                          ) ||
                                          moment(date?.$d).isAfter(
                                            sessionCreatedAt
                                          )
                                        ) {
                                          return (
                                            <>
                                              <div
                                                className={`exercise-card d-block `}
                                                key={index}
                                                style={{
                                                  cursor: "pointer",
                                                 // maxHeight: "15vh",
                                                 backgroundColor:
                                                 backgroundColor,
                                                }}



                                              >
                                                <div className="d-flex justify-content-between align-items-center"   onClick={() =>
    toggleSessionOpen(session._id)
  }
> 
                                                <div className="img-text-part" >
                                                  <div className="image-block">
                                                    <img
                                                      src={
                                                        session?.image
                                                          ? session?.image
                                                          : defaultSVG
                                                      }
                                                      height={70}
                                                      width={70}
                                                      style={{
                                                        borderRadius: "55%",
                                                      }}
                                                      alt="session-img"
                                                    />
                                                  </div>
                                                  <div className="text-part ps-3">
                                                    <h6>
                                                      {session?.name?.replace(
                                                        "Workout Session",
                                                        ""
                                                      )}
                                                    </h6>
                                                    <p>
                                                        {`${completedExercises} / ${totalExercises} exercises`}
                                                      </p>
                                                  </div>

                                                  <div>
                 


                                                  </div>
                                                </div>
                                                <div className="d-flex align-items-center gap-2">
                                                  <div className="text-center">
                                                  <p >
    Total  Time
   <p style={{color:"#ADADAD",fontSize:"12px"}}>
  {formatDuration(
    session?.workout?.reduce((acc, exercise) => {
      // Filter the completedRatios where the date matches the selected date
      const totalExerciseTime = exercise?.completedRatios
        ?.filter((ratio) => moment(ratio.date).isSame(date?.$d, 'day')) // Match only if the date is same as selected date
        ?.reduce((exAcc, ratio) => exAcc + ratio.duration, 0) || 0; // Sum the durations for matching dates

      return acc + totalExerciseTime;
    }, 0)
  )} </p>
</p>
                                                  </div>
                                                  <div className="arrow-icon">
                                                    {/* Render the arrow conditionally based on the session's open/close state */}
                                                    {openSessions[
                                                      session._id
                                                    ] ? (
                                                      <MdKeyboardArrowUp />
                                                    ) : (
                                                      <IoIosArrowDown />
                                                    )}
                                                  </div>
                                                </div></div>
                                                {openSessions[session._id] && (
                                                <div className="dropdown-content">
                                
                                  <div className="">
                                    {
                                      // selectedWorkout?.map((session) => (

                                      session?.workout?.map(
                                        (exercise, index) => {
                                          let dt = new Date(date)
                                          const isCompleted =
                                            exercise.date_Complted.includes(
                                              moment(
                                                dt?.setUTCHours(0, 0, 0, 0)
                                              ).toISOString()
                                            ); // Check if selected date is in the date_completed array

                                          return (
                                            <div
                                              className="exercise-card overviewTabExercise"
                                              key={index}
                                              style={{ cursor: "pointer" }}
                                              onClick={(e) => {
                                                e.preventDefault();
                                                swal({
                                                  title: "Are you sure?",
                                                  text: `Are you sure to Edit this workout Exercise?`,
                                                  icon: "warning",
                                                  dangerMode: true,
                                                  buttons: ["No", "Yes"],
                                                }).then(async (willDelete) => {
                                                  if (willDelete) {
                                                    navigate("/exerciseEdit", {
                                                      state: {
                                                        patient_id:
                                                          location?.state
                                                            ?.patient_id,
                                                        session: "morning",
                                                        bodyPart:
                                                        session?.name,
                                                        workout:
                                                        session,
                                                        workoutItemId:
                                                          session?._id,
                                                        workoutId: workout,
                                                      },
                                                    });
                                                  } else {
                                                  }
                                                });
                                              }}
                                            >
                                              <div className="img-text-part">
                                                <div className="img-block">
                                                  <img
                                                    src={
                                                      exercise?.exercise_id
                                                        ?.exerciseImage
                                                    }
                                                    height={60}
                                                    width={70}
                                                    alt="ex-1"
                                                  />
                                                </div>
                                                <div className="text-part ps-3">
                                                  <h6 style={{marginBottom:"0px"}}>
                                                    {
                                                      exercise?.exercise_id
                                                        ?.title
                                                    }
                                                  </h6>
                                                  <p>
                                                  {Number(exercise?.count)} Reps x {Number(exercise?.set)} Set{" "}
                                                    
                                                  </p>
                                               
                                                </div>
                                              </div>
                                              <div className="d-flex gap-3 ">
                                              <p className="text-center">
<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M8 0C6.41775 0 4.87103 0.469192 3.55544 1.34824C2.23985 2.22729 1.21447 3.47672 0.608967 4.93853C0.00346615 6.40034 -0.15496 8.00887 0.153721 9.56072C0.462403 11.1126 1.22433 12.538 2.34315 13.6569C3.46197 14.7757 4.88743 15.5376 6.43928 15.8463C7.99113 16.155 9.59966 15.9965 11.0615 15.391C12.5233 14.7855 13.7727 13.7602 14.6518 12.4446C15.5308 11.129 16 9.58225 16 8C15.9977 5.87898 15.1541 3.8455 13.6543 2.34572C12.1545 0.845932 10.121 0.00232928 8 0ZM8 15.36C6.54433 15.36 5.12135 14.9283 3.91101 14.1196C2.70066 13.3109 1.75731 12.1614 1.20025 10.8165C0.643189 9.47168 0.497437 7.99183 0.781424 6.56413C1.06541 5.13643 1.76638 3.82501 2.7957 2.79569C3.82501 1.76638 5.13644 1.06541 6.56414 0.78142C7.99184 0.497433 9.47169 0.643185 10.8166 1.20025C12.1614 1.75731 13.3109 2.70066 14.1196 3.911C14.9283 5.12135 15.36 6.54433 15.36 8C15.3579 9.95134 14.5818 11.8222 13.202 13.202C11.8222 14.5818 9.95135 15.3579 8 15.36ZM12.8 8C12.8 8.08487 12.7663 8.16626 12.7063 8.22627C12.6463 8.28628 12.5649 8.32 12.48 8.32H8C7.91513 8.32 7.83374 8.28628 7.77373 8.22627C7.71372 8.16626 7.68 8.08487 7.68 8V3.52C7.68 3.43513 7.71372 3.35374 7.77373 3.29373C7.83374 3.23371 7.91513 3.2 8 3.2C8.08487 3.2 8.16626 3.23371 8.22628 3.29373C8.28629 3.35374 8.32 3.43513 8.32 3.52V7.68H12.48C12.5649 7.68 12.6463 7.71371 12.7063 7.77372C12.7663 7.83374 12.8 7.91513 12.8 8Z" fill="#1D2432"/>
</svg> <br />
<p style={{whiteSpace:"nowrap",fontSize:"12px"}}>
{formatDuration(
          exercise?.completedRatios
            ?.filter((ratio) => moment(ratio.date).isSame(date?.$d, "day")) // Match only if the date is the same
            ?.reduce((acc, ratio) => acc + ratio.duration, 0) || 0
        )}
        </p>
</p>
                                         

                                              {exercise?.difficultyLevel ? (
                                                      <>
                                                        <button
                                                          className={`btn ${
                                                            exercise?.difficultyLevel ===
                                                            "Very Easy"
                                                              ? "btn-outline-success"
                                                              : exercise?.difficultyLevel ===
                                                                "Very Hard"
                                                              ? "btn-outline-danger"
                                                              : exercise?.difficultyLevel ===
                                                                "Medium"
                                                              ? "btn-outline-secondary"
                                                              : exercise?.difficultyLevel ===
                                                                "Easy"
                                                              ? "btn-outline-info"
                                                              : exercise?.difficultyLevel ===
                                                                "Hard"
                                                              ? "btn-outline-warning"
                                                              : ""
                                                          } `}
                                                          style={{fontSize: "12px",
                                                            "height": "30px",
                                                            "width": "fit-content",
                                                            "white-space":"nowrap"}}
                                                        >
                                                          {" "}
                                                          {
                                                            exercise?.difficultyLevel
                                                          }
                                                        </button>
                                                      </>
                                                    ) : (
                                                      ""
                                                    )}

                                              <p
                        className="edit-label"
                        style={{ cursor: "pointer",fontSize:"12px" }}
                      >
                        Edit
                      </p>
                                                
                                              </div>
                                              {/* <div className="checkbox me-2">
                                                <input
                                                  type="checkbox"
                                                  id={`checkbox_${index}`}
                                                  checked={isCompleted} // Set the checked state based on whether the selected date is in the date_completed array
                                                />
                                                <label
                                                  htmlFor={`checkbox_${index}`}
                                                ></label>
                                              </div> */}
                                            </div>
                                          );
                                        }
                                      )

                                      // ))
                                    }
                                  </div> </div> )}
                                              </div>
                                             
                                            </>
                                          );
                                        }
                                      }
                                      })
                                      ) : (
                                        <div className="text-center">No Workout Found</div>
                                      )}
                                  </div>

                                 
                              </div>
                            </Tab>
                          )}
                          {filteredWorkoutsAfterNoon?.length > 0 && (
                            <Tab eventKey="afternoon" title="Afternoon">
                              <div className="exercise-content">
                                {workout?.afternoon &&
                                  workout?.afternoon.length <= 0 && (
                                    <div className="text-centet">
                                      {" "}
                                      No Exercise
                                    </div>
                                  )}

                                {filteredWorkoutsAfterNoon.length > 0 ? (
                                  filteredWorkoutsAfterNoon.map(
                                    (session, index) => {
                                      const morningIndex =
                                        index %
                                        Object.keys(sessionClassMap).length;
                                      const altClass =
                                        Object.keys(sessionClassMap)[
                                          morningIndex
                                        ];
                                      const sessionCreatedAt = moment(
                                        session?.startDate
                                      ); // Assuming 'created_at' is a date string
                                      let currentDate = new Date(date);
                                      let totalExercises =
                                        session?.workout.length;
                                      let completedExercises =
                                        session?.workout.filter((exercise) => {
                                          return exercise?.date_Complted?.includes(
                                            moment(
                                              new Date(date).setUTCHours(
                                                0,
                                                0,
                                                0,
                                                0
                                              )
                                            ).toISOString()
                                          );
                                        }).length;

                                      // Calculate completion ratio
                                      const completionRatio =
                                        totalExercises > 0
                                          ? completedExercises / totalExercises
                                          : 0;

                                      // Determine background color based on completion ratio
                                      let backgroundColor;
                                      if (completionRatio === 1) {
                                        backgroundColor = "#90ee906e"; // Light green for fully completed
                                      } else if (completionRatio > 0) {
                                        backgroundColor = "#FFFFE0"; // Light yellow for partially completed
                                      } else {
                                        backgroundColor = "transparent"; // Default background for not completed
                                      }
                                      const isAnyExerciseIncomplete =
                                        session?.workout?.some((exercise) => {
                                          // Check if the exercise has incomplete dates
                                          return (
                                            exercise?.date_Complted?.length ===
                                              0 ||
                                            !exercise.date_Complted.includes(
                                              moment(
                                                currentDate?.setUTCHours(
                                                  0,
                                                  0,
                                                  0,
                                                  0
                                                )
                                              ).toISOString()
                                            )
                                          );
                                        });
                                      const sessionEndDate = moment(
                                        session?.startDate
                                      ).add(session.daysToComplete - 1, "days");
                                      if (
                                        moment(date?.$d).isSameOrBefore(
                                          sessionEndDate,
                                          "day"
                                        )
                                      ) {
                                        if (
                                          moment(date?.$d).isSame(
                                            sessionCreatedAt,
                                            "day"
                                          ) ||
                                          moment(date?.$d).isAfter(
                                            sessionCreatedAt
                                          )
                                        ) {
                                          return (
                                            <>
                                              <div
                                                className={`exercise-card d-block `}
                                                key={index}
                                                style={{
                                                  cursor: "pointer",
//maxHeight: "15vh",
backgroundColor:
backgroundColor,
                                                }}
                                                
                                              >

                                                <div className="d-flex justify-content-between align-items-center" onClick={() =>
                                                  toggleSessionOpen(session._id)
                                                }>
                                                <div className="img-text-part">
                                                  <div className="image-block">
                                                    <img
                                                      src={
                                                        session?.image
                                                          ? session?.image
                                                          : defaultSVG
                                                      }
                                                      height={70}
                                                      width={70}
                                                      style={{
                                                        borderRadius: "55%",
                                                      }}
                                                      alt="session-img"
                                                    />
                                                  </div>
                                                  <div className="text-part ps-3">
                                                    <h6>
                                                      {session?.name?.replace(
                                                        "Workout Session",
                                                        ""
                                                      )}
                                                    </h6>
                                                    <p>
                                                      {`${completedExercises} / ${totalExercises} exercises`}
                                                    </p>
                                                    <p>
                                                      {/* {Number(exercise?.count) *
                                                    Number(exercise?.set)}{" "}
                                                  Reps{" "}
                                                  {exercise?.difficultyLevel
                                                    ? ", Difficulaty Level : " +
                                                      exercise?.difficultyLevel
                                                    : ""} */}
                                                    </p>
                                                  </div>
                                                  <div>
                                                 

                                                  </div>
                                                </div>
                                                <div className="d-flex align-items-center gap-2">
                                                  <div className="text-center">
                                                  <p >
    Total  Time
   <p style={{color:"#ADADAD",fontSize:"12px"}}>
  {formatDuration(
    session?.workout?.reduce((acc, exercise) => {
      // Filter the completedRatios where the date matches the selected date
      const totalExerciseTime = exercise?.completedRatios
        ?.filter((ratio) => moment(ratio.date).isSame(date?.$d, 'day')) // Match only if the date is same as selected date
        ?.reduce((exAcc, ratio) => exAcc + ratio.duration, 0) || 0; // Sum the durations for matching dates

      return acc + totalExerciseTime;
    }, 0)
  )} </p>
</p>
                                                  </div>
                                                  <div className="arrow-icon">
                                                    {/* Render the arrow conditionally based on the session's open/close state */}
                                                    {openSessions[
                                                      session._id
                                                    ] ? (
                                                      <MdKeyboardArrowUp />
                                                    ) : (
                                                      <IoIosArrowDown />
                                                    )}
                                                  </div>
                                                </div>
                                                </div>
                                                {openSessions[session._id] && (
                                                <div className="dropdown-content">
                                                  <div className="">
                                                    {
                                                      // workout?.afternoon.map((session) => (

                                                      session?.workout?.map(
                                                        (exercise, index) => {
                                                          let dt = new Date(
                                                            date
                                                          );
                                                          const isCompleted =
                                                            exercise.date_Complted.includes(
                                                              moment(
                                                                dt?.setUTCHours(
                                                                  0,
                                                                  0,
                                                                  0,
                                                                  0
                                                                )
                                                              ).toISOString()
                                                            ); // Check if selected date is in the date_completed array

                                                          return (
                                                            <div
                                                              className="exercise-card overviewTabExercise"
                                                              style={{
                                                                cursor:
                                                                  "pointer",
                                                              }}
                                                              key={index}
                                                              onClick={(e) => {
                                                                e.preventDefault();
                                                                swal({
                                                                  title:
                                                                    "Are you sure?",
                                                                  text: `Are you sure to Edit this workout Exercise?`,
                                                                  icon: "warning",
                                                                  dangerMode: true,
                                                                  buttons: [
                                                                    "No",
                                                                    "Yes",
                                                                  ],
                                                                }).then(
                                                                  async (
                                                                    willDelete
                                                                  ) => {
                                                                    if (
                                                                      willDelete
                                                                    ) {
                                                                      navigate(
                                                                        "/exerciseEdit",
                                                                        {
                                                                          state:
                                                                            {
                                                                              patient_id:
                                                                                patient_id,
                                                                              session:
                                                                                "afternoon",
                                                                              bodyPart:
                                                                              session?.name,
                                                                              workout:
                                                                                session,
                                                                              workoutItemId:
                                                                              session?._id,
                                                                              workoutId:
                                                                                workout,
                                                                            },
                                                                        }
                                                                      );
                                                                    } else {
                                                                    }
                                                                  }
                                                                );
                                                              }}
                                                            >
                                                              <div className="img-text-part">
                                                                <div className="img-block">
                                                                  <img
                                                                    src={
                                                                      exercise
                                                                        ?.exercise_id
                                                                        ?.exerciseImage
                                                                    }
                                                                    height={60}
                                                                    width={70}
                                                                    alt="ex-1"
                                                                  />
                                                                </div>
                                                                <div className="text-part ps-3">
                                                                <h6 style={{marginBottom:"0px"}}>
                                                                    {
                                                                      exercise
                                                                        ?.exercise_id
                                                                        ?.title
                                                                    }
                                                                  </h6>
                                                                  <p>
                                                                    {Number(
                                                                      exercise?.count
                                                                    )}{" "}
                                                                    Reps x{" "}
                                                                    {Number(
                                                                      exercise?.set
                                                                    )}{" "}
                                                                    Set
                                                                    
                                                                  </p>
                                                                </div>
                                                              </div>
                                                              <div className="d-flex gap-3 ">
                                              <p className="text-center">
<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M8 0C6.41775 0 4.87103 0.469192 3.55544 1.34824C2.23985 2.22729 1.21447 3.47672 0.608967 4.93853C0.00346615 6.40034 -0.15496 8.00887 0.153721 9.56072C0.462403 11.1126 1.22433 12.538 2.34315 13.6569C3.46197 14.7757 4.88743 15.5376 6.43928 15.8463C7.99113 16.155 9.59966 15.9965 11.0615 15.391C12.5233 14.7855 13.7727 13.7602 14.6518 12.4446C15.5308 11.129 16 9.58225 16 8C15.9977 5.87898 15.1541 3.8455 13.6543 2.34572C12.1545 0.845932 10.121 0.00232928 8 0ZM8 15.36C6.54433 15.36 5.12135 14.9283 3.91101 14.1196C2.70066 13.3109 1.75731 12.1614 1.20025 10.8165C0.643189 9.47168 0.497437 7.99183 0.781424 6.56413C1.06541 5.13643 1.76638 3.82501 2.7957 2.79569C3.82501 1.76638 5.13644 1.06541 6.56414 0.78142C7.99184 0.497433 9.47169 0.643185 10.8166 1.20025C12.1614 1.75731 13.3109 2.70066 14.1196 3.911C14.9283 5.12135 15.36 6.54433 15.36 8C15.3579 9.95134 14.5818 11.8222 13.202 13.202C11.8222 14.5818 9.95135 15.3579 8 15.36ZM12.8 8C12.8 8.08487 12.7663 8.16626 12.7063 8.22627C12.6463 8.28628 12.5649 8.32 12.48 8.32H8C7.91513 8.32 7.83374 8.28628 7.77373 8.22627C7.71372 8.16626 7.68 8.08487 7.68 8V3.52C7.68 3.43513 7.71372 3.35374 7.77373 3.29373C7.83374 3.23371 7.91513 3.2 8 3.2C8.08487 3.2 8.16626 3.23371 8.22628 3.29373C8.28629 3.35374 8.32 3.43513 8.32 3.52V7.68H12.48C12.5649 7.68 12.6463 7.71371 12.7063 7.77372C12.7663 7.83374 12.8 7.91513 12.8 8Z" fill="#1D2432"/>
</svg> <br />
<p style={{whiteSpace:"nowrap",fontSize:"12px"}}>
{formatDuration(
          exercise?.completedRatios
            ?.filter((ratio) => moment(ratio.date).isSame(date?.$d, "day")) // Match only if the date is the same
            ?.reduce((acc, ratio) => acc + ratio.duration, 0) || 0
        )}
        </p>
</p>
                                         

                                              {exercise?.difficultyLevel ? (
                                                      <>
                                                        <button
                                                          className={`btn ${
                                                            exercise?.difficultyLevel ===
                                                            "Very Easy"
                                                              ? "btn-outline-success"
                                                              : exercise?.difficultyLevel ===
                                                                "Very Hard"
                                                              ? "btn-outline-danger"
                                                              : exercise?.difficultyLevel ===
                                                                "Medium"
                                                              ? "btn-outline-secondary"
                                                              : exercise?.difficultyLevel ===
                                                                "Easy"
                                                              ? "btn-outline-info"
                                                              : exercise?.difficultyLevel ===
                                                                "Hard"
                                                              ? "btn-outline-warning"
                                                              : ""
                                                          } `}
                                                          style={{fontSize: "12px",
                                                            "height": "30px",
                                                            "width": "fit-content",
                                                            "white-space":"nowrap"}}
                                                        >
                                                          {" "}
                                                          {
                                                            exercise?.difficultyLevel
                                                          }
                                                        </button>
                                                      </>
                                                    ) : (
                                                      ""
                                                    )}

                                              <p
                        className="edit-label"
                        style={{ cursor: "pointer",fontSize:"12px" }}
                      >
                        Edit
                      </p>
                                                
                                              </div>
                                                              {/* <div className="checkbox me-2">
                                                                <input
                                                                  type="checkbox"
                                                                  id={`checkbox_${index}`}
                                                                  checked={
                                                                    isCompleted
                                                                  } // Set the checked state based on whether the selected date is in the date_completed array
                                                                />
                                                                <label
                                                                  htmlFor={`checkbox_${index}`}
                                                                ></label>
                                                              </div> */}
                                                            </div>
                                                          );
                                                        }
                                                      )

                                                      // ))
                                                    }
                                                  </div>
                                                </div>
                                              )}
                                                
                                              </div>
                                             
                                            </>
                                          );
                                        }
                                      }
                                    }
                                  )
                                ) : (
                                  <div className="text-center">
                                    No Workout Found
                                  </div>
                                )}
                              </div>
                            </Tab>
                          )}
                          {filteredWorkoutsEvening?.length > 0 && (
                            <Tab eventKey="evening" title="Evening">
                              <div className="exercise-content">
                                {workout?.evening &&
                                  workout?.evening.length <= 0 && (
                                    <div className="text-centet">
                                      {" "}
                                      No Exercise
                                    </div>
                                  )}

                                <div className="session-card-content">
                                  {filteredWorkoutsEvening.length > 0 ? (
                                    filteredWorkoutsEvening.map(
                                      (session, index) => {
                                        const morningIndex =
                                          index %
                                          Object.keys(sessionClassMap).length;
                                        const altClass =
                                          Object.keys(sessionClassMap)[
                                            morningIndex
                                          ];
                                        const sessionCreatedAt = moment(
                                          session?.startDate
                                        ); // Assuming 'created_at' is a date string
                                        let currentDate = new Date(date);
                                        let totalExercises =
                                          session?.workout.length;
                                        let completedExercises =
                                          session?.workout.filter(
                                            (exercise) => {
                                              return exercise?.date_Complted?.includes(
                                                moment(
                                                  new Date(date).setUTCHours(
                                                    0,
                                                    0,
                                                    0,
                                                    0
                                                  )
                                                ).toISOString()
                                              );
                                            }
                                          ).length;
                                        // Calculate completion ratio
                                        const completionRatio =
                                          totalExercises > 0
                                            ? completedExercises /
                                              totalExercises
                                            : 0;

                                        // Determine background color based on completion ratio
                                        let backgroundColor;
                                        if (completionRatio === 1) {
                                          backgroundColor = "#90ee906e"; // Light green for fully completed
                                        } else if (completionRatio > 0) {
                                          backgroundColor = "#FFFFE0"; // Light yellow for partially completed
                                        } else {
                                          backgroundColor = "transparent"; // Default background for not completed
                                        }

                                        const isAnyExerciseIncomplete =
                                          session?.workout?.some((exercise) => {
                                            // Check if the exercise has incomplete dates
                                            return (
                                              exercise?.date_Complted
                                                ?.length === 0 ||
                                              !exercise.date_Complted.includes(
                                                moment(
                                                  currentDate?.setUTCHours(
                                                    0,
                                                    0,
                                                    0,
                                                    0
                                                  )
                                                ).toISOString()
                                              )
                                            );
                                          });
                                        const sessionEndDate = moment(
                                          session?.startDate
                                        ).add(
                                          session.daysToComplete - 1,
                                          "days"
                                        );
                                        if (
                                          moment(date?.$d).isSameOrBefore(
                                            sessionEndDate,
                                            "day"
                                          )
                                        ) {
                                          if (
                                            moment(date?.$d).isSame(
                                              sessionCreatedAt,
                                              "day"
                                            ) ||
                                            moment(date?.$d).isAfter(
                                              sessionCreatedAt
                                            )
                                          ) {
                                            return (
                                              <>
                                                <div
                                                  className={`exercise-card  d-block`}
                                                  key={index}
                                                  style={{
                                                    cursor: "pointer",
                                                   // maxHeight: "15vh",
                                                   backgroundColor:
                                                   backgroundColor,
                                                  }}
                                                >
<div className="d-flex justify-content-between align-items-center" onClick={() =>
                                                      toggleSessionOpen(
                                                        session._id
                                                      )
                                                    }>
                                                  <div
                                                    className="img-text-part"
                                                    
                                                  >
                                                    <div className="image-block">
                                                      <img
                                                        src={
                                                          session?.image
                                                            ? session?.image
                                                            : defaultSVG
                                                        }
                                                        height={70}
                                                        width={70}
                                                        style={{
                                                          borderRadius: "55%",
                                                        }}
                                                        alt="session-img"
                                                      />
                                                    </div>
                                                    <div className="text-part ps-3">
                                                      <h6>
                                                        {session?.name?.replace(
                                                          "Workout Session",
                                                          ""
                                                        )}
                                                      </h6>
                                                      <p>
                                                        {`${completedExercises} / ${totalExercises} exercises`}
                                                      </p>
                                                      <p>
                                                        {/* {Number(exercise?.count) *
                                                    Number(exercise?.set)}{" "}
                                                  Reps{" "}
                                                  {exercise?.difficultyLevel
                                                    ? ", Difficulaty Level : " +
                                                      exercise?.difficultyLevel
                                                    : ""} */}
                                                      </p>
                                                    </div>
                                                  </div>
                                                  <div className="d-flex align-items-center gap-2">
                                                  <div className="text-center">
                                                  <p >
    Total  Time
   <p style={{color:"#ADADAD",fontSize:"12px"}}>
  {formatDuration(
    session?.workout?.reduce((acc, exercise) => {
      // Filter the completedRatios where the date matches the selected date
      const totalExerciseTime = exercise?.completedRatios
        ?.filter((ratio) => moment(ratio.date).isSame(date?.$d, 'day')) // Match only if the date is same as selected date
        ?.reduce((exAcc, ratio) => exAcc + ratio.duration, 0) || 0; // Sum the durations for matching dates

      return acc + totalExerciseTime;
    }, 0)
  )} </p>
</p>
                                                  </div>
                                                  <div className="arrow-icon">
                                                    {/* Render the arrow conditionally based on the session's open/close state */}
                                                    {openSessions[
                                                      session._id
                                                    ] ? (
                                                      <MdKeyboardArrowUp />
                                                    ) : (
                                                      <IoIosArrowDown />
                                                    )}
                                                  </div>
                                                </div>
                                                  </div>
                                                  {openSessions[session._id] && (
                                                  <div className="dropdown-content">
                                                    {" "}
                                                    <div className="">
                                                      {
                                                        // workout?.night.map((session) => (

                                                        session.workout.map(
                                                          (exercise, index) => {
                                                            let dt = new Date(
                                                              date
                                                            );
                                                            const isCompleted =
                                                              exercise.date_Complted.includes(
                                                                moment(
                                                                  dt?.setUTCHours(
                                                                    0,
                                                                    0,
                                                                    0,
                                                                    0
                                                                  )
                                                                ).toISOString()
                                                              ); // Check if selected date is in the date_completed array

                                                            return (
                                                              <div
                                                                className="exercise-card overviewTabExercise"
                                                                key={index}
                                                                style={{
                                                                  cursor:
                                                                    "pointer",
                                                                }}
                                                                onClick={(
                                                                  e
                                                                ) => {
                                                                  e.preventDefault();
                                                                  swal({
                                                                    title:
                                                                      "Are you sure?",
                                                                    text: `Are you sure to Edit this workout Exercise?`,
                                                                    icon: "warning",
                                                                    dangerMode: true,
                                                                    buttons: [
                                                                      "No",
                                                                      "Yes",
                                                                    ],
                                                                  }).then(
                                                                    async (
                                                                      willDelete
                                                                    ) => {
                                                                      if (
                                                                        willDelete
                                                                      ) {
                                                                        navigate(
                                                                          "/exerciseEdit",
                                                                          {
                                                                            state:
                                                                              {
                                                                                patient_id:
                                                                                  patient_id,
                                                                                session:
                                                                                  "evening",
                                                                                bodyPart:
                                                                                session?.name,
                                                                                workout:
                                                                                session,
                                                                                workoutItemId:
                                                                                session?._id,
                                                                                workoutId:
                                                                                  workout,
                                                                              },
                                                                          }
                                                                        );
                                                                      } else {
                                                                      }
                                                                    }
                                                                  );
                                                                }}
                                                              >
                                                                <div className="img-text-part">
                                                                  <div className="img-block">
                                                                    <img
                                                                      src={
                                                                        exercise
                                                                          ?.exercise_id
                                                                          ?.exerciseImage
                                                                      }
                                                                      height={
                                                                        60
                                                                      }
                                                                      width={70}
                                                                      alt="ex-1"
                                                                    />
                                                                  </div>
                                                                  <div className="text-part ps-3">
                                                                  <h6 style={{marginBottom:"0px"}}>
                                                                      {
                                                                        exercise
                                                                          ?.exercise_id
                                                                          ?.title
                                                                      }
                                                                    </h6>
                                                                    <p>
                                                                      {Number(
                                                                        exercise?.count
                                                                      )}{" "}
                                                                      Reps x{" "}
                                                                      {Number(
                                                                        exercise?.set
                                                                      )}{" "}
                                                                      Set
                                                                    </p>
                                                                  </div>
                                                                </div>
                                                                <div className="d-flex gap-3 ">
                                              <p className="text-center">
<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M8 0C6.41775 0 4.87103 0.469192 3.55544 1.34824C2.23985 2.22729 1.21447 3.47672 0.608967 4.93853C0.00346615 6.40034 -0.15496 8.00887 0.153721 9.56072C0.462403 11.1126 1.22433 12.538 2.34315 13.6569C3.46197 14.7757 4.88743 15.5376 6.43928 15.8463C7.99113 16.155 9.59966 15.9965 11.0615 15.391C12.5233 14.7855 13.7727 13.7602 14.6518 12.4446C15.5308 11.129 16 9.58225 16 8C15.9977 5.87898 15.1541 3.8455 13.6543 2.34572C12.1545 0.845932 10.121 0.00232928 8 0ZM8 15.36C6.54433 15.36 5.12135 14.9283 3.91101 14.1196C2.70066 13.3109 1.75731 12.1614 1.20025 10.8165C0.643189 9.47168 0.497437 7.99183 0.781424 6.56413C1.06541 5.13643 1.76638 3.82501 2.7957 2.79569C3.82501 1.76638 5.13644 1.06541 6.56414 0.78142C7.99184 0.497433 9.47169 0.643185 10.8166 1.20025C12.1614 1.75731 13.3109 2.70066 14.1196 3.911C14.9283 5.12135 15.36 6.54433 15.36 8C15.3579 9.95134 14.5818 11.8222 13.202 13.202C11.8222 14.5818 9.95135 15.3579 8 15.36ZM12.8 8C12.8 8.08487 12.7663 8.16626 12.7063 8.22627C12.6463 8.28628 12.5649 8.32 12.48 8.32H8C7.91513 8.32 7.83374 8.28628 7.77373 8.22627C7.71372 8.16626 7.68 8.08487 7.68 8V3.52C7.68 3.43513 7.71372 3.35374 7.77373 3.29373C7.83374 3.23371 7.91513 3.2 8 3.2C8.08487 3.2 8.16626 3.23371 8.22628 3.29373C8.28629 3.35374 8.32 3.43513 8.32 3.52V7.68H12.48C12.5649 7.68 12.6463 7.71371 12.7063 7.77372C12.7663 7.83374 12.8 7.91513 12.8 8Z" fill="#1D2432"/>
</svg> <br />
<p style={{whiteSpace:"nowrap",fontSize:"12px"}}>
{formatDuration(
          exercise?.completedRatios
            ?.filter((ratio) => moment(ratio.date).isSame(date?.$d, "day")) // Match only if the date is the same
            ?.reduce((acc, ratio) => acc + ratio.duration, 0) || 0
        )}
        </p>
</p>
                                         

                                              {exercise?.difficultyLevel ? (
                                                      <>
                                                        <button
                                                          className={`btn ${
                                                            exercise?.difficultyLevel ===
                                                            "Very Easy"
                                                              ? "btn-outline-success"
                                                              : exercise?.difficultyLevel ===
                                                                "Very Hard"
                                                              ? "btn-outline-danger"
                                                              : exercise?.difficultyLevel ===
                                                                "Medium"
                                                              ? "btn-outline-secondary"
                                                              : exercise?.difficultyLevel ===
                                                                "Easy"
                                                              ? "btn-outline-info"
                                                              : exercise?.difficultyLevel ===
                                                                "Hard"
                                                              ? "btn-outline-warning"
                                                              : ""
                                                          } `}
                                                          style={{fontSize: "12px",
                                                            "height": "30px",
                                                            "width": "fit-content",
                                                            "white-space":"nowrap"}}
                                                        >
                                                          {" "}
                                                          {
                                                            exercise?.difficultyLevel
                                                          }
                                                        </button>
                                                      </>
                                                    ) : (
                                                      ""
                                                    )}

                                              <p
                        className="edit-label"
                        style={{ cursor: "pointer",fontSize:"12px" }}
                      >
                        Edit
                      </p>
                                                
                                              </div>
                                                                {/* <div className="checkbox me-2">
                                                                  <input
                                                                    type="checkbox"
                                                                    id={`checkbox_${index}`}
                                                                    checked={
                                                                      isCompleted
                                                                    } // Set the checked state based on whether the selected date is in the date_completed array
                                                                  />
                                                                  <label
                                                                    htmlFor={`checkbox_${index}`}
                                                                  ></label>
                                                                </div> */}
                                                              </div>
                                                            );
                                                          }
                                                        )

                                                        // ))
                                                      }
                                                    </div>
                                                  </div>
                                                )}
                                                </div>
                                               
                                              </>
                                            );
                                          }
                                        }
                                      }
                                    )
                                  ) : (
                                    <div className="text-center">
                                      No Workout Found
                                    </div>
                                  )}
                                </div>
                              </div>
                            </Tab>
                          )}
                          {filteredWorkoutsNight?.length > 0 && (
                            <Tab eventKey="night" title="Night">
                              <div className="exercise-content">
                                {workout?.night &&
                                  workout?.night.length <= 0 && (
                                    <div className="text-centet">
                                      {" "}
                                      No Exercise
                                    </div>
                                  )}

                                <div className="session-card-content">
                                  {filteredWorkoutsNight.length > 0 ? (
                                    filteredWorkoutsNight.map(
                                      (session, index) => {
                                        const morningIndex =
                                          index %
                                          Object.keys(sessionClassMap).length;
                                        const altClass =
                                          Object.keys(sessionClassMap)[
                                            morningIndex
                                          ];
                                        const sessionCreatedAt = moment(
                                          session?.startDate
                                        ); // Assuming 'created_at' is a date string
                                        let currentDate = new Date(date);
                                        let totalExercises =
                                          session?.workout.length;
                                        let completedExercises =
                                          session?.workout.filter(
                                            (exercise) => {
                                              return exercise?.date_Complted?.includes(
                                                moment(
                                                  new Date(date).setUTCHours(
                                                    0,
                                                    0,
                                                    0,
                                                    0
                                                  )
                                                ).toISOString()
                                              );
                                            }
                                          ).length;
                                        // Calculate completion ratio
                                        const completionRatio =
                                          totalExercises > 0
                                            ? completedExercises /
                                              totalExercises
                                            : 0;

                                        // Determine background color based on completion ratio
                                        let backgroundColor;
                                        if (completionRatio === 1) {
                                          backgroundColor = "#90ee906e"; // Light green for fully completed
                                        } else if (completionRatio > 0) {
                                          backgroundColor = "#FFFFE0"; // Light yellow for partially completed
                                        } else {
                                          backgroundColor = "transparent"; // Default background for not completed
                                        }
                                        const isAnyExerciseIncomplete =
                                          session?.workout?.some((exercise) => {
                                            // Check if the exercise has incomplete dates
                                            return (
                                              exercise?.date_Complted
                                                ?.length === 0 ||
                                              !exercise.date_Complted.includes(
                                                moment(
                                                  currentDate?.setUTCHours(
                                                    0,
                                                    0,
                                                    0,
                                                    0
                                                  )
                                                ).toISOString()
                                              )
                                            );
                                          });
                                        const sessionEndDate = moment(
                                          session?.startDate
                                        ).add(
                                          session.daysToComplete - 1,
                                          "days"
                                        );
                                        if (
                                          moment(date?.$d).isSameOrBefore(
                                            sessionEndDate,
                                            "day"
                                          )
                                        ) {
                                          if (
                                            moment(date?.$d).isSame(
                                              sessionCreatedAt,
                                              "day"
                                            ) ||
                                            moment(date?.$d).isAfter(
                                              sessionCreatedAt
                                            )
                                          ) {
                                            return (
                                              <>
                                                <div
                                                  className={`exercise-card  d-block`}
                                                  key={index}
                                                  style={{
                                                    cursor: "pointer",
                                                    backgroundColor:
                                                    backgroundColor,
                                                  }}
                                                >
                                                  <div className="d-flex justify-content-between align-items-center"  onClick={() =>
                                                      toggleSessionOpen(
                                                        session._id
                                                      )
                                                    }>
                                                  <div
                                                    className="img-text-part"
                                                   
                                                  >
                                                    <div className="image-block">
                                                      <img
                                                        src={
                                                          session?.image
                                                            ? session?.image
                                                            : defaultSVG
                                                        }
                                                        height={70}
                                                        width={70}
                                                        style={{
                                                          borderRadius: "55%",
                                                        }}
                                                        alt="session-img"
                                                      />
                                                    </div>
                                                    <div className="text-part ps-3">
                                                      <h6>
                                                        {session?.name?.replace(
                                                          "Workout Session",
                                                          ""
                                                        )}
                                                      </h6>
                                                      <p>
                                                        {`${completedExercises} / ${totalExercises} exercises`}
                                                      </p>
                                                      <p>
                                                        {/* {Number(exercise?.count) *
                                                    Number(exercise?.set)}{" "}
                                                  Reps{" "}
                                                  {exercise?.difficultyLevel
                                                    ? ", Difficulaty Level : " +
                                                      exercise?.difficultyLevel
                                                    : ""} */}
                                                      </p>
                                                    </div>
                                                   
                                                  </div>
                                                  <div className="d-flex align-items-center gap-2">
                                                  <div className="text-center">
                                                  <p >
    Total  Time
   <p style={{color:"#ADADAD",fontSize:"12px"}}>
  {formatDuration(
    session?.workout?.reduce((acc, exercise) => {
      // Filter the completedRatios where the date matches the selected date
      const totalExerciseTime = exercise?.completedRatios
        ?.filter((ratio) => moment(ratio.date).isSame(date?.$d, 'day')) // Match only if the date is same as selected date
        ?.reduce((exAcc, ratio) => exAcc + ratio.duration, 0) || 0; // Sum the durations for matching dates

      return acc + totalExerciseTime;
    }, 0)
  )} </p>
</p>
                                                  </div>
                                                  <div className="arrow-icon">
                                                    {/* Render the arrow conditionally based on the session's open/close state */}
                                                    {openSessions[
                                                      session._id
                                                    ] ? (
                                                      <MdKeyboardArrowUp />
                                                    ) : (
                                                      <IoIosArrowDown />
                                                    )}
                                                  </div>
                                                </div>
                                                  </div>
                                                  {openSessions[session._id] && (
                                                  <div className="dropdown-content">
                                                    {" "}
                                                    <div className="">
                                                      {
                                                        // workout?.night.map((session) => (

                                                        session.workout.map(
                                                          (exercise, index) => {
                                                            let dt = new Date(
                                                              date
                                                            );
                                                            const isCompleted =
                                                              exercise.date_Complted.includes(
                                                                moment(
                                                                  dt?.setUTCHours(
                                                                    0,
                                                                    0,
                                                                    0,
                                                                    0
                                                                  )
                                                                ).toISOString()
                                                              ); // Check if selected date is in the date_completed array

                                                            return (
                                                              <div
                                                                className="exercise-card overviewTabExercise"
                                                                key={index}
                                                                style={{
                                                                  cursor:
                                                                    "pointer",
                                                                }}
                                                                onClick={(
                                                                  e
                                                                ) => {
                                                                  e.preventDefault();
                                                                  swal({
                                                                    title:
                                                                      "Are you sure?",
                                                                    text: `Are you sure to Edit this workout Exercise?`,
                                                                    icon: "warning",
                                                                    dangerMode: true,
                                                                    buttons: [
                                                                      "No",
                                                                      "Yes",
                                                                    ],
                                                                  }).then(
                                                                    async (
                                                                      willDelete
                                                                    ) => {
                                                                      if (
                                                                        willDelete
                                                                      ) {
                                                                        navigate(
                                                                          "/exerciseEdit",
                                                                          {
                                                                            state:
                                                                              {
                                                                                patient_id:
                                                                                  patient_id,
                                                                                session:
                                                                                  "night",
                                                                                bodyPart:
                                                                                session?.name,
                                                                                workout:
                                                                                session,
                                                                                workoutItemId:
                                                                                session?._id,
                                                                                workoutId:
                                                                                  workout,
                                                                              },
                                                                          }
                                                                        );
                                                                      } else {
                                                                      }
                                                                    }
                                                                  );
                                                                }}
                                                              >
                                                                <div className="img-text-part">
                                                                  <div className="img-block">
                                                                    <img
                                                                      src={
                                                                        exercise
                                                                          ?.exercise_id
                                                                          ?.exerciseImage
                                                                      }
                                                                      height={
                                                                        60
                                                                      }
                                                                      width={70}
                                                                      alt="ex-1"
                                                                    />
                                                                  </div>
                                                                  <div className="text-part ps-3">
                                                                  <h6 style={{marginBottom:"0px"}}>
                                                                      {
                                                                        exercise
                                                                          ?.exercise_id
                                                                          ?.title
                                                                      }
                                                                    </h6>
                                                                    <p>
                                                                      {Number(
                                                                        exercise?.count
                                                                      )}{" "}
                                                                      Reps x{" "}
                                                                      {Number(
                                                                        exercise?.set
                                                                      )}{" "}
                                                                      Set
                                                                      
                                                                    </p>
                                                                  </div>
                                                                </div>
                                                                <div className="d-flex gap-3 ">
                                              <p className="text-center">
<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M8 0C6.41775 0 4.87103 0.469192 3.55544 1.34824C2.23985 2.22729 1.21447 3.47672 0.608967 4.93853C0.00346615 6.40034 -0.15496 8.00887 0.153721 9.56072C0.462403 11.1126 1.22433 12.538 2.34315 13.6569C3.46197 14.7757 4.88743 15.5376 6.43928 15.8463C7.99113 16.155 9.59966 15.9965 11.0615 15.391C12.5233 14.7855 13.7727 13.7602 14.6518 12.4446C15.5308 11.129 16 9.58225 16 8C15.9977 5.87898 15.1541 3.8455 13.6543 2.34572C12.1545 0.845932 10.121 0.00232928 8 0ZM8 15.36C6.54433 15.36 5.12135 14.9283 3.91101 14.1196C2.70066 13.3109 1.75731 12.1614 1.20025 10.8165C0.643189 9.47168 0.497437 7.99183 0.781424 6.56413C1.06541 5.13643 1.76638 3.82501 2.7957 2.79569C3.82501 1.76638 5.13644 1.06541 6.56414 0.78142C7.99184 0.497433 9.47169 0.643185 10.8166 1.20025C12.1614 1.75731 13.3109 2.70066 14.1196 3.911C14.9283 5.12135 15.36 6.54433 15.36 8C15.3579 9.95134 14.5818 11.8222 13.202 13.202C11.8222 14.5818 9.95135 15.3579 8 15.36ZM12.8 8C12.8 8.08487 12.7663 8.16626 12.7063 8.22627C12.6463 8.28628 12.5649 8.32 12.48 8.32H8C7.91513 8.32 7.83374 8.28628 7.77373 8.22627C7.71372 8.16626 7.68 8.08487 7.68 8V3.52C7.68 3.43513 7.71372 3.35374 7.77373 3.29373C7.83374 3.23371 7.91513 3.2 8 3.2C8.08487 3.2 8.16626 3.23371 8.22628 3.29373C8.28629 3.35374 8.32 3.43513 8.32 3.52V7.68H12.48C12.5649 7.68 12.6463 7.71371 12.7063 7.77372C12.7663 7.83374 12.8 7.91513 12.8 8Z" fill="#1D2432"/>
</svg> <br />
<p style={{whiteSpace:"nowrap",fontSize:"12px"}}>
{formatDuration(
          exercise?.completedRatios
            ?.filter((ratio) => moment(ratio.date).isSame(date?.$d, "day")) // Match only if the date is the same
            ?.reduce((acc, ratio) => acc + ratio.duration, 0) || 0
        )}
        </p>
</p>
                                         

                                              {exercise?.difficultyLevel ? (
                                                      <>
                                                        <button
                                                          className={`btn ${
                                                            exercise?.difficultyLevel ===
                                                            "Very Easy"
                                                              ? "btn-outline-success"
                                                              : exercise?.difficultyLevel ===
                                                                "Very Hard"
                                                              ? "btn-outline-danger"
                                                              : exercise?.difficultyLevel ===
                                                                "Medium"
                                                              ? "btn-outline-secondary"
                                                              : exercise?.difficultyLevel ===
                                                                "Easy"
                                                              ? "btn-outline-info"
                                                              : exercise?.difficultyLevel ===
                                                                "Hard"
                                                              ? "btn-outline-warning"
                                                              : ""
                                                          } `}
                                                          style={{fontSize: "12px",
                                                            "height": "30px",
                                                            "width": "fit-content",
                                                            "white-space":"nowrap"}}
                                                        >
                                                          {" "}
                                                          {
                                                            exercise?.difficultyLevel
                                                          }
                                                        </button>
                                                      </>
                                                    ) : (
                                                      ""
                                                    )}

                                              <p
                        className="edit-label"
                        style={{ cursor: "pointer",fontSize:"12px" }}
                      >
                        Edit
                      </p>
                                                
                                              </div>
                                                                {/* <div className="checkbox me-2">
                                                                  <input
                                                                    type="checkbox"
                                                                    id={`checkbox_${index}`}
                                                                    checked={
                                                                      isCompleted
                                                                    } // Set the checked state based on whether the selected date is in the date_completed array
                                                                  />
                                                                  <label
                                                                    htmlFor={`checkbox_${index}`}
                                                                  ></label>
                                                                </div> */}
                                                              </div>
                                                            );
                                                          }
                                                        )

                                                        // ))
                                                      }
                                                    </div>
                                                  </div>
                                                )}
                                                </div>
                                                
                                              </>
                                            );
                                          }
                                        }
                                      }
                                    )
                                  ) : (
                                    <div className="text-center">
                                      No Workout Found
                                    </div>
                                  )}
                                </div>
                              </div>
                            </Tab>
                          )}
                        </Tabs>
                      </div>
                    </div>
                  </div>
                </div>
              </Col>
            </Row>

         
          </div>
        </div>}

<Workout
patient={patient}
user={user}
workoutHistory={workoutHistory}
/>
<Appointment patient={patient} appointmentList={appointmentList} />
</> 
      )}

      <Modal
        show={ModalNotes}
        onHide={(e) => {
          setModalNotes(false);
        }}
        size="md"
        className="complete-modal"
        centered
      >
        <Modal.Header>
          <div className="top-heading w-100 d-flex justify-content-end text-end">
            <button
              className="btn btn-transpatent"
              onClick={(e) => {
                setModalNotes(false);
              }}
            >
              X
            </button>
          </div>
        </Modal.Header>
        <Modal.Body>
          <div className="content-block text-center">
            <img src={doneImg} height={60} width={60} alt="modal-video" />
          </div>
          <div className="bottom-block text-center">
            <div
              className="workout-desc body-content"
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                flexDirection: "column",
                paddingTop: "25px",
              }}
            >
              <p>{selectedWorkout?.name} </p>

              <div className="precautions">
                <span className="text-secondary">Precautions:</span>
                {!selectedWorkout?.note && " No Notes Provided"}
                {selectedWorkout?.note && (
                  <ul className="text-secondary" style={{ textAlign: "left" }}>
                    {selectedWorkout?.note &&
                      selectedWorkout?.note
                        .split(".")
                        .filter((note) => note.trim() !== "")
                        .map((note, index) => <li key={index}>{note}</li>)}
                  </ul>
                )}
              </div>
              <button
                className="theme-button btn btn-primary mt-3"
                style={{ minWidth: "300px", padding: "14px" }}
                onClick={(e) => {
                  setModalNotes(false);
                }}
              >
                Close
              </button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default OverView;
