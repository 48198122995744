import { useEffect, useState } from "react";
import Layout from "../../layout";
import TopBarNavigation from "../../components/TopBarNavigation";
import { post, put, getAuthConfigImage } from "../../libs/http-hydrate";
import { useNavigate, useLocation } from "react-router-dom";
import UploadIcon from "../../assets/images/upload.svg";
import Modal from 'react-bootstrap/Modal';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import Animation from "../../Animation/Animation";

function Community() {
  const [completeCommunity, setCompleteCommunity] = useState(false);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();

  // Check if the community data is coming from location (for Edit functionality)
  const isEdit = !!location?.state?.community;

  const initialFormState = {
    title: isEdit ? location?.state?.community?.title : "",
    description: isEdit ? location?.state?.community?.description : "",
    community_image: isEdit ? location?.state?.community?.community_image : null,
  };

  const [formValues, setFormValues] = useState(initialFormState);
  const [formErrors, setFormErrors] = useState({});
  const [imagePreview, setImagePreview] = useState(isEdit ? location?.state?.community?.community_image : null); // For image preview

  // Handle form field changes
  const handleChange = (e) => {
    const { name, value, files, type } = e.target;

    if (type === 'file' && name === 'community_image') {
      const selectedImage = files[0];
      if (selectedImage && selectedImage.size > 2097152) { // 2MB in bytes
        setFormErrors({...formErrors, community_image: "Image must be less than 2MB"});
        setFormValues({ ...formValues, community_image: "" });
        setImagePreview(null);
      } else {
        setFormErrors({...formErrors, community_image: ""});
        setFormValues({ ...formValues, community_image: selectedImage });
        setImagePreview(URL.createObjectURL(selectedImage)); // Display image preview
      }
    } else {
      setFormValues({ ...formValues, [name]: value });
    }
  };

  const removeImage = () => {
    setImagePreview(null); // Remove image preview
    setFormValues({ ...formValues, community_image: null }); // Remove the image from form data
  };

  // Handle form submit
  const handleSubmit = async (e) => {
    e.preventDefault();
    const errors = validate(formValues);
    if (Object.keys(errors).length === 0) {
      if (isEdit) {
        handleUpdateCommunity();
      } else {
        handleCreateCommunity();
      }
    } else {
      setFormErrors(errors);
    }
  };

  const validate = (values) => {
    const errors = {};
    if (!values.title) errors.title = "Title is required";
    if (!values.description) errors.description = "Description is required";
    if (!isEdit && !values.community_image) errors.community_image = "Community image is required"; // Image is required for create only
    return errors;
  };

  // Create Community API call
  async function handleCreateCommunity() {
    setLoading(true); // Start loading
    const formData = new FormData();
    formData.append("title", formValues.title);
    formData.append("description", formValues.description);
    if (formValues.community_image) {
      formData.append("community_image", formValues.community_image);
    }

    try {
      const data = await post("/community", formData, getAuthConfigImage());
      if (data?.status === 200) {
        setLoading(false);
        setCompleteCommunity(true);
      }
    } catch (error) {
      setLoading(false);
    }
  }

  // Update Community API call
  async function handleUpdateCommunity() {
    setLoading(true); // Start loading
    const formData = new FormData();
    formData.append("title", formValues.title);
    formData.append("description", formValues.description);

    // Check if a new image is uploaded
    if (formValues.community_image && formValues.community_image instanceof File) {
      formData.append("community_image", formValues.community_image);
    }

    try {
      const communityId = location?.state?.community?._id; // Get the community ID
      const data = await post(`/community/update?id=${communityId}`, formData, getAuthConfigImage());
      if (data?.status === 200) {
        setLoading(false);
        setCompleteCommunity(true);
      }
    } catch (error) {
      setLoading(false);
    }
  }

  return (
    <>
      <Layout>
        <TopBarNavigation
          backlink={"/community"}
          title={isEdit ? "Edit Community" : "Add Community"}
          tab={"Community"}
          tabChild={isEdit ? "Edit Community" : "Add Community"}
        />
        <div className="main-content-part">
          <div className="form-data">
            <h5 className="title">{isEdit ? "Edit Community" : "Community Details"}</h5>

            {/* Title */}
            <div className="filed">
              <label>Title<span className="text-danger">*</span></label>
              <input
                type="text"
                name="title"
                className={`input-field ${formErrors.title ? 'field-error' : ''}`}
                value={formValues.title}
                onChange={handleChange}
                placeholder="Community Title"
              />
              {formErrors.title && <div className="text-danger">{formErrors.title}</div>}
            </div>

            {/* Description */}
            <div className="filed">
              <label>Description<span className="text-danger">*</span></label>
              <CKEditor
                editor={ClassicEditor}
                data={formValues.description}
                onChange={(event, editor) => {
                  const data = editor.getData();
                  setFormValues((p) => ({ ...p, description: data }));
                }}
              />
              {formErrors.description && <div className="text-danger">{formErrors.description}</div>}
            </div>

            {/* Image Upload */}
            <div className="filed">
              <label>{isEdit ? "Update Community Image" : "Add Community Image"}<span className="text-danger">*</span></label>

            
                <div className="upload-part mt-2">
                  <div className="form-group file-area">
                    <input
                      type="file"
                      id="community_image"
                      accept="image/png,image/jpeg,image/jpg"
                      name="community_image"
                      className="input-field"
                      onChange={handleChange}
                    />
                    <div className="file-dummy" style={{flexDirection:"column"}}>
                      <div className="image-block mb-2">
                        {imagePreview ?  <img
        src={imagePreview}
        className="img-fluid preview-image" // added class "preview-image" for styling
        alt="icon"
        style={{ maxHeight: '65px', maxWidth: '100%', objectFit: 'cover' }} // Ensure image fits the container
      /> :<img src={UploadIcon} alt="icon" /> }
                        
                      </div> <br />
                     <p>Upload Community Image here (png, jpg) - Max size 2MB</p> 
                    </div>
                  </div>
                </div>
            

              {formErrors.community_image && (
                <div className="text-danger">{formErrors.community_image}</div>
              )}
            </div>

            {/* Submit Button */}
            <div className="buttonPart mt-5 d-flex justify-content-center">
              <button
                className="theme-button btn btn-primary"
                onClick={handleSubmit}
                disabled={loading}
              >
                {loading ? (isEdit ? "Updating Community" : "Saving Community") : (isEdit ? "Update Community" : "Add Community")}
              </button>
            </div>
          </div>
        </div>

        {/* Modal on successful submission */}
        <Modal
          show={completeCommunity}
          onHide={() => setCompleteCommunity(false)}
          size="md"
          className="complete-modal"
        >
          <Modal.Body>
            <div className="top-heading text-end">
              <button
                className="btn btn-transparent"
                onClick={() => setCompleteCommunity(false)}
              >
                X
              </button>
            </div>
            <div className="content-block text-center">
              <Animation />
            </div>
            <div className="bottom-block text-center">
              <div className="workout-desc body-content">
                <p>Community {isEdit ? "updated" : "added"} successfully</p>
                <button className="theme-button btn btn-primary" onClick={() => navigate("/community")}>
                  Done
                </button>
              </div>
            </div>
          </Modal.Body>
        </Modal>
      </Layout>
    </>
  );
}

export default Community;
