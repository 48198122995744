import React, { useCallback, useEffect, useRef, useState } from "react";
import "../../assets/css/home.css";
import Layout from "../../layout";
import TopBar from "../../components/TopBarNavigation";
import DoctorCard from "../../components/DoctorCard";
import { Row, Col, Modal, Button, Spinner } from "react-bootstrap";
import { useLocation, Link, useNavigate } from "react-router-dom";
import { get, getAuthConfig, post } from "../../libs/http-hydrate"; // Include the `post` function
import Auth from "../../libs/auth";
import { UseEffectOnce } from "../../Hook/UseEffectOnce";
import SearchIcon from "../../assets/images/search.svg";
import Animation from "../../Animation/Animation";

const DoctorList = () => {
  const location = useLocation();
  const from = location?.state?.from;
  const navigate = useNavigate();
  const [data, setData] = useState(() => {
    const storedData = localStorage.getItem("notificationData");
    if (storedData) {
      try {
        return JSON.parse(storedData);
      } catch (error) {
        console.error(
          "Error parsing notificationData from localStorage:",
          error
        );
        return {};
      }
    }
    return {};
  });

  const [physiotherapistList, setPhysiotherapistList] = useState({
    items: [],
    has_more: false,
  });
  const [page, setPage] = useState(0);
  const [filter, setFilter] = useState({
    searchTerm: "",
    title: "",
  });

  // Modal state for confirmation
  const [showModal, setShowModal] = useState(false);
  const [selectedPhysiotherapist, setSelectedPhysiotherapist] = useState(null);

  // Loader state
  const [loading, setLoading] = useState(false);

  const [loadingPhysio, setLoadingPhysio] = useState(false);
  // Success modal state
  const [completeWorkout, setCompleteWorkout] = useState(false);

  useEffect(() => {
    getPhysiotherapistList();
  }, [page, filter?.searchTerm]);

  const observer = useRef();
  const lastBookElementRef = useCallback(
    (node) => {
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && physiotherapistList?.has_more) {
          setPage((prevPageNumber) => prevPageNumber + 1);
        }
      });
      if (node) observer.current.observe(node);
    },
    [physiotherapistList?.has_more]
  );

  async function getPhysiotherapistList() {
    try {
      setLoadingPhysio(true);

      const isMyClinicPhysio = location?.state?.from === "ClinicTeam";
      const clinicId = data?.associatedEntity?._id;

      const endpoint = isMyClinicPhysio
        ? `/myClinicPhysiotherapist?clinic_id=${clinicId}&pageNumber=${page}&limit=10`
        : `/physiotherapistlist?pageNumber=${page}&limit=10&searchTerm=${filter?.searchTerm}`;

      const response = await get(endpoint, getAuthConfig());
      if (response?.status === 200) {
        setLoadingPhysio(false);

        const newItems = response?.data?.data;
        if (page > 0) {
          setPhysiotherapistList((prev) => ({
            ...prev,
            items: prev.items.concat(newItems),
          }));
        } else {
          setPhysiotherapistList((prev) => ({
            ...prev,
            items: newItems,
          }));
        }
        setPhysiotherapistList((prev) => ({
          ...prev,
          has_more: response?.data?.has_more,
        }));
      }
    } catch (error) {
      setLoadingPhysio(false);
      if (error?.response?.status === 401) {
        Auth.logout();
        navigate("/login");
      }
    }
  }

  // Show confirmation modal
  const handleAssignPatient = (physiotherapist) => {
    setSelectedPhysiotherapist(physiotherapist);
    setShowModal(true);
  };

  // Call API to add a shared patient
  const confirmAssignPatient = async () => {
    setLoading(true); // Show loader
    try {
      const assignmentNotes = "Assigning patient for specialized treatment."; // Customize the assignment notes
      const response = await post(
        "/addSharedPatient",
        {
          assignedTo: selectedPhysiotherapist._id,
          assignmentNotes,
          patientid: location?.state?.patient?._id,
        },
        getAuthConfig()
      );

      if (response.status === 201) {
        // Close confirmation modal
        setShowModal(false);
        // Show success modal
        setCompleteWorkout(true);
      } else {
        alert("Failed to assign patient.");
      }
    } catch (error) {
      console.error("Error assigning patient:", error);
    } finally {
      setLoading(false); // Hide loader
    }
  };

  return (
    <Layout>
      <TopBar
        backlink={"/dashboard"}
        title={
          from === "ClinicTeam"
            ? data?.associatedEntity?.name
            : "Physiotherapist"
        }
        tab={"All Physiotherapist"}
        tabChild={
          from === "ClinicTeam"
            ? data?.associatedEntity?.name
            : "Physiotherapist"
        }
      />
      <div className="main-content-part">
        <div className="serchPart">
          <div className="searchInput">
            <div className="searchIcon">
              <img src={SearchIcon} alt="searchIcon" />
            </div>
            <input
              type="text"
              placeholder="Search Physiotherapist"
              value={filter?.searchTerm}
              onChange={(e) => {
                e.preventDefault();
                setPage(0);
                setFilter((prev) => ({
                  ...prev,
                  searchTerm: e?.target?.value,
                }));
              }}
            />
          </div>
        </div>
        {loadingPhysio && (
          <div className="preloader-whirlpool text-center align-items-center">
            <div className="whirlpool"></div>
          </div>
        )}
        <Row className="g-4">
          {physiotherapistList?.items?.length <= 0 && (
            <div className="text-center">No Physiotherapist Found</div>
          )}
          {physiotherapistList?.items &&
            physiotherapistList?.items?.length >= 0 &&
            physiotherapistList?.items?.map((item, index) => (
              <Col lg="6" ref={lastBookElementRef} key={item?._id}>
                <div
                  className="linkCard"
                  onClick={() => handleAssignPatient(item)} // Open modal on click
                >
                  <DoctorCard doctor={item} />
                </div>
              </Col>
            ))}
        </Row>
      </div>

      {/* Confirmation Modal */}
      <Modal show={showModal} onHide={() => setShowModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Confirm Assignment</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Are you sure you want to assign the patient to{" "}
          {selectedPhysiotherapist?.name}?
        </Modal.Body>
        <Modal.Footer
          className="d-flex justify-content-end"
          style={{ flexDirection: "row" }}
        >
          <Button variant="secondary me-3" onClick={() => setShowModal(false)}>
            Cancel
          </Button>
          <Button
            variant="primary"
            onClick={confirmAssignPatient}
            disabled={loading}
          >
            {loading ? <Spinner animation="border" size="sm" /> : "Confirm"}
          </Button>
        </Modal.Footer>
      </Modal>

      {/* Success Modal */}
      <Modal
        show={completeWorkout}
        onHide={() => setCompleteWorkout(false)}
        size="md"
        className="complete-modal"
      >
        <Modal.Body>
          <div className="content-block text-center">
            <Animation />

            {/* You can add a custom animation here */}
          </div>
          <div className="bottom-block text-center">
            <div
              className="workout-desc body-content"
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                flexDirection: "column",
                paddingTop: "25px",
              }}
            >
              <p>Patient Assigned successfully</p>

              <button
                className="theme-button btn btn-primary mt-3"
                style={{ minWidth: "300px", padding: "14px" }}
                onClick={(e) => {
                  e.preventDefault();
                  navigate("/allPatient");
                }}
              >
                Done
              </button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </Layout>
  );
};

export default DoctorList;
