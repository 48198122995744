import React from 'react'
import '../assets/css/commonComponent.css'
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import { AiOutlineArrowLeft } from "react-icons/ai"; // Importing back arrow icon from react-icons
import { useLocation, useNavigate } from "react-router-dom";
import PageLink from './PageLink';

const TopBarNavigation = (props) => {
  const navigate = useNavigate()
  const handleBack = () => {
    // Implement the back functionality here
    // For example, using history from react-router
    // navigate(props?.backlink)
    if (props.backlink && props.backLinkData) {
      // Directly navigate to the path and pass the state
      navigate(props.backlink, { state: props.backLinkData });
    }  else {
      // Fallback to simple back navigation without passing state
      navigate(-1);
    }
  };
  return (
    <div className='topBar'>
      <div className="theraphy-name d-flex align-items-center ">
        {props?.backlink && 
        <div className="back-button" style={{marginRight:"1rem",cursor:"pointer"}} onClick={handleBack}>
          <AiOutlineArrowLeft /> 
        </div>
}

        <h6 className="title ">{props?.title}</h6>
      </div>
    <div className='navigation'>
        <Breadcrumb>
        {/* <Breadcrumb.Item href="#">{props?.tab}</Breadcrumb.Item>
        <Breadcrumb.Item  active>
          {props?.tabChild}
        </Breadcrumb.Item> */}
        <PageLink />
        {/* {props?.tabSmallChild && <Breadcrumb.Item  active>
          {props?.tabSmallChild}
        </Breadcrumb.Item>} */}


        </Breadcrumb>
    </div>
  </div>
  )
}

export default TopBarNavigation