import { useState, React, useEffect } from 'react'
import Layout from '../../layout'
import { Button, Col, Form, Row } from 'react-bootstrap';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import TimingComponent from "./timingComponent"
import { get, getAuthConfig, post } from '../../libs/http-hydrate';
import { toast, ToastContainer } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DateCalendar } from '@mui/x-date-pickers/DateCalendar';
import { LocalizationProvider } from '@mui/x-date-pickers';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';
import Auth from '../../libs/auth';
import { UseEffectOnce } from '../../Hook/UseEffectOnce';
import FieldValidationError from '../../common/FieldValidation';
import PageLink from '../../components/PageLink';
const AppointSetting = () => {
    const navigate = useNavigate();
    const [key, setKey] = useState('General');
    const initialTimeSlots = [{ startTime: '', endTime: '' ,duration:''}];

    const [setting, setSetting] = useState({
        Days: [],
        Availibility: false,
        duration: "",
        selectedDate: ""
    });
    const [err,setErr] =useState({
        days:false,
        duration:false,
        slots:false,
        invalidTimeSlot:false,
        notCompleteData:false
    })
    const [timeSlots, setTimeSlots] = useState([{ startTime: '', endTime: '',duration:'' }]);
    useEffect(() =>{
        getSetting()
    },[key,setting?.selectedDate])
    function setDays(Day) {
        if (setting?.Days.includes(Day)) {
            setSetting((p) => ({ ...p, Days: setting.Days.filter(e => e !== Day) }))
        }
        else {
            setSetting((p) => ({ ...p, Days: setting.Days.concat(Day) }))
        }
        setErr((p) =>({...p,days:false}))
    }
    const handleAddField = () => {
        // Add a new time slot with empty startTime and endTime to the timeSlots array
        setTimeSlots([...timeSlots, { startTime: '', endTime: '' }]);
    };
    const handleChange = (index, field, value) => {
        // Update the state with the new field value
        const newTimeSlots = [...timeSlots];
        newTimeSlots[index][field] = value;
        setTimeSlots(newTimeSlots);

        setErr((p) =>({...p,invalidTimeSlot:false}))
        if(err?.notCompleteData === true){
            setErr((p) =>({...p,notCompleteData:false}))
        }
    };

    async function AddSetting() {
        let duration = true;


        if (setting?.Availibility === "") {
            toast.error("Please select Availibility");
            return;
        }

        if(setting?.Availibility === true){

        // if (!setting?.duration) {
        //     toast.error("Please select duration of the slots");
        //     return;
        // }
        if (key === "General" && setting?.Days.length <= 0) {
            setErr((p) =>({...p,days:true}))
            // toast.error("Please select enough Days");
            return
        }

        if (timeSlots?.length <= 0) {
            setErr((p) =>({...p,timeSlot:true}))
            // toast.error("Please select enough Time Slots");
            return
        }
    }
        const formdata = new FormData();
        formdata.append("AvailableType", key);
        formdata.append("Available", setting?.Availibility);

        if (key === "Custom") {
            formdata.append("selectedDate", setting?.selectedDate);

        }
        else if (key === "General") {
            setting?.Days.forEach((item, index) => {
                formdata.append(`Days[${index}]`, item);
            });
        }
        timeSlots.forEach((item, index) => {
            if(item?.startTime?.trim() === "" || !item?.duration || item?.endTime?.trim() === ""){
                duration = false
                setErr((p) =>({...p,notCompleteData:true}))
                // toast.error("Please provide correct data for the timeSlot");
                return false;
            }
            if (new Date(`01/01/2011 ${item?.endTime}`) <= new Date(`01/01/2011 ${item?.startTime}`)) {
                setErr((p) =>({...p,invalidTimeSlot:true}))
                duration = false
                // toast.error("End Time of the slots should be greater than start Time of slot")
                return false;
            }
        
            formdata.append(`Time[${index}][startTime]`,item?.startTime);
            formdata.append(`Time[${index}][endTime]`,item?.endTime);
            formdata.append(`Time[${index}][duration]`,item?.duration);
        })

        if(duration === false){
             return false;
        }
        try {
            const response = await post("/appointmentSetting", formdata, getAuthConfig()).then((data) => {
                if (data?.status === 200) {
                    navigate("/dashboard")
                    // toast.success("Settings Updated SuccessFully");
                }
            }).catch((err) => {
                toast.error(err?.response?.data?.err);
            })
        } catch (error) {

            toast.error(error?.response?.data?.err);
        }
    }

    async function getSetting(){
        try {
            let url ;
            if(key === "General")
            {
                url = `/appointment?AvailableType=${key}`
            }
            else if(key === "Custom"){
                if(setting?.selectedDate){
                    url = `/appointment?AvailableType=${key}&date=${setting?.selectedDate}`
                }
                else{
                    url = `/appointment?AvailableType=${key}`
                }
            }

            const data = await get(url, getAuthConfig());
            if (data?.status === 200) {

                if(data?.data?.msg?.Days){
                    setSetting((p) =>({...p,Days:data?.data?.msg?.Days}))
                }
                if(data?.data?.msg?.Available){
                    setSetting((p) =>({...p,Availibility:data?.data?.msg?.Available}))
                }
                if(data?.data?.msg?.Time){
                    setTimeSlots(data?.data?.msg?.Time)
                }

            }
    
        } catch (error) {
            if (error?.response?.status === 401) {
                Auth.logout();
                navigate("/login")
    
            }
    
        }
    }
    const handleDeleteTimeSlot = (index) => {
        // Create a new array without the specified time slot
        const newTimeSlots = timeSlots.filter((_, i) => i !== index);
        setTimeSlots(newTimeSlots);
    };


    return (
        <>
            <ToastContainer />

            <Layout>
                <div className='main-content-part'>

                    <div className='exercise-tab-part'>

                        <Tabs
                            id="controlled-tab-example"
                            activeKey={key}
                            onSelect={(k) => { setKey(k); setTimeSlots(initialTimeSlots); }}
                            className="mb-3 mt-0  d-flex justify-content-center"
                        >
                            <Tab eventKey="General" title="General">
                                <div className='form-data'>

                                    <div className='filed'>
                                        <label>Availablility</label>
                                        <div className='yes-no-radio'>
                                            <label style={{ flex: 1 }}>
                                                <input type="radio" name="radio8998" checked={setting?.Availibility} onClick={(e) => {
                                                    setSetting((p) => ({ ...p, Availibility: true }))
                                                }} />
                                                <div className="front-end box w-100 py-2">
                                                    <span>Yes</span>
                                                </div>
                                            </label>

                                            <label style={{ flex: 1 }}>
                                                <input type="radio" name="radio880998" checked={!setting?.Availibility} onClick={(e) => {
                                                    setSetting((p) => ({ ...p, Availibility: false }))
                                                }} />
                                                <div className="back-end box w-100 py-2">
                                                    <span>No</span>
                                                </div>
                                            </label>
                                        </div>
                                    </div>

                                    <div className='filed'>
                                        {err?.invalidTimeSlot && <FieldValidationError message="End Time of the slots should be greater than start Time of slot" />}
                                        {err?.notCompleteData && <FieldValidationError message="Please provide correct data for the timeSlot" />}
                                        <div className='d-flex justify-content-between'>
                                        <label>Available Time</label>
                                        <button className='theme-outline-btn  d-block  btn btn-primary' style={{ minWidth: "fit-content", borderRadius: "8px" }} onClick={(e) =>{
                        e.preventDefault();
                        handleAddField();
                    }}>
                        +
                    </button>
                    </div>

                                        <div className='start-part'>

                                            {timeSlots.map((timeSlot, index) => (
                                                <div key={index}>
                                                    <TimingComponent timeSlot={timeSlot} handleAddField={handleAddField} index={index} handleChange={handleChange}  handleDelete={handleDeleteTimeSlot}/>
                                                </div>
                                            ))}

                                        </div>

                                    </div>
                                    <div className='filed'>

                                        {err?.days === true && <FieldValidationError message="Please select days" /> }
                                        <label>Select Days </label>
                                        <div className='radio-badge-part radio-badge-outline'>
                                            <form>
                                                <label>
                                                    <input type="radio" name="radio" checked={setting?.Days.includes("Mon") ? true : false} onClick={() => setDays("Mon")} />
                                                    <span>Mon</span>
                                                </label>
                                                <label>
                                                    <input type="radio" name="radio2" checked={setting?.Days.includes("Tue") ? true : false} onClick={() => setDays("Tue")} />
                                                    <span>Tue</span>
                                                </label>
                                                <label>
                                                    <input type="radio" name="radio3" checked={setting?.Days.includes("Wed") ? true : false} onClick={() => setDays("Wed")} />
                                                    <span>Wed</span>
                                                </label>
                                                <label>
                                                    <input type="radio" name="radio4" checked={setting?.Days.includes("Thu") ? true : false} onClick={() => setDays("Thu")} />
                                                    <span>Thu</span>
                                                </label>
                                                <label>
                                                    <input type="radio" name="radio5" checked={setting?.Days.includes("Fri") ? true : false} onClick={() => setDays("Fri")} />
                                                    <span>Fri</span>
                                                </label>
                                                <label>
                                                    <input type="radio" name="radio6" checked={setting?.Days.includes("Sat") ? true : false} onClick={() => setDays("Sat")} />
                                                    <span>Sat</span>
                                                </label>
                                                <label>
                                                    <input type="radio" name="radio7" checked={setting?.Days.includes("Sun") ? true : false} onClick={() => setDays("Sun")} />
                                                    <span>Sun</span>
                                                </label>
                                            </form>
                                        </div>
                                    </div>
                                  
                                    <div className='buttonPart mt-5 d-flex justify-content-center'>
                                        <Button className='theme-button' onClick={(e) => {
                                            e.preventDefault()
                                            AddSetting()
                                        }}>Done</Button>
                                    </div>
                                </div>
                            </Tab>
                            <Tab eventKey="Custom" title="Day Wise">
                                <Row>
                                    <Col className='doctorDetailsCard' lg={4}>

                                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                                            <DateCalendar autoFocus={true} onChange={(newValue) => {
                                                setSetting((p) => ({ ...p, selectedDate: moment(newValue?.$d).format("YYYY-MM-DD") }))
                                            }} />

                                        </LocalizationProvider>


                                    </Col>
                                    <Col lg={6}>

                                          
                                        <div className='form-data  w-100'>

                                            <div className='filed'>
                                            {err?.invalidTimeSlot && <FieldValidationError message="End Time of the slots should be greater than start Time of slot" />}
                                        {err?.notCompleteData && <FieldValidationError message="Please provide correct data for the timeSlot" />}
                                      
                                                <label>Availablility</label>
                                                <div className='yes-no-radio'>
                                                <label style={{ flex: 1 }}>
                                                <input type="radio" name="radio88" checked={setting?.Availibility} onClick={(e) => {
                                                    setSetting((p) => ({ ...p, Availibility: true }))
                                                }} />
                                                <div className="front-end box w-100 py-2">
                                                    <span>Yes</span>
                                                </div>
                                            </label>

                                            <label style={{ flex: 1 }}>
                                                <input type="radio" name="radio888" checked={!setting?.Availibility} onClick={(e) => {
                                                    setSetting((p) => ({ ...p, Availibility: false }))
                                                }} />
                                                <div className="back-end box w-100 py-2">
                                                    <span>No</span>
                                                </div>
                                            </label>
                                                </div>
                                            </div>

                                            <div className='filed'>
                                            <div className='d-flex justify-content-between'>
                                        <label>Available Time</label>
                                        <button className='theme-outline-btn  d-block  btn btn-primary' style={{ minWidth: "fit-content", borderRadius: "8px" }} onClick={(e) =>{
                        e.preventDefault();
                        handleAddField();
                    }}>
                        +
                    </button>
                    </div>                                                <div className='start-part'>
                                                    {timeSlots.map((timeSlot, index) => (
                                                        <div key={index}>
                                                            <TimingComponent timeSlot={timeSlot} handleAddField={handleAddField} index={index} handleChange={handleChange} handleDelete={handleDeleteTimeSlot} />
                                                        </div>
                                                    ))}
                                                </div>

                                            </div>
{/* 
                                            <div className='filed'>
                                                <label>Duration</label>
                                                <Form.Select aria-label="Default select example" onChange={(e) => {
                                            e.preventDefault();
                                            setSetting((p) => ({ ...p, duration: e.target.value }))
                                        }}>
                                            <option value={"05"}>5:00 min</option>
                                            <option value={"15"}>15:00 min</option>
                                            <option value={"25"}>25:00 min</option>
                                            <option value={"35"}>35:00 min</option>
                                        </Form.Select>
                                            </div> */}

                                            <div className='buttonPart mt-5 d-flex justify-content-center'>
                                                <Button className='theme-button' onClick={(e) => {
                                                    e.preventDefault()
                                                    AddSetting()
                                                }}>Done</Button>
                                            </div>
                                        </div>
                                    </Col>
                                </Row>
                            </Tab>
                        </Tabs>

                        <div style={{ position: 'absolute', top: '16%', right: '26vh', transform: 'translateY(-50%)' }}>

<PageLink />
</div>
                    </div>


                </div>
            </Layout>

        </>
    )
}

export default AppointSetting
