import React from 'react'
import '../assets/css/commonComponent.css'
import FillStar from '../assets/images/star.svg'
import BlanckStar from '../assets/images/star-blanck.svg'
import Doctor from '../assets/images/doctor.png'
import DoctorDummy from "../assets/images/defaultdoctor@3x.png"
import PatientDummy from "../assets/images/defaultpatient@3x.png"

const DoctorCard = (props) => {
  return (
   <div className='doctor-card'>
    <div className='doctor-card-content'>
        <div className='image-part'>
            <img className='' src={props?.doctor?.profile_image ? props?.doctor?.profile_image : DoctorDummy } style={{borderRadius:"55%"}} width={"120px"} alt='doctor' height="120px" />
        </div>
        <div className='text-part'>
            <h5>{props?.doctor?.name}</h5>
            <h6>{props?.doctor?.designation}</h6>
            <p>{props?.doctor?.years_of_experience } yrs of exp. overall</p>

            {props?.doctor?.address && <p>{props?.doctor?.address?.state} {props?.doctor?.address?.state && props?.doctor?.address?.country && ","}  {props?.doctor?.address?.country}</p>}

            {/* <p>{props?.doctor?.address?.city}, {props?.doctor?.address?.state}</p> */}
            <div className='rating'>

            <div className='rating-part'>

{Array.from({ length: 5 }, (_, indexw) => (
<i
key={indexw}
className={`star fa fa-star${indexw < props?.doctor?.averageRating ? ' filled' : ''}`}
></i>
))}

</div>
               
            </div>
        </div>
    </div>
   <div className='bottom-bar'>
        <p>Assign Patient</p>
   </div>
   </div>
  )
}

export default DoctorCard