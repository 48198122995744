import React, { useEffect, useState } from "react";
import logo from "../assets/images/Virtue Life logo1.svg";
// import logo from "../assets/images/RecureMe.svg";
import "../assets/css/sidebar.css";
import logout from "../assets/images/signOut.svg";
import home from "../assets/images/home.svg";
import patient from "../assets/images/patients.svg";
import point from "../assets/images/point.svg";
import homeActive from "../assets/images/homeActive.svg";
import pointActive from "../assets/images/pointActive.svg";
import heartSvg from "../assets/images/heartBlack.svg";
import heartFilled from "../assets/images/heartFilled.svg";
import patientActive from "../assets/images/patientActive.svg";
import { useLocation, Link, useNavigate } from "react-router-dom";
import folderSvg from "../assets/images/folder-download.svg";
import folderSvgBlack from "../assets/images/folderBlack.svg";
import MyExercise from "../assets/images/myExercise.svg";
import MyExerciseFill from "../assets/images/myExerciseFill.svg";
import CommunitySidebar from "../assets/images/communitySidebad.svg";
import CommunityFillSidebar from "../assets/images/communityFillSidebar.svg";
import payment from "../assets/images/payment.svg";
import paymentActive from "../assets/images/paymentActive.svg";
import Auth from "../libs/auth";
import ProfileCompletionModal from "../pages/AddProfileData/ProfileCompletionModal";
import CalendarActive from "../assets/images/calendar.svg";
import Calendar from "../assets/images/calendarbefore.svg";
import ClinicVerficationModal from "./ClinicVerificationModal";
const Sidebar = () => {
  const location = useLocation();
  const activePage = location.pathname.replace("/", "");
  const navigate = useNavigate();
  const pointsActivePaths = [
    "points",
    "exerciseLab",
    "surgeryTemplate",
    "createExercise",
  ];
  const user = Auth.getCurrentUser();
  const [data, setData] = useState(() => {
    // Retrieve the item from localStorage
    const storedData = localStorage.getItem("notificationData");
    if (storedData) {
      try {
        // Parse it to an object and return as initial state
        return JSON.parse(storedData);
      } catch (error) {
        console.error(
          "Error parsing notificationData from localStorage:",
          error
        );
        // Return a fallback initial state if parsing fails
        return {};
      }
    }
    // Return an initial state if nothing is stored in localStorage
    return {};
  });
  const handleLogout = () => {
    localStorage.clear();
    navigate("/login");
  };
  const [showProfileCompletionModal, setShowProfileCompletionModal] =
    useState(false);
  const [showClinicModal, setShowClinicModal] = useState(false);

  const handleAddPatientClick = () => {
    // if (user?.profileCompleted === false) {
    //   setShowProfileCompletionModal(true);
    // }
    // else
    
    if(data?.associatedEntity != "Individual" && data?.clinicVerified === false)
    {
      setShowClinicModal(true)
    }
    else {
      navigate("/personalInformation");
    }
  };

  useEffect(() => {
    if (
      data?.associatedEntity != "Individual" &&
      data?.clinicVerified === false
    ) {
      setShowClinicModal(true);
    } else {
      setShowClinicModal(false);
    }
  }, [data]);

  const handleConditionalNavigation = (event, path) => {
    // Prevent default link action
    event.preventDefault();

    // Check conditions before navigating
    if (data?.associatedEntity !== "Individual" && data?.clinicVerified === false) {
      // Optionally, show a modal or an alert if needed
      // e.g., setShowClinicModal(true);
      console.log("Navigation prevented due to clinic verification.");
    } else {
      // If conditions are met, navigate to the path
      navigate(path);
    }
  };

  return (
    <>
      <div className="sidebar-main">
        <div className="logo-part">
          <Link to={"/dashboard"}>
            <img src={logo} alt="docpy-logo" width={180} height={70} />
          </Link>
        </div>
        {/* <Link to={"/personalInformation"}>   */}
        <button className="btn btn-add" onClick={handleAddPatientClick}>
          + Add Patient
        </button>

        {/* </Link> */}
        <div className="nav-part">
          <div className="top-navpart">
            <Link
              to="/dashboard"
              style={{ textDecoration: "none", color: "inherit" }}
            >
              <div
                className={` ${
                  activePage === "dashboard" || activePage === "doctorList"
                    ? "nav-btn active"
                    : "nav-btn"
                }`}
              >
                <img
                  src={
                    activePage === "dashboard" || activePage === "doctorList"
                      ? homeActive
                      : home
                  }
                  alt=""
                  className="icon"
                />
                <p className="text">Home</p>
              </div>
            </Link>
            <Link
              to="/allPatient"
              style={{ textDecoration: "none", color: "inherit" }}
            >
              <div
                className={`${
                  activePage === "allPatient" ||
                  activePage === "patientDetails" ||
                  activePage === "history"
                    ? "nav-btn active"
                    : "nav-btn"
                }`}
              >
                <img
                  src={
                    activePage === "allPatient" ||
                    activePage === "patientDetails" ||
                    activePage === "history"
                      ? patientActive
                      : patient
                  }
                  alt=""
                  className="icon"
                />
                <p className="text">Patient</p>
              </div>
            </Link>
            <Link
              to="/points"
              style={{ textDecoration: "none", color: "inherit" }}
            >
              <div
                className={`${
                  pointsActivePaths.includes(activePage)
                    ? "nav-btn active"
                    : "nav-btn"
                }`}
              >
                <img
                  src={
                    pointsActivePaths.includes(activePage) ? pointActive : point
                  }
                  alt=""
                  className="icon"
                />
                <p className="text">Library</p>
              </div>
            </Link>
            <Link
              to="/selectedExercise"
              style={{ textDecoration: "none", color: "inherit" }}
            >
              <div
                className={`${
                  activePage === "selectedExercise"
                    ? "nav-btn active"
                    : "nav-btn"
                }`}
              >
                <img
                  src={
                    activePage === "selectedExercise" ? heartFilled : heartSvg
                  }
                  alt=""
                  className={`icon`}
                />
                <p className="text">Saved Exercise</p>
              </div>
            </Link>

            <Link
              to="/savedExercise"
              style={{ textDecoration: "none", color: "inherit" }}
            >
              <div
                className={`${
                  activePage === "savedExercise" ? "nav-btn active" : "nav-btn"
                }`}
              >
                <img
                  src={
                    activePage === "savedExercise" ? folderSvg : folderSvgBlack
                  }
                  alt=""
                  className={`icon ${
                    activePage === "savedExercise" ? "heart" : ""
                  }`}
                />
                <p className="text">Saved Protocol</p>
              </div>
            </Link>
            <Link
              to="/myExercise"
              style={{ textDecoration: "none", color: "inherit" }}
            >
              <div
                className={`${
                  activePage === "myExercise" || activePage === "exerciseAdd"
                    ? "nav-btn active"
                    : "nav-btn"
                }`}
              >
                <img
                  src={
                    activePage === "myExercise" || activePage === "exerciseAdd"
                      ? MyExercise
                      : MyExerciseFill
                  }
                  alt=""
                  className={`icon ${
                    activePage === "myExercise" ? "heart" : ""
                  }`}
                />
                <p className="text">My Exercise</p>
              </div>
            </Link>
            <Link
              to="/community"
              style={{ textDecoration: "none", color: "inherit" }}
            >
              <div
                className={`${
                  activePage === "community" || activePage === "AddBlog"
                    ? "nav-btn active"
                    : "nav-btn"
                }`}
              >
                <img
                  src={
                    activePage === "community" || activePage === "AddBlog"
                      ? CommunityFillSidebar
                      : CommunitySidebar
                  }
                  alt=""
                  className={`icon ${
                    activePage === "community" ? "heart" : ""
                  }`}
                />
                <p className="text">Community</p>
              </div>
            </Link>

            <Link
              to="/appointmentCalendar"
              style={{ textDecoration: "none", color: "inherit" }}
            >
              <div
                className={`${
                  activePage === "appointmentCalendar"
                    ? "nav-btn active"
                    : "nav-btn"
                }`}
              >
                <img
                  src={
                    activePage === "appointmentCalendar"
                      ? CalendarActive
                      : Calendar
                  }
                  alt=""
                  className={`icon ${
                    activePage === "appointmentCalendar" ? "heart" : ""
                  }`}
                />
                <p className="text">Calendar</p>
              </div>
            </Link>
            {/* {data?.associatedEntity === "Individual" && 
             user?.profileCompleted === true && */}

             { data?.credits === 0 && data?.address?.country === "India" && 
              <Link
                to="/creditPurchase"
                style={{ textDecoration: "none", color: "inherit" }}
              >
                <div
                  className={`${
                    activePage === "creditPurchase" ? "nav-btn active" : "nav-btn"
                  }`}
                >
                  <img
                    src={activePage === "creditPurchase" ? paymentActive : payment}
                    alt=""
                    className={`icon ${
                      activePage === "creditPurchase" ? "heart" : ""
                    }`}
                  />
                  <p className="text">Payment</p>
                </div>
              </Link>}

              { data?.credits === 0 &&  data?.address?.country !== "India" && 
              <Link
                to="/paymentStripe"
                style={{ textDecoration: "none", color: "inherit" }}
              >
                <div
                  className={`${
                    activePage === "paymentStripe" ? "nav-btn active" : "nav-btn"
                  }`}
                >
                  <img
                    src={activePage === "paymentStripe" ? paymentActive : payment}
                    alt=""
                    className={`icon ${
                      activePage === "paymentStripe" ? "heart" : ""
                    }`}
                  />
                  <p className="text">Payment</p>
                </div>
              </Link>}
            {/* } */}
            <div
              style={{ textDecoration: "none", color: "inherit" }}
              onClick={() => handleLogout()}
              className="mb-5"
            >
              <div className={`nav-btn`}>
                <img src={logout} alt="" className={`icon`} />
                <p className="text">Logout</p>
              </div>
            </div>
          </div>
          {/* <div className="bottom-logoutpart">
          <button className='logout-btn' onClick={() => handleLogout()}>
            <img src={logout} alt="logout-icon" className='icon' />
            <p className='text'>Logout</p>
          </button>
        </div> */}
        </div>
      </div>
      <ProfileCompletionModal
        show={showProfileCompletionModal}
        onHide={() => setShowProfileCompletionModal(false)}
        From={"Add Patient"}
      />

      <ClinicVerficationModal
        show={showClinicModal}
        onHide={() => setShowClinicModal(false)}
      />
    </>
  );
};

export default Sidebar;
