import React, { useState, useRef } from "react";
import { Modal, Form } from "react-bootstrap";
import { toast } from "react-toastify";

const OtpModal = ({ show, onHide, onVerify, email ,errorMessage, successMessage}) => {
  const [otpDigits, setOtpDigits] = useState(["", "", "", "", "", ""]);
  const [otpErrorMessage, setOtpErrorMessage] = useState("");
  const [loadingVer, setLoadingVer] = useState(false);
  const otpInputRefs = useRef([]);

  const handleChange = (e, index) => {
    const newOtpDigits = [...otpDigits];
    newOtpDigits[index] = e.target.value;
    setOtpDigits(newOtpDigits);

    // Move to the next input if a value is entered
    if (e.target.value !== "" && index < otpDigits.length - 1) {
      otpInputRefs.current[index + 1].focus();
    }
  };

  const handleKeyDown = (e, index) => {
    // Move to the previous input if backspace is pressed and the current input is empty
    if (e.key === "Backspace" && otpDigits[index] === "" && index > 0) {
      otpInputRefs.current[index - 1].focus();
    }
  };

  const handleVerifyOTP = async () => {
    const isAllFieldsFilled = otpDigits.every((digit) => digit !== "");
    if (!isAllFieldsFilled) {
      setOtpErrorMessage("Please fill all OTP fields");
      return;
    }

    try {
      setLoadingVer(true);
      const isVerified = await onVerify(otpDigits.join(""), email);
      if (isVerified) {
        onHide();
        setOtpDigits(["", "", "", "", "", ""]);
      } else {
        setOtpErrorMessage("Incorrect OTP. Please try again.");
      }
      setLoadingVer(false);
    } catch (error) {
      setLoadingVer(false);
      toast.error("Failed to verify OTP. Please try again.");
    }
  };
  const handlePaste = (e) => {
    const pastedData = e.clipboardData.getData("Text").slice(0, otpDigits.length);
    const newOtpDigits = [...otpDigits];
    
    // Distribute the pasted data into the OTP fields
    for (let i = 0; i < pastedData.length; i++) {
      newOtpDigits[i] = pastedData[i];
      if (otpInputRefs.current[i]) {
        otpInputRefs.current[i].value = pastedData[i]; // Update the input fields
      }
    }
    setOtpDigits(newOtpDigits);

    // Move the focus to the last filled input field
    const lastFilledIndex = pastedData.length - 1;
    if (lastFilledIndex < otpDigits.length - 1) {
      otpInputRefs.current[lastFilledIndex + 1].focus();
    }
  };

  return (
    <Modal show={show} onHide={onHide}>
      <Modal.Header closeButton>
        <Modal.Title>Verify OTP</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>Enter the OTP sent to {email}</p>
        <div className="otp-inputs d-flex justify-content-center">
          {otpDigits.map((digit, index) => (
            <Form.Control
              key={index}
              type="text"
              maxLength="1"
              value={digit}
              ref={(ref) => (otpInputRefs.current[index] = ref)}
              onChange={(e) => handleChange(e, index)}
              onKeyDown={(e) => handleKeyDown(e, index)}
              onPaste={index === 0 ? handlePaste : null} // Add paste handling only on the first input
              className="text-center otp-input"
              style={{ width: "40px", marginRight: "5px" }}
            />
          ))}
        </div>
        {/* {otpErrorMessage && <p className="text-danger">{otpErrorMessage}</p>} */}
        {errorMessage && <p className="text-danger mt-2">{errorMessage}</p>}

        {/* Display success message */}
        {successMessage && <p className="text-success mt-2">{successMessage}</p>}
     
      </Modal.Body>
      <Modal.Footer>
        <div className="d-flex justify-content-end w-100">
          <button
            className="theme-buttonOuteline btn btn-primary me-2"
            style={{ minWidth: "150px", padding: "10px" }}
            onClick={onHide}
          >
            Cancel
          </button>
          <button
            className="theme-button btn btn-primary"
            style={{ minWidth: "150px", padding: "10px" }}
            onClick={handleVerifyOTP}
            disabled={loadingVer}
          >
            {loadingVer ? "Verifying..." : "Verify OTP"}
          </button>
        </div>
      </Modal.Footer>
    </Modal>
  );
};

export default OtpModal;
