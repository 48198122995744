
import { useState, React, useEffect } from 'react'
import '../../assets/css/home.css'
import Layout from '../../layout'
import TopBar from '../../components/TopBarNavigation'
import '../../assets/css/doctorInfo.css'
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import Ex1 from '../../assets/images/ex-1.png'
import Button from 'react-bootstrap/Button';
import { Link, useLocation, useNavigate } from 'react-router-dom'
import { deletes, get, getAuthConfig, getAuthConfigImage, post } from '../../libs/http-hydrate'
import { toast, ToastContainer } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import { Modal } from 'react-bootstrap';
import UploadIcon from '../../assets/images/upload.svg'
import doneImg from "../../assets/images/doneImg.png";
import addMore from "../../assets/images/addMore.png";
import Auth from '../../libs/auth'
import RemoveIcon from '../../assets/images/remove.png'
import swal from "sweetalert";

import defaultSVG from "../../assets/images/defaultWorkout.jpg"
import Animation from '../../Animation/Animation'

const WorkoutList = () => {

    const sessionClassMap = {
        Heart: "heart",
        Arm: "arm",
        Shoulder: "shoulder",
        // Add more session names and their class names as needed
    };
    const [loading , setLoading] = useState(true)
    const navigate = useNavigate()
    const location = useLocation();
    const [ExerciseData, setExrerciseeData] = useState();
    const [showNote, setShowNote] = useState(false);
    const handleCloseNote = () => setShowNote(false);
    const handleShowNote = () => setShowNote(true);
    const [completeWorkout, setCompleteWorkout] = useState(false);
    const [Addmore, setAddMore] = useState(false)
    const defaultActiveTab = () => {
        if (ExerciseData?.morning?.length > 0) {
            return "morning";
        } else if (ExerciseData?.afternoon?.length > 0) {
            return "afternoon";
        } else if (ExerciseData?.evening?.length > 0) {
            return "evening";
        } else if (ExerciseData?.night?.length > 0) {
            return "night";
        } else {
            // If none of the sessions have data, set a default tab
            return "morning"; // or "afternoon", "evening", "night" as desired
        }
    };
    
    const [activeTab, setActiveTab] = useState(defaultActiveTab());
    const handleTabSelect = (tabKey) => {
        setActiveTab(tabKey);
    };

    useEffect(() => {
        getPatientWorkout(location?.state?.patient_id)
    }, [location?.state?.patient_id])
    const [key, setKey] = useState('morning');

    
    async function getPatientWorkout() {
        try {
            setLoading(true)
            const data = await get(`/PatientWorkout?patientId=${location?.state?.patient_id}`, getAuthConfig());
            if (data?.status === 200) {

                setExrerciseeData(data?.data?.msg)
            }
            setLoading(false)

        } catch (error) {
            setLoading(false)

            if (error?.response?.status === 401) {
                Auth.logout();
                navigate("/login")

            }
        }
    }
    async function saveWorkout() {

        try {
            const response = await post("/workout", ExerciseData, getAuthConfig()).then((data) => {

                if (data?.status === 200) {
                    setShowNote(false)
                    setCompleteWorkout(true)


                    // toast.success("Patient workout updated successfully");

                }
            }).catch((err) => {
                toast.error(err?.response?.data?.err);
            })
        } catch (error) {
            toast.error(error);
        }
    }

    async function DeleteWorkout(workoutId,session,workoutItemId) {
        try {
            setLoading(true)
            const formdata = new FormData();
            formdata.append("workoutId",workoutId);
            formdata.append("session",session);
            formdata.append("workoutItemId",workoutItemId);
            const response = await deletes("/delete-workout",formdata).then((data) => {
                if (data?.status === 200) {
                    getPatientWorkout()
                }
            }).catch((err) => {
                toast.error(err?.response?.data?.err);
            })
        } catch (error) {
            toast.error(error);
        } finally  {
            setLoading(false)
        }

    }
    useEffect(()=>{
        setActiveTab(defaultActiveTab())
    },[ExerciseData])

    
    return (
        <>
            <ToastContainer />
            <Layout>
                <TopBar title={"Workout List"} tab={"Update Exercises"} tabChild={"Workout List"} />            <div className='main-content-part excersiceList'>
                    <div className='exercise-tab-part'>
                     
                    {loading  ? <div className="preloader-whirlpool text-center align-items-center">
              <div className="whirlpool"></div>
            </div>   : 
            (<>
                        <Tabs
                            id="controlled-tab-example"
                            activeKey={activeTab} onSelect={handleTabSelect}
                            className="mb-3"
                        >

             
                            {ExerciseData?.morning?.length > 0 && (

                                <Tab eventKey="morning" title="Morning">
                                    <div className="session-card-content" style={{gap:"10px"}}>
                                        {ExerciseData?.morning?.map((session, index) => {
                                            const morningIndex = index % Object.keys(sessionClassMap).length;
                                            const altClass = Object.keys(sessionClassMap)[morningIndex];
                                            return (
                                                <>
                                                    <div className={ ` session-card ${sessionClassMap[altClass]}`}>
                                                        <div className={`session-card ${sessionClassMap[altClass]}`} key={index} style={{ cursor: "pointer",boxShadow:"none" ,padding:"0px"}} onClick={(e) => {
                                                            e.preventDefault();
                                                            navigate("/exerciseEdit", { state: { patient_id: location?.state?.patient_id, session: "morning", bodyPart: session?.name, workout: session, workoutItemId: session?._id, workoutId: ExerciseData?._id } })
                                                        }}>
                                                            <div className="details mt-3">
                                                                <h6 className="session-title">{session?.name?.replace("Workout Session", "")}</h6>
                                                                <p className="workout-text">Workout Session</p>

                                                            </div>
                                                            <div className="image-block">
                                                            <img src={session?.image ? session?.image : defaultSVG} height={70} width={70} style={{borderRadius:"55%"}} alt="session-img" />
                                                            </div>
                                                        </div>
                                                        <img src={RemoveIcon} height={30} style={{cursor:"pointer"}} onClick={(e) => {
                                                            e.preventDefault();

                                                            swal({
                                                                title: "Are you sure?",
                                                                text: `Are you sure to delete workout !`,
                                                                icon: "warning",
                                                                dangerMode: true,
                                                                buttons: ["cancel", "ok"],
                                                              }).then((willDelete) => {
                                                                if (willDelete) {
                                                                    DeleteWorkout(ExerciseData?._id,"morning",session?._id)
                                                                } else {
                                                                }
                                                              });
                                                        }}/>
                                                    </div>
                                                </>
                                            );
                                        })}
                                    </div>
                                </Tab>)}
                            {ExerciseData?.afternoon?.length > 0 && (

                                <Tab eventKey="afternoon" title="Afternoon">

                                    <div className="session-card-content" style={{gap:"10px"}}>


                                        {ExerciseData?.afternoon?.map((session, index) => {
                                            const afternoonIndex = index % Object.keys(sessionClassMap).length;
                                            const altClass = Object.keys(sessionClassMap)[afternoonIndex];
                                            return (

                                                <>
                                                                              <div className={ ` session-card ${sessionClassMap[altClass]}`}>
                                                        <div className={`session-card ${sessionClassMap[altClass]}`} key={index} style={{ cursor: "pointer",boxShadow:"none" ,padding:"0px"}} onClick={(e) => {
                                                            e.preventDefault();
                                                            navigate("/exerciseEdit", { state: { patient_id: location?.state?.patient_id, session: "afternoon", bodyPart: session?.name, workout: session, workoutItemId: session?._id, workoutId: ExerciseData?._id } })
                                                        }}>
                                                            
                                                            <div className="details mt-3">
                                                                <h6 className="session-title">{session?.name?.replace("Workout Session", "")}</h6>
                                                                <p className="workout-text">Workout Session</p>

                                                            </div>
                                                            <div className="image-block">
                                                            <img src={session?.image ? session?.image : defaultSVG} height={70} width={70} style={{borderRadius:"55%"}} alt="session-img" />
                                                            </div>
                                                        </div>

                                                        <img src={RemoveIcon} height={30} style={{cursor:"pointer"}} onClick={(e) => {
                                                            e.preventDefault();

                                                            swal({
                                                                title: "Are you sure?",
                                                                text: `Are you sure to delete workout !`,
                                                                icon: "warning",
                                                                dangerMode: true,
                                                                buttons: ["cancel", "ok"],
                                                              }).then((willDelete) => {
                                                                if (willDelete) {
                                                                    DeleteWorkout(ExerciseData?._id,"afternoon",session?._id)
                                                                } else {
                                                                }
                                                              });
                                                        }}/>                                                    </div>
                                                </>

                                            );
                                        })}
                                    </div>
                                </Tab>)}

                            {ExerciseData?.evening?.length > 0 && (
                                <Tab eventKey="evening" title="Evening">

                                    <div className="session-card-content" style={{gap:"10px"}}>

                                        {ExerciseData?.evening?.map((session, index) => {
                                            const eveningIndex = index % Object.keys(sessionClassMap).length;
                                            const altClass = Object.keys(sessionClassMap)[eveningIndex];
                                            return (

                                                <>
                                                                      <div className={ ` session-card ${sessionClassMap[altClass]}`}>
                                                        <div className={`session-card ${sessionClassMap[altClass]}`} key={index} style={{ cursor: "pointer",boxShadow:"none" ,padding:"0px"}} onClick={(e) => {
                                                            e.preventDefault();
                                                            navigate("/exerciseEdit", { state: { patient_id: location?.state?.patient_id, session: "evening",bodyPart: session?.name, workout: session, workoutItemId: session?._id, workoutId: ExerciseData?._id } })
                                                        }}>
                                                            <div className="details mt-3">
                                                                <h6 className="session-title">{session?.name?.replace("Workout Session", "")}</h6>
                                                                <p className="workout-text">Workout Session</p>

                                                            </div>
                                                            <div className="image-block">
                                                            <img src={session?.image ? session?.image : defaultSVG} height={70} width={70} style={{borderRadius:"55%"}} alt="session-img" />
                                                            </div>
                                                        </div>

                                                        <img src={RemoveIcon} height={30} style={{cursor:"pointer"}} onClick={(e) => {
                                                            e.preventDefault();
                                                            swal({
                                                                title: "Are you sure?",
                                                                text: `Are you sure to delete workout !`,
                                                                icon: "warning",
                                                                dangerMode: true,
                                                                buttons: ["cancel", "ok"],
                                                              }).then((willDelete) => {
                                                                if (willDelete) {
                                                                    DeleteWorkout(ExerciseData?._id,"evening",session?._id)
                                                                } else {
                                                                }
                                                              });
                                                        }}/>                                                    </div>
                                                </>

                                            );
                                        })}
                                    </div>
                                </Tab>
                            )}

                            {ExerciseData?.night?.length > 0 && (
                                <Tab eventKey="night" title="Night">

                                    <div className="session-card-content" style={{gap:"10px"}}>

                                        {ExerciseData?.night?.map((session, index) => {
                                            const nightiNDE = index % Object.keys(sessionClassMap).length;
                                            const altClass = Object.keys(sessionClassMap)[nightiNDE];
                                            return (

                                                <>
                                                                              <div className={ ` session-card ${sessionClassMap[altClass]}`}>
                                                        <div className={`session-card ${sessionClassMap[altClass]}`} key={index} style={{ cursor: "pointer",boxShadow:"none" ,padding:"0px"}} onClick={(e) => {
                                                            e.preventDefault();
                                                            navigate("/exerciseEdit", { state: { patient_id: location?.state?.patient_id, session: "night", bodyPart: session?.name, workout: session, workoutItemId: session?._id, workoutId: ExerciseData?._id} })
                                                        }}>
                                                            <div className="details mt-3">
                                                                <h6 className="session-title">{session?.name?.replace("Workout Session", "")}</h6>
                                                                <p className="workout-text">Workout Session</p>

                                                            </div>
                                                            <div className="image-block">
                                                            <img src={session?.image ? session?.image : defaultSVG} height={70} width={70} style={{borderRadius:"55%"}} alt="session-img" />
                                                            </div>
                                                        </div>

                                                        <img src={RemoveIcon} height={30} style={{cursor:"pointer"}} onClick={(e) => {
                                                            e.preventDefault();
                                                            swal({
                                                                title: "Are you sure?",
                                                                text: `Are you sure to delete workout !`,
                                                                icon: "warning",
                                                                dangerMode: true,
                                                                buttons: ["cancel", "ok"],
                                                              }).then((willDelete) => {
                                                                if (willDelete) {
                                                                    DeleteWorkout(ExerciseData?._id,"night",session?._id)
                                                                } else {
                                                                }
                                                              });
                                                        }}/>                                                    </div>
                                                </>

                                            );
                                        })}
                                    </div>
                                </Tab>
                            )}
                                  
                        </Tabs></> ) }
                    </div>

                    {ExerciseData?.note &&
                        <div className='note-part mt-5'>
                            <h6>Note</h6>
                            <p>{ExerciseData?.note}</p>
                        </div>}


                        {!loading &&
                    <div className='button-part mt-5'>
                        {/* <Link to={"/patientpoints"} state={{ patient_id: location?.state?.patient_id ,patientDetail:location?.state?.patientDetail}} ><Button className="theme-outline-btn mb-3">Change Exercise</Button></Link> */}
                        <Link to={"/patientpoints"} state={{ patient_id: location?.state?.patient_id, patientDetail:location?.state?.patientDetail}} >   <Button className="theme-button mb-3" >Add New Workout</Button></Link>
                        <Link to={"/patientpoints"} state={{ patient_id: location?.state?.patient_id, patientDetail:location?.state?.patientDetail , isRenew:1}} >   <Button className="theme-button" >Renew Workout</Button></Link>
                        
                    </div>}
                </div>

            </Layout>


            <Modal show={showNote} onHide={handleCloseNote} centered size="lg">
                <Modal.Header closeButton>
                    <Modal.Title className='text-center'>Add Note</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className='body-content'>
                        <p className='body-title'>Information Note</p>
                        <div className="mb-3">
                            <textarea className="form-control" id="exampleFormControlTextarea1" rows="5" placeholder='Write text…' value={ExerciseData?.note} // Bind the value to the note state
                                onChange={(e) => {

                                    setExrerciseeData((p) => ({ ...p, note: e.target.value }))
                                }}
                            ></textarea>
                        </div>
                        <p className='body-title'>Add Reports</p>
                        <div className='upload-part'>
                            <div className="form-group file-area">
                                <input type="file" name="images" id="images" required="required" multiple="multiple" />
                                <div className="file-dummy">
                                    <div className="success">Great, your files are selected. Keep on.</div>
                                    <div className="default"><div className='image-block mb-2'>
                                        <img src={UploadIcon} alt='icon' />
                                    </div>Add report here (png, jpg, pdf)</div>
                                </div>
                            </div>

                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button className='theme-button' onClick={(e) => {
                        e.preventDefault();
                        saveWorkout()


                    }}>
                        Done
                    </Button>
                </Modal.Footer>
            </Modal>

            <Modal
                show={completeWorkout}
                onHide={() => setCompleteWorkout(false)}
                size="md"
                className="complete-modal"
            >
                <Modal.Body>
                    <div className="top-heading text-end">
                        <button
                            className="btn btn-transpatent"
                            onClick={() => setCompleteWorkout(false)}
                        >
                            X
                        </button>
                    </div>
                    <div className="content-block text-center">
                <Animation />
            </div>
                    <div className="bottom-block text-center">
                        <div className="workout-desc body-content" style={{
                            "display": "flex",
                            "align-items": "center",
                            "justify-content": "center",
                            "flex-direction": "column",
                            "padding-top": "25px"
                        }}>
                            <p>Exercise Assigned successfully</p>


                            <button className="theme-outline-btn mb-3 btn btn-primary" onClick={(e) => {
                                e.preventDefault();
                                navigate("/patientpoints")
                            }}>Add More + </button>
                            <button className="theme-button btn btn-primary" style={{ minWidth: "300px", padding: "14px" }} onClick={(e) => {
                                e.preventDefault();
                                setAddMore(true)
                                //   navigate("/difficult", { state: { workout: location?.state?.workout, session: location?.state?.session } })
                            }}>Done</button>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>

            <Modal
                show={Addmore}
                onHide={() => setAddMore(false)}
                size="md"
                className="complete-modal"
            >
                <Modal.Body>
                    <div className="top-heading text-end">
                        <button
                            className="btn btn-transpatent"
                            onClick={() => setAddMore(false)}
                        >
                            X
                        </button>
                    </div>
                    <div className="content-block text-center">
                        <img src={addMore} height={300} width={350} alt="modal-video" />
                    </div>
                    <div className="bottom-block text-center">
                        <div className="workout-desc body-content" style={{
                            "display": "flex",
                            "align-items": "center",
                            "justify-content": "center",
                            "flex-direction": "column",
                            "padding-top": "25px"
                        }}>
                            <p>Exercise Assigned To <br /> { } </p>
                            <span className="modal-text">
                                Email:
                            </span>

                            <span className="modal-text">
                                Mobile Number:
                            </span>


                            <button className="theme-outline-btn mb-3 btn btn-primary" onClick={(e) => {
                                e.preventDefault();
                                // navigate("/points")
                            }}>Share </button>
                            <button className="theme-button btn btn-primary" style={{ minWidth: "300px", padding: "14px" }} onClick={(e) => {
                                e.preventDefault();
                                navigate("/dashboard")
                            }}>Done</button>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    )
}

export default WorkoutList
