import React, { useState } from "react";
import { Modal, Button, Form } from "react-bootstrap";

const CurrentPasswordModal = ({
  show,
  onHide,
  onConfirm,
  errorMessage,
  successMessage,
}) => {
  const [currentPassword, setCurrentPassword] = useState("");

  const handleConfirm = () => {
    if (currentPassword.trim() === "") {
      // Handle empty password error
      alert("Please enter your current password.");
    } else {
      // Proceed with the confirmation
      onConfirm(currentPassword);
    }
  };

  return (
    <Modal show={show} onHide={onHide}>
      <Modal.Header closeButton>
        <Modal.Title>Enter Current Password</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form.Group controlId="formPassword">
          <Form.Label>Current Password</Form.Label>
          <Form.Control
            type="password"
            placeholder="Enter your current password"
            value={currentPassword}
            onChange={(e) => setCurrentPassword(e.target.value)}
          />
        </Form.Group>
        {errorMessage && (
          <div className="text-danger mt-2 ">{errorMessage}</div>
        )}
        {successMessage && (
          <div className="text-success mt-2">{successMessage}</div>
        )}
      </Modal.Body>
      <Modal.Footer>
        <div className="d-flex justify-content-end w-100">
          <button
            className="theme-buttonOuteline btn btn-primary me-2 "
            style={{ minWidth: "150px", padding: "10px" }}
            onClick={onHide}
          >
            Cancel
          </button>
          <button
            className="theme-button btn btn-primary"
            style={{ minWidth: "150px", padding: "10px" }}
            onClick={handleConfirm}
          >
            Confirm
          </button>
        </div>
      </Modal.Footer>
    </Modal>
  );
};

export default CurrentPasswordModal;
